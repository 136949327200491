import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import moment from 'moment';
import { Loading } from 'Components';
import _ from 'lodash';

//actions
import {
    catalogInitial,
    debugUnitGetUnitData,
    clearRedux
} from 'Redux/actions';

import './styles.scss';

const DebugUnitView = (props) => {
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const { id } = useParams();
    const response = useSelector(state => state.debugUnitRedux.response);
    const loading = useSelector(state => state.debugUnitRedux.loading);
    const error = useSelector(state => state.debugUnitRedux.error);

    const loadingUnits = useSelector(state => state.catalogsRedux.loadingUnits);
    const loadingReadings = useSelector(state => state.catalogsRedux.loadingReadings);
    const units = useSelector(state => state.catalogsRedux.units);
    const readings = useSelector(state => state.catalogsRedux.readings);

    useEffect(() => {
        dispatch(catalogInitial());
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(debugUnitGetUnitData({ id }));
        }
    }, [id]);

    const unit = units?.find(u => u?.id == id);
    const device = readings?.find(u => u?.deviceId == id);

    console.log("unit by id " + id, unit);
    console.log("reading by id " + id, device);
    console.log("response", response);

    const getFormattedValue = (value, field) => {
        if (!value) {
            return "";
        }
        
        if (["unitTime", "serverTime", "LastMessageTime"].includes(field)) {
            return !value ? "" : `${value} (${moment(value * 1).format("MMM DD YYYY, h:mm:ss a")})}`;
        } else {
            return value;
        }
    }

    const getUnitFieldValue = (field) => {
        let value = device ? device[field] : null;
        if (!value && unit) {
            value = unit[field];
        } 

        if (!value) {
            return "";
        }

        if (_.isObject(value)) {
            return value?.formatted || value?.address || JSON.stringify(value);
        }
        
        return getFormattedValue(value, field);
    }

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <div className='table-container'>
                {
                    (loading || loadingUnits || loadingReadings) ?
                        <Loading full /> :
                        <table className='debug-unit-table'>
                            <caption>Unit id: {id}</caption>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Redux/Units/Readings</th>
                                    <th>MySQL</th>
                                    <th>Memcached</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    response?.attributes?.length && response?.attributes?.map((field, key) => {
                                        return (
                                            <tr key={key}>
                                                <td>{field}</td>
                                                <td>{getUnitFieldValue(field)}</td>
                                                <td>{response?.mysql ? getFormattedValue(response?.mysql[field], field) : ""}</td>
                                                <td>{response?.memcached ? getFormattedValue(response?.memcached[field], field) : ""}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                }
            </div>
        </div>
    );
};

export default DebugUnitView;
