import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {clientQuery} from "Core/data/GraphQLApi";
import {endpoints} from "Core/defaultValues";
import {ExceptionManager} from "Core/logManager"

//STATE INITIAL
const INIT_STATE = {
    loadingSearchReportLog: false,
    reportsLogs: [],
    totalReportsLogs: 0,
    error: null,
    loadingReportParams: false,
    reportParams: null,
    errorReportParams: null
};

const route = "modules/reports/views/main/components/reportsTab/redux/slice";

export const searchReportLog = createAsyncThunk("modules/reports/views/main/components/reportsTab/searchReportLog", async (payload, {rejectWithValue}) => {
        try {
            const {category = 'recents', openSearch = "", limit = 30, offset = 1} = payload;
            let response = await clientQuery(`
              query searchReportLog ($openSearch: String, $limit: Int, $offset: Int, $category: CategoryReport, $endDate: AWSDateTime) {
                reports: searchReportLog (openSearch: $openSearch, limit: $limit, offset: $offset, category: $category, endDate: $endDate) {
                    items {
                        id
                        reportName
                        reportType
                        format
                        outputUrl
                        reportStatus
                        sendByEmail
                        scheduled
                        createdOn
                        completedOn
                        lastProcessUpdate
                        dataResultPath
                        reportLogId
                        advancedFilter
                        viewBy
                        runForIsAll
                        runForCount
                        runForType
                        dateRangeName
                        dateRangeStart
                        dateRangeEnd
                        reportTimeRanges {
                          day
                          starTime
                          endTime
                        }
                        includeInactiveDrivers
                        excludeEmptyTables
                        topEntities
                    }
                    total
                    offset
                }
              }
              `, {
                    openSearch,
                    limit,
                    offset,
                    category,
                    endDate: new Date().toISOString()
                },
                endpoints.GRAPHQL_PX_REPORTING
            );
            return {...response.reports, offset, category};
        } catch (exc) {
            ExceptionManager(exc, route, 'searchReportLog');
            return rejectWithValue(exc);
        }
    }
)

export const searchReportParams = createAsyncThunk( "modules/reports/views/main/components/reportsTa/getReportParams" , async ( { id , createdOn } , { rejectWithValue } ) => {
        try {
            let response = await clientQuery(`
              query getReportParams ($id: String!, $createdOn: String!) {
                data: getReportParams(id: $id, createdOn: $createdOn) {
                    params
                }
              }
          `, {
                    id,
                    createdOn: new Date(createdOn).toISOString() // Asegúrate de que createdOn sea de tipo AWSDateTime
                },
                endpoints.GRAPHQL_PX_REPORTING
            );
            let UIParams = JSON.parse(response.data.params)
            if (typeof UIParams === 'string') {
                try {
                    UIParams = JSON.parse(UIParams)
                } catch (e) {
                }
            }
            return UIParams;
        } catch (exc) {
            ExceptionManager(exc, route, 'reportsFilterHideReportFilter');
            return rejectWithValue(exc);
        }
    }
);

export const reportsTapSearchReportLogRedux = createSlice(
    {
        name: 'reportsTapRT',
        initialState: INIT_STATE,
        reducers: {
            resetReportLog(state) {
                return INIT_STATE;
            },
            resetReportParams(state) {
                return {...state, reportParams: null, errorReportParams: null, loadingReportParams: false};
            }
        },

        extraReducers: (builder) => {
            builder
                .addCase(searchReportLog.pending, (state, action) => {
                    state.loadingSearchReportLog = true;
                    state.reportsLogs = action.meta.arg.offset <= 1 ? [] : state.reportsLogs
                    state.totalReportsLogs = action.meta.arg.offset <= 1 ? 0 : state.totalReportsLogs
                    state.error = null;
                })
                .addCase(searchReportLog.fulfilled, (state, action) => {
                    state.loadingSearchReportLog = false;
                    state.reportsLogs = action.payload.offset > 1 ? [...(state.reportsLogs || []), ...action.payload.items] : action.payload.items;
                    state.totalReportsLogs = action.payload.total;
                    state.error = null;
                })
                .addCase(searchReportLog.rejected, (state, action) => {
                    state.loadingSearchReportLog = false;
                    state.error = action.payload;
                })

                .addCase(searchReportParams.pending, (state) => {
                    state.loadingReportParams = true;
                    state.errorReportParams = null;
                })
                .addCase(searchReportParams.fulfilled, (state, action) => {
                    state.loadingReportParams = false;
                    state.reportParams = action.payload;
                    state.errorReportParams = null;
                })
                .addCase(searchReportParams.rejected, (state, action) => {
                    state.loadingReportParams = false;
                    state.errorReportParams = action.payload;
                });
        }
    })

const {actions, reducer} = reportsTapSearchReportLogRedux;

// Extract and export each action creator by name
export const {
    resetReportLog,
    resetReportParams
} = actions;

export default reducer;
