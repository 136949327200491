export default {
    alertsTabView_Select: "Select",
    alertsTabView_FilterBy: "Filter By",
    alertsTabView_FilterLabel: "Filter",
    alertsTabView_Unit: "Unit",
    alertsTabView_Driver: "Driver",
    alertsTabView_label: "Label",
    alertsTabView_addButtonLabel: "New Alert",
    alertsTabView_action: "Action",
    alertsTabView_subscribers: "Subscribers",
    alertsTabView_summary: "Summary",
    alertsTabView_severity: "Severity",
    alertsTabView_deleteAlertTitle: "Delete Alerts",
    alertsTabView_deleteAlertContent: "Are you sure you want to delete the alert(s)",
    alertsTabView_deleteAlertDelete: "Delete",
    alertsTabView_deleteAlertCancel: "Cancel",
    alertsTabView_deleteAlertSubscriberTitle: "Warning!",
    alertsTabView_deleteAlertSubscriberContent: "Are you sure you want to delete the alert subscriber?",
    alertsTabView_deleteAlertSubscriberDelete: "Delete",
    alertsTabView_deleteAlertSubscriberCancel: "Cancel",
    alertsSideMenu_buttonEdit: "Edit",
    alertsSideMenu_buttonCancel: "Cancel",
    alertsSideMenu_subscribersTab: "Subscribers",
    alertsTabView_Edit_Title: "Edit",
    alertsFiltersMenu_menuTitle: "Advanced filters",
    alertsFiltersMenu_typeAlertFilter: "Alert Type",
    alertsFiltersMenu_filterAll: "All",
    alertsFiltersMenu_clearFilters: "Clear filters",
    alertsSideMenu_buttonClose: "Close",
    alertsTabView_stoppedReporting: "Stopped Reporting",
    alertsTabView_vehicleCongregation: "Vehicle Congregation",
    alertsTabView_driverBehavior: "Driver Behavior",
    alertsTabView_consistentSpeed: "Consistent Speed",
    alertsTabView_lightDutyDTC: "Light Duty Diagnostics",
    alertsTabView_offHour: "Off Hour",
    alertsTabView_heavyDutyDTC: "Heavy Duty Diagnostics",
    alertsTabView_fuelCard: "Fuel Card",
    alertsTabView_onEvent: "On Event",
    alertsTabView_idle: "Idle",
    alertsTabView_speed: "Speed",
    alertsTabView_unassignedDriver: "Unassigned Driver",
    alertsTabView_smartOneCLowBattery: "SmartOneC Low Battery",
    alertsTabView_OBDII: "Light Duty (OBDII)",
    alertsTabView_totalDriveTime: "Total Drive Time",
    alertsTabView_ConsecutiveDriving: "Consecutive Driving",
    alertsTabView_successSubscriberDeletion: "The subscriber has been removed",
    alertsTabView_failedSubscriberDeletion: "An error occurred deleting the subscriber",
    alertsTabView_successAlertDeletion: "Alerts have been removed successfully",
    alertsTabView_failedAlertDeletion: "An error occurred deleting alerts",
    alertsTabView_cameraEvent: "Camera Event",
    alertsTabView_lowBattery: "Low Battery",
    alertsTabView_installSensor: "Install Sensor",
    alertsTabView_userUpdate: "User Update",
    alertsTabView_tamperSensor: "Tamper Sensor",
    alertsTabView_lightSensor: "Light Sensor",
    alertsTabView_people : "people",
    alertsTabView_search: "search",
    alertsTabView_assigned: "Assigned",
    alertsTabView_assignedUnits: "Assigned Units",
    alertsTabView_assignedDrivers: "Assigned Drivers",
    alertsTabView_driver: "driver",
    alertsTabView_drivers: "drivers",
    alertsTabView_unit: "unit",
    alertsTabView_units: "units",
    alertsTabView_tags: "tags",
    alertsTabView_tag: "tag",
    alertsTabView_noSubscribersFound: "There are not subscribers",
    alertsTabView_subscriber: "subscriber",
    alertsTabView_allUnitsSelected: "All units",
    alertsTabView_all: "All",
    alertsTabView_allDriversSelected: "All drivers",
    alertsTabView_allContacts: "All contacts",
    alertsTabView_currentDriver: "Current driver",
    alertsTabView_contacts: "contacts",
    alertsTabView_contact: "contact",
    alertsTabView_noname: "No name",
    alertsTabView_fsmMealBreak: "Break Meal",
    alertsTabView_route_watchers: "Routes Watchers",
    alertsTabView_fuelLevel: "Fuel Changes",
};
