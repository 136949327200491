export default {
  unit_settings_tab: "Ajustes",
  unit_settings_groups: "Etiquetas",
  unit_settings_label_and_icon: "Etiqueta de unidad y tipo de icono",
  unit_settings_unit: "Unidad",
  unit_settings_licenseplate: "Matrícula",
  unit_settings_speed: "Velocidad",
  unit_settings_calculated_by_ecu: "Calculado por ECU",
	unit_settings_calculated_by_gps: "Calculado por GPS",
  unit_settings_odometer: "Odometro",
  unit_settings_odometer_dash_reading: "Lectura de Tablero",
  unit_settings_last_updated: "Ultima Actualización",
  unit_settings_engine_hours: "Horas de Motor",
  unit_settings_actual: "Actual",
  unit_settings_consistent_speed: "Límite de Velocidad",
  unit_settings_driver: "Conductor",
  unit_settings_driver_assigned: "Conductor Asignado",
  unit_settings_driver_default: "Conductor Predeterminado",
  unit_settings_driver_default_info: `El conductor principal será asignado automáticamente como "Conductor asignado" cuando el vehículo tenga movimiento sin un conductor asignado. La asignación del conductor se realizará según el valor definido en el campo "Conductor Predeterminado".`,
  unit_settings_location: "Ubicación",
  unit_settings_time_zone: "Zona Horaria",
  unit_settings_data: "Datos",
  unit_settings_input_labels: "Etiquetas de Entrada",
  unit_settings_output_labels: "Etiquetas de Salida",
  unit_settings_notes: "Notas",
  unit_settings_make: "Marca",
  unit_settings_model: "Modelo",
  unit_settings_year: "Año",
  unit_settings_date_validation_message: "Por favor ingrese un año valido de 4 dígitos,entre 1950 a",
  unit_settings_engine_liters: "Litros Motor",
  unit_settings_engine_liters_validation_message: "Puedes tener maximo 10000 litros de motor",
  unit_settings_input_label: "Etiqueta de Entrada",
  unit_settings_output_label: "Etiqueta de Salida",
  unit_settings_type: "Tipo",
  unit_settings_highway: "Autopista",
  unit_settings_city: "Ciudad",
  unit_settings_groups_units_description: "Las unidades seleccionadas pertenecerán a los siguientes grupos:",
  unit_settings_tags_units_description: "Las unidades seleccionadas pertenecerán a las siguientes etiquetas:",
  unit_settings_edit_multiple_units: "Configuración masiva",
  unit_settings_units: "Unidades",
  unit_settings_add_or_delete_groups_units: "Agregar o eliminar grupos",
  unit_settings_update_groups: "Se actualizaron los grupos existosamente.",
  unit_settings_tags: "Agregar una Etiqueta",
  unit_settings_tags_simple: "Etiquetas",
  unit_settings_tags_label: "Etiqueta del punto de referencia",
  unit_settings_tags_search: "Buscar etiquetas disponibles",
  unit_settings_tags_type: "Crear usando",
  unit_settings_tags_shape: "Forma de hito",
  unit_settings_tags_required: "Debe seleccionar al menos una etiqueta",
  unit_settings_custom: "Adaptar",
  unit_settings_alert_contacts_required: "Debe seleccionar al menos un contacto",
  unit_settings_alert_tags_required: "Debe seleccionar al menos una etiqueta",
  unit_settings_modal_icon_type: "Seleccione un tipo de icono para esta unidad:",
}