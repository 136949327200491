export default {
    accountApiKeys_name: 'Nombre',
    accountApiKeys_nameLabel: 'Nombre / Etiqueta',
    accountApiKeys_apiKey: 'API Key ',
    accountApiKeys_assignedToUser: 'Asignado al usuario',
    accountApiKeys_createTitle: 'Nueva API Key',
    accountApiKeys_editTitle: 'Editar',
    accountApiKeys_minLengthMessage: "El nombre debe contener un mínimo de caracteres ",
    accountApiKeys_maxLengthMessage: "El nombre debe contener un máximo de caracteres: ",
    accountApiKeys_deleteTitle: "Eliminar API keys",
    accountApiKeys_deleteWarning: "¿Está seguro que desea eliminar las siguientes API keys?",
    accountApiKeys_success: "API key guardada",
    accountApiKeys_deleted: "API keys eliminadas con éxito",
    accountApiKeys_user_assignedToUser: "Asignado al usuario",
    accountApiKeys_user_select: "Seleccionar Usuario",
    accountApiKeys_user_select_place: "Seleccionar", 
};