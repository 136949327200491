// Dependencies
import { all, call, takeEvery, fork, put } from "@redux-saga/core/effects";

// Action Types
import {
  CLIP_REQUEST_GET_UNITS,
  CLIP_REQUEST_CREATE,
  CLIP_REQUEST_SEARCH,
  VIDEO_RESTORE_CLIP_REQUEST,
  CLIP_AVAILABLE_SEARCH,
  CLIP_REQUEST_REFRESH_CLIP
} from 'Redux/actionTypes';

// Actions
import {
  clipRequestGetUnitsResult,
  createClipRequestResponse,
  searchClipRequeststResponse,
  searchAvailableClipstResponse,
  videoRestoreClipRequestResponse,
  refreshClipResponse
} from 'Redux/actions';

// Helpers
import { clientQuery, clientMutation } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager } from 'Core/logManager';

function* searchVideoDevices() {
  const query =
    `query searchVideoDevices ($openSearch: String, $limit: Int, $offset: Int) {
    searchVideoDevices (openSearch: $openSearch, limit: $limit, offset: $offset) {
      items {
        unit {
          label
        }
        linkedDevice {
          id
          hasVideo
          esn
          deviceModelName
          manufacturerName
          simProviderName
          liveMode
          firmware
          firmwareId
        }
      }
      total
    }
}`
  const variables = { openSearch: '', limit: 10000, offset: 0 };

  try {
    const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      yield put(clipRequestGetUnitsResult(data.searchVideoDevices.items));
    }
  } catch (exc) {
    yield put(clipRequestGetUnitsResult([]));
    ExceptionManager(
      exc,
      'modules/video/views/clipRequestTab/redux/sagas',
      'searchVideoDevices',
    );
  }
}

function* createClipRequest({ payload }) {
  const mutation =
    `mutation createClipRequest (
      $dateRange: AWSDateTimeRange!,
      $deviceId: String!,
      $thumbnailUrl: String, 
      $transactionId: String, 
      $tripID: String, 
      $mp4Url: String, 
      $rotate: String,
      $clipID: String
    ) {
    createClipRequest (
      deviceId: $deviceId, 
      dateRange: $dateRange,
      thumbnailUrl: $thumbnailUrl, 
      transactionId: $transactionId, 
      tripID: $tripID, 
      mp4Url: $mp4Url, 
      rotate: $rotate,
      clipID: $clipID
    ) {
      id
      accountId
      createdOn
      deviceId
      label
      status
      time
      tripID
      clipID
      rotate
      url
      startDate
      endDate
      notes
      starred
      errorMsg
      deviceModelId
      deviceModelName
      unitId
      unit {
        id
        label
      }
    }
}`

  try {
    const data = yield call(clientMutation, mutation, payload, endpoints.GRAPHQL_GENERAL);

    if (data) {
      yield put(createClipRequestResponse(data.createClipRequest));
    } else {
      throw 'Clip has not been created';
    }

  } catch (exc) {
    if (!payload?.hideNotification) {
      ExceptionManager(
        exc,
        'modules/video/views/clipRequestTab/redux/sagas',
        'createClipRequest',
      );
    }

    let message = "";
    let errorCode = 0;
    if (exc.errorData && exc.errorData.errors) {
			exc.errorData.errors.forEach(error => {
				if (error.errorInfo && error.errorInfo.code) {
          errorCode = error.errorInfo.code;

          switch (error.errorInfo.code) {
            case 1011:
              message = "infoBubble_cameraNotFound";
              break;

            case 1001:
              message = "infoBubble_requestRangeTimeError";
              break;

            default: 
              message = "errorMessage";
              break;
          }
          
        }
      });
    }

    yield put(createClipRequestResponse({ error: true, message: message, errorCode: errorCode }));
  }
}


function* restoreClipRequest({ payload }) {
  let mutations = [];
  try {
    const { data: body } = payload
    body.forEach((clip, index) => {
      mutations.push(`
      Restore${index}: restoreClipRequest(
        id: "${clip.id}"
      )`);
    })
    let mutation = `mutation { ${mutations.join("")} }`;
    let data = yield call(
      clientMutation,
      mutation,
      {},
      endpoints.GRAPHQL_GENERAL,
    );
    if (data && data.Restore0) {
      yield put(videoRestoreClipRequestResponse(data.Restore0))
    } else {
      yield put(videoRestoreClipRequestResponse({ error: true }))
    }

  } catch (exc) {
    yield put(videoRestoreClipRequestResponse({ error: true }))
    ExceptionManager(
      exc,
      'modules/video/views/clipRequestTab/redux/sagas',
      'createClipRequest',
    );
  }
}

function* searchClipRequests({ payload }) {
  const {
    limit,
    dateRange,
    resetSearch,
    offset,
    entities,
    hasStarred,
    hasNotes,
    isDeleted,
    status,
    openSearch
  } = payload;
  const query = `
    query searchClipRequests ($openSearch: String, $entities: [EntityInput]!, $hasNotes: Boolean, $hasStarred: Boolean, $isDeleted: Boolean, $dateRange: AWSDateTimeRange!, $limit: Int, $offset: Int, $status: String) {
      searchClipRequests (openSearch: $openSearch, entities: $entities, hasNotes: $hasNotes, hasStarred: $hasStarred, isDeleted: $isDeleted, dateRange: $dateRange, limit: $limit, offset: $offset, status: $status) {
          items {
              id
              accountId
              createdOn
              deviceId
              esn
              label
              status
              time
              tripID
              clipID
              rotate
              url
              startDate
              endDate
              notes
              starred
              errorMsg
              deviceModelId
              deviceModelName
              driverName
              driverExternalId
              thumbnailUrl
              transactionId
              details
              isDeleted
              createdBy
              createdByUser {
                  authId
                  username
              }
          }
          total
      }
    }
  `
  const variables = {
    dateRange,
    offset,
    limit,
    entities: !entities || !entities?.length || entities.find(e => e.id == -1) ? [{ name: 'All', id: -1 }] : entities,
    isDeleted: isDeleted || false,
    hasStarred: hasStarred || false,
    hasNotes: hasNotes || false,
    status: status || 'all',
    openSearch
  };


  try {
    const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL);
    if (data) {
      const { searchClipRequests: { items, total } } = data;
      yield put(searchClipRequeststResponse({ items, total, resetSearch, error: false, openSearch }));
    } else {
      throw 'Error while searching clips video module';
    }
  } catch (exc) {
    yield put(searchClipRequeststResponse({ items: [], resetSearch: true, error: true }));
    ExceptionManager(
      exc,
      'modules/video/views/clipRequestTab/redux/sagas',
      'searchClipRequests',
    );
  }
}

function* searchAvailableClips({ payload }) {
  const {
    esn,
    preloaded
  } = payload;
  const query = `
  query searchAvailableClips ($esn: String!, $preloaded: Boolean) {
    searchAvailableClips (esn: $esn, preloaded: $preloaded) {
        items {
            esn
            validTimeRangeSeq
            updatedOn
            requestedOn
        }
    }
  }`
  const variables = {
    esn,
    preloaded
  };

  try {
    const data = yield call(clientQuery, query, variables, endpoints.GRAPHQL_GENERAL_V2);
    if (data) {
      const { searchAvailableClips: { items } } = data;
      yield put(searchAvailableClipstResponse({ items }));
    } else {
      throw 'Error in search Available clips module';
    }
  } catch (exc) {
    yield put(searchAvailableClipstResponse({ items: [], error: true }));
    ExceptionManager(
      exc,
      'modules/video/views/clipRequestTab/redux/sagas',
      'searchAvailableClips',
    );
  }
}

function* getRefreshClip({ payload }) {
  const { id } = payload;
  const query = `query ($id: String!) {
    clip: getClipRequest (id: $id) 
    {
      id
      accountId
      createdOn
      deviceId
      esn
      label
      status
      time
      tripID
      clipID
      rotate
      url
      startDate
      endDate
      notes
      starred
      errorMsg
      deviceModelId
      deviceModelName
      driverName
      driverExternalId
      thumbnailUrl
      transactionId
      details
      isDeleted
      createdBy
      createdByUser {
          authId
          username
      }
    }
  }`

  try {
    const data = yield call(clientQuery, query, { id }, endpoints.GRAPHQL_GENERAL);
    if (data) {
      const item = data?.clip;
      yield put(refreshClipResponse({ item }));
    } else {
      throw 'Error while searching clips video module';
    }
  } catch (exc) {
    yield put(refreshClipResponse({ }));
    ExceptionManager(
      exc,
      'modules/video/views/clipRequestTab/redux/sagas',
      'refreshClipResponse',
    );
  }
}


function* watchMethods() {
  yield takeEvery(CLIP_REQUEST_GET_UNITS, searchVideoDevices);
  yield takeEvery(CLIP_REQUEST_CREATE, createClipRequest);
  yield takeEvery(CLIP_REQUEST_SEARCH, searchClipRequests);
  yield takeEvery(CLIP_AVAILABLE_SEARCH, searchAvailableClips);
  yield takeEvery(CLIP_REQUEST_REFRESH_CLIP, getRefreshClip)
  yield takeEvery(VIDEO_RESTORE_CLIP_REQUEST, restoreClipRequest)
}

export default function* rootSaga() {
  yield all([fork(watchMethods)]);
}
