//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientGraphQLAuthMutation } from 'Core/data/GraphQLApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { getLegacyUrl } from 'Core/data/Helpers';
//Action types
import {
    MIGRATE_USER_SEND,
    MIGRATE_USER_CONFIRM
} from 'Redux/actionTypes';

//actions
import {
    login,
    updateTermsAndCondition,
    migrateUserResult,
    migrateUserConfirmResult,
    clearRedux
} from 'Redux/actions';

import {
  setCookie
} from 'Core/views/login/helper';
/* FUNTIONS ASYNC */
function* migrateUserRequest({ payload }) {
    try {
        const { username, email, password } = payload

        let data = yield call(
            clientGraphQLAuthMutation,
            `
                mutation ($username: String!, $email: String!, $password: String!){
                    result: migrateUser (
                        username: $username
                        email: $email
                        password: $password
                    ){
                        authenticationResult{
                            AccessToken
                            ExpiresIn
                            IdToken
                            RefreshToken
                        },
                        termAndCondition,
                        emailVerified
                    }
                }
            `,
            {
                username,
                email,
                password
            }
        );

        if (data && data.result && data.result.authenticationResult) {
            const { authenticationResult,termAndCondition } = data.result
            yield put(migrateUserResult({...authenticationResult,termAndCondition,password}));
        } else {
            yield put(migrateUserResult(null, data.errorMessage));
        }
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/migrateUser/redux', 'forgotPasswordRequest')
        yield put(migrateUserResult(null, exc.toString()));

    }
}

function* migrateUserConfirmRequest({ payload }) {
    try {
        const { username, cognitoValues, code, navigate } = payload
        
        let data = yield call(
            clientGraphQLAuthMutation,
            `
                mutation ($accessToken: String!, $code: String!){
                   result: verifyEmail (
                        accessToken: $accessToken
                        code: $code
                    )
                }
            `,
            {
                accessToken: cognitoValues.AccessToken,
                code: code.toString()
            }
        );

        if (data && data.result) {
            if(cognitoValues?.termAndCondition){
                navigate('/');
                yield put(login(username.trim(),cognitoValues?.password, navigate));
                yield put(updateTermsAndCondition(username.trim(),cognitoValues?.password));
            }else{
                localStorage.setItem("AccessToken", cognitoValues.AccessToken)
                localStorage.setItem("RefreshToken", cognitoValues.RefreshToken)
                localStorage.setItem("IdToken", cognitoValues.IdToken)
                setCookie("GPSTSharedOAUTHToken", cognitoValues.IdToken, 1)
                localStorage.setItem("username", username)
                //remove local preferences
                localStorage.removeItem("preferencesUser")
                localStorage.removeItem("preferencesCompany")
                localStorage.removeItem("preferencesOther")
               
                navigate('/');
                yield put(migrateUserConfirmResult(true));
                yield put(clearRedux("MIGRATE_USER")); 
            }
        } else {
            yield put(migrateUserConfirmResult(false, data.errorMessage));
        }
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/migrateUser/redux', 'forgotPasswordConfirmRequest')
        yield put(migrateUserConfirmResult(false, exc.toString()));

    }
}

function* watchMigrateUserRequest() {
    yield takeEvery(MIGRATE_USER_SEND, migrateUserRequest);
}

function* watchMigrateUserConfirmRequest() {
    yield takeEvery(MIGRATE_USER_CONFIRM, migrateUserConfirmRequest);
}

//merge sagas
export default function* rootSaga() {
    yield all([
        fork(watchMigrateUserRequest),
        fork(watchMigrateUserConfirmRequest)
    ]);
}