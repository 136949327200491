import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"


//STATE INITIAL
const INIT_STATE = {
    loading: false,
    open: false,
    columns: [],
    columnsToExport: []
};

//Slice
const modalExportColumnsPdf = createSlice({
    name: 'fleet/modalExportColumnsPdf',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        openModalExportColumnPdf(state, action) {
            state.open = action.payload?.open;
            state.columns = action.payload?.columns;
        },
        onColumnsToExport(state, action) {
            state.columnsToExport = action.payload?.columnsToExport;
        }
    }
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = modalExportColumnsPdf;

// Extract and export each action creator by name
export const { reset, openModalExportColumnPdf, onColumnsToExport } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;