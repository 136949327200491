//Module Keys
let locales = {};

//Merge Views
import mainView from 'Modules/fleet/views/main/lan/es';
import ModalScheduleStarter from 'Modules/fleet/components/ModalScheduleStarter/lan/es';
import ModalEnableDisable from 'Modules/fleet/components/ModalEnableDisable/lan/es';
//import ModalInputs from 'Modules/fleet/components/ModalInputs/lan/es';
import ModalPing from 'Modules/fleet/components/ModalPing/lan/es';
import ModalLandMark from 'Modules/fleet/components/ModalLandMark/lan/es';
import ModalOutputs from 'Modules/fleet/components/ModalOutputs/lan/es';
import ModalBulkOutputs from 'Modules/fleet/components/ModalBulkOutputs/lan/es';
import ModalExportColumnsPdf from 'Modules/fleet/components/modalExportColumnsPdf/lan/es';

locales = { 
  ...locales, 
  ...mainView, 
  ...ModalScheduleStarter, 
  ...ModalEnableDisable, 
  //...ModalInputs, 
  ...ModalPing, 
  ...ModalLandMark,
  ...ModalOutputs,
  ...ModalBulkOutputs,
  ...ModalExportColumnsPdf
};

export default locales;
