export default {
    troubleshootDevice_title: "Troubleshooting",
    troubleshootDevice_isThisDeviceNotReportingCorrectly: "Is this device not reporting correctly?",
    troubleshootDevice_clickStartToBeginTroubleshooting: "Click Start to begin troubleshooting, at any time during the process you can click",
    troubleshootDevice_alternatively: "Alternatively, you can explore the recommended steps in the Help Center",
    troubleshootDevice_connectionHistory: "Connection History",
    troubleshootDevice_location: "Location",
    troubleshootDevice_OdometerEngineHours: "Odometer and Engine Hours",
    troubleshootDevice_noInstallationHistory: "No Installation History",
    troubleshootDevice_recommendedSteps: "We are unable to find any history of the device being installed. If it has been installed, please contact our support team or follow the recommended steps in the Help Center",
    troubleshootDevice_supportChat: "Support Chat",
    troubleshootDevice_installationHistory: "Installation History",
    troubleshootDevice_installedOn: "Installed On",
    troubleshootDevice_confirmingInstallation: "Confirming Installation",
    troubleshootDevice_checkingCellularConnection: "Checking Cellular Connection",
    troubleshootDevice_cellularHistory: "Cellular History",
    troubleshootDevice_lastSession: "Last Session",
    troubleshootDevice_lastStatus: "Status",
    troubleshootDevice_connectivityIssuesMessage: "We've detected that the device SIM has connectivity issues. Please contact support using the chat below or explore the recommended steps in the Help Center",
    troubleshootDevice_validInstallationDate: "We've identified a valid installation date.  The cellular history indicates the unit has a healthy connection. Click Next to continue to location",
    troubleshootDevice_supportForAdditionalAssistance: "to chat with support for additional assistance.",
    troubleshootDevice_yes: "Yes ",
    troubleshootDevice_no: "No ",
    troubleshootDevice_bad: "Bad",
    troubleshootDevice_poor: "Poor",
    troubleshootDevice_good: "Good",
    troubleshootDevice_refreshingLocation: "Refreshing Location. This may take up to 2 minutes",
    troubleshootDevice_checkingLocation: "Checking Location",
    troubleshootDevice_gpsSignal: "GPS Signal",
    troubleshootDevice_address: "Address",
    troubleshootDevice_unitUnresponsive: "The unit was unresponsive please contact support using the chat below or explore the recomended steps in the Help Center.",
    troubleshootDevice_unitResponsive: "The unit responded and location has been refreshed is this where the unit is located?",
    troubleshootDevice_theLocationIsAccurate: "The location is accurate click Next continue",
    troubleshootDevice_IsThisWhereTheUnitIsLocated: "Is this where the unit is located?",
    troubleshootDevice_clickPingBuntton: "Click PING to force the unit to report a new location",
    troubleshootDevice_checkingOdometer: "Checking Odometer",
    troubleshootDevice_CheckingEngineHours: "Checking Engine Hours",
    troubleshootDevice_odometer: "Odometer",
    troubleshootDevice_isTheOdometerReadingCorrect: "Is the Odometer reading Correct?",
    troubleshootDevice_dashReading: "Dash Reading",
    troubleshootDevice_offset: "Offset",
    troubleshootDevice_engineHours: "Engine Hours",
    troubleshootDevice_IsThisEngineHoursReadingCorrect: "Is this Engine Hours reading correct?",
    troubleshootDevice_clickNextToContinue: "The Odometer and/or Engine Hours will be update when clicking Next. Click Next to continue",
    troubleshootDevice_clickNextToContinueOnlyOdometer: "The Odometer will be update when clicking Next. Click Next to continue",
    troubleshootDevice_clickNextToContinueOnlyEngineHours: "The Engine Hours will be update when clicking Next. Click Next to continue",
    troubleshootDevice_summary: "Summary",
    troubleshootDevice_theTrobleshootingCompleted: "The Troubleshooting has been completed.",
    troubleshootDevice_additionalSupport: "If you still seeing incorrect behavior please click Support Chat for additional support, or follow the recomended steps in the Help Center.",
    troubleshootDevice_endMessage: "If the issue was resolved click end",
    troubleshootDevice_resettingTheDevice: "Resetting the device",
    troubleshootDevice_noReading: "No Reading",
    troubleshootDevice_resetProcessHasBeenExecuted: "The reset process has been executed",
    troubleshootDevice_restartProcessCouldNotBeExecuted: "The restart process could not be executed.",
    troubleshootDevice_pleaseContactOurSupport: "Please contact our support team or follow the recommended steps in the Help Center",
    troubleshootDevice_resetDeviceMessage: "Resetting a device under good conditions can take 10 to 15 minutes to be successful. Once reset the device will power back up and re-establish a connection. If the issueis unresolved. you may need to re-run the trobleshooter.",
    troubleshootDevice_resetDeviceConfirm: "Are you sure you want to Reset?",
    troubleshootDevice_back: "Back ",
    troubleshootDevice_Reset: "Reset ",
    troubleshootDevice_resetDeviceResponse: "This unit is responding with a bad location. This could be due to an inaccurate GPS fix. Resetting the device may resolve this issue. Click Reset to restart the device ",
}