// Dependencies
import moment from "moment";

// Helpers
import { NotificationManager } from 'Components/Notifications';
import { ExceptionManager } from '../../../core/logManager'
import { checkDeviceData } from 'Core/data/Helpers'

const DEFAULT_TIMEZONE = 'US/Eastern'

/**
 * Generates a range from the given date to the current date.
 * @returns { string, string } - { startDate, endDate }
 */
export const getAbsoluteDateRange = (initialDate) => {
  const start = moment(initialDate).format();
  const end = moment().format();

  return { start, end };
}

export const formatAvailableMedia = (availableMediaList) => {

  return availableMediaList.map((availableMediaItem) => {
    const {
      reading: {
        id,
        driverId,
        driverName,
        deviceData,
        eventName,
        eventCode,
        unitTime,
        starred,
        notes,
        speed,
        speedLimit,
        normalizedSpeed,
        ecuSpeed,
        unitType,
        rawMessage,
        formattedAddress
      },
      linkedDevice: {
        id: deviceId,
        deviceModelName,
        esn
      }
    } = availableMediaItem;

    const { unit } = availableMediaItem;

    return {
      ...getDeviceData(deviceData, eventName, rawMessage),
      id,
      unitId: unit?.id,
      deviceId,
      timeZone: unit?.timeZone,
      eventName,
      unitTime,
      label: unit?.label,
      driverId,
      driverName,
      starred,
      notes,
      speed,
      speedLimit,
      normalizedSpeed,
      ecuSpeed,
      unitType,
      useEcuSpeed: unit?.useEcuSpeed,
      deviceModelName,
      eventCode,
      formattedAddress,
      esn,
      deviceData,
      tags: unit?.tags,
      reviewStatus: availableMediaItem?.reviewStatus?.jsonValue && JSON.parse(JSON.parse(availableMediaItem.reviewStatus.jsonValue))
    };
  });
};

const getDeviceData = (deviceDataJSON, eventName) => {
  try {
    //const deviceDataObject = JSON.parse(JSON.parse(deviceDataJSON));
    let deviceDataObject = checkDeviceData(deviceDataJSON);

    const pictureData = (data) => {
      const { pictureID, eventCategory, thumbnailUrl, videoReview, mediaDeletedByUsername } = data;
      return { pictureID, eventCategory, thumbnailUrl, videoReview, mediaDeletedByUsername };
    };

    const videoData = (data) => {
      const { clipID, thumbnailUrl, duration, url, videoReview, mediaDeletedByUsername } = data;
      return { clipID, thumbnailUrl, duration, url, videoReview, mediaDeletedByUsername };
    };

    return eventName === 'AutoSnapshot' ? pictureData(deviceDataObject) : videoData(deviceDataObject);
  } catch (exc) {
    ExceptionManager(exc, 'modules/video/.globals/helpers', 'getDeviceData')
  }

  return null
};

export const showNotification = (title, text, severity) => {
  NotificationManager[severity](text, title, 3000, null, null, severity, null);
}

export const getInitialRange = (value) => {
  let start, end;
  switch (value) {
    case 'today':
      start = moment().startOf('day').toDate()
      end = moment.max(moment()).toDate()
      break;

    case 'yesterday':
      start = moment().subtract(1, 'days').startOf('day').toDate()
      end = moment.max(moment().subtract(1, 'days')).endOf('day').toDate()
      break;

    case 'week':
      start = moment().day(0).startOf('day').toDate()
      end = moment.max(moment()).toDate()
      break;

    case 'lastWeek':
      start = moment().day(-7).startOf('day').toDate()
      end = moment().day(-1).endOf('day').toDate()
      break;

    case 'thisMonth':
      start = moment().startOf('month').toDate()
      end = moment.max(moment()).toDate()
      break;

    case 'lastMonth':
      start = moment(moment()).subtract(1, 'months').startOf('month').toDate()
      end = moment(moment()).subtract(1, 'months').endOf('month').toDate()
      break;

    case 'quarter':
      start = moment().startOf('quarter').toDate()
      end = moment.max(moment()).toDate()
      break;

    case 'lastQuarter':
      const quarter = moment().quarter();
      start = moment().quarter(quarter - 1).startOf('quarter').toDate()
      end = moment().quarter(quarter - 1).endOf('quarter').toDate()
      break;
    case 'last7days':
      start = moment().subtract(7, 'days').startOf('day').toDate()
      end = moment.max(moment()).endOf('day').toDate()
      break;
    case 'last24hours':
      start = moment().subtract(24, 'hours').startOf('day').toDate()
      end = moment.max(moment()).endOf('day').toDate()
      break;
    case 'last30Days':
      start = moment().subtract(30, 'days').startOf('day').toDate()
      end = moment.max(moment()).endOf('day').toDate()
      break;
    default:
      start = moment().startOf('month').toDate()
      end = moment.max(moment()).toDate()
      break;
  }

  return { start, end };
}

export const configEventCategory = (messages) => {
  return {
    //"CameraEvent.DRIVING_HIT": { eventName: messages['videoMainView_drivingHit'] },
    //"CameraEvent.DRIVING_HEAVY_HIT": { eventName: messages['videoMainView_drivingheavyHit'] },
    "CameraEvent.HARD_ACCEL": { eventName: messages['videoMainView_hardAccel'] },
    "CameraEvent.HARD_BRAKE": { eventName: messages['videoMainView_hardBrake'] },
    "CameraEvent.SHARP_TURN": { eventName: messages['videoMainView_sharpTurn'] },
    "CameraEvent.HARSH_ACCEL": { eventName: messages['videoMainView_harshAccel'] },
    "CameraEvent.HARSH_BRAKE": { eventName: messages['videoMainView_harshBrake'] },
    "CameraEvent.HARSH_TURN": { eventName: messages['videoMainView_harshTurn'] },
    "CameraEvent.SEVERE_ACCEL": { eventName: messages['videoMainView_severeAccel'] },
    "CameraEvent.SEVERE_BRAKE": { eventName: messages['videoMainView_severeBrake'] },
    "CameraEvent.SEVERE_TURN": { eventName: messages['videoMainView_severeTurn'] },
    "CameraEvent.ASLEEP": { eventName: messages['videoMainView_asleep'] },
    "CameraEvent.DROWSINESS": { eventName: messages['videoMainView_drowsy'] },
    "CameraEvent.YAWN": { eventName: messages['videoMainView_yawn'] },
    "CameraEvent.DAYDREAMING": { eventName: messages['videoMainView_dayDreaming'] },
    "CameraEvent.MANUAL": { eventName: messages['videoMainView_manual'] },
    "CameraEvent.USING_PHONE": { eventName: messages['videoMainView_usingPhone'] },
    "CameraEvent.DISTRACTED": { eventName: messages['videoMainView_distracted'] },
    "CameraEvent.SMOKING": { eventName: messages['videoMainView_smoking'] },
    "CameraEvent.NO_SEATBELT": { eventName: messages['videoMainView_noSeatBelt'] },
    "CameraEvent.HEADWAY_MONITORING_WARNING": { eventName: messages['videoMainView_closeFollowing'] },
    "CameraEvent.HEADWAY_MONITORING_EMERGENCY": { eventName: messages['videoMainView_tailgating'] },
    "CameraEvent.FORWARD_COLLISION_WARNING": { eventName: messages['videoMainView_collisionWarning'] },
    "CameraEvent.LANE_DEPARTURE": { eventName: messages['videoMainView_laneDeparture'] },
    "CameraEvent.POTENTIAL_INCIDENT_LOW": { eventName: messages['videoMainView_potentialIncidentLow'] },
    "CameraEvent.POTENTIAL_INCIDENT_MEDIUM": { eventName: messages['videoMainView_potentialIncidentMedium'] },
    "CameraEvent.POTENTIAL_INCIDENT_HIGH": { eventName: messages['videoMainView_potentialIncidentHigh'] },
  }
};

export const getLabel = (messages, item, locale = 'en') => {
  const { eventName, eventCode, unitTime, label, driverName, timeZone } = item;
  const validTimezone = timeZone?.name ?? DEFAULT_TIMEZONE
  return `${configEventCategory(messages)[eventCode]?.eventName || eventName}, ${moment(unitTime).tz(validTimezone).locale(locale).format('llll')}, ${label} / ${driverName || messages['unassigned']}`;
}

export const formatEntities = (selectedItems) => {
  const formattedItems = selectedItems.map((item) => {
    const { id, name, label } = item;

    return { id, name, label };
  });

  return formattedItems;
};