
export const ScriptSFChat = ({username, lastname, email, subject}) => {
  const script = document.createElement("script");
  const lang = localStorage.getItem('lan') || 'en'
  script.id = "salesForceGeneralScript"
  script.src = `https://service.force.com/embeddedservice/5.0/esw.min.js`;
  document.body.appendChild(script);
  const script2 = document.createElement("script");
  script2.id = "salesForceScript"
  script2.innerHTML = `
    var initESW = function(gslbBaseURL) {
      embedded_svc.settings.displayHelpButton = false; //Boolean for show button, start
      embedded_svc.settings.language = '${lang}'; //For example, enter 'en' or 'en-US'
      //embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
      //embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)
      //embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
      //embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)
      // Settings for Chat
      //embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
        // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
        // Returns a valid button ID.
      //};
      embedded_svc.settings.prepopulatedPrechatFields = {
        FirstName: "${username || ''}",
        LastName: "${lastname || ''}",
        Email: "${email || ''}",
        Subject: "${subject || ''}"
      }; //Sets the auto-population of pre-chat form fields
      //embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
      //embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)
      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      embedded_svc.init(
        'https://gpstrackit-docusign.my.salesforce.com',
			  'https://gpstrackit-docusign.my.salesforce-sites.com/Chat',
        gslbBaseURL,
        '00DF00000007FZY',
        'Support_Chat',
        {
          baseLiveAgentContentURL: 'https://c.la1-c2-ia6.salesforceliveagent.com/content',
          deploymentId: '5723k000000Tg7f',
          buttonId: '5733k000000TgQw',
          baseLiveAgentURL: 'https://d.la1-c2-ia6.salesforceliveagent.com/chat',
          eswLiveAgentDevName: 'Support_Chat',
          isOfflineSupportEnabled: false
        }
      );

    };
    if (!window.embedded_svc) {
      var s = document.createElement('script');
      s.setAttribute('src', 'https://gpstrackit-docusign--full.my.salesforce.com/embeddedservice/5.0/esw.min.js');
      s.onload = function() {
        initESW(null);
      };
      document.body.appendChild(s);
    } else {
      initESW('https://service.force.com');
    }
    `;

  script.onload = () => {
    document.body.appendChild(script2);
    //   setInitialized(true);
    //   // if (callback) callback();
  };
};

export const blacListSFChat = ({dealerId}) => {
  let canChat = true
  const blackList = [
    {
      dealerName : 'Bahamas Asset Tracking Ltd Dealer',
      dealerID : '26',
      activeUnits : 366,
      chatAvailable: 'No'
    },
    {
      dealerName : 'Crimestopper Security Products',
      dealerID : '54',
      activeUnits : 5,
      chatAvailable: 'Yes'
    },
    {
      dealerName : 'Fleet IQ MVC Technology',
      dealerID : '74',
      activeUnits : 308,
      chatAvailable: 'No'
    },
    {
      dealerName : 'Fleet Trax',
      dealerID : '6',
      activeUnits : 6609,
      chatAvailable: 'Yes'
    },
    {
      dealerName : 'Frecom Inc',
      dealerID : '40',
      activeUnits : 28,
      chatAvailable: 'Yes'
    },
    {
      dealerName : 'Global Tracking Communications',
      dealerID : '1',
      activeUnits :  50763,
      chatAvailable: 'Yes'
    },
    {
      dealerName : 'GPS LEADERS',
      dealerID : '65',
      activeUnits : 77,
      chatAvailable: 'No'
    },
    {
      dealerName : 'Nielson Communications',
      dealerID : '32',
      activeUnits : 2,
      chatAvailable: 'Yes'
    },
    {
      dealerName : 'Pure Connect GPS',
      dealerID : 	'75',
      activeUnits : 23,
      chatAvailable: 'No'
    },
    {
      dealerName : 'Radioworks Communications Inc',
      dealerID : 	'45',
      activeUnits : 19,
      chatAvailable: 'Yes'
    },
    {
      dealerName : 'USCC Services, LLC',
      dealerID : 	'70',
      activeUnits :  8791,
      chatAvailable: 'Yes'
    },
  ]

  blackList.forEach(item => {
    if(item.dealerID === dealerId){
      if(item.chatAvailable === 'No'){
        canChat = false
      }
    }
  });

  return canChat

}