export const MODE_ENUM = {
  video: "video",
  picture: "picture",
};

export const TYPE_ENUM = {
  streaming: "streaming",
  photo: "photo",
  clipStreaming: "clip-streaming",
  playback: "playback",
};

export const SIM_PROVIDER_NAME_ENUM = {
  usc: "USC",
  usCellular: "US Cellular",
};

export const STATUS_ENUM = {
    live: 'live',
    mp4Uploaded: "mp4Uploaded",
    uploading: 'uploading',
    waitForPublish: 'waitForPublish',
    waitForAwake: 'waitForAwake',
    waitForStop: 'waitForStop',
    stopped: 'stopped',
    timeout: 'timeout',
    offline: 'offline',
    firmwareNotSupport: 'firmwareNotSupport',
    failed: 'failed'
}

export const ACTION_ENUM = {
    start: 'start',
    stop: 'stop'
}

export const ROTATE_ENUM = {
    upsidedown: 'upsidedown'
}

export const DEVICEMODEL_ENUM = {
    device360: '360',
    SmartWitness: 'SmartWitness'
}

export const THUMBNAILURL_SMARTWITNESS = "/SmartWitness/";

export const MAX_ATTEMPTS = 30