//import reportsRedux from 'Modules/reports/views/main/redux/reducer';
//Reports Main Report Tab Redux Object
import reportsMainRTRedux from 'Modules/reports/views/main/components/reportsTab/redux/reducer';
import reportsMainSTRedux from 'Modules/reports/views/main/components/scheduleTab/redux/reducer';
import reportsModalExtraTime from 'Modules/reports/views/main/components/reportsTab/.components/modalExtraTime/redux/reducer';
import reportsSetupModal from 'Modules/reports/views/main/components/scheduleTab/.components/reportSetup/redux/reducer';
import reportsModalDownloadFiles from 'Modules/reports/views/main/components/reportsTab/.components/modalDownloadFiles/redux/reducer';
import reportsTypesModal from 'Modules/reports/views/main/components/reportsTab/.components/reportTypes/redux/reducer';
import  reportsListShowReportFilterRedux  from "Modules/reports/views/main/components/reportsTab/.components/reportListFilters/redux/slice";
import  reportsTapSearchReportLogRedux  from "Modules/reports/views/main/components/reportsTab/redux/slice";


export default {
  //reportsRedux,
  reportsMainRTRedux,
  reportsMainSTRedux,
  reportsModalExtraTime,
  reportsSetupModal,
  reportsModalDownloadFiles,
  reportsTypesModal,
  reportsListShowReportFilterRedux,
  reportsTapSearchReportLogRedux,

};
