import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Grid, Icon, IconButton } from '@mui/material';
import Skeleton from '@mui/lab/Skeleton';
import { Button } from 'Components';
import './input.scss';
import { MenuComponent } from "Components/Menu";

const WAIT_INTERVAL = 300;
const ENTER_KEY = 13;
export default class Input extends PureComponent {
	typeTimeout = null;
	constructor(props) {
		super(props);

		this.state = {
			isValid: true,
			errorMessage: null,
			showPassword: false,
			focused: false,
			value: props.value,
			backupValue: props.value,
			editMode: false
		};

		this.validate = this.validate.bind(this);
		this.reset = this.reset.bind(this);
		this.onChangeTxt = this.onChangeTxt.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({
				value: this.props.value,
			});
		}
	}

	validate(val) {
		let isValid = true;
		let errorMessage;
		let value = val || this.props.value;

		if (!this.props.disabled) {
			if (this.props.required) {
				if (!value || value.length === 0 || !`${value}`.trim()) {
					isValid = false;
					errorMessage = this.props.requiredMessage;
				}
			}

			if (this.props.regex && value && !this.props.regex?.test(value)) {
				isValid = false;
				errorMessage = this.props.requiredMessage;
			}

			if (['number', 'currency'].includes(this.props.type) && this.props.min !== null) {
				if (!value || value === "")
					value = 0
				if (parseFloat(value) < parseFloat(this.props.min)) {
					isValid = false;
					errorMessage = this.props.minMessage;
				}
			}

			if (['number', 'currency'].includes(this.props.type) && this.props.max) {
				if (parseFloat(value) > this.props.max) {
					isValid = false;
					errorMessage = this.props.maxMessage;
				}
			}

			if (value && value.length > 0 && this.props.exactLength) {
				if (!value || value.length != this.props.exactLength) {
					isValid = false;
					errorMessage = this.props.exactLengthMessage;
				}
			}

			if (value && this.props.maxLength && value?.length > this.props.maxLength) {
				isValid = false;
				errorMessage = this.props.maxLengthMessage;
			}

			if (value && this.props.minLength && value?.length < this.props.minLength) {
				isValid = false;
				errorMessage = this.props.minLengthMessage;
			}

			if (value && this.props.email && !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
				isValid = false;
				errorMessage = this.props.emailMessage;
			}

			if (value && this.props.ipAddress) {
				const ipAddressReg = new RegExp('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$');
				if (!ipAddressReg.test(value)) {
					isValid = false;
					errorMessage = this.props.ipAddressMessage;
				}
			}

			if (value && this.props.valueToCompare) {
				if (value !== this.props.valueToCompare) {
					isValid = false;
					errorMessage = this.props.valueToCompareMessage;
				}
			}

			if (!isValid && !errorMessage && this.props.errorMessage) {
				errorMessage = this.props.errorMessage;
			}
		}

		this.setState({ isValid, errorMessage });
		return isValid;
	}

	reset() {
		this.setState({ isValid: true });
	}

	onChangeTxt(event) {
		if (this.typeTimeout) {
			clearTimeout(this.typingTimeout);
		}

		this.setState({ value: event.target.value });

		this.typeTimeout = setTimeout(this.triggerChange, WAIT_INTERVAL);
	}


	handleKeyDown = (e) => {
		if (e.keyCode === ENTER_KEY) {
			clearTimeout(this.typeTimeout);
			this.triggerChange();
		}
	};

	triggerChange = () => {
		const { value } = this.state;
		this.props.onChange(value);
	};

	renderType(props) {
		//number
		//password
		//currency
		//email
		//text

		const validateNumber = (value) => {
			let isValid = true;
			if (this.props.integer) isValid = /^\d*$/.test(value);
			else isValid = /^-?\d*[,.]?\d*$/.test(value);

			return isValid;
		};
		const handleNumberChange = (event) => {

			const { value } = event.target;

			let isValid = true;

			var charCode = event.which ? event.which : event.keyCode;

			if (this.props.integer)
				isValid = validateNumber(value + String.fromCharCode(event.which));
			else if (charCode === 46) {

				if (!value.indexOf('.') === -1) {
					isValid = false;
				}
				else if (!this.props.decimalPlaces) {
					isValid = false;
				}

				else isValid = validateNumber(value + String.fromCharCode(event.which));
			} else if (value?.length > props.maxLength) {
				isValid = false;
			} else if (value?.length < props.minLength) {
				isValid = false;
			} else if (charCode == 44 && this.props.step == 1) {
				isValid = false
			} else isValid = validateNumber(value + String.fromCharCode(event.which));

			if (this.props.onKeyPress && isValid) this.props.onKeyPress(event);

			if (!isValid) event.preventDefault();
		}

		const handleCurrencyChange = (event) => {
			let { value } = event.target;
			let isValid = true;
			if (isNaN(value)) {
				value = 0;
			}

			var charCode = event.which ? event.which : event.keyCode;
			if (this.props.integer)
				isValid = validateNumber(value + String.fromCharCode(event.which));
			else if (charCode === 46) {
				if (!value.indexOf('.') === -1) {
					isValid = false;
				} else isValid = validateNumber(value + String.fromCharCode(event.which));
			} else if (value?.length > props.maxLength) {
				isValid = false;
			} else isValid = validateNumber(value + String.fromCharCode(event.which));

			if (this.props.onKeyPress && isValid) this.props.onKeyPress(event);

			if (!isValid) event.preventDefault();
		}
		switch (this.props.type) {
			default:
			case 'text':
				return (
					<input
						type="text"
						{...props}
						onClick={this.props.onClick}
						onChange={(e) => {
							if (this.props.regex && e.target.value && !e.target.value.match(this.props.regex)) {
								return
							}

							//only doble white space ending the string.
							e.target.value = e.target?.value?.replace(/ {2,}$/g, ' ');
							if (this.props.email) {
								e.target.value = e.target?.value?.toLocaleLowerCase();
							}
							this.onChangeTxt(e);
						}}
						onBlur={(e) => {
							const trimmedValue = e.target.value.trim();
							e.target.value = trimmedValue;
							this.onChangeTxt(e);
						}}
						value={this.state.value}
						onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : this.handleKeyDown}
						onKeyPress={this.props?.onKeyPress || null}
					/>
				);
			case 'password':
				return (
					<input
						style={{ paddingRight: "2rem" }}
						type={this.state.showPassword ? 'text' : 'password'}
						{...props}
						value={this.props.value}
					/>
				);
			case 'email':
				return (
					<input
						type="text"
						{...props}
						onClick={this.props.onClick}
						onChange={(e) => {
							if (this.props.regex && e.target.value && !e.target.value.match(this.props.regex)) {
								//return
							}

							e.target.value = e.target?.value?.replace(/ {2,}/g, ' ');
							if (this.props.email) {
								e.target.value = e.target?.value?.toLocaleLowerCase();
							}
							this.onChangeTxt(e);
						}}
						value={this.state.value}
						onKeyDown={this.props.onKeyDown ? this.props.onKeyDown : this.handleKeyDown}
						onKeyPress={this.props?.onKeyPress || null}
					/>
				);
			case 'number':
				return (
					<input
						type="number"
						onBlur={(e) => {
							if (this.props.onBlur) {
								this.props.onBlur(e);
							}
						}}
						className={this.props.className || ''}
						style={this.props?.style || {}}
						{...props}
						min={this.props.min}
						max={this.props.max}
						onChange={(e) => {
							if (this.props.onlyPositive && Number(e.target.value) < 0) {
								return
							}

							if (this.props.preventOnChange && (Number(e.target.value) > this.props.max || Number(e.target.value) < this.props.min)) {
								return
							}

							if (e.target.value.split(".")[1]) {
								if (e.target.value.split(".")[1]?.length > this.props.decimalPlaces) {
									return
								}
							}

							let newValue = e.target.value;
							if (this.props.maxLength && e?.target?.value?.length > this.props.maxLength) {
								//newValue = parseInt(e.target.value.toString().substring(0, this.props.maxLength));
								newValue = e.target.value.toString().substring(0, this.props.maxLength);
							}

							this.props.onChange(newValue);
						}}
						value={this.props.value != undefined && this.props.value != null ? this.props.value : ""}
						onPaste={handleNumberChange}
						onKeyPress={handleNumberChange}
						step={this.props.step}
						pattern={this.props.pattern}
					/>
				);
			case 'time':
				return (
					<input
						type="number"
						onBlur={(e) => {
							if (this.props.onBlur) {
								this.props.onBlur(e);
							}
						}}
						className={this.props.className || ''}
						{...props}
						maxLength={3}
						min={this.props.min}
						max={this.props.max}
						onChange={(e) => {
							if (this.props.onlyPositive && Number(e.target.value) < 0) {
								this.props.onChange(1);
								return;
							}

							if (this.props.preventOnChange) {
								if (Number(e.target.value) > this.props.max) {
									this.props.onChange(this.props.max);
									return;
								} else if (Number(e.target.value) < this.props.min) {
									this.props.onChange(this.props.min);
									return;
								}
							}

							if (e.target.value.split(".")[1]) {
								if (e.target.value.split(".")[1]?.length > this.props.decimalPlaces) {
									return;
								}
							}

							let value = e.target.value;

							if (value.length >= 3) {
								value = value.slice(-2);
							}

							this.props.onChange(value);
						}}
						value={this.props.value != undefined && this.props.value != null ? this.props.value : ""}
						onPaste={handleNumberChange}
						onKeyPress={handleNumberChange}
						step={this.props.step}
						pattern={this.props.pattern}
					/>
				);
			case 'currency':
				return (
					<Fragment>
						{this.state.focused ? (
							<input
								type="number"
								{...props}
								value={this.props.value != undefined && this.props.value != null ? this.props.value : ""}
								onPaste={handleNumberChange}
								onKeyPress={handleCurrencyChange}
								onChange={(event) => {

									const { value } = event.target;


									//preventing decimal places
									if (value.split(".")[1]) {
										//console.log("this.props.decimalPlaces",this.props.decimalPlaces)
										if (value.split(".")[1]?.length > this.props.decimalPlaces) {
											return
										}
									}

									//preventing on change with prop
									if (this.props.preventOnChange && (Number(value) > this.props.max || Number(value) < this.props.min)) {
										return
									}

									this.validate(value);
									this.props.onChange(value);
								}}
								step="any"
							/>
						) : (
							<input
								type="text"
								{...props}
								value={
									validateNumber(this.props.value) && this.props.value != ''
										? '$' + parseFloat(this.props.value).toFixed(this.props.decimals || 2)
										: ''
								}
							/>
						)}
					</Fragment>
				);
			case 'textarea':
				return (
					<textarea
						style={{ minHeight: 50 }}
						{...props}
						value={this.props.value}
						maxLength={this.props.maxLength || 250}
						className={this.props.className || ''}
					/>
				);
			case 'inline':
				return (
					<React.Fragment>
						{
							(this.state.editMode || !this.state.value || this.props.disabledEditMode) ?
								<div className="inline-textedit-container">
									<div style={{ zIndex: 50 }}>
										<input
											type="text"
											{...props}
											className={cx(
												'inline-text',
												props.className
											)}
											onClick={this.props.onClick}
											onChange={(e) => {
												if (this.props.regex && e.target.value && !e.target.value.match(this.props.regex)) {
													return
												} else if (!this.props.regex && !e.target.value.trim() && !!e.target.value.match(this.props.regex)) {
													this.setState({ isValid: false, errorMessage: this.props.requiredMessage, backupValue: e.target.value });
													return;
												}

												this.setState({ isValid: true, errorMessage: "", backupValue: e.target.value });
											}}
											value={this.state.backupValue}
											onKeyDown={(e) => {
												this.handleKeyDown(e)
											}}
										/>
										<div className="inline-check-button">
											<Icon
												className="inline-icon check-icon"
												onClick={(e) => {
													if (e) e.preventDefault();
													let backupValue = this.props.disabledEditMode ? '' : this.state.backupValue;
													this.setState({ editMode: false, value: this.state.backupValue, backupValue });
													this.validate(this.state.backupValue);
													this.props.onChange(this.state.backupValue);
												}}
											>
												check_circle
											</Icon>
										</div>

										<div className="inline-cancel-button">
											<Icon
												className="inline-icon cancel-icon"
												onClick={(e) => {
													if (e) e.preventDefault();
													this.setState({ editMode: false, isValid: true, errorMessage: "" });
													if (this.state.value === '') {
														this.setState({ backupValue: '' });
													}
												}}
											>
												cancel
											</Icon>
										</div>
									</div>
								</div> :
								<div
									className="inline-textedit-value"
									onClick={() => {
										this.setState({ editMode: true, backupValue: this.state.value });
									}}
								>
									<span>{this.state.value}</span>
								</div>
						}
					</React.Fragment>
				);
			case 'inline-report':
				return (
					<React.Fragment>
						{
							(this.state.editMode || !this.state.value || this.props.disabledEditMode) ?
								<div className="inline-textedit-container"
									id={'edit-name-report-textedit'}
									onClick={(e) => {
										if (e.target?.id === 'edit-name-report-textedit') {
											if (e) e.preventDefault();
											this.setState({ editMode: false, isValid: true, errorMessage: "", });
											if (this.state.value === '') {
												this.setState({ backupValue: '' });
											}
										}
									}}
								>
									<div className='input__edit-report' style={{ zIndex: 50 }}>
										<input
											type="text"
											{...props}
											className={cx(
												props.className,
												'inline-edit'

											)}
											onClick={this.props.onClick}
											onChange={(e) => {
												if (this.props.regex && e.target.value && !e.target.value.match(this.props.regex)) {
													return
												} else if (!this.props.regex && !e.target.value.trim() && !!e.target.value.match(this.props.regex)) {
													this.setState({ isValid: false, errorMessage: this.props.requiredMessage, backupValue: e.target.value });
													return;
												}
												this.setState({ isValid: true, errorMessage: "", backupValue: e.target.value });
											}}
											value={this.state.backupValue}
											onKeyDown={(e) => {
												if (this.props.showHoverIcon && e.key === 'Enter') {
													if (!e.target.value.trim()) {
														this.setState({ isValid: false, errorMessage: this.props.requiredMessage, backupValue: e.target.value });
														return
													}
													if (e) e.preventDefault();
													let backupValue = this.props.disabledEditMode ? '' : this.state.backupValue;
													this.setState({ editMode: false, value: this.state.backupValue, backupValue });
													this.validate(this.state.backupValue);
													this.props.onChange(this.state.backupValue);
												} else {
													this.handleKeyDown(e)
												}
											}}
										/>
										<div className='input__edit-report-btn'>
											<Button
												id={this.props.showHoverIconIndex ? "icon-editable-check-icon-" + this.props.showHoverIconIndex : ""}
												disabled={!this.state.backupValue || this.state.value === this.state.backupValue}
												className="btn-save-report-name"
												onClick={(e) => {
													if (e) e.preventDefault();
													let backupValue = this.props.disabledEditMode ? '' : this.state.backupValue;
													this.setState({ editMode: false, value: this.state.backupValue, backupValue });
													this.validate(this.state.backupValue);
													this.props.onChange(this.state.backupValue);
												}}
											>
												{this.props?.labelSave ? this.props.labelSave : 'Save'}
											</Button>
											<Button
												id={this.props.showHoverIconIndex ? "icon-editable-cancel-icon-" + this.props.showHoverIconIndex : ""}
												className="btn-cancel-report-name"
												onClick={(e) => {
													if (e) e.preventDefault();
													this.setState({ editMode: false, isValid: true, errorMessage: "" });
													if (this.state.value === '') {
														this.setState({ backupValue: '' });
													}
												}}
											>
												{this.props.labelCancel ? this.props.labelCancel : 'Cancel'}
											</Button>
										</div>
									</div>
								</div> :
								<div
									className="inline-textedit-value"
									id={'inline-textedit-report'}
									onClick={(e) => {
										this.setState({ editMode: true, backupValue: this.state.value });
									}}
								>
									{
										this.props.showHoverIcon ? (
											<>
												<Grid container alignItems="center" className="schedule-report-title-ctn" spacing={2}>
													<Grid item sm={10}>
														<label>
															{this.state.value?.length > 15 ? `${this.state.value.slice(0, 15)}...` : this.state.value}
														</label>
													</Grid>
													<Grid item sm={2} className="grid-icon-edit">
														<MenuComponent
															options={[]}
															icon={'edit'}
															className="icon-menu-vehicle-service block-report-icon-edit schedule-report-title-ctn-showInHoverMenu"
														/>
													</Grid>
												</Grid>
											</>
										) : (<>
											<span>{this.state.value}</span>
										</>)
									}
								</div>
						}
					</React.Fragment>
				);
		}
	}

	render() {
		return (
			<div
				className="form-item"
				style={this.props.containerStyle || {}}
			>
				{this.props.label && (
					<label className={cx(this.props.titleStyles, "input-normal-label")}>
						{this.props.label}
                      { ( ! this.props.notRequiderdShow && this.props.required ) || this.props.addRequiredLabel ? (
                        <span className="text-danger"> *</span>
                      ) : null }
					</label>
				)}

				{this.props.customLabel && (
					<label className="input-normal-label">
						{this.props.customLabel}
					</label>
				)}

				{this.props.loading ? (
					<div className="input-loading">
						<Skeleton />
					</div>
				) : (
					<div className="input-icon-container">
						{!this.props.disabled &&
							((this.props.editable && this.state.focused) || !this.props.editable) &&
							this.renderType({
								ref: 'input',
								id: this.props.id,
								name: this.props.name,
								placeholder: this.props.placeholder,
								value: this.props.value != undefined && this.props.value != null ? this.props.value : "",
								onFocus: () => {
									this.setState({ focused: true });
								},
								onBlur: () => {
									this.setState({ focused: false });
									if (this.props.onBlur) {
										this.props.onBlur();
									}
								},
								readOnly: this.props.readOnly,
								rows: this.props.rows,
								autoFocus: this.props.autoFocus || this.state.focused,
								autoComplete: this.props.autoComplete || 'off',
								maxLength: this.props.maxLength || 200,
								onChange: (e) => {
									let { value } = e.target;
									this.validate(value);
									this.props.onChange(value);
								},
								className: cx(
									'input-normal form-control',
									this.props.className || '',
									this.props.icon && 'input-normal-icon',
									(!this.state.isValid && 'form-control-invalid') || '',
								),
								regex: this.props.regex
							})}

						{(this.props.disabled || this.props.editable) && (
							<div
								className={cx('input-editable', this.props.disabled && 'disabled', this.props.className || '')}
								onClick={() => {
									!this.props.disabled && this.setState({ focused: true });
								}}
							>
								{this.props.value
									? this.props.type === 'currency'
										? '$' + parseFloat(this.props.value).toFixed(this.props.decimals === 0 ? 0 : (this.props.decimals || 2))
										: this.props.value
									: this.props.placeholder}
							</div>
						)}

						{this.props.icon && (
							<div
								className={cx(
									'input-icon-button',
									this.state.focused && 'input-icon-button-focused',
									this.props.disabled && 'disabled',
								)}
							>
								<IconButton
									aria-label="placeholder"
									disabled={this.props.disabled}
									className={this.props.classNameIconButton || ''}
									onClick={() => {
										if (this.props.editable) {
											this.setState({ focused: true });
										} else {
											this.refs.input.focus();
											this.props.onClickIcon && this.props.onClickIcon();
										}
									}}
									size="large">
									<Icon>{this.props.icon}</Icon>
								</IconButton>
							</div>
						)}

						{this.props.button && (
							<div
								className={cx(
									'input-button',
									this.state.focused && 'input-icon-button-focused',
									this.props.disabled && 'disabled',
								)}
							>
								{this.props.button}
							</div>
						)}

						{this.props.type === 'password' && (
							<div
								className={cx(
									'input-icon-button',
									this.state.focused && 'input-icon-button-focused',
								)}
							>
								<IconButton
									aria-label="placeholder"
									disabled={this.props.disabled}
									onClick={() => this.setState({ showPassword: !this.state.showPassword })}
									size="large">
									<Icon>remove_red_eye</Icon>
								</IconButton>
							</div>
						)}
					</div>
				)}
				{!this.state.isValid && this.state.errorMessage && (
					<small className="text-danger" style={{ paddingTop: 5 }}>
						{this.state.errorMessage}
					</small>
				)}

				{this.props.helpText && (
					<small id={`${this.props.id}-help`} className="form-text text-muted">
						{this.props.helpText}
					</small>
				)}
			</div>
		);
	}
}

Input.propTypes = {
	//id: PropTypes.string.isRequired,
	//type: PropTypes.string.isRequired,
	type: PropTypes.oneOf(['text', 'currency', 'number', 'time', 'password', 'email', 'textarea', 'inline', 'inline-report']),
	integer: PropTypes.bool,
	icon: PropTypes.string,
	value: PropTypes.any,
	onChange: PropTypes.func.isRequired,
	min: PropTypes.number,
	minMessage: PropTypes.string,
	max: PropTypes.number,
	maxMessage: PropTypes.string,
	maxLength: PropTypes.number,
	regex: PropTypes.any
};
