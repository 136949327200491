import fleetMainRedux from 'Modules/fleet/views/main/redux/reducer';
import vehicleScheduleStarterRedux from 'Modules/fleet/components/ModalScheduleStarter/redux/reducer';
import vehicleEnableDisableRedux from 'Modules/fleet/components/ModalEnableDisable/redux/reducer';
import vehiclePingRedux from 'Modules/fleet/components/ModalPing/redux/reducer';
import vehicleLandMarkRedux from 'Modules/fleet/components/ModalLandMark/redux/reducer';
import vehicleModalOuputsRedux from 'Modules/fleet/components/ModalOutputs/redux/reducer';
import fleetModalBulkOutputsRedux from 'Modules/fleet/components/ModalBulkOutputs/redux/reducer';
import fleetModalExportColumnsPdfRedux from 'Modules/fleet/components/modalExportColumnsPdf/redux/slice';

//export redux objects
export default {
	fleetMainRedux,
	vehicleScheduleStarterRedux,
	vehicleEnableDisableRedux,
	vehiclePingRedux,
	vehicleLandMarkRedux,
	vehicleModalOuputsRedux,
	fleetModalBulkOutputsRedux,
	fleetModalExportColumnsPdfRedux
};
