//Module Keys
let locales = {
    template_menu: 'Template',
};

//Merge Views
import list from '../../views/list/lan/en';
import edit from '../../modals/edit/lan/en';

locales = {
    ...locales,
    ...list,
    ...edit
};

export default locales;
