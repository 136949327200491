//imports
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {injectIntl, useIntl} from 'react-intl';

// component imports
import Tabs, {TabContainer} from 'Components/Tabs';
// Material UI
import {Checkbox, Grid} from "@mui/material";
// temporary imports
import ReportTooLarge from './.components/ReportTooLarge';
import Loading from 'Components/Loading'
import {Button, Input, InputSearch, Pill, Tooltip} from 'Components';
import {getDataForEntityTags, GetFormatForMomentTwoCharacters} from 'Core/data/Helpers';

// actions
//actions
import {
    catalogLoad,
    clearRedux,
    deleteReportLog,
    getPreSchedule,
    openModal,
    reportsMainGenerateReport,
    reportsMainRemoveReport,
    reportsMainSetFormDataReportSetup,
    reportsMainShowReportSetup,
    reportsScheduleShowReportSetup,
    reportsVerifyStateReport,
    reportUpdateField,
    reportUpdateFieldBatch,
    sendGenericExport
} from 'Redux/actions'
import {searchReportLog, searchReportParams} from "./redux/slice";
import cx from 'classnames'

import {v4 as uuidv4} from 'uuid';

// Hooks
import {useReportCustomFields, useReportTypeOptions} from 'Core/hooks';

// helper
import {
    changeDate,
    FIELD_NAME_IS_STARRED,
    FIELD_NAME_REPORT,
    formatDatetime,
    formatTabLabel,
    getEntitiesAllType,
    getEntityType,
    getNameUnitDriverTag,
    TYPE_REPORT_RECENT,
    TYPE_REPORT_STARRED
} from 'Modules/reports/views/main/components/reportsTab/helper'
import {
    ActReport,
    AdvancedSummaryReport,
    AlertLogReport,
    BehaviorReport,
    ChatHistoryReport,
    ChatHistoryReportV2,
    DetailedReport,
    DeviceSummaryInfoReport,
    DiagnosticIndicatorsReport,
    DiagnosticParametersReport,
    DriverCoachingReport,
    DriverFormsReport,
    DriverFormsReportV2,
    DriverMileageSummaryReport,
    DriverSafetyReport,
    DriverStatusReportV2,
    DriverTimesheetReport,
    EngineHoursReport,
    EventReport,
    FormStopSummaryReport,
    FormStopSummaryReportV2,
    FSMCombinedReport,
    FSMCombinedReportV2,
    FsmDriverStatusReport,
    FSMTimeCardReport,
    FuelCardReport,
    GeofenceBasedReport,
    GeofenceReport,
    HourOfUseReport,
    IdleReport,
    IgnitionReport,
    InputReport,
    JBDiagnosticsReport,
    JBDTCReport,
    JBSummaryReport,
    LandmarkBasedReport,
    LandmarkCrewReport,
    LandmarkEngineHoursReport,
    LandmarkReport,
    LandmarkSummaryReport,
    LatitudeLongitudeReport,
    LocationReport,
    MaintenanceItemsReport,
    MileageReport,
    MileageSummaryReport,
    MilesPerGallonReport,
    MovingReport,
    NonReportingReport,
    OdometerReport,
    PingReport,
    ServiceItemsReport,
    SimpleReport,
    SmartOneCUtilizationReport,
    SpeedCoachingReport,
    SpeedReport,
    StartStopIdleReport,
    StateMileageCrossingReport,
    StateMileageReport,
    StateMileageSummariesReport,
    StopHistoryReport,
    StopHistoryReportV2,
    StopReport,
    StopSummaryReport,
    StopSummaryReportV2,
    SummaryReport,
    TemperatureReport,
    TimeCardReportV2,
    TimesheetReport,
    TripReport,
    UnitInfoReport,
    UtilizationReport,
    VehicleMaintenanceItemsReport,
    VehServiceItemsReport,
    FuelLevelReport
} from './.components/reports'
//styles
import 'Modules/reports/views/main/main.scss';
import {NotificationManager} from 'Components/Notifications';
//views
import ReportSetup from './.components/reportSetup';
import ModalLandMark from "Modules/fleet/components/ModalLandMark";
import {AddButton} from "Components/Table";
import {TableButtons} from "Components/uiControls/Table";
import moment from "moment/moment";
import {TagsGroup} from "../../../../../../components/Tag";
import {MenuComponent} from "Components/Menu";
import Modal, {ModalBody, ModalFooter} from "Components/Modal";
import Form from "../../../../../../components/Form";
import {usePagination} from "Components/uiControls/Table/hooks";
import ReportListFilters from "Modules/reports/views/main/components/reportsTab/.components/reportListFilters";
import {getReportTypeName} from "Modules/reports/.globals/helpers";
import VirtualizedTable from "Components/uiControls/VirtualizedTable";
import {catalogSetUrlsDownload} from "Core/catalogs/redux/actions";
import {reportsOpenModalDownloadFiles} from "Modules/reports/views/main/components/reportsTab/.components/modalDownloadFiles/redux/actions";
import { getIntegerRange } from "Modules/reports/views/main/components/scheduleTab/.components/reportSetup/helper";

const ReportsTab = (props) => {
    const {messages} = props.intl;
    const divRef = useRef();
    const modalDeletePinnedRef = useRef();
    const tabRef = useRef();
    const tableRef = useRef();
    const reports = useSelector(state => state.reportsMainRTRedux.reports);
    const loadingGlobalReport = useSelector(state => state.reportsMainRTRedux.loadingGlobalReport);
    const showMessageReport = useSelector(state => state.reportsMainRTRedux.showMessageReport);
    const messageReport = useSelector(state => state.reportsMainRTRedux.messageReport);
    const statusMessageReport = useSelector(state => state.reportsMainRTRedux.statusMessageReport);
    const titleReport = useSelector(state => state.reportsMainRTRedux.titleReport);
    const updateReport = useSelector(state => state.reportsMainRTRedux.updateReport);
    const fieldUpdated = useSelector(state => state.reportsMainRTRedux.fieldUpdated);
    const newReport = useSelector(state => state.catalogsRedux.newReport)
    const drivers = useSelector(state => state.catalogsRedux.drivers);
    const driversIgnoreStatus = useSelector(state => state.catalogsRedux.driversIgnoreStatus);
    const services = useSelector(state => state.catalogsRedux.maintenanceServicesItems);

    const {
        loadingSearchReportLog,
        reportsLogs,
        totalReportsLogs,
        loadingReportParams,
        reportParams,
        error
    } = useSelector(state => state.reportsTapSearchReportLogRedux);

    const units = useSelector(state => state.catalogsRedux.unitsList);
    const {timeZone} = useSelector(state => state.securityRedux.user);
    const user = useSelector(state => state.securityRedux.user);

    const reportTypeOptions = useReportTypeOptions(user);

    const lang = useSelector(state => state.commomRedux.language);
    const [openReportsTooLargeModal, setOpenReportsTooLargeModal] = useState(false);
    const loadingDeleteReportLog = useSelector(state => state.reportsMainRTRedux.loadingDeleteReportLog);
    const deletedReportLog = useSelector(state => state.reportsMainRTRedux.deletedReportLog);
    const [searchText, setSearchText] = useState("");

    const [selectedItems, setSelectedItems] = useState([]);
    const [exclusionsItems, setExclusionsItems] = useState([])
    const [selectedAll, setSelectedAll] = useState(false);

    const [openDelete, setOpenDelete] = useState(false);
    const [rowsToDelete, setRowsToDelete] = useState([]);
    const [rowToExcludes, setRowToExcludes] = useState([])

    const [rowsPerPage, setRowsPerPage] = useState(30);
    const [offset, setOffset] = useState(1);
    const [resetCache, setResetCache] = useState(0);
    const [currentFilterReport, setCurrentFilterReport] = useState({id: TYPE_REPORT_RECENT, label: 'Recent', value: true});

    const [width, setWidth] = useState(0)
    const [numberTab, setNumberTab] = useState(0)

    const dispatch = useDispatch();
    const frmDeleteSchedule = useRef();
    const pagination = usePagination(tableRef, searchReportLog);
    const exportResult = useSelector(state => state.commomRedux.exportResult);
    const momentFormat = GetFormatForMomentTwoCharacters(user.dateformat)
    const dataEntityReport = useSelector(state => state.reportsListShowReportFilterRedux)
    const {isModalOpen} = dataEntityReport || false;
    const [shouldRender, setShouldRender] = useState(false);
    const [currentViewReport, setCurrentViewReport] = useState({});
    const [executeTypeParams, setExecuteTypeParams] = useState('');

    useEffect(() => {
        if (isModalOpen) {
            setShouldRender(true);
        }
    }, [isModalOpen]);

    const [dataModalPinned, setDataModalPinned] = useState({
        report: {},
        isOpenModal: false
    });

    const tags = useSelector(state => state.catalogsRedux.tags);
    const haveUntagged = tags?.find(t => t?.id == -1) ? true : false;
    const customFieldsReports = useReportCustomFields(haveUntagged)
    const pageCountRef = useRef(1)

    useEffect(() => {
        // Effect to load the initial information in the home
        setOffset(1)
        dispatch(searchReportLog({category: currentFilterReport.id, openSearch: "", limit: rowsPerPage, offset: 1}));

        return () => {
            dispatch(clearRedux("REPORTS_MAIN_RT"))
            dispatch(clearRedux("REPORTS_MODAL_EXTRA_TIME"))
            // Clear previous rep.handleChangeTab
            dispatch(clearRedux("CATALOG_REDUX", {newReport: null}))
            dispatch(clearRedux("CATALOG_REDUX", {urlsDownload: []}))
        }
    }, []);

    useEffect(() => {
        if (!loadingReportParams && reportParams && executeTypeParams === 'getReport') {
            const allDrivers = currentViewReport.includeInactiveDrivers ? driversIgnoreStatus : drivers;
            getReport(currentViewReport.id, currentViewReport.reportName, currentViewReport.format, currentViewReport.outputUrl, reportParams.UIParams, false, currentViewReport.dataResultPath, currentViewReport.createdOn, currentViewReport.id, [],
                messages, dispatch, reportTypeOptions, allDrivers, units, lang)
            setExecuteTypeParams('');
        } else if (!loadingReportParams && reportParams && executeTypeParams === 'copyReportToSetup') {
            dispatch(reportsMainShowReportSetup())
            dispatch(reportsMainSetFormDataReportSetup({...reportParams.UIParams, reportParams}))
        } else if (!loadingReportParams && reportParams && executeTypeParams === 'copyReportToSchedule') {
            const schedule = {
                "name": currentViewReport.reportName,
                "reportType": currentViewReport.reportType,
                "category": {
                    "field": currentViewReport.field,
                    "includeInactiveDrivers": currentViewReport.includeInactiveDrivers || false,
                    "value": [],
                    "categoryName": reportParams.UIParams.filters.filterEntity,
                    "dataResultFilter": reportParams.category.dataResultFilter,
                    "entities": reportParams.UIParams.filters.filterArray,
                },
                frequency: 'daily',
                monthDays: [0],
                "integerRanges": getIntegerRange(currentViewReport.reportType, reportParams.UIParams.filters?.otherFilters),
                "dateRange": reportParams.dateRange,
                "weekDays": [],
                "subscribers": [],
                "format": currentViewReport.format,
                "stringValues": [],
                "filterValues": [],
                "deliveryOn": [],
                "selectAll": reportParams.UIParams.isAll,
            }
            if (currentViewReport.reportType === 'fuelLevelReport'){
                schedule.stringValues = [{
                        values:[Number(reportParams.UIParams.filters.otherFilters?.percentage) || 10]
                    }
                ]
                schedule.filterValues = [{
                    entities: [{ id: reportParams.UIParams.filters.otherFilters?.event}]
                    }
                ]
            }
            dispatch(getPreSchedule(schedule))
            dispatch(reportsScheduleShowReportSetup())
        }
    }, [loadingReportParams])

    useEffect(() => {
        dispatch(catalogLoad(["drivers", "driversIgnoreStatus", "maintenanceServicesItems"]));
    }, [])

    useEffect(() => {
        if (numberTab == 0) {
            setResetCache(prev => prev + 1)
        }
    }, [numberTab])

    useEffect(() => {
            setResetCache(0)
        if (currentFilterReport) {
        }
    }, [currentFilterReport])

    useEffect(() => {
        if (newReport) {
            if (newReport.status === "FAILED") {
                NotificationManager.error(
                    messages['reportsMainRT_exportReportMessageFailed'],
                    messages['error'],
                    3000,
                    null,
                    null,
                    'error'
                );
            } else if (newReport.exportFile || newReport.status === "SUCCESS") {
                // when a new report is added through the catalog, we show the notification, reload the table and the home items
                setOffset(1)
                dispatch(searchReportLog({category: currentFilterReport.id, openSearch: "", limit: rowsPerPage, offset: 1}));
                if (!newReport.exportFile) {
                    NotificationManager.success(
                        messages['reportsMainRT_exportReportMessageSuccess'],
                        messages['success'],
                        3000,
                        null,
                        null,
                        'success',
                    );
                }
            }

            const timer = setTimeout(() => {
                if (!newReport.exportFile && newReport.status === "SUCCESS") {
                    dispatch(reportsVerifyStateReport(newReport.reportInstanceId, newReport.report, true));
                }
                dispatch(clearRedux("CATALOG_REDUX", {newReport: null}))
            }, 500);
            return () => clearTimeout(timer);

        }
    }, [JSON.stringify(newReport)]);

    useEffect(() => {
        const countReports = Object.values(reports).length;
        if (countReports > 0) {
            let selectedTab = tabRef.current?.selectedTab || 0
            if (selectedTab > 0 || countReports != selectedTab) {
                //tabRef.current?.onChangeSelectedTab(countReports)
                setNumberTab(countReports)
            }
        }
    }, [Object.values(reports).length]);

    useEffect(() => {

        if (deletedReportLog) {
            NotificationManager.success(
                messages['success'],
                messages['reportsMainST_deleteReport'],
                3000,
                null,
                null,
                'warning',
                null
            );
            dispatch(searchReportLog({category: currentFilterReport.id, openSearch: "", limit: rowsPerPage, offset}));
            setSelectedItems([]);
            setExclusionsItems([]);
            setOpenDelete(false)
            tableRef.current?.setSelectedItems([]);
            setRowsToDelete([]);
        }
    }, [deletedReportLog]);


    useEffect(() => {
        if (exportResult) {
            NotificationManager.info(
                messages['exportInProcessBody'],
                messages['exportInProcessTitle'],
                4000,
                null,
                null,
                'info',
                null
            );
            dispatch(clearRedux("COMMON", {exportResult: null, exportName: null}));
        }
    }, [exportResult]);

    useEffect(() => {
        if (showMessageReport) {
            // Show message when report is generated
            if (statusMessageReport === 'SUCCESS') {
                NotificationManager.success(
                    null,
                    null,
                    3000,
                    null,
                    null,
                    null,
                    titleReport,
                    messageReport
                );
                setOffset(1)
                dispatch(searchReportLog({category: currentFilterReport.id, openSearch: "", limit: rowsPerPage, offset: 1}));
            } else {
                NotificationManager.error(
                    null,
                    null,
                    3000,
                    null,
                    null,
                    null,
                    titleReport,
                    messageReport
                );
            }
            dispatch(clearRedux('REPORTS_MAIN_RT', {showMessageReport: false, messageReport: '', titleReport: '', statusMessageReport: ''}))
        }
    }, [showMessageReport])

    useEffect(() => {
        if (updateReport?.data.id) {

            // this event is triggered occurs when a report is updated either by name or becomes one of the favorites

            if (updateReport.actionName == 'reportName') {
                NotificationManager.success(
                    messages['reportsMainRT_updatedReport'],
                    messages['success'],
                    3000,
                    null,
                    null,
                    'success',
                );
            } else {
                NotificationManager.success(
                    messages['reportsMainRT_addBeen'],
                    messages['success'],
                    3000,
                    null,
                    null,
                    'success',
                );
            }


            if (fieldUpdated === FIELD_NAME_IS_STARRED) {
                // If the report is starred just update the starred List
                dispatch(searchReportLog({category: currentFilterReport.id, openSearch: "", limit: 99, offset: 0}));
                setSelectedItems([]);
                setExclusionsItems([])
                setOpenDelete(false)
                tableRef.current?.setSelectedItems([]);
                setRowsToDelete([]);
                setOffset(0)
            } else if (fieldUpdated === FIELD_NAME_REPORT) {
                // If the name changed update all three lists
                setOffset(1)
                dispatch(searchReportLog({category: currentFilterReport.id, openSearch: "", limit: rowsPerPage, offset: 1}));
            }
            dispatch(clearRedux("REPORTS_MAIN_RT", {fieldUpdated: '', updateReport: null}))
        }
    }, [updateReport?.data]);

    useEffect(() => {
        setWidth(divRef.current?.clientWidth)

        function handleWindowResize() {
            setWidth(divRef.current?.clientWidth);
        }

        window.addEventListener('resize', handleWindowResize);
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, [divRef.current?.clientWidth]);

    const pinnedReportHandler = (pin, id, createdOn) => {
        // function to upload the starred field
        if (id) {
            dispatch(reportUpdateField(id, pin, FIELD_NAME_IS_STARRED, createdOn))
        }
    }

    const renderIconReportReady = (number, isIconRight) => {
        // Render icons based on the tab it is on
        return (
            <h3 className={cx("number-reports-ready-ctn", "reports-no-margin", isIconRight ? "icon-right-home-report" : "")}>
            <span className={cx("number-reports-ready", isIconRight ? "span-icon-right-home-report" : "")}>
                {number || 0}
            </span>
            </h3>
        )
    }
    const onChangeTab = (tabNumber) => {
        setNumberTab(tabNumber)
    }
    //change label item report module
    const selectedDriverUnitsLabel = (label) => {
        switch (label) {
            case 'unitGroupId':
                return messages['Unit_Groups']
            case 'deviceId':
                return messages['units']
            case 'driverId':
                return messages['drivers']
            case 'driverGroupId':
                return messages['driver_groups']
            default:
                return messages['units']
        }
    }

    const TimeframeLabel = (label) => {

        switch (label) {
            case 'Today':
            case 'Hoy':
                return messages['date_today']

            case 'Yesterday':
            case 'Ayer':
                return messages['date_yesterday']

            case 'This Week':
            case 'Esta semana':
                return messages['date_this_week']

            case 'Last Week':
            case 'La semana pasada':
                return messages['date_last_week']

            case 'This Quarter':
            case 'Este trimestre':
                return messages['date_this_quarter']

            case 'Last Quarter':
            case 'El trimestre pasado':
                return messages['date_last_quarter']

            case 'Custom':
            case 'Personalizado':
                return messages['date_custom']

            case 'This Month':
            case 'Este Mes':
                return messages['date_this_month']

            case 'Last Month':
            case 'El mes pasado':
                return messages['date_last_month']

            default:
                return messages['date_today']
        }

    }

    const renderItems = (parameters) => {
        if (currentFilterReport?.value) {
            return <TableViewRecently {...parameters}></TableViewRecently>;
        } else {
            return <TableViewPinned {...parameters}></TableViewPinned>;
        }
    }

    const handleModalFilter = (content) => {
        dispatch(openModal( ))
        dispatch(searchReportParams({
            id: content.id,
            createdOn: content.createdOn,
        }))
    }

    const headers = (items) => {
        if (currentFilterReport?.value) {
            return <TableViewRecentlyHeader showCheck={items?.length} items={items}></TableViewRecentlyHeader>;
        } else {
            return <TableViewPinnedHeader showCheck={items?.length} items={items}></TableViewPinnedHeader>;
        }
    }

    const renderColumnReports = (title, items, total) => {
        const recentFilter = {id: TYPE_REPORT_RECENT, label: messages['reportsRecentlyGenerated'], value: true}
        const pinnedFilter = {id: TYPE_REPORT_STARRED, label: messages['reportsPinnedReports'], value: false}
        return (
            <>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item xs={6} sm={1} style={{display: "flex"}}>
                        <TableButtons
                            messages={messages}
                            tableRef={tabRef}
                            {...pagination}
                            buttons={['export']}
                            onExport={(type) => {
                                const headers = columns.filter(x => x.show && x.export).map(x => {
                                    return {
                                        header: x.title,
                                        column: x.key,
                                        functionString: x.functionString
                                    }
                                }) || [];

                                dispatch(sendGenericExport(
                                    "getReportLog",
                                    type,
                                    `
                                        query getReportLog ($openSearch: String, $limit: Int, $offset: Int, $category: CategoryReport, $endDate: AWSDateTime) {
                                            getReportLog (openSearch: $openSearch, limit: $limit, offset: $offset, category: $category, endDate: $endDate) {
                                                items {
                                                    id
                                                    reportName
                                                    reportType
                                                    format
                                                    outputUrl
                                                    reportStatus
                                                    createdOn
                                                    params
                                                    delayedOn
                                                    scheduled
                                                    dataResultPath
                                                    reportLogId
                                                    dateRangeFilter
                                                    runFor
                                                    reportTypeFilter
                                                    timeframeName
                                                    formatFilter
                                                    createOnFilter
                                                    filterType
                                                    status
                                                }
                                                total
                                                offset
                                            }
                                          }
                                        `,
                                    {openSearch: searchText, category: currentFilterReport.id, endDate: new Date().toISOString()},
                                    headers,
                                    {unit: 'px', size: 'A4', orientation: 'landscape', fontSize: 10}
                                ));
                            }}
                        />
                        <AddButton icon="delete" tooltip={messages['delete']}
                                   onClick={() => {
                                       if (selectedItems?.length > 0) {
                                           setOpenDelete(true)
                                           setRowsToDelete(selectedItems.concat())
                                       } else {
                                           setOpenDelete(true)
                                           setRowToExcludes(exclusionsItems.concat())
                                       }
                                   }}
                                   className={((selectedItems.length > 0 || exclusionsItems.length > 0) || selectedAll) ? 'icon-button' : 'disabled hidden-delete-schedule'}
                        />
                    </Grid>

                    <Grid item xs={6} sm={2} style={{display: "flex", gap: "5px"}}>
                        <Grid item xs key={1}>
                            <Pill
                                key={1}
                                textField="label"
                                item={recentFilter}
                                outlined={recentFilter?.id !== currentFilterReport.id}
                                onClick={() => {
                                    setCurrentFilterReport(recentFilter)
                                    setRowsPerPage(rowsPerPage)
                                    setSearchText('')
                                    setSelectedItems([])
                                    setExclusionsItems([])
                                    setSelectedAll(false)
                                    setOpenDelete(false)
                                    pageCountRef.current = 1
                                    setRowsToDelete([]);
                                    setOffset(1)
                                    dispatch(searchReportLog({category: recentFilter.id, openSearch: "", limit: rowsPerPage, offset: 1}));
                                }}
                                style={{border: '0', width: '100%'}}
                                colorField="default"
                                colorSelection="66bb6a"
                            />
                        </Grid>
                        <Grid item xs key={2}>
                            <Pill
                                key={2}
                                textField="label"
                                item={pinnedFilter}
                                outlined={pinnedFilter?.id !== currentFilterReport.id}
                                onClick={() => {
                                    setCurrentFilterReport(pinnedFilter)
                                    setRowsPerPage(rowsPerPage)
                                    setSearchText('')
                                    setSelectedItems([])
                                    setExclusionsItems([])
                                    setSelectedAll(false)
                                    setOpenDelete(false)
                                    setRowsToDelete([]);
                                    pageCountRef.current = 1
                                    setOffset(1)
                                    dispatch(searchReportLog({category: pinnedFilter.id, openSearch: "", limit: rowsPerPage, offset: 1}));
                                }}
                                style={{border: '0', width: '100%'}}
                                colorField="default"
                                colorSelection="66bb6a"
                            />
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sm={4} className="mb-10 text-right">
                        <InputSearch
                            onSearch={onChangeSearchText}
                            onSearchWhenFinish={(txt) => {
                                // getDriverTasks(txt);
                            }}
                            searchWhenFinishedTyping={true}
                            searchText={searchText}
                        />
                    </Grid>
                </Grid>

                <Grid item sm={12} style={{overflowX: "auto"}}>
                    {(headers(items))}
                    <VirtualizedTable
                        container={tableRef}
                        renderItems={renderItems}
                        isNextPageLoading={loadingSearchReportLog}
                        handleNewPageLoad={() => {
                            const page = pageCountRef.current + 1
                            dispatch(searchReportLog({category: currentFilterReport.id, openSearch: searchText, limit: rowsPerPage, offset: page}));
                            pageCountRef.current = page
                            setOffset(page)
                        }}
                        data={items || []}
                        total={total}
                        noDataMessage={currentFilterReport.value ? messages['noGeneratedReport_defaultNewMessage'] : messages['thereAreNoPinned']}
                        containerClass={`container-virtualized-reports-home ${messages['at'] === "a las" && "container__table_large"}`}
                        resetCache={resetCache}
                    />
                </Grid>

                <ModalDeletePinned
                    state={dataModalPinned}
                    setState={setDataModalPinned}
                />

                <Modal
                    size={'small'}
                    open={openDelete}
                    handleClose={handleCloseDelete}
                    title={`${messages['delete']}`}
                >
                    <ModalBody>
                        <div style={{padding: '0px 25px'}}>
                            <Form ref={frmDeleteSchedule} onValidSubmit={() => {

                                const ids = rowsToDelete?.map((item) => item.reportLogId)
                                const idsExclusions = rowToExcludes?.map((item) => item.reportLogId) ?? []
                                if (recentFilter?.id === currentFilterReport.id || pinnedFilter?.id === currentFilterReport.id) {
                                    setSelectedAll(false)
                                    if (exclusionsItems.length > 0) {
                                        dispatch(
                                            deleteReportLog(
                                                rowToExcludes[0].id,
                                                rowToExcludes[0].createdOn,
                                                rowToExcludes[0].reportLogId,
                                                ['-99'],
                                                currentFilterReport.id,
                                                idsExclusions
                                            )
                                        )
                                    } else {
                                        dispatch(
                                            deleteReportLog(
                                                rowsToDelete[0]?.id,
                                                rowsToDelete[0]?.createdOn,
                                                rowsToDelete[0]?.reportLogId,
                                                selectedAll ? ['-99'] : (total === rowsToDelete.length ? ['-99'] : ids),
                                                currentFilterReport.id,
                                                idsExclusions
                                            )
                                        )
                                    }
                                } else {
                                    dispatch(reportUpdateFieldBatch(selectedItems, '', FIELD_NAME_IS_STARRED))
                                }
                            }}>
                                <p style={{overflowWrap: 'anywhere'}}>
                                    {rowsToDelete?.length > 0 ?
                                        (selectedAll
                                            ? messages['reportsMainST_deleteAllReportsMessage']
                                            : messages['reportsMainST_deleteReportsMessage']) :
                                        messages['reportsMainST_deleteReportsMessage']}
                                </p>
                            </Form>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className='container-buttons-delete-modal-reports'>
                            <Button
                                id={'btn-cancel-schedule'}
                                type="button"
                                onClick={handleCloseDelete}
                                className="btn-cancel"
                            >
                                {messages['cancel']}
                            </Button>
                            <Button
                                id={'btn-schedule'}
                                type="button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    frmDeleteSchedule.current.submit();
                                }}
                                className="btn-blue"
                                disabled={loadingDeleteReportLog}
                            >
                                {messages['delete']}
                            </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <div>
                    {shouldRender && <ReportListFilters messages={messages}/>}
                </div>
            </>
        )
    }

    const ReportStatus = ({report, loading, reports, onChangeTab}) => {
        const id = `reportStatus-${uuidv4()}`

        const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

        const copyReportToSetup = (report = null, params = null) => {
            dispatch(searchReportParams({
                id: report.id,
                createdOn: report.createdOn,
            }))
            setCurrentViewReport(report)
            setExecuteTypeParams('copyReportToSetup');
        }

        const checkIfReportIsOpen = () => {
            const index = Object.values(reports).findIndex((reportOpened, key) => {
                return reportOpened.dataResultPath === report.dataResultPath;
            })

            if (index >= 0) {
                onChangeTab(index + 1)
                return true;
            }
        }
        if (currentFilterReport.value) {
            switch (report.reportStatus) {
                case 'fail': {
                    return (
                        <strong>{messages['videoClipRequest_failed']}</strong>
                    )
                }
                case 'inProgress': {
                    return (
                        <span>{messages['videoClipRequest_inProgress']}</span>
                    )
                }
                case 'completed': {
                    const menuScheduleOptions = [messages["reportsPinReports"], report.format === 'json' ? messages["generatedReport_view"] : messages["generatedReport_download"], messages['generatedReport_copy'], messages['reportsView_schedule']];
                    let newUrl = "";
                    let newLink;
                    if (report.outputUrl) {
                        let url = "";
                        try {
                            url = JSON.parse(JSON.stringify(report.outputUrl));
                            url = JSON.parse(url);
                        } catch (e) {
                        }
                        if (Array.isArray(url)) {
                            if (url.length > 1) {
                                newLink = url
                            } else {
                                newLink = url[0]?.url
                            }
                        } else {
                            newLink = report.outputUrl
                        }
                        if (newLink) {
                            newUrl = newLink?.includes("http") ? newLink : `https://${newLink}`
                        }
                    }
                    return (
                        <>
                            <div className={isSafari ? 'btn-report-status-safari' : 'btn-report-status'}
                                 style={{"--position": (currentFilterReport.value ? 19 : 18) + "px"}}>
                                {
                                    report.format === 'json' ? (
                                        <Button
                                            onClick={() => {
                                                const reportIsOpen = checkIfReportIsOpen();
                                                if (!reportIsOpen) {
                                                    dispatch(searchReportParams({
                                                        id: report.id,
                                                        createdOn: report.createdOn,
                                                    }))
                                                    setCurrentViewReport(report)
                                                    setExecuteTypeParams('getReport');
                                                }
                                            }}
                                            id="generate-report-btn"
                                            className="btn-blue"
                                        >
                                            {messages["generatedReport_view"]}
                                        </Button>
                                    ) : (
                                        <>
                                            {newLink ?
                                                (<Button
                                                    onClick={() => {
                                                        if (Array.isArray(newLink) && newLink.length > 1) {
                                                            dispatch(catalogSetUrlsDownload(newLink));
                                                            dispatch(reportsOpenModalDownloadFiles(true));
                                                        } else {
                                                            const a = document.createElement('a');
                                                            a.href = newUrl;
                                                            a.download = "";
                                                            document.body.appendChild(a);
                                                            a.click();
                                                            document.body.removeChild(a);
                                                        }
                                                    }}
                                                    id="generate-report-btn"
                                                    className="btn-blue"
                                                >
                                                    {messages["generatedReport_download"]}
                                                </Button>) :
                                                (<Button
                                                    id="generate-report-btn"
                                                    className="btn-disabled"
                                                >
                                                    {messages["reportSetup_noDataFound"]}
                                                </Button>)
                                            }
                                        </>
                                    )
                                }
                                <MenuComponent
                                    options={newLink ? menuScheduleOptions : menuScheduleOptions.filter(value => value !== messages["generatedReport_download"])}
                                    icon={'more_vert'}
                                    className="icon-menu-item-report icon-menu-home-report"
                                    getContentAnchorEl={null}
                                    elementId={id}
                                    onClick={(type) => {
                                        if (type === messages["generatedReport_view"]) {
                                            const reportIsOpen = checkIfReportIsOpen();
                                            if (!reportIsOpen) {
                                                dispatch(searchReportParams({
                                                    id: report.id,
                                                    createdOn: report.createdOn,
                                                }))
                                                setCurrentViewReport(report)
                                                setExecuteTypeParams('getReport');
                                            }
                                        } else if (type === messages["generatedReport_download"]) {
                                            window.open(newUrl, "_blank");
                                        } else if (type === messages["reportsPinReports"]) {
                                            dispatch(reportUpdateField(report.id, new Date().toISOString(), FIELD_NAME_IS_STARRED, report.createdOn))
                                        } else if (type === messages["generatedReport_copy"]) {
                                            copyReportToSetup(report);
                                        } else if (type === messages['reportsView_schedule']) {
                                            dispatch(searchReportParams({
                                                id: report.id,
                                                createdOn: report.createdOn,
                                            }))
                                            setCurrentViewReport(report)
                                            setExecuteTypeParams('copyReportToSchedule');
                                            props.handleChangeTab(1)
                                        }
                                    }}
                                    disabled={loading}
                                />
                            </div>
                            <span id={id} className="span-report-status">{messages['reportsReady_ready']}</span>
                        </>
                    )
                }
                case 'pending': {
                    return (
                        <span style={{color: "gray"}}>{messages['videoClipRequest_inProgress']}</span>
                    )
                }
                case 'processFailed': {
                    return (
                        <span>{messages['videoClipRequest_failed']}</span>
                    )
                }
            }
        } else {
            const menuScheduleOptions = [messages["generatedReport_edit"], messages['delete'], messages['reportsView_schedule']];
            return (
                <>
                    <div className={isSafari ? 'btn-report-status-safari' : 'btn-report-status'}
                         style={{"--position": (currentFilterReport.value ? 19 : 18) + "px"}}>
                        {
                            <Button
                                onClick={() => {
                                    dispatch(searchReportParams({
                                        id: report.id,
                                        createdOn: report.createdOn,
                                    }))
                                    setCurrentViewReport(report)
                                    setExecuteTypeParams('getReport');
                                }}
                                id="generate-report-btn"
                                className="btn-blue"
                            >
                                {messages["reportSetup_getReport"]}
                            </Button>
                        }
                        <MenuComponent
                            options={menuScheduleOptions}
                            icon={'more_vert'}
                            className="icon-menu-item-report icon-menu-home-report"
                            elementId={id}
                            onClick={(type) => {
                                if (type === messages["delete"]) {
                                    setDataModalPinned({
                                        report,
                                        isOpenModal: true
                                    })
                                } else if (type === messages["edit"]) {
                                    copyReportToSetup(report, {edit: {id: report.id, createdOn: report.createdOn}});
                                } else if (type === messages['reportsView_schedule']) {
                                    dispatch(searchReportParams({
                                        id: report.id,
                                        createdOn: report.createdOn,
                                    }))
                                    setCurrentViewReport(report)
                                    setExecuteTypeParams('copyReportToSchedule');
                                    props.handleChangeTab(1)
                                }
                            }}
                            getContentAnchorEl={null}
                            disabled={loading}
                        />
                    </div>
                    <span id={id}>{report.format === 'json' ? 'Web' : report.format.toLocaleUpperCase()}</span>
                </>
            )
        }
    }

    const ModalDeletePinned = (props) => {

        const {state, setState} = props

        const {isOpenModal, report} = state;

        const setIsOpenModal = (value) => {
            setState({
                ...dataModalPinned,
                isOpenModal: value
            })
        }

        return (
            <Modal
                size={'small'}
                open={isOpenModal}
                handleClose={() => setIsOpenModal(false)}
                title={`${messages['delete']}`}
            >
                <ModalBody>
                    <div style={{padding: '0px 25px'}}>
                        <Form ref={modalDeletePinnedRef}
                              onValidSubmit={() => { dispatch(reportUpdateField(report.id, '', FIELD_NAME_IS_STARRED, report.createdOn)) }}
                        >
                            <p style={{overflowWrap: 'anywhere'}}>
                                {messages['reportReportType_DeletePinned']}
                            </p>
                        </Form>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className='container-buttons-delete-modal-reports'>
                        <Button
                            id={'btn-cancel-schedule'}
                            type="button"
                            onClick={() => setIsOpenModal(false)}
                            className="btn-cancel"
                        >
                            {messages['cancel']}
                        </Button>
                        <Button
                            id={'btn-schedule'}
                            type="button"
                            onClick={(e) => {
                                e.preventDefault();
                                modalDeletePinnedRef.current.submit();
                                setDataModalPinned({
                                    report: {},
                                    isOpenModal: false
                                })
                            }}
                            className="btn-blue"
                        >
                            {messages['delete']}
                        </Button>
                    </div>
                </ModalFooter>
            </Modal>
        )
    }

    const TableViewRecentlyHeader = (data) => {
        return (
            <Grid container xs={12} item direction={"row"}
                  className={`container-headers-reports-home ${messages['at'] === "a las" && "container__table_large"} ${(data.items?.length ? '' : 'nodata')}`}>
                <Grid item sm={0.8} className={'reports-cut-text'}>
                    <Checkbox
                        style={{color: "black", zIndex: 0}}
                        className={'checkbox-table-select'}
                        checked={selectedAll}
                        disabled={!data.showCheck}
                        indeterminate={selectedItems.length > 0 || exclusionsItems.length > 0}
                        onChange={() => {
                            if (selectedItems.length > 0 && !selectedAll) {
                                setSelectedItems([])
                                setExclusionsItems([])
                                setSelectedAll(false)
                            } else {
                                setSelectedAll(!selectedAll)
                                setExclusionsItems([])
                            }
                        }}
                        inputProps={{'aria-label': 'select all desserts'}}
                        size="small"
                    />
                </Grid>
                <Grid item sm={1.7} className={'reports-cut-text'}>
                    {messages['reportsMainST_report']}
                </Grid>
                <Grid item sm={0.8} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectViewBy']}
                </Grid>
                <Grid item sm={1.4} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectRunFor']}
                </Grid>
                <Grid item sm={4.3} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectTimeframe']}
                </Grid>
                <Grid item sm={2} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectCreated']}
                </Grid>
                <Grid item sm={1} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectStatus']}
                </Grid>
            </Grid>
        )
    }
    const TableViewRecently = ({index, style, content, id, previousItem}) => {
        const textAll = getEntitiesAllType(content.viewBy, messages);
        const count = content.runForIsAll ? textAll : content.runForCount;
        const name = `${count} ${getNameUnitDriverTag(content.runForType, messages, true)}${(content.runForCount && content.runForIsAll) ? messages['reportSetup_finallyPlural'] : ''}`;
        const localTimeStart = moment.utc(content.dateRangeStart).local().format();
        const localTimeEnd = moment.utc(content.dateRangeEnd).local().format();
        const start = formatDatetime(localTimeStart, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone).split(' ');
        const end = formatDatetime(localTimeEnd, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone).split(' ');

        const localTime = moment.utc(content.createdOn).local().format();
        let topEntities = content.topEntities ? JSON.parse(content.topEntities) : []
        if (typeof topEntities === 'string') {
            try {
                topEntities = JSON.parse(topEntities)
            } catch (e) {
            }
        }

        const useCheckReportTitle = (services && customFieldsReports) ? getInfoReportFilter(content, {services, customFieldsReports}) : '';

        const getEditLabel = (row, isSafari, size) => {
            return <div className="item-name-content-report-home" id={isSafari ? 'item-name-content-report-home-safari' : 'item-name-content-report-home'}
                        style={{"--text": (size) + "px"}}>
                <Tooltip placement="left" title={row?.reportName}>
                    <div className={"report-content-editable-label-home"}>
                        <Input
                            id="name"
                            type={"inline-report"}
                            placeholder={messages['mapLandmarkCreateEditLandmark_name']}
                            value={row?.reportName || ""}
                            showHoverIcon
                            showHoverIconIndex={row.reportLogId}
                            onChange={(newLabel) => {
                                if (newLabel.trim())
                                    dispatch(reportUpdateField(row.id, newLabel, FIELD_NAME_REPORT, row.createdOn))
                            }}
                            labelSave={messages['save']}
                            labelCancel={messages['cancel']}
                            required
                            requiredMessage={messages['required_field']}
                            maxLength={50}
                        />
                    </div>
                </Tooltip>
            </div>;
        }

        return (
            <div
                className="listItem homeItemReport content-item-reports-home"
                style={style}
                id={id}
            >
                <Grid container direction={"column"} className={"container-row-reports-home"}>
                    <Grid container item direction={"row"} style={{paddingTop: "1px", paddingBottom: "1px"}}>
                        <Grid item className={"report-ctn-text"} sm={0.8}>
                            <Checkbox
                                style={{color: "black", zIndex: 0}}
                                className={'checkbox-table-select'}
                                checked={
                                    selectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1 || (
                                        selectedAll && !(exclusionsItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1))
                                }
                                onChange={() => {

                                    let newSelectedItems = selectedAll ? exclusionsItems.concat() : selectedItems.concat();

                                    let indexCheck = newSelectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content)

                                    if (indexCheck > -1) {
                                        newSelectedItems.splice(indexCheck, 1)
                                    } else {
                                        newSelectedItems.push(content)
                                    }

                                    selectedAll ? setExclusionsItems(newSelectedItems) : setSelectedItems(newSelectedItems)

                                }}
                                inputProps={{'aria-label': 'select all desserts'}}
                                size="small"
                            />
                            {useCheckReportTitle !== '' && (
                                <div className='report-table-info-tooltip'>
                                    <Button
                                        type="icon"
                                        icon="info"
                                        placement="right"
                                        tooltip={useCheckReportTitle}
                                        tooltiptype="report"
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1.7}>
                            <div id="content-edi-report-name">
                                {getEditLabel(content, false, currentFilterReport.value ? (width > 960 ? 16 : 16.5) : 16)}
                                <div id={'temp-edit-report-name'}>
                                    <p>.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={0.8}>

                            <span className="">{`${getEntityType({filters: {filterType: content.viewBy === 'Unit' ? 'deviceId' : 'driverId'}}, messages)}`}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text report-tags-group"} sm={1.4}>

                            {count !== textAll && content.runForType === 'Tag' ? (
                                <>
                                    <Grid item sm>
                                        <div onClick={() => {
                                            handleModalFilter(content)
                                        }}>
                                            <TagsGroup
                                                options={(topEntities.map(data => {
                                                    data.color = data.color ? data.color : '000000'
                                                    return data
                                                }))}
                                                nameField="label"
                                                colorField="color"
                                                showMore = {content.runForCount - 4}
                                            />
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="show-more-text"
                                        onClick={() => {
                                            handleModalFilter(content)
                                        }}
                                    >
                                    <span
                                        className="home-report-title">{name}</span>
                                    </div>
                                </>
                            )}

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={4.3}>

                            {start[0] === end[0] ? (
                                <span>{`${TimeframeLabel(content.dateRangeName || 'Custom')}
                                (${start[0]} ${start[1]} ${start[2]} - ${end[1]} ${end[2]})`}
                            </span>
                            ) : (
                                <span>{`${TimeframeLabel(content.dateRangeName || 'Custom')}
                                (${start[0]} ${start[1]} ${start[2]}
                                -
                                ${end[0]} ${end[1]} ${end[2]})`}
                            </span>
                            )}

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2}>

                            <span>{formatDatetime(localTime, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone)}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1} id={"column-status-reports-home"}>

                            <ReportStatus report={content} loading={loadingSearchReportLog} reports={reports} onChangeTab={onChangeTab}></ReportStatus>

                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const TableViewPinnedHeader = (data) => {
        return (
            <Grid container xs={12} item direction={"row"}
                  className={`container-headers-reports-home ${messages['at'] === "a las" && "container__table_large"} ${data.items?.length ? '' : 'nodata'}`}>
                <Grid item sm={0.8} className={'reports-cut-text'}>
                    <Checkbox
                        style={{color: "black", zIndex: 0}}
                        className={'checkbox-table-select'}
                        checked={selectedAll}
                        disabled={!data.showCheck}
                        indeterminate={selectedItems.length > 0 || exclusionsItems.length > 0}
                        onChange={() => {
                            if (selectedItems.length > 0 && !selectedAll) {
                                setSelectedItems([])
                                setExclusionsItems([])
                                setSelectedAll(false)
                            } else {
                                setSelectedAll(!selectedAll)
                                setExclusionsItems([])
                            }
                        }}
                        inputProps={{'aria-label': 'select all desserts'}}
                        size="small"

                    />
                </Grid>
                <Grid item sm={2.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_reportName']}
                </Grid>
                <Grid item sm={2.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_reportType']}
                </Grid>
                <Grid item sm={1.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectViewBy']}
                </Grid>
                <Grid item sm={1.5} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectRunFor']}
                </Grid>
                <Grid item sm={2.2} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectTimeframe']}
                </Grid>
                <Grid item sm={1} className={'reports-cut-text'}>
                    {messages['reportsMainST_selectStatus']}
                </Grid>
            </Grid>
        )
    }

    const TableViewPinned = ({index, style, content, id, previousItem}) => {
        const textAll = getEntitiesAllType(content.viewBy, messages);
        const count = content.runForIsAll ? textAll : content.runForCount;
        const name = `${count} ${getNameUnitDriverTag(content.runForType, messages, true)}${(content.runForCount && content.runForIsAll) ? messages['reportSetup_finallyPlural'] : ''}`;
        const topEntities = JSON.parse(content.topEntities)
        const useCheckReportTitle = (services && customFieldsReports) ? getInfoReportFilter(content, {services, customFieldsReports}) : '';

        const getEditLabel = (row, isSafari, size) => {
            return <div className="item-name-content-report-home" id={isSafari ? 'item-name-content-report-home-safari' : 'item-name-content-report-home'}
                        style={{"--text": (size) + "px"}}>
                <Tooltip placement="left" title={row?.reportName}>
                    <div className={"report-content-editable-label-home"}>
                        <Input
                            id="name"
                            type={"inline-report"}
                            placeholder={messages['mapLandmarkCreateEditLandmark_name']}
                            value={row?.reportName || ""}
                            showHoverIcon
                            showHoverIconIndex={row.reportLogId}
                            onChange={(newLabel) => {
                                if (newLabel.trim())
                                    dispatch(reportUpdateField(row.id, newLabel, FIELD_NAME_REPORT, row.createdOn))
                            }}
                            required
                            requiredMessage={messages['required_field']}
                            maxLength={50}
                        />
                    </div>
                </Tooltip>
            </div>;
        }

        return (
            <div
                className="listItem homeItemReport content-item-reports-home"
                style={style}
                id={id}
            >
                <Grid container direction={"column"} className={"container-row-reports-home"}>
                    <Grid container item direction={"row"} style={{paddingTop: "1px", paddingBottom: "1px"}}>
                        <Grid item className={"report-ctn-text"} sm={0.8}>
                            <Checkbox
                                style={{color: "black", zIndex: 0}}
                                className={'checkbox-table-select'}
                                checked={
                                    selectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1 || (
                                        selectedAll && !(exclusionsItems.findIndex(x => x.reportLogId === content.reportLogId || x === content) > -1))
                                }
                                onChange={() => {

                                    let newSelectedItems = selectedAll ? exclusionsItems.concat() : selectedItems.concat();

                                    let indexCheck = newSelectedItems.findIndex(x => x.reportLogId === content.reportLogId || x === content)

                                    if (indexCheck > -1) {
                                        newSelectedItems.splice(indexCheck, 1)
                                    } else {
                                        newSelectedItems.push(content)
                                    }

                                    selectedAll ? setExclusionsItems(newSelectedItems) : setSelectedItems(newSelectedItems)

                                }}
                                inputProps={{'aria-label': 'select all desserts'}}
                                size="small"
                            />
                            {useCheckReportTitle !== '' && (
                                <div className='report-table-info-tooltip'>
                                    <Button
                                        type="icon"
                                        icon="info"
                                        placement="right"
                                        tooltip={useCheckReportTitle}
                                        tooltiptype="report"
                                    />
                                </div>
                            )}
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2.5}>
                            <div id="content-edi-report-name">
                                {getEditLabel(content, false, currentFilterReport.value ? (width > 960 ? 16 : 16.5) : 16)}
                                <div id={'temp-edit-report-name'}>
                                    <p>.</p>
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2.5}>

                            <span className="">{`${getReportTypeName(content.reportType, messages)}`}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1.5}>

                            <span className="">{`${getEntityType({filters: {filterType: content.viewBy === 'Unit' ? 'deviceId' : 'driverId'}}, messages)}`}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text report-tags-group"} sm={1.5}>

                            {count !== textAll && content.runForType === 'Tag' ? (
                                <>
                                    <Grid item xs>
                                        <div onClick={() => {
                                            handleModalFilter(content)
                                        }}>
                                            <TagsGroup
                                                options={(topEntities.map(data => {
                                                    data.color = data.color ? data.color : '000000'
                                                    return data
                                                }))}
                                                nameField="label"
                                                colorField="color"
                                                showMore = {content.runForCount - 4}
                                            />
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    <div
                                        className="show-more-text"
                                        onClick={() => {
                                            handleModalFilter(content)
                                        }}
                                    >
                                    <span
                                        className="home-report-title">{name}</span>
                                    </div>
                                </>
                            )}

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={2.2}>

                            <span>{TimeframeLabel(content.dateRangeName)}</span>

                        </Grid>
                        <Grid item className={"report-ctn-text"} sm={1} id={"column-status-reports-home"}>
                            <ReportStatus report={content} loading={loadingSearchReportLog} reports={reports} onChangeTab={onChangeTab}></ReportStatus>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        )
    }

    const onChangeSearchText = (newSearchText) => {
        setSearchText(newSearchText)
        pageCountRef.current = 1
        setSelectedItems([])
        setExclusionsItems([])
        setOffset(1)
        dispatch(searchReportLog({category: currentFilterReport.id, openSearch: newSearchText, limit: rowsPerPage, offset: 1}));
    }

    const handleCloseDelete = () => {
        setOpenDelete(false);
    };

    const copyReportToSetup = (report = null, params = null) => {
        let uIParams = formatParams(report.params, 'UIParams');


        if (!uIParams.filters) {
            uIParams = JSON.parse(uIParams)
        }

        if (params) uIParams = {...uIParams, ...params}
        dispatch(reportsMainShowReportSetup())
        dispatch(reportsMainSetFormDataReportSetup(uIParams))
    }

    const columns = getColumns(messages, loadingSearchReportLog, timeZone, dispatch, reportTypeOptions, units, currentFilterReport, TimeframeLabel, width, copyReportToSetup, props.handleChangeTab, user.dateformat);


    const copyReportToSetupFromReportInstance = (report = null, params = null) => {
        dispatch(reportsMainShowReportSetup())
        dispatch(reportsMainSetFormDataReportSetup(report))
    }

    const refClearReportSetup = useRef(null)
    const openReportSetup = () => {
        dispatch(reportsMainShowReportSetup())
        refClearReportSetup.current()
    }

    const scheduleReport = (report) => {
        const entities = [];
        report.filters.filterArray?.forEach(filter => {
            entities.push({...filter, type: filter.name})
        })
        const schedule = {
            "name": messages['reportsMainRT_' + report.type],
            "reportType": report.type,
            "category": {
                "field": report.filters.filterType,
                "includeInactiveDrivers": report.filters.includeInactiveDrivers,
                "value": [],
                "categoryName": report.filters.filterEntity,
                "dataResultFilter": false,
                "entities": entities,
            },
            frequency: 'daily',
            monthDays: [0],
            "integerRanges": getIntegerRange(report?.type, report.filters?.otherFilters),
            "dateRange": {
                "fields": [
                    "unitTime"
                ],
                "GTE": report.filters.startRange,
                "LTE": report.filters.endRange,
                "timeframeName": "Custom",
                "reportTimeRanges": []
            },
            "weekDays": [],
            "subscribers": [],
            "format": report.exportType,
            "stringValues": [],
            "filterValues": [],
            "deliveryOn": [],
            "selectAll": report.isAll,
        }

        if (report.type === 'fuelLevelReport'){
            schedule.stringValues = [{
                values:[Number(report.filters.otherFilters?.percentage) || 10]
            }
            ]
            schedule.filterValues = [{
                entities: [{ id: report.filters.otherFilters?.event}]
            }
            ]
        }
        props.handleChangeTab(1)
        dispatch(getPreSchedule(schedule))
        dispatch(reportsScheduleShowReportSetup())
    }
    return (
        <div>
            <Tabs
                ref={tabRef}
                type="square"
                id="tab-square-reports-background"
                onCloseTab={(index) => {
                    //remove from array the selected report from tab
                    const reportIdValues = Object.values(reports);
                    //remove from array the selected report from tab
                    let reportId = reportIdValues[index - 1].id
                    dispatch(reportsMainRemoveReport(reportId))
                }}
                scrollButtons
                tab={numberTab}
                onChange={(e) => {
                    setNumberTab(e)
                }}
                showTooltip
                labelTooltip={`${messages['reportsMainRT_more']}`}
                positionTooltip="bottom"

            >
                <TabContainer label={messages['generatedReport_home']} index={0} renderAlways={{classNameCustom: 'reports'}}
                              className="reports-tab-container-items">
                    <Grid
                        ref={divRef}
                        container
                        spacing={1}
                    >
                        {renderColumnReports(currentFilterReport.value ? messages['reportsRecentlyGenerated_title'] : messages['reportsPinnedReports_title'], reportsLogs, totalReportsLogs)}
                    </Grid>
                </TabContainer>
                {

                    Object.values(reports).map((report, key) => {
                        const paramsReport = {
                            messages,
                            report,
                            user,
                            copyReportToSetup: copyReportToSetupFromReportInstance,
                            openReportSetup,
                            scheduleReport,
                            isActive: numberTab === (key + 1),
                            reportsLogs
                        }
                        return (
                            <TabContainer label={getReportTypeName(report.type, messages) || formatTabLabel(report.type, key + 1)} index={key + 1} key={key} closeable renderAlways
                                          className="no-padding-top-report">
                                {
                                    report.type === "detailedReport" &&
                                    <DetailedReport {...paramsReport} />
                                }
                                {
                                    report.type === "actReport" &&
                                    <ActReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverFormsReportV2" &&
                                    <DriverFormsReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "driverStatusReportV2" &&
                                    <DriverStatusReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "summaryReport" &&
                                    <SummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "idleReport" &&
                                    <IdleReport {...paramsReport} />
                                }
                                {
                                    report.type === "ignitionReport" &&
                                    <IgnitionReport {...paramsReport} />
                                }
                                {
                                    report.type === "mileageReport" &&
                                    <MileageReport {...paramsReport} />
                                }
                                {
                                    report.type === "speedReport" &&
                                    <SpeedReport {...paramsReport} />
                                }
                                {
                                    report.type === "speedCoachingReport" &&
                                    <SpeedCoachingReport {...paramsReport} />
                                }
                                {
                                    report.type === "stopReport" &&
                                    <StopReport {...paramsReport} />
                                }
                                {
                                    report.type === "startStopIdleReport" &&
                                    <StartStopIdleReport {...paramsReport} />
                                }
                                {
                                    report.type === "simpleReport" &&
                                    <SimpleReport {...paramsReport} />
                                }
                                {
                                    report.type === "advancedSummaryReport" &&
                                    <AdvancedSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "mileageSummaryReport" &&
                                    <MileageSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverMileageSummaryReport" &&
                                    <DriverMileageSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "landmarkReport" &&
                                    <LandmarkReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverSafetyReport" &&
                                    <DriverSafetyReport {...paramsReport} />
                                }
                                {
                                    report.type === "alertLogReport" &&
                                    <AlertLogReport {...paramsReport} />
                                }
                                {
                                    report.type === "stateMileageReport" &&
                                    <StateMileageReport {...paramsReport} />
                                }
                                {
                                    report.type === "utilizationReport" &&
                                    <UtilizationReport {...paramsReport} />
                                }
                                {
                                    report.type === "latitudeLongitudeReport" &&
                                    <LatitudeLongitudeReport {...paramsReport} />
                                }
                                {
                                    report.type === "eventReport" &&
                                    <EventReport {...paramsReport} />
                                }
                                {
                                    report.type === "timesheetReport" &&
                                    <TimesheetReport {...paramsReport} />
                                }
                                {
                                    report.type === "pingReport" &&
                                    <PingReport {...paramsReport} />
                                }
                                {
                                    report.type === "landmarkBasedReport" &&
                                    <LandmarkBasedReport {...paramsReport} />
                                }
                                {
                                    report.type === "odometerReport" &&
                                    <OdometerReport {...paramsReport} />
                                }
                                {
                                    report.type === "geofenceBasedReport" &&
                                    <GeofenceBasedReport {...paramsReport} />

                                }
                                {
                                    report.type === "landmarkCrewReport" &&
                                    <LandmarkCrewReport {...paramsReport} />
                                }

                                {
                                    report.type === "landmarkEngineHoursReport" &&
                                    <LandmarkEngineHoursReport {...paramsReport} />
                                }
                                {
                                    report.type === "nonReportingReport" &&
                                    <NonReportingReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverTimesheetReport" &&
                                    <DriverTimesheetReport {...paramsReport} />
                                }
                                {
                                    report.type === "UnitInfoReport" &&
                                    <UnitInfoReport {...paramsReport} />
                                }
                                {
                                    report.type === "movingReport" &&
                                    <MovingReport {...paramsReport} />
                                }
                                {
                                    report.type === "landmarkSummaryReport" &&
                                    <LandmarkSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "locationReport" &&
                                    <LocationReport {...paramsReport} />
                                }
                                {
                                    report.type === "geofenceReport" &&
                                    <GeofenceReport {...paramsReport} />
                                }
                                {
                                    report.type === "jbDiagnosticsReport" &&
                                    <JBDiagnosticsReport {...paramsReport} />
                                }
                                {
                                    report.type === "diagnosticParametersReport" &&
                                    <DiagnosticParametersReport {...paramsReport} />
                                }
                                {
                                    report.type === "diagnosticIndicatorsReport" &&
                                    <DiagnosticIndicatorsReport {...paramsReport} />
                                }
                                {
                                    report.type === "jbSummaryReport" &&
                                    <JBSummaryReport {...paramsReport} />
                                }

                                {
                                    report.type === "temperatureReport" &&
                                    <TemperatureReport {...paramsReport} />
                                }
                                {
                                    report.type === "jbdtcReport" &&
                                    <JBDTCReport {...paramsReport} />
                                }
                                {
                                    report.type === "chatHistoryReport" &&
                                    <ChatHistoryReport {...paramsReport} />
                                }
                                {
                                    report.type === "engineHoursReport" &&
                                    <EngineHoursReport {...paramsReport} />
                                }
                                {
                                    report.type === "stopHistoryReport" &&
                                    <StopHistoryReport {...paramsReport} />
                                }
                                {
                                    report.type === "fsmTimeCardReport" &&
                                    <FSMTimeCardReport {...paramsReport} />
                                }
                                {
                                    report.type === "timeCardReportV2" &&
                                    <TimeCardReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "stopSummaryReport" &&
                                    <StopSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "formStopSummaryReport" &&
                                    <FormStopSummaryReport {...paramsReport} />
                                }
                                {
                                    report.type === "formStopSummaryReportV2" &&
                                    <FormStopSummaryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "driverStatusReport" &&
                                    <FsmDriverStatusReport {...paramsReport} />
                                }
                                {
                                    report.type === "driverFormsReport" &&
                                    <DriverFormsReport {...paramsReport} />
                                }
                                {
                                    report.type === "smartOneCUtilizationReport" &&
                                    <SmartOneCUtilizationReport {...paramsReport} />
                                }
                                {
                                    report.type === "fuelCardReport" &&
                                    <FuelCardReport {...paramsReport} />
                                }
                                {
                                    report.type === "deviceSummaryInfoReport" &&
                                    <DeviceSummaryInfoReport {...paramsReport} />
                                }
                                {
                                    report.type === "milesPerGallonReport" &&
                                    <MilesPerGallonReport {...paramsReport} />
                                }
                                {
                                    report.type === "vehicleMaintenanceItemsReport" &&
                                    <VehicleMaintenanceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "maintenanceItemsReport" &&
                                    <MaintenanceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "vehServiceItemsReport" &&
                                    <VehServiceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "serviceItemsReport" &&
                                    <ServiceItemsReport {...paramsReport} />
                                }
                                {
                                    report.type === "inputReport" &&
                                    <InputReport {...paramsReport} />
                                }
                                {
                                    report.type === "fsmCombinedReport" &&
                                    <FSMCombinedReport {...paramsReport} />
                                }
                                {
                                    report.type === "chatHistoryReportV2" &&
                                    <ChatHistoryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "behaviorReport" &&
                                    <BehaviorReport {...paramsReport} />
                                }
                                {
                                    report.type === "stopHistoryReportV2" &&
                                    <StopHistoryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "fsmCombinedReportV2" &&
                                    <FSMCombinedReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "stopSummaryReportV2" &&
                                    <StopSummaryReportV2 {...paramsReport} />
                                }
                                {
                                    report.type === "hourOfUseReport" &&
                                    <HourOfUseReport {...paramsReport}/>
                                }
                                {
                                    report.type === "stateMileageCrossingReport" &&
                                    <StateMileageCrossingReport {...paramsReport}/>
                                }
                                {
                                    report.type === "stateMileageSummariesReport" &&
                                    <StateMileageSummariesReport {...paramsReport}/>
                                }
                                {
                                    report.type === "driverCoachingReport" &&
                                    <DriverCoachingReport {...paramsReport}/>
                                }
                                {
                                    report.type === "tripReport" &&
                                    <TripReport {...paramsReport}/>
                                }
                                {
                                    report.type === "fuelLevelReport" &&
                                    <FuelLevelReport {...paramsReport}/>
                                }
                            </TabContainer>
                        )
                    })
                }
            </Tabs>

            <ReportSetup
                messages={messages}
                refClearReportSetup={refClearReportSetup}
            />
            {
                loadingGlobalReport &&
                <Loading overlay show/>
            }
            <ModalLandMark/>

            {
                openReportsTooLargeModal &&
                <ReportTooLarge open={openReportsTooLargeModal} onClose={() => { setOpenReportsTooLargeModal(false); }}/>
            }
        </div>
    )
}

const deleteComma = (string, type) => {
    if (string && type == 'csv') {
        return String(string).replaceAll(',', ' - ')
    }
    return string;
}

const formatParams = (param, select) => {
    if (param) {
        const formatParams = JSON.parse(param);
        return formatParams[select] ? formatParams[select] : JSON.parse(formatParams)[select]
    }
}

const getColumns = (messages, loading, timeZone, dispatch, reportTypeOptions, units, currentFilterReport, timeframeLabel, width, copyReportToSetup, handleChangeTab, dateformat) => {
    const momentFormat = GetFormatForMomentTwoCharacters(dateformat)
    const addColumnType = (currentFilterReport.value) ? [] :
        [{
            key: 'reportType',
            show: true,
            sort: false,
            title: currentFilterReport.value ? messages['reportsMainST_selectViewBy'] : messages['reportsMainST_reportType'],
            export: true,
            render: null,
            functionString: 'return row?.reportTypeFilter'
        }];

    const addColumnCreated = (currentFilterReport.value) ?
        [{
            key: 'selectCreated',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectCreated'],
            export: true,
            render: null,
            exportValue: (row) => {
                const localTime = moment.utc(row.createdOn).local().format();
                return formatDatetime(localTime, `${momentFormat || "M/DD/YY"} h:mm A`, timeZone);
            },
            functionString: 'return row?.createOnFilter'
        }] : [];


    return [
        {
            key: 'name',
            show: true,
            sort: false,
            title: currentFilterReport.value ? messages['reportsMainST_report'] : messages['reportsMainST_reportName'],
            width: '18%',
            export: true,
            render: null,
            functionString: 'return row?.reportName'
        },
        ...addColumnType,
        {
            key: 'selectViewBy',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectViewBy'],
            export: true,
            render: null,
            functionString: 'return row?.filterType'
        },
        {
            key: 'selectRunFor',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectRunFor'],
            export: true,
            render: null,
            functionString: `
        if (!['completed'].includes(row.reportStatus)){
            let filters = JSON.parse(row.params);
            filters = JSON.parse(filters);
            return row?.runFor !== '' ? row?.runFor : ('All ' + filters.category.entities[0].type + 's')
        } else {
            const filters = JSON.parse(row.params);
            return row?.runFor !== '' ? row?.runFor : ('All ' + filters.category.entities[0].type + 's')
        }
        `
        },
        {
            key: 'selectTimeframe',
            show: true,
            sort: false,
            title: messages['reportsMainST_selectTimeframe'],
            export: true,
            render: null,
            functionString: `return ${currentFilterReport.value ? 'row?.dateRangeFilter' : 'row?.timeframeName'}`
        },
        ...addColumnCreated,
        {
            key: 'selectStatus',
            show: true,
            sort: false,
            title: currentFilterReport.value ? messages['reportsMainST_selectStatus'] : messages['reportsMainST_delivery'],
            export: true,
            render: null,
            exportValue: (row) => {
                return row.reportStatus;
            },
            functionString: `return ${currentFilterReport.value ? 'row?.status' : 'row?.formatFilter'}`
        }
    ];
}

const getReport = (id, name, viewOption, url, UIParams, generateReportAgain, dataResultPath, createdOn, userId, entities, messages, dispatch, reportTypeOptions, drivers, units, lang) => {
    if (typeof url === 'string') {
        url = JSON.parse(url)
    }
    if (typeof UIParams === 'string') {
        try {
            UIParams = JSON.parse(UIParams)
        } catch (e) {
        }
    }
    if ((viewOption === 'csv' || viewOption === 'pdf') && !generateReportAgain) {
      const newLink = Array.isArray(url)
            ? url[0]?.url
            : getURLFromItem(url)
        if (newLink) {
            const newUrl = newLink.includes("http") ? newLink : `https://${newLink}`;
            let a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            a.href = newUrl;
            a.click();
            document.body.removeChild(a);
        } else {
            // if the file does not have url
            NotificationManager.error(
                messages['reportsMainRT_exportReportMessageFailedNoData'],
                messages['error'],
                3000,
                null,
                null,
                'error'
            );
        }
    } else {

        // generate the report again with the same parameters
        // the end date could change in cases like: This Quarter, this week, this month
        let endDate;
        let startDate;
        if (UIParams?.timeframeName != "Custom") {
            const newDates = changeDate(UIParams.timeframeName, {})
            endDate = newDates.endDate?.utc().format();
            startDate = newDates.startDate?.utc().format();
        }
        // get entity label from  filterArray or entities
        const newEntities = getEntities(UIParams.filters.filterArray, entities);
        const allTag = newEntities?.find(tag => {
            return tag.id == "-99";
        });

        const includeAllTag = !!allTag;
        let allEntities = []

        if (includeAllTag) {
            allEntities = newEntities?.map(e => e.type).includes('Tag') ? UIParams.allEntities : getDataForEntityTags(UIParams.filters.filterEntity, drivers, units)
        }

        dispatch(reportsMainGenerateReport(
            allEntities,
            includeAllTag,
            UIParams.timeZone,
            UIParams.filters.filterEntity,
            UIParams.type, //reportType
            UIParams.filters.filterType, //entity to filter
            newEntities,
            startDate || UIParams.filters.startRange,
            endDate || UIParams.filters.endRange,
            UIParams.filters.otherFilters,
            UIParams.export,
            UIParams.exportType,
            UIParams.sendByEmail,
            UIParams.emailsTo,
            reportTypeOptions.getNameReportType(UIParams.type) || name || UIParams.name || '',
            UIParams.categoryName || '',
            UIParams.timeframeName || '',
            UIParams.delayed,
            UIParams.excludeEmptyTables,
            generateReportAgain ? null : dataResultPath,
            createdOn,
            userId,
            UIParams.reportTimeRanges,
            UIParams.durationFormat || '',
            UIParams.filters.includeInactiveDrivers, lang
        ));

    }
}

const getEntities = (filterArray, entities) => {
    if (!filterArray) {
        return entities;
    }

    if (!entities) {
        return filterArray;
    }

    return filterArray.map(item => {
        const newEntity = entities.find(entity => (item?.id == entity?.id && item?.type == entity?.type))
        if (newEntity) {
            return {
                ...newEntity,
                ...item
            }
        }
        return item;
    })
}

const getURLFromItem = (urlItem) => {
    if (urlItem === "[]") {
        return ""
    }
    return urlItem;
}

export const getInfoReportFilter = (report, { services, customFieldsReports }) => {
    const filters = JSON.parse(report.advancedFilter)

    const {allForms: forms, landmarks, geofences, landmarkViewOptions, driverViewOptions, selectEventTypeOptions, geofenceViewOptions, eventFuelLeventOptions} = customFieldsReports;

    const {messages} = useIntl()

    const getInfoForm = (fil) => {
        let infos = [];

        const label = driverViewOptions?.filter(data => data.id == fil.formView)[0]?.label || ""
        infos.push(
            {title: `Form Filter = `, value: `${label}`},
        )

        if (fil.formView === "single") {
            const label = forms?.filter(data => data.id == fil.form)[0]?.name || ""
            infos.push(
                {title: `Form = `, value: `${label}`},
            )
        }

        return infos;
    }

    let infos = [];
    let label = ""
    switch (report.reportType) {
        case 'speedReport':
            infos.push(
                {title: 'reportReportType_seedOr', value: `${filters.speed} mph`},
                {title: 'reportReportType_overPosted', value: `${filters.speedLimitDelta} mph`},
            )
            break;
        case 'stopReport':
        case 'movingReport':
        case 'idleReport':
            infos.push(
                {title: 'reportReportType_minutes', value: `${filters.minute}`},
            )
            break;
        case 'formStopSummaryReportV2':
        case 'formStopSummaryReport':
            infos.push(
                {title: 'reportReportType_minutes', value: `${filters.minute}`},
                ...getInfoForm(filters)
            )
            break;
        case 'driverFormsReport':
            infos.push(...getInfoForm(filters))
            break;
        case 'driverFormsReportV2':
            infos.push(...getInfoForm(filters))
            break;
        case 'eventReport':
            label = selectEventTypeOptions?.filter(data => data.id == filters.event)[0]?.label || ""
            infos.push(
                {title: 'reportReportType_eventFilter', value: `${label}`},
            )
            break;
        case 'driverCoachingReport':
            infos.push({title: 'reportsMainRT_eventType'})
            if (filters?.eventTypes) {
                infos.push(...filters?.eventTypes?.map(data => {
                    return {valueMessage: `videoMainView_${data?.key}`, name: '- '}
                }));
            }
            break;
        case 'serviceItemsReport':
            label = services?.filter(data => data.id == filters.event)[0]?.name || ""
            infos.push(
                {title: 'reportReportType_serviceItemFilter', value: `${label}`},
            )
            break;

        case 'landmarkBasedReport':
        case 'landmarkSummaryReport':
            label = landmarkViewOptions?.filter(data => data.id == filters.landmarkBaseView)[0]?.label || ""
            infos.push(
                {title: 'reportReportType_landmarkView', value: `${label}`},
            )
            if (filters.landmarkBaseView === "single") {
                const label = landmarks?.filter(landmark => landmark.id == filters.landmark)[0]?.name || ""
                infos.push(
                    {title: 'reportReportType_landmark', value: `${label}`},
                )
            } else if (filters.landmarkBaseView === "tags") {
                const label = filters?.landmarkTags.label || ""
                infos.push(
                    {title: 'reportReportType_tags', value: `${label}`},
                )
            }
            break;
        case 'geofenceBasedReport':
            label = geofenceViewOptions?.filter(data => data.id === filters.geofenceBaseView)[0]?.label || ""
            infos.push(
                {title: 'reportReportType_geofenceView', value: `${label}`},
            )
            if (filters.geofenceBaseView === "single") {
                const label = geofences?.filter(geofence => geofence.id === filters.geofence)[0]?.name || ""
                infos.push(
                    {title: 'reportReportType_geofence', value: `${label}`},
                )
            } else if (filters.geofenceBaseView === "tags") {
                const label = filters?.geofenceTags.label || ""
                infos.push(
                    {title: 'reportReportType_tags', value: `${label}`},
                )
            }
            break;
        case 'fuelLevelReport':
            label = eventFuelLeventOptions?.filter(data => data.id === filters.event)[0]?.name || ""
            infos.push(
                {title: 'reportsMainRT_eventType', value: ` = ${label}`},
                {title: 'generalReport_percentage', value: ` = ${filters.percentage} %`},
            )
            break;

    }
    if (infos.length > 0) {
        return (
            <>
                {
                    infos.map((info, index) => (
                        <p key={`${index}-${info}`} className="info-speed-tooltip"> {
                            info.title ? messages[`${info.title}`] : info.name
                        }<strong>{info.value ? info.value : messages[info.valueMessage]}</strong></p>
                    ))
                }
            </>
        )
    } else return '';
}
export default injectIntl(ReportsTab);
