import React, { useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import Modal, { ModalBody, ModalFooter } from 'Components/Modal';
import { SearchInput, Table } from "Components/uiControls";
import { Button, Pill } from "Components";
import { Grid, Icon, Typography } from "@mui/material";
import { closeModal } from 'Redux/actions';
import './reportListFilters.scss';

const ReportListFilters = ({ messages }) => {
    const dispatch = useDispatch();
    const { reportParams , loadingReportParams } = useSelector( state => state.reportsTapSearchReportLogRedux );
    const controlModal = useSelector( state => state.reportsListShowReportFilterRedux );
    const [ searchText , setSearchText ] = useState( '' );
    const [ data , setData ] = useState( [] );
    const [ title , setTitle ] = useState("")
    const tableRef = useRef();
    const { isModalOpen } = controlModal || false;
    
    const translationTypes = {
        "Unit" : messages['generatedReport_unit'] ,
        'Driver' : messages['generatedReport_driver'] ,
        "Tag" : messages['generatedReport_tag'],
        "Units" : messages['generatedReport_units'] ,
        'Drivers' : messages['generatedReport_drivers'] ,
        "Tags" : messages['generatedReport_tags'] ,
    }
    
    useEffect( () => {
        if ( ! loadingReportParams && reportParams && isModalOpen ) {
            const { UIParams , category } = reportParams || {};
            const { entities } = category || {};
            let data = get( UIParams , 'filters.filterArray' , [] );
            
            if ( data[0]?.id === -99 ) {
                data = get( UIParams , 'allEntities' , [] );
                setTitle( `All ${translationTypes[entities[0].type+"s"]}` );
            } else {
                setTitle(`${ entities.length } ${ entities.length > 1 ? translationTypes[entities[0].type+"s"] : translationTypes[entities[0].type] }`)
            }
            setData( data );
        }
        return () => {
            setSearchText( '' );
            tableRef.current?.setPage( 1 );
            tableRef.current?.setSelectedItems( [] );
        }
    } , [ loadingReportParams , reportParams ] );
    
    
    const handleCancel = () => {
        dispatch(closeModal());
        setData([]);
        setSearchText('');
        tableRef.current?.setPage(1);
        tableRef.current?.setSelectedItems([]);
    };

    const handleSearchTextChange = (newSearchText) => {
        setSearchText(newSearchText);
        tableRef.current?.setPage(1);
        tableRef.current?.setSelectedItems([]);
    };
    
    const columns = getColumns(messages, title.split(' ')[1]);

    return (
        <Modal
            title={title || ''}
            open={isModalOpen}
            handleClose={handleCancel}
            size="extra-small"
            position="right"
            useCloseButtonTop
            hiddenCloseButton
            className="modal-report-list-filter"
        >
            <ModalBody className="modal-body-report-list-filter">
                {isModalOpen && data.length > 0 && (
                    <>
                        <div className="modal-search-report-list-filter">
                            <SearchInput
                                placeholder={messages['search']}
                                searchText={searchText}
                                onSearchText={handleSearchTextChange}
                            />
                        </div>
                        <div className="modal-div-report-list-filter">
                            <Table
                                ref={tableRef}
                                data={data}
                                order={'asc'}
                                loading={loadingReportParams}
                                keyColumn="id"
                                topheight={300}
                                columns={columns}
                                searchText={searchText}
                                valuesPerPage={[]}
                                fixHeader
                                className="modal-table-report-list-filter"
                                hideDragable
                                disableItemSelection
                                hidePerPage
                                hideTotal
                                hideAllInPagination
                                selectAllRows={data.length}
                                messageNoData={messages['reportsMainST_withoutSchedules']}
                            />
                        </div>
                    </>
                )}
            </ModalBody>
            <ModalFooter>
                <Button className='btn-blue' onClick={handleCancel}>
                    {messages['close']}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

const getColumns = (messages, title) => {
    return [{
        key: 'reportType',
        show: true,
        sort: true,
        title: title,
        sortValue: (item) => item?.label,
        export: false,
        render: (item) => {
            const defaultColor = (item?.color === 'ffffff') ? '000000' : item.color;
            return item.color ? (
                <span style={{ display: 'inline-flex' }}>
                    <Icon style={{ fontSize: 20, marginRight: 5, color: '#' + defaultColor }}>local_offer</Icon>
                    <Typography noWrap>{item?.label?.length > 30 ? item?.label.substring(0, 30) + "..." : item?.label}</Typography>
                </span>
            ) : (
                <Grid item xs zeroMinWidth style={{ display: 'flex', justifyContent: 'space-between', width: '95%' }}>
                    <Typography noWrap>{item?.label}</Typography>
                    {!item?.status && item.name !== 'Unit' && (
                        <Pill
                            textField="label"
                            item={{ label: messages['entityTagPicker_inactive'] }}
                            clickable={false}
                            outlined={true}
                            style={{ border: '0', width: '110px', minWidth: '110px' }}
                            colorField="default"
                            colorSelection="66bb6a"
                        />
                    )}
                </Grid>
            );
        }
    }];
};

export default ReportListFilters;
