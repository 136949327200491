import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../data/GraphQLApi';
import { endpoints } from '../../../defaultValues';
import { resetALL } from '../../../../.globals/redux/actions';

import { checkDeviceData, GetFormatForMoment, validateJsonObject, getFormattedDateByTimeZone } from '../../../data/Helpers'
import { LogManagerConsole, ExceptionManager } from '../../../logManager'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    media: null
};

const route = 'core/views/sharedLiveVideoSec/redux/slice'

export const getMediaDetail = createAsyncThunk('core/sharedLiveVdeoSec', async ({ id, deviceId, messages }, { rejectWithValue, dispatch, getState }) => {
    try {

        const state = getState()
        const user = state.securityRedux.user
        const result = await clientQuery(`
                query ($readingId: Float!, $deviceId: String!) {
                    data: getAvailableMedia (readingId: $readingId, deviceId: $deviceId) {
                        reading {
                            id
                            deviceId
                            latitude
                            longitude
                            heading
                            eventCode
                            eventName
                            address
                            unitTime
                            unitType
                            driverName
                            mediaDeleted
                            starred
                            speed
                            ecuSpeed
                            deviceData
                        }
                        unit {
                            id
                            label
                            timeZone {
                                name
                            }
                            tags {
                                id
                                label
                                color
                                hasChildren
                            }
                            useEcuSpeed
                            driver {
                                id
                                supervisor {
                                    firstName
                                    lastName
                                }
                            }
                        }
                        linkedDevice {
                            id
                            esn
                            manufacturerName
                        }
                        reviewStatus {
                            jsonValue
                        }
                    }
                }`,
            {
                readingId: id,
                deviceId
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        let data
        LogManagerConsole("VIDEO", "getMediaDetail", result)
        if (result?.data) {
            const { reading, linkedDevice, unit, reviewStatus: reviewStatusJSON } = result?.data
            let deviceData = reading && checkDeviceData(reading.deviceData);
            let reviewStatus = reviewStatusJSON && JSON.parse(JSON.parse(reviewStatusJSON.jsonValue))

            let videoOptions = {
                mode: "picture",
                id: deviceData?.pictureID,
                pictureId: deviceData?.pictureID,
            };

            if (deviceData.clipID) {
                if (deviceData.duration) {
                    videoOptions.mode = "video";
                }
                videoOptions.id = deviceData?.clipID;
                videoOptions.pictureId = null;
            }

            if (deviceData.url) {
                videoOptions.url = deviceData?.url;
            }

            videoOptions = {
                ...videoOptions,
                messages: messages,
                type: "playback",
                autoplay: true,
                thumbnailUrl: deviceData.duration === 0
                    ? deviceData.url
                    : !deviceData?.clipID && !deviceData?.thumbnailUrl
                        ? `/assets/images/clipRequestFailed.png`
                        : deviceData?.thumbnailUrl,
                deviceModel: linkedDevice?.deviceModelName === "360" ? "360" : "4K",
            };

            let speed = reading?.speed;
            if (reading?.useEcuSpeed) {
                speed = reading?.ecuSpeed;
            }

            if (speed) speed = parseFloat(speed);
            if (isNaN(speed)) speed = 0;

            let address = validateJsonObject(reading?.address);

            data = {
                eventName: reading?.eventName,
                eventCode: reading?.eventCode,
                latitude: reading?.latitude,
                longitude: reading?.longitude,
                heading: reading?.heading,
                unitType: reading?.unitType,
                unitTime: getFormattedDateByTimeZone(
                    reading?.unitTime,
                    unit?.timeZone?.name,
                    messages,
                    `h:mm:ss A, ${GetFormatForMoment(user.dateformat)}`
                ),
                address: address?.formatted || address,
                label: unit?.label,
                driverName: reading?.driverName,
                speed: speed || 0,
                //reviewNotes: notes,
                starred: reading?.starred ? true : false,
                isDeleted: reading?.mediaDeleted,
                reviewStatus: reviewStatus?.status || "Unreviewed",
                deviceData: reading,
                type: linkedDevice?.manufacturerName,
                mediaDeletedByUsername: deviceData?.mediaDeletedByUsername,
                videoOptions,

                tags: unit?.tags,
                useEcuSpeed: unit?.useEcuSpeed,

                supervisor:
                    unit?.driver?.supervisor?.firstName &&
                        unit?.driver?.supervisor?.lastName
                        ? `${unit.driver.supervisor.firstName} ${unit.driver.supervisor.lastName}`
                        : messages["videoMainView_unassigned"],
            }

            dispatch(getMediaDetailNotes({ readingId: `${reading.deviceId}-${reading.id}` }))

            // let newFormData = { ...formData };

            // if (deviceData?.videoReview) {
            //     newFormData = {
            //         ...newFormData,
            //         status: deviceData?.videoReview?.status || "Unreviewed",
            //         notes: "",
            //     };
            // } else {
            //     newFormData = { ...newFormData, status: "Unreviewed", notes: "" };
            // }


        }

        return data
    } catch (exc) {
        ExceptionManager(exc, route, 'getMediaDetail');
        return rejectWithValue(exc)
    }
});

//Slice
const sharedLiveVideoRedux = createSlice({
    name: 'core/sharedLiveVdeoSec',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(getMediaDetail.pending, (state, action) => {
            state.loading = true
            state.error = null
        });
        builder.addCase(getMediaDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.media = action.payload
        });
        builder.addCase(getMediaDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

      
    },
})

export const { actions, reducer } = sharedLiveVideoRedux;
// Extract and export each action creator by name
export const { reset } = actions;
export default reducer;