import colors from 'Assets/sass/core/vars.scss';
export const urlIcons = "/assets/icons";
export const urlImages = "/assets/images";

export const vidFleetVideoEvents = {
    /** EVENTOS EXISTENTE Y FALTANTES DE DB COMENTADOS**/
    "CameraEvent.ASLEEP": { name: "Asleep", lanKey: 'Asleep', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.CYCLIST_COLLISION": { name: "Cyclist Warning", lanKey: 'Cyclist Warning', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.DAYDREAMING": { name: "Drowsiness", lanKey: 'Drowsiness', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.DISTRACTED": { name: "Distracted", lanKey: 'Distracted', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.DRIVING_HEAVY_HIT": { name: "Potential Incident", lanKey: "Potential Incident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "CameraEvent.DRIVING_LIGHT_HIT": { name: "Potential Incident", lanKey: "Potential Incident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "CameraEvent.DRIVING_SUSPICIOUS_HIT": { name: "Potential Incident", lanKey: "Potential Incident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "CameraEvent.DROWSINESS": { name: "Drowsy", lanKey: 'Drowsiness', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.FORWARD_COLLISION_WARNING": { name: "Collision Warning", lanKey: 'Collision Warning', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.HARD_ACCEL": { name: "Rapid Acceleration", lanKey: "Rapid Acceleration", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_acc.png` },
    "CameraEvent.HARSH_ACCEL": { name: "Rapid Acceleration", lanKey: "Rapid Acceleration", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_acc.png` },
    "CameraEvent.HARSH_BRAKE": { name: "Hard Brake", lanKey: "Hard Brake", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_brake.png` },
    "CameraEvent.HARSH_TURN": { name: "Hard Turn", lanKey: "Hard Turn", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_turn.png` },
    "CameraEvent.HEADWAY_MONITORING_EMERGENCY": { name: "Tailgating", lanKey: 'Tailgating', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.HEADWAY_MONITORING_WARNING": { name: "Close Following", lanKey: 'Close Following', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.IDLE_NO_DRIVER": { name: "Idle No Driver", lanKey: 'Idle No Driver', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.LANE_DEPARTURE_WARNING": { name: "Lane Departure", lanKey: 'Lane Departure', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.MANUAL": { name: "Manual", lanKey: 'Manual', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.NO_DRIVER": { name: "Camera Obstructed", lanKey: 'Camera Obstructed', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.NO_SEATBELT": { name: "No Seatbelt", lanKey: 'No Seatbelt', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.OVER_MAX_SPEED": { name: "Speed Threshold", lanKey: "Speed Threshold", color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.PASSENGERS_INSIDE": { name: "Passenger Inside", lanKey: "Passenger Inside", color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.PASSENGERS_NO_SEATBELT": { name: "Passenger Seatbelt", lanKey: "Passenger Seatbelt", color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.PEDESTRIAN_COLLISION": { name: "Pedestrian Detection", lanKey: "Pedestrian Detection", color: '#16aaf2', icon: `${urlIcons}/markervideo/crash.png` },
    "CameraEvent.POTENTIAL_INCIDENT_HIGH": { name: "Potential Incident High", lanKey: 'Potential Incident High', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.POTENTIAL_INCIDENT_LOW": { name: "Potential Incident Low", lanKey: 'Potential Incident Low', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.POTENTIAL_INCIDENT_MEDIUM": { name: "Potential Incident Medium", lanKey: 'Potential Incident Medium', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.POWER_LOST": { name: "Main Power Disconnect", lanKey: "Main Power Disconnect", color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.SEVERE_ACCEL": { name: "Rapid Acceleration", lanKey: "Rapid Acceleration", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_acc.png` },
    "CameraEvent.SEVERE_BRAKE": { name: "Hard Brake", lanKey: "Hard Brake", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_brake.png` },
    "CameraEvent.SEVERE_TURN": { name: "Hard Turn", lanKey: "Hard Turn", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_turn.png` },
    "CameraEvent.SHARP_TURN": { name: "Hard Turn", lanKey: "Hard Turn", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_turn.png` },
    "CameraEvent.SMOKING": { name: "Smoking", lanKey: 'Smoking', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.SPEEDING": { name: "Posted Speed", lanKey: "Posted Speed", color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.STOP_SIGN_VIOLATION": { name: "Stop Sign Violation", lanKey: 'Stop Sign Violation', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.USING_PHONE": { name: "Using Phone", lanKey: 'Using Phone', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.YAWN": { name: "Yawn", lanKey: 'Yawn', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    
    "CameraEvent.DRIVING_HIT": { name: "Potential Incident", lanKey: "Potential Incident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "DEFAULT": { name: "Default", lanKey: null, color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "CameraEvent.HARD_BRAKE": { name: "Hard Brake", lanKey: "Hard Brake", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_brake.png` },
    "CameraEvent.PARKING_MOTION": { name: "Parking", lanKey: "Parking", color: '#16aaf2', icon: `${urlIcons}/markervideo/parking.png` },
    "CameraEvent.PARKING_HIT": { name: "Parking", lanKey: "Parking", color: '#16aaf2', icon: `${urlIcons}/markervideo/parking.png` },
    "CameraEvent.PARKING_HEAVY_HIT": { name: "Parking", lanKey: "Parking", color: '#16aaf2', icon: `${urlIcons}/markervideo/parking.png` },
    "AutoSnapshot": { name: "AutoSnapshot", lanKey: "AutoSnapshot", color: '#16aaf2', icon: `${urlIcons}/markervideo/auto_snapshot.png` },
    "CameraEvent.LANE_DEPARTURE": { name: "Lane Departure", lanKey: 'Lane Departure', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
};

export const getVidFleetEvent = (code) => {
    let event = vidFleetVideoEvents[code];
    if (!event) event = vidFleetVideoEvents["DEFAULT"];

    return event;
};


export const vidFleetClipStatus = {
    "requestAccepted": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },
    "startUpload": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },
    "uploading": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },

    "requestDecline": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },
    "failed": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },
    "timeout": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },
    "interrupted": { lanKey: 'videoClipRequest_failed', color: colors.red, icon: `${urlIcons}/markervideo/ico_failed.svg`, image: `${urlImages}/clipRequestFailed.png` },

    "requestPending": { lanKey: 'videoClipRequest_pending', color: colors.black, icon: `${urlIcons}/markervideo/ico_pending.svg`, image: `${urlImages}/clipRequestPending.png` },

    "finished": { lanKey: 'videoClipRequest_inProgress', color: colors.black, icon: `${urlIcons}/markervideo/ico_inprogress.svg`, image: `${urlImages}/clipRequestInprogress.png` },
    "mp4RemuxFinished": { lanKey: 'videoClipRequest_completed', color: colors.green, icon: `${urlIcons}/markervideo/ico_completed.svg`, image: "" },


    "mp4Uploaded": { name: "AutoSnapshot", lanKey: "", color: '#16aaf2', icon: `${urlIcons}/markervideo/auto_snapshot.png` },

    "unknown": { lanKey: 'videoClipRequest_unknown', color: colors.blue, icon: `${urlIcons}/markervideo/ico_generic.svg`, image: `${urlImages}/clipRequestFailed.png` }
}

export const getVidFleetClipStatus = (status) => {
    let clipStatus = vidFleetClipStatus[status];
    if (!clipStatus) event = vidFleetClipStatus["unknown"];

    return clipStatus;
};


export const switnessVideoEvents = {
    "DEFAULT": { name: "Default", lanKey: null, color: '#16aaf2', icon: `${urlIcons}/markervideo/driving.png` },
    //"LocationChanged": { name: "Drive", lanKey: null, color: '#16aaf2', icon: `${urlIcons}/markervideo/driving.png` },
    //"LiveTrack": { name: "Drive", lanKey: null, color: '#16aaf2', icon: `${urlIcons}/markervideo/driving.png` },
    "Accelerate": { name: "Rapid Acceleration", lanKey: "videoMainView_hardAccel", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_acc.png` },
    "SmartAccelerate": { name: "Rapid Acceleration", lanKey: "videoMainView_hardAccel", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_acc.png` },
    "Brake": { name: "Hard Brake", lanKey: "videoMainView_hardBrake", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_brake.png` },
    "SmartBrake": { name: "Hard Brake", lanKey: "videoMainView_hardBrake", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_brake.png` },
    "CameraObstruction": { name: "Camera Obstructed", lanKey: 'videoMainView_noDriver', color: '#16aaf2', icon: `${urlIcons}/markervideo/generic.png` },
    "SevereShock": { name: "Potential Incident", lanKey: "videoMainView_potentialIncident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "Shock": { name: "Potential Incident", lanKey: "videoMainView_potentialIncident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "SmartShock": { name: "Potential Incident", lanKey: "videoMainView_potentialIncident", color: '#ddaea6', icon: `${urlIcons}/markervideo/crash.png` },
    "SmartTurn": { name: "Hard Turn", lanKey: "videoMainView_harshTurn", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_turn.png` },
    "Turn": { name: "Hard Turn", lanKey: "videoMainView_harshTurn", color: '#b763bb', icon: `${urlIcons}/markervideo/hard_turn.png` }
};

export const getSwitnessEvent = (code) => {
    let event = code ? switnessVideoEvents[code] : null;
    return event;
};