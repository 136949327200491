//Global keys
let locales = {
	ok: 'Aceptar',
	close: 'Cerrar',
	add: 'Agregar',
	cancel: 'Cancelar',
	okay: 'Okay',
	print: 'Imprimir',
	continue: 'Continuar',
	back: 'Atrás',
	save: "Guardar",
	characters: 'Caracteres',
	new: "Nuevo",
	required_field: 'Campo Requerido',
	clearAll: 'Limpiar Todo',
	clear: 'Limpiar',
	selectAnOption: 'Por favor seleccione una opción',
	select: 'Selecccionar',
	files: 'Archivos',
	remove_all: 'Remover Todo',
	choose: 'Elegir',

	all: 'Todo',
	dates: 'Fechas',
	days: 'Dias',
	day: 'Dia',
	Days: 'Dias',
	Day: 'Dia',
	miles: 'Millas',
	kilometers: 'kilómetros',
	feet: 'Pies',
	hours: 'Horas',
	hour: 'Hora',
	Hours: 'Horas',
	Hour: 'Hora',
	min: 'minuto',
	mins: 'Mins',
	seconds: 'Segundos',
	months: 'Meses',
	month: 'Mes',
	Months: 'Meses',
	Month: 'Mes',
	engineHours: 'Horas de Motor',
	odometer: 'Odómetro',
	select_date: 'Seleccionar fecha',
	select_time: 'Select hora',
	etalink_eta: 'Tiempo estimado de llegada (ETA)',

	Monday: 'L',
	Tuesday: 'M',
	Wednesday: 'Mi',
	Thursday: 'J',
	Friday: 'V',
	Saturday: 'S',
	Sunday: 'D',


	dtc_codes: 'Códigos DTC',
	success: 'Éxito',

	min: 'Min',
	max: 'Max',

	pending: 'Pendiente',
	due: 'Vencido',
	dueSoon: 'Prox a Vencer',
	dueNow: 'Vencido',
	pastDue: 'Atrasado',
	scheduled: 'Programado',
	schedule: 'Programar',
	reminder: 'Recordatorio',
	next: 'Siguiente',
	done: "Terminado",
	edit: "Editar",
	complete: 'Completar',
	update: 'Actualizar',
	delete: 'Eliminar',
	DELETE: 'ELIMINAR',
	ago: "hace",
	// "1010": "Ya existe un registro con el nombre introducido",
	duplicated_name: 'Ya existe un registro con el nombre introducido',
	invalid_params: 'Parámetros incorrectos',
	invalid_params_ftp: 'Error de conexión a FTP: Parámetros incorrectos',
	exist_ftp: 'El FTP no puede ser eliminado hasta que esos horarios sean eliminados o actualizados para usar otro Servidor FTP o modo de entrega.',
	attachmentsFilesAllowed: 'jpg, png, gif, txt, doc, docx, pdf, csv, xls, xlsx. 4MB',


	context_menu_table: 'Columnas de Tabla',
	context_menu_show_all: 'Mostrar Todo',
	visibility_validation_title: '!Espera!',
	visibility_validation_message: '!Debe tener al menos una columna visible¡',

	collapse: 'Collapsar',
	expand: 'Expandir',

	time_today_reported: 'Reportado Hoy',
	time_today_not_reported: 'No reportado Hoy',
	time_not_reported_seven_days: 'No reportado en 7 dias',
	time_not_reported_thirty_days: 'No reportado en 30 dias',

	search: 'Buscar',
	noOptions: 'No hay opciones',
	prev: 'Anterior',
	nextp: 'Siguiente',
	perPage: 'por página',
	all: 'todos',
	noMoreItems: 'No hay más elementos.',
	//select menu
	select_all: "Seleccionar todo",
	must_select_at_least_one_option: "Selecciona una opción",
	fetching_more_list_items: "Cargando mas items...",

	//table pagination
	pag_prev: "Anterior",
	pag_next: "Siguiente",
	pag_per_page: "Por pagina",

	//table toolbar
	table_search: "Buscar",
	table_export_csv: "Exportar CSV",
	table_export_pdf: "Exportar PDF",
	table_filter: "Filtro",
	table_add: "Adicionar",
	downloadExport: 'Descargar Reporte',
	table_delete: "Borrar",
	table_refresh: "Actualizar",
	table_export: "Exportar",
	table_visibility: "Visibilidad",
	table_all: "todos",
	table_share: "Compartir",

	//input search
	Search: "Buscar",
	Cancel: "Cancelar",
	Apply: "Aplicar",

	yes: "Si",
	no: "No",

	// Dates
	date_today: 'Hoy',
	date_current: 'Actual',
	date_yesterday: 'Ayer',
	date_this_week: 'Esta semana',
	date_last_week: 'La semana pasada',
	date_this_quarter: 'Este trimestre',
	date_last_quarter: 'El trimestre pasado',
	date_custom: 'Personalizado',
	date_this_month: 'Este Mes',
	date_last_month: 'El mes pasado',
	date_last_24hours: 'Últimas 24 Horas',
	date_last_yesterday: 'Ayer',
	date_last_today: 'Hoy',
	date_last_7days: 'Últimos 7 Dias',
	date_last_15days: 'Últimos 15 Dias',
	date_last_30days: 'Últimos 30 Dias',
	date_last_90days: 'Últimos 90 Dias',
	date_last_3months: 'Últimos 3 Meses',
	//for interval pill
	due_every: "Vencimiento a",
	reminder_at: "Recordatorio a",
	pastDue_at: "Atrasado en",
	next_in: "Próximo en",

	//for dates
	at_time: "a las",
	dateAndTime: "Hora y Fecha",
	start: "Inicio",
	end: "Fin",
	set: "Aplicar",

	//collapse button select menu
	show_all: "Mostrar todo",
	show_less: "Mostrar menos",
	collapse_to_ten: "Mostrar solo diez",

	menu_dashboard: "Consola",
	menu_fleet: "Estado", // Renamed from "Vehículos" temporary
	menu_vehicleStatus: "Estado de Vehiculos",
	menu_map: "Mapas",
	menu_realtimeMaps: "Mapas",
	menu_reports: "Reportes",
	menu_video: "Video",
	menu_delete: "Borrar",
	menu_add: "Agregar",
	menu_manage: "Administración",
	menu_behavior: "Comportamiento",
	menu_routes: "Rutas",
	menu_maintenance: "Mantenimiento",
	menu_fieldSync: "FieldSync",
	menu_MDT: "MDT",
	menu_FSM: "FSM",
	menu_media: "Media",
	menu_account: "Mi Cuenta",
	menu_preferences: "Preferencias",
	menu_contacts: "Contactos",
	menu_help: "Centro de ayuda",
	menu_marketplace: "Tienda",
	menu_driverStatus: "Estado de conductor",
	menu_advaceRouting: "Rutas Avanzadas",
	menu_customers: "Clientes",
	menu_rfid: "RFID",
	menu_promiles: "Promiles",
	menu_apollo_eld: "Apollo ELD",
	menu_car_iq: "Car IQ Pay",
	menu_eldfleet_eld: "ELDFleet (heredado)",
	menu_elogs: "elogs",
	menu_loginHistory: "Historial Inicios de sesión",
	menu_accountInformation: "Información de la cuenta",
	menu_users: "Usuarios",
	menu_feedback: "Retroalimentación",
	menu_settings: "Opciones",
	menu_apiKeys: "API",
	menu_tags: "Etiquetas",
	menu_role: "Roles",
	menu_dataExport: "Exportar Datos",
	menu_dynamic_forms: "Formularios",
	menu_billingPortal: "Portal de Facturación",

	from: "from",
	to: "para",

	FROM: "recibido",
	TO: "enviado",

	error: 'Error',
	information: 'Información',

	errorMessage: "Ha ocurrido un error. Por favor intente de nuevo.",
	notAvailable: "N/D",
	noItemsFound: "No se encontraron coincidencias",

	download: "Descargar",
	addFavorites: "Agregar a favoritos",
	removeFavorites: "Quitar de favoritos",
	favorites: "Favoritos",
	noItemsFound: "No se encontraron coincidencias.",

	unassigned: "Sin asignar",

	excellent: "Excelente",
	fair: "Regular",
	good: "Bueno",
	poor: "Débil",
	unknown: "Desconocida",
	offline: "Fuera de Línea",
	parking: "Parqueado",
	driving: "En movimiento",
	idling: "de marcha en vacío",
	av_clips: "Clips dis",
	stopped: "Detenido",
	no_video_av: "Video No Disponible",
	others: "Otro",
	no_data: "Sin información",
	second_clip: "Rango de clips",

	low: 'Bajo',
	medium: 'Medio',
	high: 'Alto',

	at: 'a las',

	show: "Ver",
	expandAll: "Expandir",
	hide: "Ocultar",
	documentation: "Documentación",
	draw: 'Dibujar control',
	zoomOut: 'Alejar',
	zoomIn: 'Acercar',
	layerControl: 'control de capa',



	exportCSV: 'Exportar CSV',
	exportPDF: 'Exportar PDF',
	export: 'Exportar',
	downloadExport: 'Descargar Reporte',
	changeColumnOrder: 'Cambiar orden de columnas',
	typeToSearch: "Escriba para buscar",
	address: "Dirección",
	unit: "Unidad",
	landmark: "Landmark",
	geofence: "Geocerca",
	unit_value: "Unidad",

	custom: "Personalizado",

	create: 'Crear',
	daily: 'Diario',
	weekly: 'Semanal',
	monthly: 'Mensual',
	assign: "Asignar",

	merge: "Combinar",
	map_legend: 'Leyenda',

	//modules
	units: 'Unidades',
	Unit_Groups: 'Grupos de unidades',
	drivers: 'Conductores',
	driver_groups: 'Grupos de conductores',

	filters: 'Filtros',
	apply: 'Aplicar',
	//map layers
	Hybrid_layer: 'Híbrido',
	Map_layer: 'mapa',
	Satellite_layer: 'satélite',
	Terrain_layer: 'terreno',
	Camera: "Cámara",

	//map traffic
	map_traffic: 'Tráfico',
	map_weather: 'Clima',
	map_radar: 'Radar',
	map_satellite: 'Satélite',
	map_heat_index: 'Índice de Calor',
	map_forecasted_heat_index: 'Índice de Calor Pronosticado',
	map_humidity: 'Humedad',
	map_surface_analysis: 'Análisis de Superficie',
	map_drought_monitor: 'Monitor de Sequía',
	map_forecasted_feels_like_temperature: 'Temperatura de Sensación Pronosticada',
	map_forecasted_jet_stream: 'Corriente en Chorro Pronosticada (250mb)',
	map_forecasted_precipitation: 'Precipitación Pronosticada (1 Hora)',
	map_forecasted_precipitation_6: 'Precipitación Pronosticada (6 Horas)',
	map_view_detail: 'Ver Detalle...',

	remove: 'Remover',
	untagged: "Sin etiquetar",

	on: "Encendido",
	off: "Apagado",
	email: "Correo Electrónico",
	none: "Ninguno",
	selectAll: "Seleccionar Todo",

	exportInProcessTitle: "Exportando...",
	exportInProcessBody: "Esto podría llevar algún tiempo, el archivo se descargará automáticamente cuando esté listo",
	exportSuccess: 'El archivo se ha descargado correctamente',

	loading: "Cargando...",
	phone: "Teléfono",
	contacts: "Contactos",
	emailAddress: "Dirección Email:",
	phoneNumber: "Número de Teléfono:",
	selectContact: "Seleccionar Contacto:",
	add_recipient: "Agregar Destinatario",
	required_email: "Por favor inserte un email válido",
	required_phone: "Por favor inserte un teléfono válido",
	select_country_code: "Seleccionar País:",
	enterEmailAddress: "Ingrese dirección de correo electrónico",
	enterPhoneNumber: "Ingrese Número de Teléfono",
	OF: 'DE',
	chat: 'Chat',
	troubleshooting: "Solución de problemas",
	supportAgent: "agente de soporte",
	unitOverViewPanel: "Descripción general del vehículo",
	system: 'Sistema',
	driverID: "Identificación del conductor",
	vehicle: "Vehículo",
	eventID: 'ID del evento',
	date: "Fecha",
	time: "Hora",
	notes: "Notas"
};

let videoEvents = {
	'Asleep': 'Dormido',
	'Camera Obstructed': 'Cámara obstruida',
	'Close Following': 'Aviso de proximidad',
	'Collision Warning': 'Aviso de colisión',
	'Cyclist Warning': 'Alerta de Ciclista',
	'Distracted': 'Distraído',
	'Drowsiness': 'Somnoliento',
	'Hard Brake': 'Frenado brusco',
	'Hard Turn': 'Giro fuerte',
	'Idle No Driver': 'Inactiva sin conductor',
	'Lane Departure': 'Salida de carril',
	'Main Power Disconnect': 'Desconectado principal',
	'Manual': 'Manual',
	'No Seatbelt': 'Sin cinturón de seguridad',
	'Passenger Inside': 'Detección de Pasajero',
	'Passenger Seatbelt': 'Pasajero sin cinturón',
	'Pedestrian Detection': 'Detección de peatones',
	'Posted Speed': 'Limite de velocidad',
	'Potential Incident': 'Incidente potencial',
	'Rapid Acceleration': 'Aceleración Rápida',
	'Smoking': 'Fumando',
	'Speed Threshold': 'Exceso de Velocidad',
	'Stop Sign Violation': 'Violación de la señal de alto',
	'Tailgating': 'Emergencia de Proximidad',
	'Using Phone': 'Usando el teléfono',
	'Yawn': 'Bostezo',
  'Day Dreaming': 'Sueño diurno',
  'Parking Hit': 'Impactado en estacionamiento',
  'Drowsy': 'Somnoliento',
  'Sudden Stop': 'Parada repentina',
	'Parking': 'Parqueado',
	'AutoSnapshot': 'AutoSnapshot',
	'Potential Incident Low': 'Incidente Potencial Bajo',
	'Potential Incident Medium': 'Incidente Potencial Medio',
	'Potential Incident High': 'Incidente Potencial Alto'
}


//Merge Modules
import alerts from 'Modules/alerts/.globals/lan/es';
import behavior from 'Modules/behavior/.globals/lan/es';
import fleet from 'Modules/fleet/.globals/lan/es';
import maintenance from 'Modules/maintenance/.globals/lan/es';
import reports from 'Modules/reports/.globals/lan/es';
import security from 'Core/.globals/lan/es';
import realtimeMaps from 'Modules/realtimeMaps/.globals/lan/es';
import common from 'Modules/.common/.globals/lan/es';
import video from 'Modules/video/.globals/lan/es';
import account from 'Modules/account/.globals/lan/es';
import settings from 'Modules/settings/.globals/lan/es';
import dashboard from 'Modules/dashboard/.globals/lan/es';
import fsm from 'Modules/fsm/.globals/lan/es';
import { gtc_locales_es } from "@gpstrackit/gtc-react-controls";
import dynamicForms from 'Modules/dynamicForms/.globals/lan/es';
import chat from '../../../modules/chat/.globals/lan/es';
import routes from '../../../modules/routes/.globals/lan/es';
import incidents from 'Modules/incidents/.globals/lan/es'
import template from 'Modules/.template/.globals/lan/en'

locales = {
	...alerts,
	...behavior,
	...fleet,
	...locales,
	...maintenance,
	...reports,
	...security,
	...realtimeMaps,
	...common,
	...video,
	...account,
	...fsm,
	...settings,
	...dashboard,
	...gtc_locales_es,
	...dynamicForms,
	...chat,
	...routes,
	...incidents,
	...videoEvents,
	...template
};

export default locales;
