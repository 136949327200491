import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";
import { ExceptionManager } from "Core/logManager";
import { resetALL } from "Redux/actions";

const INIT_STATE = {
    open: false,
    loading: false,
    result: null,
    error: null,
    items: [],
};

const route = 'modules/.template/modals/delete/redux/slice'

export const deleteTemplate = createAsyncThunk("template/delete/action",
    async ({ ids }, { rejectWithValue }) => {
        try {
            // const response = await clientMutation(
            //     `
            //     mutation deleteTemplates($ids: [Int]!) {
            //         data: deleteTemplates(ids: $ids)
            //     }
            //     `,
            //     ids: ids,
            //     endpoints.GRAPHQL_GENERAL_V2
            // );

            // return response?.data;
            return true
        } catch (exc) {
            ExceptionManager(exc, route, 'deleteTemplate');
            return rejectWithValue(exc)
        }
    }
);


const templateDeleteRedux = createSlice({
    name: "template/delete",
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE };
        },
        openDeleteTemplate(state, action) {
            state.open = true;
            state.items = action?.payload?.items || [];
        },
    },
    extraReducers: (builder) => {
        //use for reset all when logout
        builder.addCase(resetALL, () => INIT_STATE);

        //manageDeleteManageCrew
        builder.addCase(deleteTemplate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(deleteTemplate.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload;
        });
        builder.addCase(deleteTemplate.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

// Extract and export the action creators object and the reducer
export const { actions, reducer } = templateDeleteRedux;

// Extract and export each action creator by name
export const { reset, openDeleteTemplate } = actions;

// Export the reducer, either as a default or named export
export default reducer;