import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { resetALL } from '../../../../../../../.globals/redux/actions';

import { formatUnixToDate } from '../../../../../../../core/data/Helpers'
import { LogManagerConsole, ExceptionManager } from '../../../../../../../core/logManager'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    loadingNotes: false,
    result: null,
    error: null
};

const route = 'modules/video/views/videoDetail/views/clip/redux/slice'

export const getClipDetail = createAsyncThunk('video/detail/clip/get', async ({ id }, { rejectWithValue, dispatch, getState }) => {
    try {

        const state = getState()
        const user = state.securityRedux.user
        const result = await clientQuery(`
             query GetClipRequest($id: String!) {
                data: getClipRequest(id: $id) {
                    id
                    status
                    time
                    tripID
                    clipID
                    rotate
                    url
                    startDate
                    endDate
                    notes
                    starred
                    errorMsg
                    thumbnailUrl
                    transactionId
                    details
                    deviceModelId
                    deviceModelName
                    unit {
                        id
                        label
                        deviceModel {
                            name
                        }
                        tags {
                            id
                            label
                            color
                            hasChildren
                        }
                    }
                    unitId
                    driver {
                        id
                        name
                        externalId
                    }
                    isDeleted
                }
            }
            `,
            {
                id
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        let data
        if (result?.data) {
            const imageRoute = `/assets/images/clipRequestFailed.png`;
            let videoOptions = {
                mode: "video",
                id: result.data?.clipID,
                thumbnailUrl:
                    !result.data?.clipID && !result.data?.thumbnailUrl
                        ? imageRoute
                        : result.data?.thumbnailUrl,
                //messages: messages,
                type: "playback",
                autoplay: true,
                deviceModel: result.unit?.deviceModel?.name === "360" ? "360" : "4K",
                disabled: !result.data?.clipID,
            };

            data = {
                unit: result.data?.unit,
                id: result.data?.id,
                status: result.data?.status,
                unitTime: formatUnixToDate(parseInt(result?.data?.time) / 1000, user.dateformat),
                label: result.data?.unit?.label,
                driverName: result.data?.driver?.name
                    ? `${result.data?.driver?.name}${result.data?.driver?.externalId
                        ? ` (${result.data?.driver?.externalId})`
                        : ""
                    }`
                    : "",
                notes: result.data?.notes,
                isDeleted: result.data?.isDeleted ? true : false,
                starred: result.data?.starred ? true : false,
                videoOptions,
                details: result.data?.details,
                deviceModelName: result.data?.deviceModelName
            }
        }
        LogManagerConsole("VIDEO", "getClipDetail", data)

        return data
    } catch (exc) {
        ExceptionManager(exc, route, 'getClipDetail');
        return rejectWithValue(exc)
    }
});

export const saveNote = createAsyncThunk('video/detail/clip/saveNote', async ({ id, notes }, { rejectWithValue, dispatch, getState }) => {
    try {
        LogManagerConsole("VIDEO", "saveNote",id, notes)

        const result = await clientMutation(`
            mutation UpdateClipRequest($id: String!, $notes: String) {
                data: updateClipRequest(id: $id, notes: $notes) {
                    id
                    notes
                }
            }
           `,
            {
                id,
                notes
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        return result?.data
    } catch (exc) {
        ExceptionManager(exc, route, 'saveNote');
        return rejectWithValue(exc)
    }
});


//Slice
const videoDetailClipRedux = createSlice({
    name: 'video/detail/clip',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        openModalEventChangeStatus(state, action) {
            return {
                ...state,
                openModalChangeStatus: action.payload.open,
                changeStatusData: action.payload.data
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(getClipDetail.pending, (state, action) => {
            state.loading = true
            state.error = null
        });
        builder.addCase(getClipDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
        });
        builder.addCase(getClipDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(saveNote.pending, (state, action) => {
            state.loadingNotes = true
            state.error = null
        });
        builder.addCase(saveNote.fulfilled, (state, action) => {
            state.loadingNotes = false;
            state.result = action.payload
            state.data.notes = action.payload.notes
        });
        builder.addCase(saveNote.rejected, (state, action) => {
            state.loadingNotes = false;
            state.error = action.payload;
        });


    },
})

export const { actions, reducer } = videoDetailClipRedux;
// Extract and export each action creator by name
export const { reset, openModalEventChangeStatus } = actions;
export default reducer;