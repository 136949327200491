import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import PanelLabel from "./PanelLabel";
import AreaMenu, { Area, Menu } from "Components/AreaMenu";
import DatePicker from "react-datepicker";

export default function SliderDatePicker({
  date,
  selectedTime,
  availableClips,
  setSelectedTime,
  requestClipData
}) {
  const { messages } = useIntl();
  const areaMenuRef = useRef();
  const [availableTimeClips, setAvailableTimeClips] = useState([])


  useEffect(() => {
    if (availableClips && availableClips?.length) {
      const avClips = availableClips[0]?.validTimeRangeSeq
      if(!avClips) return
      const lastClip = avClips[avClips.length - 1]
      const lastAvClip = JSON.parse(lastClip)
      if (requestClipData && requestClipData?.noSearchAvClips) {
        setSelectedTime(new Date(requestClipData.dateTime))
      } else {
        setSelectedTime(new Date(lastAvClip[0]))
      }
      const formatAvailableClips = availableClips[0]?.validTimeRangeSeq.map(item => {
        const itemDate = JSON.parse(item)
        return new Date(itemDate[0])
      })
      setAvailableTimeClips(formatAvailableClips)
    } else {
      setAvailableTimeClips([])
    }
  }, [availableClips]);

  return (
    <>
      {date ? (
        <div className="datepicker" style={{ marginTop: '-35px !important' }}>
          <AreaMenu id={"datepicker"} ref={areaMenuRef}>
            <Area>
              <PanelLabel
                message={messages["videoClipRequest_dates"]}
                value={date}
                row
              />
            </Area>
            <Menu className="datepicker_container datepicker_container--slider" style={{ marginTop: "-10px" }}>
              <DatePicker
                //value={selectedTime}
                selected={selectedTime}
                disabledKeyboardNavigation
                maxDate={new Date()}
                onChange={(newDate) => { setSelectedTime(newDate) }}
                disabled={availableTimeClips.length > 0}
                inline={availableTimeClips.length > 0}
                includeDates={availableTimeClips.length > 0 ? availableTimeClips : []}
              />
            </Menu>
          </AreaMenu>
        </div>
      ) : (
        <PanelLabel
          message={messages["no_data"]}
          value='-----'
        />
      )}
    </>
  );
}
