import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from 'Core/defaultValues';
import { ExceptionManager, LogManagerConsole } from 'Core/logManager';
import moment from "moment";

import { resetALL } from 'Redux/actions';

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    result: null,
    loadingPreference: false,
    preferencesCompany: [],
    preferencesUser: [],
    preferencesOThers: [],
    returnServer: false,
    error: null,
    resultUpdate: false
};

const route = 'core/containers/preferences/redux/slice'

const getPreferencesName = (entityName) => {
    switch (entityName) {
        case "User":
            return "preferencesUser"
        case "Company":
            return "preferencesCompany"
        default:
            return "preferencesOther"
    }
}

const getPreferencesByKey = async (entityName, entityId, force) => {
    let getDataServer = false;
    let data, preferences = []

    LogManagerConsole('PREFERENCES', 'getPreferencesByKey', entityName, entityId, force)
    if (!force) {
        data = localStorage.getItem(getPreferencesName(entityName));
        if (data) data = JSON.parse(data);

        LogManagerConsole('PREFERENCES', 'getPreferencesByKey', "local", data)
        if (data && data.date) {
            let date = moment(data.date).add(600, 'seconds'); //10 minutes
            if (date > moment()) {
                preferences = data.data
            }
            else
                getDataServer = true;
        }
        else
            getDataServer = true;
    }
    else {
        getDataServer = true
    }

    if (getDataServer) {
        data = await clientQuery(`
            query ($projectKey: ProjectKey!, $entityName: EntityName!, $entityId: String!, $preferenceKey: [String]){
                Preferences: searchPreferences (projectKey: $projectKey, entityName: $entityName, entityId: $entityId, preferenceKeys: $preferenceKey) {
                    id
                    entityName
                    entityId
                    preferenceKey
                    preferenceValue
                }
            }
        `,
            {
                projectKey: "PX",
                entityName,
                entityId,
                //preferenceKey
            },
            endpoints.GRAPHQL_GENERAL,
        );

        LogManagerConsole('PREFERENCES', 'getPreferencesByKey', "server", data)
        if (data && data.Preferences) {
            data = { date: moment(), data: data.Preferences }
            localStorage.setItem(getPreferencesName(entityName), JSON.stringify(data))
            preferences = data.data
        }
    }

    LogManagerConsole('PREFERENCES', 'getPreferencesByKey', "local", preferences)
    let result = {}
    result[getPreferencesName(entityName)] = preferences
    return result

}


export const getAllPreferences = createAsyncThunk('preferences/getAll', async ({ authenticatedId, companyId }, { rejectWithValue }) => {
    try {

        LogManagerConsole('PREFERENCES', 'getAllPreferences', { authenticatedId, companyId })
        let result = {}
        let resultPreference = await getPreferencesByKey("Company", companyId)
        result = { ...result, ...resultPreference }
        resultPreference = await getPreferencesByKey("User", authenticatedId)
        result = { ...result, ...resultPreference }
        resultPreference = await getPreferencesByKey("Other", authenticatedId)
        result = { ...result, ...resultPreference }

        LogManagerConsole('PREFERENCES', 'getAllPreferences', result)
        return result

    } catch (exc) {
        ExceptionManager(exc, route, 'getAllPreferences');
        return rejectWithValue(exc)
    }
});


export const getPreferences = createAsyncThunk('preferences/get', async ({ entityName, entityId, force = false }, { rejectWithValue }) => {
    try {
        return await getPreferencesByKey(entityName, entityId, force)

    } catch (exc) {
        ExceptionManager(exc, route, 'getPreference');
        return rejectWithValue(exc)
    }
});


export const savePreference = createAsyncThunk('preferences/save', async ({ entityName, entityId, preferenceKey, preferenceValue }, { rejectWithValue, dispatch, getState }) => {
    try {
        await clientMutation(
            ` mutation savePreference ($projectKey: ProjectKey!, $entityName: EntityName!, $entityId: String!, $preferenceKey: String!, $preferenceValue: AWSJSON!){
                    preference: savePreference (projectKey: $projectKey, entityName: $entityName, entityId: $entityId, preferenceKey: $preferenceKey, preferenceValue: $preferenceValue) {
                        id
                    }
                }`,
            {
                projectKey: "PX",
                entityName,
                entityId,
                preferenceKey,
                preferenceValue: JSON.stringify(preferenceValue)
            },
            endpoints.GRAPHQL_GENERAL,
        );

        let data = localStorage.getItem(getPreferencesName(entityName));
        if (data)
            data = JSON.parse(data);
        else
            data = { date: moment(), data: [] }

        let dataPreferences = data.data;
        let index = dataPreferences.findIndex(x => x.preferenceKey === preferenceKey)
        if (index === -1) {
            let newPreference = { preferenceKey, entityName, entityId, preferenceValue: JSON.stringify(preferenceValue) }
            dataPreferences.push(newPreference)
            data = { date: moment(), data: dataPreferences }
            localStorage.setItem(getPreferencesName(entityName), JSON.stringify(data))
        }
        else {
            dataPreferences[index].preferenceValue = JSON.stringify(preferenceValue);
            data = { date: moment(), data: dataPreferences }
            localStorage.setItem(getPreferencesName(entityName), JSON.stringify(data))
        }

        let result = {}
        result[getPreferencesName(entityName)] = dataPreferences
        return result

    } catch (exc) {
        ExceptionManager(exc, route, 'savePreference');
        return rejectWithValue(exc);
    }
});

//Slice
const preferencesRedux = createSlice({
    name: 'preferences',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        catalogVideoChangeStatus(state, action) {
            return { ...state, videoChangeStatus: action.payload }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE)

        builder.addCase(getAllPreferences.pending, (state, action) => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(getAllPreferences.fulfilled, (state, action) => {
            return { ...state, result: true, loading: false, ...action.payload }
        });
        builder.addCase(getAllPreferences.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getPreferences.pending, (state, action) => {
            state.loadingPreference = true;
            state.error = null;
            state.returnServer = false
        });
        builder.addCase(getPreferences.fulfilled, (state, action) => {
            state.loading = false;
            state.loadingPreference = false
            state.returnServer = true
        });
        builder.addCase(getPreferences.rejected, (state, action) => {
            state.loadingPreference = false;
            state.error = action.payload;
        });

        builder.addCase(savePreference.pending, (state, action) => {
            state.loadingPreference = true;
            state.error = null;
        });
        builder.addCase(savePreference.fulfilled, (state, action) => {
            return { ...state, resultUpdate: true, loading: false, ...action.payload }
        });
        builder.addCase(savePreference.rejected, (state, action) => {
            state.loadingPreference = false;
            state.error = action.payload;
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = preferencesRedux;

// Extract and export each action creator by name
export const { reset, catalogVideoChangeStatus } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;