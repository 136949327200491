import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from "Core/defaultValues";
import { ExceptionManager, LogManagerConsole } from 'Core/logManager';

import { resetALL } from 'Redux/actions';

//STATE INITIAL
const INIT_STATE = {
    open: false,
    loading: false,
    error: null,
    data: null,
};

const route = 'modules/.template/modals/edit/redux/slice'

export const templateEditOpen = createAsyncThunk('template/edit/open', async (params, { rejectWithValue, dispatch, getState }) => {
    try {
        LogManagerConsole("TEMPLATE", "templateEditOpen", params);
        //if the action is created 
        if (!params?.id) return null

        //if action is edited
        // const response = await clientQuery(
        //     `
        //    query GetCrew($id: Int!) {
        //         data: getCrew(id: $getCrewId) {
        //             id
        //             firstName
        //             lastName
        //         }
        //     }
        //     `,
        //     {
        //         id
        //     },
        //     endpoints.GRAPHQL_GENERAL_V2
        // );

        LogManagerConsole("TEMPLATE", "templateEditOpen", response);

        return response?.data;
    } catch (exc) {
        ExceptionManager(exc, route, 'templateEditOpen');
        return rejectWithValue(exc)
    }
});

export const templateEditSave = createAsyncThunk('template/edit/save', async ({ id, firstName, lastName }, { rejectWithValue, dispatch, getState }) => {
    try {
        LogManagerConsole("TEMPLATE", "templateEditSave", id, firstName, lastName);

        if (lastName)
            //sample error
            throw 'Edit Error Sample'

        //dear developer the intention of this code is to show how to handle error in async action.. this only a sample

        let response
        if (id) {
            //if action is edited
            // response = await clientQuery(
            //     `
            //     mutation UpdateCrew($id: Int!, $firstName: String!, $lastName: String!, $unitId: Int) {
            //         updateCrew(id: $id, firstName: $firstName, lastName: $lastName, unitId: $unitId) {
            //             id
            //         }
            //     }
            //     `,
            //     {
            //         id,
            //         firstName,
            //         lastName
            //     },
            //     endpoints.GRAPHQL_GENERAL_V2
            // );

        }
        else {
            // response = await clientQuery(
            //     `
            //     mutation CreateCrew($firstName: String!, $unitId: Int, $lastName: String!) {
            //         data: createCrew(firstName: $firstName, unitId: $unitId, lastName: $lastName) {
            //             id
            //         }
            //     }
            //     `,
            //     {
            //         firstName,
            //         lastName
            //     },
            //     endpoints.GRAPHQL_GENERAL_V2
            // );

            response = { data: { id: 1 } }
        }



        LogManagerConsole("TEMPLATE", "templateEditSave", response);
        return response.data.id;
    } catch (exc) {
        ExceptionManager(exc, route, 'templateEditSave');
        return rejectWithValue(exc)
    }
});

const templateEditRedux = createSlice({
    name: 'template/edit',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        },
        closeModal(state, action) {
            state.open = false
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVDTSearchDrivers
        builder.addCase(templateEditOpen.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(templateEditOpen.fulfilled, (state, action) => {
            state.loading = false;
            state.open = true
            state.data = action.payload
        });
        builder.addCase(templateEditOpen.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });

        builder.addCase(templateEditSave.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(templateEditSave.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
            state.data = action.payload
        });
        builder.addCase(templateEditSave.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = templateEditRedux;

// Extract and export each action creator by name
export const { reset, closeModal } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;