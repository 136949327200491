import { all } from 'redux-saga/effects';

//Modules
import authContainerSagas from 'Core/containers/Auth/redux/saga';
import catalogsSagas from 'Core/catalogs/redux/saga';
import loginSagas from 'Core/views/login/redux/saga';
import resellersSagas from 'Core/resellers/redux/saga';
import forgotPasswordSagas from 'Core/views/forgotPassword/redux/saga';
import migrateUserSagas from 'Core/views/migrateUser/redux/saga';
import userAlertsSaga from 'Core/views/userAlerts/redux/saga';
import videoAlertSaga from 'Core/views/videoAlert/redux/saga';
import forceChangePasswordSaga from 'Core/views/forceChangePassword/redux/saga';
import alertLogSaga from 'Core/views/alertLog/redux/saga';
import etaLinkSaga from 'Core/views/etaLink/redux/saga';
import debugUnitSaga from 'Core/views/debugUnit/redux/saga';

//merge sagas
export default function* rootSaga() {
    yield all([
        authContainerSagas(),
        catalogsSagas(),
        loginSagas(),
        forgotPasswordSagas(),
        migrateUserSagas(),
        resellersSagas(),
        userAlertsSaga(),
        videoAlertSaga(),
        forceChangePasswordSaga(),
        alertLogSaga(),
        etaLinkSaga(),
        debugUnitSaga()
    ])
}
