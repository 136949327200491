import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from 'Core/data/GraphQLApi';
import { endpoints } from "../../../../../core/defaultValues";
import { ExceptionManager } from '../../../../../core/logManager';

import { resetALL } from 'Redux/actions';

//STATE INITIAL
const INIT_STATE = {
    error: null,
    loading: false,
    items: [],
    total: 0
};

//define routes to logs
const route = 'modules/.template/views/list/redux/slice'


//async action to search values by filters
const templateListSearch = createAsyncThunk('template/list/search', async ({ conditions, sort, limit = 10, offset = 0 }, { rejectWithValue, dispatch, getState }) => {
    try {
        const response = await clientQuery(
            templateListSearch.query,
            {
                conditions,
                sort,
                limit,
                offset
            },
            endpoints.GRAPHQL_GENERAL_V2
        );
        return response?.data || { items: [], total: 0 };
    } catch (exc) {
        ExceptionManager(exc, route, 'templateListSearch');
        return rejectWithValue(exc)
    }
});

//query to search values by filters for use in the table Componente to export server side
templateListSearch.query = `
    query searchCrews($conditions: [CrewSearchInput], $sort: [CrewSortInput], $limit: Int, $offset: Int) {
        data: searchCrews(conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
            items {
                id
                firstName
                lastName
                unitId
                unit {
                    label
                }
                createdOn
            }
            total
        }
    }
`
export { templateListSearch };

//reducer object
const templateListRedux = createSlice({
    name: 'template/list',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : INIT_STATE
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        //maintenanceVDTSearchDrivers
        builder.addCase(templateListSearch.pending, (state, action) => {
            state.loading = true
        });
        builder.addCase(templateListSearch.fulfilled, (state, action) => {
            state.loading = false;
            state.items = action.payload.items
            state.total = action.payload.total
        });
        builder.addCase(templateListSearch.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload
        });
    },
})

// Extract and export the action creators object and the reducer
export const { actions, reducer } = templateListRedux;

// Extract and export each action creator by name
export const { reset, changeFilters } = actions;

//Simple async action

// Export the reducer, either as a default or named export
export default reducer;