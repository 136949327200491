//imports
import {
    LOGIN,
    LOGIN_SM,
    LOGIN_RESULT,
    LOGIN_CONFIRM_CLOSE,
    LOGIN_CONFIRM,
    LOGIN_CONFIRM_RESULT,
    CLEAR_REDUX,
    CHECK_MIGRATE_USER,
    CHECK_MIGRATE_USER_RESULT,
    GET_LOGIN_PAGE,
    GET_LOGIN_PAGE_RESULT,
    NEW_PASSWORD_REQUIRED_RESULT,
    TERMS_AND_CONDITION,
    UPDATE_TERMS_AND_CONDITION
} from "Redux/actionTypes";

//initial values
const INIT_STATE = {
    loading: false,
    loadingSM: false,
    cognitoValues: null,
    error: null,
    showConfirm: false,
    token: null,
    showPassword: false,
    loadingLoginPage: false,
    loginPage: null,
    session: null,
    username: null,
    termAndConditionData:{}
};

//change redux object
export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHECK_MIGRATE_USER:
            return { ...INIT_STATE, loginPage: state.loginPage, showPassword: false, loading: true};
        case CHECK_MIGRATE_USER_RESULT:
          return { ...INIT_STATE, loginPage: state.loginPage, showPassword: action.payload.showPassword, loading: false};
        case LOGIN:
            return { ...state, loading: true, error: null };

        case LOGIN_RESULT:
            return { 
                ...state, 
                loading: false, 
                loadingSM: false, 
                cognitoValues: action.payload.cognitoValues, 
                showConfirm: action.payload.showConfirm, 
                error: action.payload.error, 
                token: action.payload.token 
            };

        case LOGIN_CONFIRM_CLOSE:
            return { ...INIT_STATE };

        case LOGIN_CONFIRM:
            return { ...state, loading: true, error: null };

        case LOGIN_CONFIRM_RESULT:
            return { ...state, loading: false, error: action.payload.error };

        case GET_LOGIN_PAGE:
            return { ...state, loginPage: null  };

        case GET_LOGIN_PAGE_RESULT:
            return { ...state, loginPage: action.payload.response };      
        case NEW_PASSWORD_REQUIRED_RESULT:
            return { ...state, session: action.payload.session, username: action.payload.username}
        case TERMS_AND_CONDITION:
            return { ...state, loading: true, error: null };
        case UPDATE_TERMS_AND_CONDITION:
            return { ...state,  termAndConditionData: action.payload };
        case CLEAR_REDUX:
            return ["LOGIN"].includes(action.payload.option)
                ? action.payload.parameters
                    ? { ...state, ...action.payload.parameters }
                    : { ...INIT_STATE }
                : { ...state }
        default:
            return state;
    }
};
