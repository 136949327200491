import {call, put} from "redux-saga/effects";
import {clientQuery} from "Core/data/GraphQLApi";
import {endpoints} from "Core/defaultValues";
import {ExceptionManager} from "Core/logManager";
// Actions
import {reportsMainGenerateReportProgress, reportsMainGenerateReportUpdate,} from "Redux/actions";

import {baseReportConditions, generateReportInstance, getTimeZone, handleAsyncReport, isJsonFormat,} from "Modules/reports/views/main/components/reportsTab/helper";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";

function* getFuelLevelReport(
    report,
    page,
    rowsPerPage,
    sortField,
    sortOrder,
    requestFlag
) {

    const {timeZone, sendByEmail, emailsTo, name, timeframeName, categoryName, delayed, dataResultPath} = report;
    const {
        filterType,
        filterArray,
        startRange,
        endRange,
        unitsSelected,
        filterEntity,
        otherFilters,
        accumulateData = true,
        dates
    } = report.filters

    const baseConditionsQuery = baseReportConditions({
        report,
        name,
        dataResultPath,
        timeZone,
        delayed,
        unitsSelected,
        timeframeName,
        filterType,
        filterArray,
        categoryName,
        startRange,
        endRange,
        dates
    })

    try {
        let payload = {
            conditions: {
                ...baseConditionsQuery,
                stringValues: {
                    values: otherFilters?.percentage
                },
                filterValues: {
                    entities: [{
                        id: otherFilters.event,
                        type: filterEntity
                    }]
                },
                sortField: {
                    field: sortField || "deviceId",
                    order: sortOrder === 'desc' ? 'DESC' : 'ASC'
                }
            },
            limit: rowsPerPage,
            offset: page
        };

        const addColumnDriver = (
            report?.exportType === 'csv' ?
                [{
                    column: filterEntity === 'Unit' ? "deviceLabel" : "driverName",
                    header: filterEntity === 'Unit' ? "Unit" : "Driver"
                }]
                : [])

        if (report.export) {
            payload = {
                ...payload,
                limit: isJsonFormat(report?.exportType, dataResultPath) ? rowsPerPage : 5000,
                offset: isJsonFormat(report?.exportType, dataResultPath) ? page : 1,
                conditions: {
                    ...payload.conditions,
                    exportOptions: {
                        sendByEmail,
                        emailsTo,
                        format: {
                            fileFormat: report?.exportType || "json",
                            formatOptions: "{\"addPagination\": \"true\", \"addLogo\": \"true\", \"addLine\": \"true\"}",
                            durationFormat: report?.durationFormat
                        },
                        culture: report.lang,
                        timeZone: getTimeZone(timeZone),
                        folderName: "px-customer-reports",
                        filePrefixName: "fuelLevelReport",
                        columns: [
                            ...addColumnDriver,
                            {
                                column: filterEntity === 'Unit' ? "driverName" : "deviceLabel",
                                header: filterEntity === 'Unit' ? "Driver" : "Unit"
                            },
                            {
                                column: "unitTime",
                                header: `${report?.exportType === "csv" ? `Time & Date (${getAbbvTimezone(timeZone)})` : `Time & Date`}`
                            },
                            {
                                column: "fuelPercentage",
                                header: "Final Fuel %",
                            },
                            {
                                column: "previousFuelPercentage",
                                header: "Previous Fuel %",
                            },
                            {
                                column: "fuelPercentageChange",
                                header: `% Changed`
                            },
                            {
                                column: "fuelEvent",
                                header: "Fuel Event",
                            },
                            {
                                column: "address",
                                header: "Address",
                            },
                        ]
                    }
                }
            }
        }
        let data = yield call(
            clientQuery,
            `query getFuelLevelReport ($conditions: ReportConditionsInput, $limit: Int, $offset: Int) {
                Report: getFuelLevelReport (conditions: $conditions, limit: $limit, offset: $offset) {
                    ${generateReportInstance(report.export, dataResultPath, report.exportFromTable) ?
                `reportInstanceId`
                : `
                    items {
                        recordInfo {
                            entityId
                            entityLabel
                            tagId
                            tagLabel
                            startDate
                            endDate
                            recordType
                            groupLabel
                            groupIndex
                            groupTotal
                            groupTagColor
                        }
                        recordDetail {
                            id
                            idLabel
                            deviceId
                            deviceLabel
                            driverId
                            driverName
                            eventName
                            unitTime
                            address
                            latitude
                            longitude
                            landmarkId
                            landmarkName
                            fuelPercentage
                            previousFuelPercentage
                            fuelPercentageChange
                            fuelEvent
                            noData
                        }
                    }
                    total
                    limit
                    offset
                    category
                    reportInstanceId
                `}
                }
              }`,
            payload,
            endpoints.GRAPHQL_PX_REPORTING,
        );
        yield handleAsyncReport(report, data, accumulateData);

    } catch (exc) {
        ExceptionManager(exc, 'reports/fuelLevelReport', 'getFuelLevelReport');
        report.loading = false
        yield put(reportsMainGenerateReportProgress(report))
        if (!requestFlag) {
            let flag = true
            yield put(reportsMainGenerateReportUpdate(report, page, rowsPerPage, sortField, sortOrder, flag))
        } else {
            report.loading = false
            yield put(reportsMainGenerateReportProgress(report))
        }
    }
}


export default getFuelLevelReport;
