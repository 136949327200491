// Imports
import React, { lazy, Suspense, useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { defaultStartPath } from 'Core/defaultValues';
import { HasPermission, ValidateVersion } from 'Core/security';

// Containers
import AuthContainer from 'Core/containers/Auth';
import Header from 'Core/containers/Header';
import Sidebar from 'Core/containers/Sidebar';
import Catalogs from 'Core/catalogs';
import Loading from 'Components/Loading';
import Dtc from "Modules/.common/components/Dtc";
import SharedMedia from "Modules/.common/components/SharedMedia";
import ModalDownloadFiles from 'Modules/reports/views/main/components/reportsTab/.components/modalDownloadFiles';

import {
	reportsOpenModalDownloadFiles,
	clearRedux,
	getResellerInfo
} from 'Redux/actions';

// Lazy load components with retry
const lazyWithRetry = (componentImport) =>
	lazy(async () => {
		const pageHasAlreadyBeenForceRefreshed = JSON.parse(
			window.localStorage.getItem(
				'page-has-been-force-refreshed'
			) || 'false'
		);

		try {
			const component = await componentImport();

			window.localStorage.setItem(
				'page-has-been-force-refreshed',
				'false'
			);

			return component;
		} catch (error) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				// Assuming that the user is not on the latest version of the application.
				// Let's refresh the page immediately.
				window.localStorage.setItem(
					'page-has-been-force-refreshed',
					'true'
				);
				return window.location.reload();
			}

			// The page has already been reloaded
			// Assuming that user is already using the latest version of the application.
			// Let's let the application crash and raise the error.
			throw error;
		}
	});

//Modules
const RealtimeMaps = lazyWithRetry(() => import('./realtimeMaps'));
const MainComponent = lazyWithRetry(() => import('./main'));
const MaintenanceRouter = lazyWithRetry(() => import('./maintenance'));
const RouterIncidents = lazyWithRetry(() => import('./incidents'));
const ReportsRouter = lazyWithRetry(() => import('./reports'));
const BehaviorComponent = lazyWithRetry(() => import('./behavior'));
const FleetComponent = lazyWithRetry(() => import('./fleet'));
const Alerts = lazyWithRetry(() => import('./alerts'));
const VideoRouter = lazyWithRetry(() => import('./video'));
const Account = lazyWithRetry(() => import('./account'));
const Settings = lazyWithRetry(() => import('./settings'));
const DashboardView = lazyWithRetry(() => import('./dashboard'));
const RoutesRoutesView = lazyWithRetry(() => import('./routes'));
const FsmView = lazyWithRetry(() => import('./fsm'));
const DynamicFormsView = lazyWithRetry(() => import('./dynamicForms'));
const ChatChannelList = lazyWithRetry(() => import('./chat/modals/chatChannelList'));

//error page
const ErrorView = lazyWithRetry(() => import('./error'));

// Integragion modules
const ApolloELDView = lazyWithRetry(() => import('./integrations/apolloEld'));
const CarIQView = lazyWithRetry(() => import('./integrations/cariq'));
const FieldSyncView = lazyWithRetry(() => import('./integrations/fieldSync'));
const ELDFleetView = lazyWithRetry(() => import('./integrations/eldFleet'));
const MarketPlace = lazyWithRetry(() => import('./integrations/marketPlace'));
const MonarchIframe = lazyWithRetry(() => import('./integrations/monarch'));
const Template = lazyWithRetry(() => import('./.template'));

const ModulesRoutes = () => {
	const { messages } = useIntl();
	const dispatch = useDispatch();
	const location = useLocation()
	const navigate = useNavigate()

	const user = useSelector(state => state.securityRedux.user);
	const idSubUserLogout = useSelector(state => state.catalogsRedux.idSubUserLogout);
	const isDeveloper  = useSelector(state => state.commomRedux.isDeveloper);
	const sidebarShow  = useSelector(state => state.commomRedux.sidebarShow);
	const isFullScreen  = useSelector(state => state.commomRedux.isFullScreen);
	const current_reseller = useSelector(state => state.resellerRedux.current_reseller);
	const type = useSelector(state => state.resellerRedux.type);
	let debug = Boolean(localStorage.getItem("debug")) === true;

	const urlLinks = {
		"maps": "/app/realtimemaps/main",
		"vehiclestatus": "/app/fleet/main",
		"main": "/app/realtimemaps/main",
		"reports": "/app/reports/main",
		"dashboard": "/app/dashboard/main",
		"/app/fleet/main": "/app/fleet/main",
		"/app/realtimemaps/main": "/app/realtimemaps/main",
		"/app/reports/main": "/app/reports/main",
		"/app/dashboard/main": "/app/dashboard/main",
		"routes": "/app/dashboard/main",
		"/app/routes/main": "/app/routes/main",
	};

	useEffect(() => {
		if (idSubUserLogout) {
			dispatch(clearRedux("CATALOG_REDUX", { idSubUserLogout: null }))
			logout(navigate, current_reseller, type)
		}
	}, [idSubUserLogout])

	useEffect(() => {
		if (!current_reseller && user?.dealerId) {
			dispatch(getResellerInfo('id', user?.dealerId))
		}
	}, [user])

	const urlsDownload = useSelector(state => state.catalogsRedux.urlsDownload)
	const openDTC = useSelector(state => state.dtcRedux.open);

	useEffect(() => {
		if (urlsDownload?.length > 0) {
			// when a new report is added through the catalog, we show the modal
			dispatch(reportsOpenModalDownloadFiles(true));
		}
	}, [urlsDownload]);

	//set default path
	let defaultPath = defaultStartPath;
	const validateDefaultTab = (user, defaultPath) => {
		// validate if user has permission to enter to the default tab
		if (defaultPath == '/app/dashboard/main' || defaultPath == 'dashboard') {
			return HasPermission(user, 'db', [63])
		} else if (defaultPath == '/app/reports/main' || defaultPath == 'reports') {
			return HasPermission(user, 'nr', [21])
		} else if (defaultPath == '/app/fleet/main' || defaultPath == 'vehiclestatus') {
			// vehiclestatus
			return HasPermission(user, 'vstat', [103])
		} else {
			return HasPermission(user, defaultPath)
		}
	}

	//get information from user custom path
	if (user && user.activeTab) {
		defaultPath = user.activeTab
		defaultPath = (defaultPath == 'units') && 'vehiclestatus' || defaultPath;
		defaultPath = urlLinks[defaultPath] || '/app/realtimemaps/main'
		if (!validateDefaultTab(user, defaultPath)) {
			defaultPath = `/app/realtimemaps/main`;
		}
	}

	//redirect to path
	if (user && (location.pathname === '/' || location.pathname === '/app' || location.pathname === '/app/')) {
		return (<Navigate to={defaultPath} />);
	}

	return (
		<AuthContainer>
			{ValidateVersion("head")}
			<Catalogs user={user} />
			<div style={{ position: 'relative', zIndex: 9999 }}>
				{/* HEADER */}
				{
					!isFullScreen &&
					<Header />
				}
				{/* CONTAINER */}
				<div id='id_appContainer' className={cx("app-container", isFullScreen && 'full-screen-legacy')}>
					{/* SIDEBAR */}
					{
						!isFullScreen &&
						<Sidebar sidebarShow={sidebarShow} user={user} isDeveloper={isDeveloper} messages={messages} />
					}
					{/* PAGES */}
					<div className={cx('module-container', !sidebarShow && 'sidebar-min', isFullScreen && 'full-screen-legacy')}>
						<ModalDownloadFiles />
						<Suspense fallback={<Loading className="center-loading" />}>
							<Routes>
								<Route path='/' element={<Navigate to="/app/legacy/dashboard" />}></Route>
								<Route path='main' element={<MainComponent />} />
								<Route path='realtimemaps/*' element={<RealtimeMaps />} />
								<Route path='maintenance/*' element={<MaintenanceRouter />} />
								<Route path='incidents/*' element={<RouterIncidents />} />
								<Route path='fleet/*' element={<FleetComponent />} />
								<Route path='reports/*' element={<ReportsRouter />} />
								<Route path='behavior/*' element={<BehaviorComponent />} />
								<Route path='marketplace' element={<MarketPlace />} />
								<Route path='video/*' element={<VideoRouter />} />
								<Route path='alerts/*' element={<Alerts />} />
								<Route path='eldfleet' element={<ELDFleetView />} />
								<Route path='apolloeld' element={<ApolloELDView />} />
								<Route path='cariq' element={<CarIQView />} />
								<Route path='fieldSync' element={<FieldSyncView />} />
								<Route path='account/*' element={<Account />} />
								<Route path='settings/*' element={<Settings />} />
								<Route path='dashboard/*' element={<DashboardView />} />
								<Route path='fsm/*' element={<FsmView />} />
								<Route path='routes/*' element={<RoutesRoutesView />} />
								<Route path='dynamicforms/*' element={<DynamicFormsView />} />
								<Route path='monarch/*' element={<MonarchIframe />} />
								{
									debug &&
									<Route path='template/*' element={<Template />} />
								}
								<Route path='error' element={<ErrorView />} />
								<Route path="*" element={<Navigate to="/app/error" />} />
							</Routes>
						</Suspense>
						{openDTC && <Dtc />}
						<SharedMedia />
						<ChatChannelList />
					</div>
					{/* FOOTER */}
				</div>
			</div>
		</AuthContainer>
	);
}


export default ModulesRoutes
