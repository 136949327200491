import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { resetALL } from '../../../../../.globals/redux/actions';
import { LogManagerConsole } from "../../../../../core/logManager";

import { getMediaDetailNotes } from "../../../views/videoDetail/views/media/redux/slice"

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    open: false,
    result: null,
    error: null,
    items: []
};

const route = 'modules/video/modals/delete/redux/slice'

export const saveChangeStatus = createAsyncThunk('video/changeStatus/save', async ({ ids, notes, status, visibleToDriver }, { rejectWithValue, dispatch, getState }) => {
    try {

        LogManagerConsole("VIDEO", "saveVideoReview", ids, notes, status)
        let result = await clientMutation(`
                mutation UpdateVideoReviewStatus($ids: [ReadingIdInput]!, $reviewStatus: enumVideoReviewStatus!, $notes: String, $visibleToDriver: Boolean) {
                    data: updateVideoReviewStatus(ids: $ids, reviewStatus: $reviewStatus, notes: $notes, visibleToDriver: $visibleToDriver)
                }
            `,
            {
                ids: ids,
                notes: notes,
                reviewStatus: status,
                visibleToDriver: visibleToDriver ? 1 : 0
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        LogManagerConsole("VIDEO", "saveVideoReview", result)

        if (!result?.data)
            throw 'error'

        return result.data
    } catch (exc) {
        ExceptionManager(exc, route, 'saveChangeStatus');
        return rejectWithValue(exc)
    }
});


//Slice
const videoChangeStatusRedux = createSlice({
    name: 'video/changeStatus',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        close(state, action) {
            state.open = false
        },
        openModalChangeStatus(state, action) {
            state.open = true
            state.items = action.payload.items
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(saveChangeStatus.pending, (state, action) => {
            state.loading = true
            state.error = null
        });
        builder.addCase(saveChangeStatus.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
        });
        builder.addCase(saveChangeStatus.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
})

export const { actions, reducer } = videoChangeStatusRedux;
// Extract and export each action creator by name
export const { reset, openModalChangeStatus, close } = actions;
export default reducer;