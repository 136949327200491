export const formatOdometer = (unit) => {
  const result = unit.useEcuOdometer ? Number(unit?.ecuEngineOdometer) + Number(unit?.offsetEcuEngineOdometer) || ""
    : Number(unit?.engineOdometer) + Number(unit.offsetEngineOdometer) || ""
  return result
}

export const getReportTypeName = (reportTypeId, messages) => {
  const reportTypeOptions = [
    { id: "actReport", name: messages['reportsMainRT_actReport'] },
    { id: "driverFormsReportV2", name: messages['reportsMainRT_driverFormsReportV2'] },
    { id: "driverStatusReportV2", name: messages['reportsMainRT_driverStatusReportV2'] },
    { id: "simpleReport", name: messages['reportsMainRT_simpleReport'] },
    { id: "speedReport", name: messages['reportsMainRT_speedReport'] },
    { id: "detailedReport", name: messages['reportsMainRT_detailedReport'] },
    { id: "stateMileageReport", name: messages['reportsMainRT_stateMileageReport'] },
    { id: "mileageReport", name: messages['reportsMainRT_mileageReport'] },
    { id: "mileageSummaryReport", name: messages['reportsMainRT_mileageSummaryReport'] },
    { id: "driverMileageSummaryReport", name: messages['reportsMainRT_driverMileageSummaryReport'] },
    { id: "idleReport", name: messages['reportsMainRT_idleReport'] },
    { id: "startStopIdleReport", name: messages['reportsMainRT_startStopIdleReport'] },
    { id: "ignitionReport", name: messages['reportsMainRT_ignitionReport'] },
    { id: "stopReport", name: messages['reportsMainRT_stopReport'] },
    { id: "landmarkReport", name: messages['reportsMainRT_landmarkReport'] },
    { id: "driverSafetyReport", name: messages['reportsMainRT_driverSafetyReport'] },
    { id: "summaryReport", name: messages['reportsMainRT_summaryReport'] },
    { id: "advancedSummaryReport", name: messages['reportsMainRT_advancedSummaryReport'] },
    { id: "speedReport", name: messages['reportsMainRT_speedReport'] },
    { id: "utilizationReport", name: messages['reportsMainRT_utilization'] },
    { id: "latitudeLongitudeReport", name: messages['reportsMainRT_latitudeLongitudeReport'] },
    { id: "eventReport", name: messages['reportsMainRT_eventReport'] },
    { id: "timesheetReport", name: messages['reportsMainRT_timesheetReport'] },
    { id: "pingReport", name: messages['reportsMainRT_pingReport'] },
    { id: "landmarkBasedReport", name: messages['reportsMainRT_landmarkBasedReport'] },
    { id: "odometerReport", name: messages['reportsMainRT_odometerReport'] },
    { id: "geofenceBasedReport", name: messages['reportsMainRT_geofenceBasedReport'] },
    { id: "landmarkEngineHoursReport", name: messages['reportsMainRT_landmarkEngineHoursReport'] },
    { id: "nonReportingReport", name: messages['reportsMainRT_NonReportingReport'] },
    { id: "driverTimesheetReport", name: messages['reportsMainRT_driverTimesheetReport'] },
    { id: "movingReport", name: messages['reportsMainRT_movingReport'] },
    { id: "UnitInfoReport", name: messages['reportsMainRT_UnitInfoReport'] },
    { id: "landmarkSummaryReport", name: messages['reportsMainRT_landmarkSummaryReport'] },
    { id: "landmarkCrewReport", name: messages['reportsMainRT_landmarkCrewReport'] },
    { id: "locationReport", name: messages['reportsMainRT_locationReport'] },
    { id: "jbDiagnosticsReport", name: messages['reportsMainRT_jbDiagnostics'] },
    { id: "diagnosticParametersReport", name: messages['reportsMainRT_diagnosticParametersReport'] },
    { id: "diagnosticIndicatorsReport", name: messages['reportsMainRT_DiagnosticIndicatorsReport'] },
    { id: "jbSummaryReport", name: messages['reportsMainRT_JBSummaryReport'] },
    { id: "temperatureReport", name: messages['reportsMainRT_temperatureReport'] },
    { id: "jbdtcReport", name: messages['reportsMainRT_jbdtcReport'] },
    { id: "engineHoursReport", name: messages['reportsMainRT_EngineHoursReport'] },
    { id: "stopHistoryReport", name: messages['reportsMainRT_stopHistory_legacy'] },
    { id: "formStopSummaryReport", name: messages['reportsMainRT_formStopSummaryReport'] },
    { id: "formStopSummaryReportV2", name: messages['reportsMainRT_formStopSummaryReportV2'] },
    { id: "driverFormsReport", name: messages['reportsMainRT_driverFormsReport'] },
    { id: "fsmTimeCardReport", name: messages['reportsMainRT_fsmTimeCardReport'] },
    { id: "timeCardReportV2", name: messages['reportsMainRT_timecardv2'] },
    { id: "smartOneCUtilizationReport", name: messages['reportsMainRT_smartOneCUtilizationReport'] },
    { id: "fuelCardReport", name: messages['reportsMainRT_fuelCardReport'] },
    { id: "milesPerGallonReport", name: messages['reportsMainRT_milesPerGallonReport'] },
    { id: "vehicleMaintenanceItemsReport", name: messages['reportsMainRT_vehicleMaintenanceItemsReport'] },
    { id: "maintenanceItemsReport", name: messages['reportsMainRT_vehicleMaintenanceItemsReport'] },
    { id: "deviceSummaryInfoReport", name: messages['reportsMainRT_deviceSummaryInfoReport'] },
    { id: "inputReport", name: messages['reportsMainRT_inputReport'] },
    { id: "chatHistoryReportV2", name: messages['reportsMainRT_chatHistory'] },
    { id: "stopHistoryReportV2", name: messages['reportsMainRT_stopHistory'] },
    { id: "stopSummaryReportV2", name: messages['reportsMainRT_stopSummaryReport'] },
    { id: "fsmCombinedReportV2", name: messages['reportsMainRT_fsmCombinedReportV2'] },
    { id: "hourOfUseReport", name: messages['reportsMainRT_hourOfUseReport'] },
    { id: "stateMileageCrossingReport", name: messages['reportsMainRT_stateMileageCrossingReport'] },
    { id: "stateMileageSummariesReport", name: messages['reportsMainRT_stateMileageSummariesReport'] },
    { id: "behaviorReport", name: messages['reportsMainRT_behaviorReport'] },
    { id: "driverCoachingReport", name: messages['reportsMainRT_driverCoaching'] },
    {id: "speedCoachingReport", name : messages['reportsMainRT_speedCoachingReport']},
    {id: "tripReport", name : messages['reportsMainRT_tripReport']},
    {id: "fuelLevelReport", name : messages['reportsMainRT_fuelLevelReport']},
  ]
  return reportTypeOptions.find(option => option.id === reportTypeId)?.name || reportTypeId;
}

export const getFrequencyName = (frequency) => {
  return frequency ? frequency.charAt(0).toUpperCase() + frequency.slice(1) : '';
}

export const getFrequencyNameInMultiLanguage = (frequency, messages) => {
  return messages[frequency]
}


export const validCustomFilters = (filter, otherFilters) => {
  let isValid = true;
  if (filter === 0) {
    isValid = false;
  }

  if(filter === "driverFormsReport" || filter === "driverFormsReportV2" || filter === 'formStopSummaryReport' || filter === 'formStopSummaryReportV2'){
    if(!otherFilters.form || !otherFilters.formView){
      isValid = false;
    }

    if (otherFilters.formView === 'single' && !otherFilters.form) {
      isValid = false;
    }
  }

  if (filter === "landmarkBasedReport" || filter === "landmarkSummaryReport") {
    if (!otherFilters.landmarkBaseView) {
      isValid = false;
    }

    if (otherFilters.landmarkBaseView === 'single' && !otherFilters.landmark) {
      isValid = false;
    }

    if (otherFilters.landmarkBaseView === 'tags' && !otherFilters.landmarkTags) {
      isValid = false;
    }
  }

  if (filter === "geofenceBasedReport") {
    if (!otherFilters.geofenceBaseView) {
      isValid = false;
    }

    if (otherFilters.geofenceBaseView === 'single' && !otherFilters.geofence) {
      isValid = false;
    }

    if (otherFilters.geofenceBaseView === 'tags' && !otherFilters.geofenceTags) {
      isValid = false;
    }
  }

  if (filter === "eventReport") {
    if (!otherFilters.event) {
      isValid = false;
    }
  }

  if (filter === "driverCoachingReport") {
    if (!otherFilters.eventTypes?.length && otherFilters.advancedFilter) {
      isValid = false;
    }
  }
  return isValid;
}



const colorsEvents = {
  'Idle': 'events-type-orange',
  'Travel Start': 'events-type-green',
  'Travel Stop': 'events-type-red',
  'Ignition Off': 'events-type-red',
  'Ignition On': 'events-type-blue',
  'Hard Turn': 'events-type-purple',
  'Hard Brake': 'events-type-purple',
  'Sudden Stop': 'events-type-purple',
  'Ping': 'events-type-green',
  'Drive': 'events-type-green',
  'Main Power On': 'events-type-green',
  'Main Power Off': 'events-type-red',
  'Input 2 On': 'events-type-purple',
  'Input 2 Off': 'events-type-purple',
  'Input 1 On': 'events-type-purple',
  'Input 1 Off': 'events-type-purple',
  'Input 3 On': 'events-type-purple',
  'Input 3 Off': 'events-type-purple',
  'Rapid Acceleration': 'events-type-purple',
};

export const getColorByEvent = (eventName) => {
  return colorsEvents[eventName] || ''
}

export const validateReportTypePermissions = (reportTypeOptions, isSchedule) => {
  const validatePermissionsReportType = reportTypeOptions.map(report => {
    const optionsWithPermissions = report?.options?.filter(reportTypeToValidate => (!reportTypeToValidate.disabled && ((!isSchedule && reportTypeToValidate.showJustInSchedule)? false: true) )) || []
    return {
      ...report,
      options: optionsWithPermissions
    }
  });
  return validatePermissionsReportType;
}
