// Dependencies
import { all } from "redux-saga/effects";
// Views
import contactsSaga from "Modules/account/views/contacts/redux/saga";
import loginHistorySaga from "Modules/account/views/loginHistory/redux/saga";
import subUserSaga from "Modules/account/views/subUser/redux/saga";
import userChangePasswordSaga from "Modules/account/.components/changePassword/redux/saga";
import accountApiKeysSaga from "Modules/account/views/apiKeys/redux/saga";
import accountTagsSaga from "Modules/account/views/tags/redux/saga";
import accountCreateEditTagSaga from "Modules/account/views/tags/components/createEditTag/redux/saga";
import accountViewEntitiesTagSaga from "Modules/account/views/tags/components/viewEntities/redux/saga";
import mergeTagsSaga from "Modules/account/views/tags/components/mergeTags/redux/saga";
import deleteTagsSaga from "Modules/account/views/tags/components/deleteTags/redux/saga";

import roleSaga from "Modules/account/views/role/redux/saga";
import modulesSaga from "Modules/account/views/role/components/modules/redux/saga";
// Merge sagas
export default function* rootSaga() {
  yield all([
    contactsSaga(), 
    loginHistorySaga(), 
    subUserSaga(),
    userChangePasswordSaga(),
    accountApiKeysSaga(),
    accountTagsSaga(),
    accountCreateEditTagSaga(),
    accountViewEntitiesTagSaga(),
    mergeTagsSaga(),
    roleSaga(),
    modulesSaga(),
    deleteTagsSaga(),
  ]);
}
