import React, { useEffect, useRef, useCallback, forwardRef, useState, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// Cloud React components
import { Box, Grid } from '@mui/material';
import InputDate from 'Components/InputDate';
import Form from 'Components/Form';
import { Loading } from 'Components';
// Utils
import moment from "moment";
import * as momentTz from 'moment-timezone';
// Actions
import {
  setRequestClipData,
  searchAvailableClips,
  clipRequestOpenForm,
  clipRequestGetUnits,

} from "Redux/actions";
//style
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'
import Calendar from 'Components/uiControls/calendar'
import { Tooltip } from 'Components';
import { RefreshAvClips } from "Modules/.common/components/refreshAvClips";


const RequestClip = forwardRef((props, ref) => {
  const unitsList = useSelector(state => state.catalogsRedux.unitsList);
  const dispatch = useDispatch();
  const { id, messages, type, data, setDisableBtn, setTooltip } = props;
  const {
    loading,
    availableClips,
    requestClipData
  } = useSelector((state) => state.clipsRedux);
  const [timezoneUnit, setTimezoneUnit] = useState()
  const [clipsPerDay, setClipsPerDay] = useState([])
  const clipRequestForm = useRef();

  const getUnitTime = useCallback((dateTime, timeZone) => {
    //Default date in case of error or invalid dateTime
    let newUnitTime = (new Date()).toString();

    if (dateTime) {
      if (Number.isInteger(dateTime * 1)) {
        if (dateTime.toString().length > 10) newUnitTime = (dateTime / 1000);

        newUnitTime = momentTz.unix(newUnitTime);
        newUnitTime = newUnitTime.tz(timeZone);
      } else if (typeof dateTime === 'string') {
        newUnitTime = momentTz(dateTime).tz(timeZone);
      }

      newUnitTime = newUnitTime?.toString();
    }

    return newUnitTime;
  });

  let unitTime = (new Date()).toString();
  if (type === "Trail") {
    unitTime = getUnitTime(data?.markerDetail?.vehicleTrail?.unitTime, data?.markerDetail?.unit?.timeZone?.name);
  } else if (type === "Vehicle") {
    unitTime = getUnitTime(data?.markerDetail?.unit?.lastReading?.unitTime, data?.markerDetail?.unit?.timeZone?.name);
  }

  const [formData, setFormData] = useState({
    dateTime: new Date(unitTime),
    seconds: 20,
  });

  const [availableTimeClips, setAvailableTimeClips] = useState([]);

  useImperativeHandle(ref, () => ({
    handleSubmit: handleSubmit,
  }));

  useEffect(() => {
    dispatch(clipRequestGetUnits());
  }, [])

  useEffect(() => {
    const dateRange = calculateRange(formData.dateTime, formData.seconds);
    setFormData({ ...formData, dateRange });
  }, [formData.dateTime, formData.seconds]);

  useEffect(() => {
    // if (!formData.dateTime) {
    //   setDisableBtn(true)
    //   setTooltip(messages['infoBubble_validDate'])
    // } else {
    //   setDisableBtn(false)
    //   setTooltip()
    // }

    // if (formData.dateRange) {
    //   const dateRange = getFormatedRange(formData.dateRange);
    //   const dateTime = formData.dateTime;
    //   const unitLabel = data?.markerDetail?.unit?.label;
    //   const dataTimeLine = {
    //     deviceId: id,
    //     label: unitLabel,
    //     dateRange,
    //     dateTime,
    //     hideNotification: true,
    //   };
    //   dispatch(setRequestClipData(dataTimeLine));
    // }
    if (availableClips) {
      getClipsPerDay()
    }
  }, [formData.dateRange]);

  useEffect(() => {
    if (id) {
      dispatch(searchAvailableClips({ esn: id }));
    }
  }, [id]);

  useEffect(() => {
    if (availableClips && availableClips?.length) {
      const avClips = availableClips[0]?.validTimeRangeSeq
      const clipsLastDay = []
      if(!avClips) return
      const lastClip = avClips[avClips.length - 1]
      const parseLastClip = JSON.parse(lastClip)[0]
      const formatAvailableClips = avClips.map(
        (item) => {
          const itemDate = JSON.parse(item);
          const getDataCurrentDay = moment(parseLastClip).isSame(moment(itemDate[0]), 'day')
          if (getDataCurrentDay) {
            clipsLastDay.push(itemDate[0])
          }
          return new Date(itemDate[0]);
        }
      );
      const restMinute = moment(clipsLastDay[0]).subtract(2, 'seconds')
      setFormData({ ...formData, dateTime: new Date(restMinute) })
      setAvailableTimeClips(formatAvailableClips);
    } else {
      setDisableBtn(true);
      setTooltip(messages['infoBubble_noEventsClip']);
      setAvailableTimeClips([]);
    }
  }, [availableClips]);

  useEffect(() => {
    const getUnit = unitsList.find(item => {
      if (item.linkedMode === 0) {
        if (item?.linkedUnit?.id === Number(id)) {
          return item
        }
      } else if (item?.id === Number(id)) {
        return item
      }
    })
    const getTimezone = getUnit?.linkedUnit?.timeZone?.name
    setTimezoneUnit(getTimezone)
  }, [id, unitsList])

  const calculateRange = useCallback((selectedDate, lapse = 10) => {
    const startDate = moment(selectedDate).subtract(lapse, "seconds").toDate();
    const endDate = moment(selectedDate).add(lapse, "seconds").toDate();
    return { start: startDate, end: endDate };
  });

  const getFormatedRange = useCallback((dateRange) => {
    const { start: startDate, end: endDate } = dateRange;
    const formatedRange = {
      start: moment(startDate).format(),
      end: moment(endDate).format(),
    };

    return formatedRange;
  });

  const handleSubmit = useCallback(() => {
    clipRequestForm.current.submit();
  });

  const getClipsPerDay = () => {
    if (availableClips && availableClips?.length) {
      const avClips = availableClips[0]?.validTimeRangeSeq
      const newRanges = []
      const formatAvailableClips = avClips.map(item => {
        const itemDate = JSON.parse(item)
        const getDestructureClipsPerHour = destructureClipsPerHour(itemDate)
        const tooltipStart = new Date(new Date(itemDate[0]).toLocaleString('en', { timeZone: timezoneUnit }))
        const tooltipEnd = new Date(new Date(itemDate[1]).toLocaleString('en', { timeZone: timezoneUnit }))
        const getRanges = getDestructureClipsPerHour.map(range => {
          let startDate = new Date(new Date(range[0]).toLocaleString('en', { timeZone: timezoneUnit }));
          let endDate = new Date(new Date(range[1]).toLocaleString('en', { timeZone: timezoneUnit }));
          if (moment(moment(endDate)).isAfter(moment(startDate).endOf("day"))) {
            endDate = new Date(moment(startDate).endOf("day").subtract(1, 'second'))
          }
          newRanges.push(
            {
              //fake our per position
              start: new Date(moment(startDate).startOf('hour')),
              end: endDate,
              startDate: startDate,
              initTime: new Date(new Date(itemDate[0]).toLocaleString('en', { timeZone: timezoneUnit })),
              tooltip: `${moment(tooltipStart).format('h:mm a')} - ${moment(tooltipEnd).format('h:mm a')}`
            }
          )
        })

        return newRanges
      })
      setClipsPerDay(formatAvailableClips[0])
    } else {
      setClipsPerDay([])
    }
  }

  const destructureClipsPerHour = (rangeClip) => {
    const [start, end] = rangeClip;

    const intervals = [];
    let startHour = moment(start).startOf('hour');
    const endHour = moment(start).endOf('hour');

    if (endHour.valueOf() > end) return [rangeClip]

    while (startHour < end) {
      const nextHour = moment(startHour).add(1, 'hour').startOf('hour');

      if (nextHour > end) {
        intervals.push([startHour.valueOf(), end]);
      } else {
        if (start > startHour.valueOf()) {
          intervals.push([start, nextHour.valueOf()]);
        } else {
          intervals.push([startHour.valueOf(), nextHour.valueOf()]);
        }
      }

      startHour = nextHour;
    }

    return intervals;
  }

  const filterComponents = {
    event: (props) => {
      const ev = props?.event
      const totalDuration = 3600;
      const diffMinutes = moment(ev?.end).diff(moment(ev?.startDate), 'seconds');
      const timeRatio = diffMinutes / totalDuration;
      const percentageWidth = timeRatio * 100;
      const currentT = moment(ev?.startDate).startOf('hour');
      const secondsDiff = moment(ev?.startDate).diff(currentT, 'seconds');
      const propTime = secondsDiff / totalDuration;
      const percetPosition = propTime * 100;
      return (
        <Tooltip placement='top' title={ev?.tooltip}>
          <div onClick={() => {
            if (id && !requestClipData?.noSearchAvClips) {
              const dateRange = getFormatedRange(formData.dateTime);

              let dateTime = moment(ev?.initTime).seconds(0);

              dateTime = new Date(dateTime);
              setFormData({ ...formData, dateTime: dateTime });


              const unitLabel = data?.markerDetail?.unit?.label;
              const deviceId = id;
              const gpsId = data?.markerDetail?.linkedDevice?.id ? data?.markerDetail?.linkedDevice?.id : id
              const firmware = data?.markerDetail?.linkedDevice?.firmware
              const firmwareId = data?.markerDetail?.linkedDevice?.firmwareId
              const deviceModel = data?.markerDetail?.linkedDevice?.deviceModelName
              const dataTimeLine = { deviceId: id, label: unitLabel, dateRange, dateTime, gpsId,  hideNotification: true, firmware, deviceModel, firmwareId };
              dispatch(setRequestClipData(dataTimeLine))
            }
            dispatch(clipRequestOpenForm(true))
          }} style={{ zIndex: 1, position: "absolute", width: `${percentageWidth}%`, height: `100%`, left: `${percetPosition}%`, background: '#3174ad', borderRadius: '5px' }} />
        </Tooltip>

      )

    }
  }

  return (
    <div className='info-window-tab-video'>
      {
        loading ?
          <Loading full /> :
          <Form ref={clipRequestForm}>
            <Box style={{ width: "100%", display: "flex", flexDirection: "column", height: '295px', overflowY: 'auto' }}>
              <Grid style={{ width: "100%", padding: "10px" }}>
                <label className="input-normal-label">
                  {messages["infoBubble_Date"]}
                </label>
                <InputDate
                  id="dateTime"
                  value={formData.dateTime}
                  onChange={(value) => {
                    setFormData({ ...formData, dateTime: value });
                  }}
                  type="date"
                  showSeconds
                  style={{ paddingTop: 0 }}
                  maxDate={new Date()}
                  direction="row"
                  required
                  disabled={(!availableTimeClips.length || loading) ? true : false}
                  includeDates={
                    availableTimeClips.length > 0 ? availableTimeClips : []
                  }
                />
              </Grid>
              <div style={{padding: '0 12px 0 12px'}}>
                <RefreshAvClips timezone={timezoneUnit} id={id}/>
              </div>

              {/* <Grid style={{ width: "30%", padding: "10px" }}>
                <label className="input-normal-label">
                  {messages["infoBubble_Time"]}
                </label>
                <InputDate
                  id="dateTime"
                  value={formData.dateTime}
                  onChange={(value) => {
                    setFormData({ ...formData, dateTime: value });
                  }}
                  type="time"
                  showSeconds
                  style={{ paddingTop: 0 }}
                  maxDate={new Date()}
                  direction="row"
                  required
                  disabled={(!availableTimeClips.length || loading) ? true : false}
                  includeDates={
                    availableTimeClips.length > 0 ? availableTimeClips : []
                  }
                />
              </Grid> */}
              <div style={{ height: "215px", padding: '15px 15px 10px 15px' }}>
                <Calendar
                  height='190px'
                  clipsPerDay={clipsPerDay}
                  selectedTime={new Date(formData.dateTime)}
                  filterComponents={filterComponents}
                  min={new Date('2023-12-01 00:00:00')}
                  max={new Date('2023-12-02 23:59:00')}
                />
              </div>
            </Box>
          </Form>
      }
    </div>
  );
});

export default RequestClip;
