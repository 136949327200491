export default {
    fsmRoute_label_name: "Nombre",
    fsmRoute_modal_label_waypoint: "Punto de Referencia",
    fsmRoute_modal_label_stop: "Parada",
    fsmRoute_modal_label_street_level: "Calle",
    fsmRoute_modal_label_route_level: "Ruta",
    fsmRoute_modal_label_route_delete_stop: "Borrar Parada",
    fsmRoute_modal_label_route_delete_waypoint: "Borrar Punto de Referencia",
    fsmRoute_modal_label_startRoute: "Inicio de Ruta",
    fsmRoute_modal_label_endRoute: "Fin de Ruta",
    fsmRoute_modal_label_route_delete_startRoute: "Borrar Inicio de Ruta",
    fsmRoute_modal_label_route_delete_endRoute: "Borrar Fin de Ruta"
}