import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import cx from 'classnames';
import { NotificationManager } from 'Components/Notifications';
import {
    Grid
} from "@mui/material";

import {
    Pill,
    Loading, ToggleSwitch, Checkbox
} from 'Components';


import Single from './components/single';
import Tags from './components/tags';

// Styles
import './styles.scss';
// Actions
import {
    entityTagPickerGetTags,
    catalogLoad,
    getKmls,
    clearRedux
} from 'Redux/actions';

const EntityTagPicker = (props) => {
    const { messages } = useIntl();
    const dispatch = useDispatch();
    const {
        entities,
        entity,
        option,
        entityLabel,
        optionLabel,
        onChange,
        disabled,
        values,
        entityLabelClass,
        entityButtonClass,
        entityOptionLabelClass,
        entityOptionButtonClass,
        entityTagPickerContainerClass,
        ignoreUnitStatus,
        onOpen,
        includeInactiveDrivers,
        hiddenCheckbox,
        useIncludesInactive,
        onOptionChangeStatus,
        module,
        includeUnassignedDriver,
        modeCameras,
        priorityDeviceModel,
        loadingEntities,
    } = props;

    const user = useSelector((state) => state.securityRedux.user);

    const drivers = useSelector(state => state.catalogsRedux.drivers);
    const driversIgnoreStatus = useSelector(state => state.catalogsRedux.driversIgnoreStatus);
    const loadingDrivers = useSelector(state => state.catalogsRedux.loadingDrivers);
    const units = useSelector(state => state.catalogsRedux.unitsList);
    const cameras = useSelector(state => state.catalogsRedux.cameras);
    const unitListToShow = useSelector(state => state.catalogsRedux.unitListToShow);
    const loadingUnits = useSelector(state => state.catalogsRedux.loadingUnitsList);
    const subusers = useSelector(state => state.catalogsRedux.subusers);
    const loadingSubusers = useSelector(state => state.catalogsRedux.loadingSubusers);
    const forms = useSelector(state => state.catalogsRedux.forms);
    const loadingForms = useSelector(state => state.catalogsRedux.loadingForms);
    const loadingReadings = useSelector(state => state.catalogsRedux.loadingReadings);
    const landmarks = useSelector((state) => state.realtimeMapRedux.jsonLandmark);
    const loadingLandmarks = useSelector((state) => state.realtimeMapRedux.loadingLandmarkJson);
    const geofences = useSelector((state) => state.realtimeMapRedux.jsonGeofence);
    const loadingGeofences = useSelector((state) => state.realtimeMapRedux.loadingGeofenceJson);

    const tags = useSelector(state => state.newEntityTagPickerRedux.tags);
    const tree = useSelector(state => state.newEntityTagPickerRedux.tree);
    const loadingTags = useSelector(state => state.newEntityTagPickerRedux.loadingTags);
    const error = useSelector(state => state.newEntityTagPickerRedux.error);
    const isLoading = loadingTags || loadingReadings || loadingDrivers || loadingUnits || loadingSubusers || loadingForms || loadingLandmarks || loadingGeofences
    loadingEntities && loadingEntities(isLoading)

    const options = props.options?.length ? props.options : [
        { id: "all", label: messages[`entityTagPicker_all${entity?.id || ""}`] },
        { id: "entities", label: messages[`entityTagPicker_single${entity?.id || ""}`] },
        { id: "tags", label: messages['entityTagPicker_tags'] },
    ];

    const availableEntities = [
        { id: "Unit", label: messages['entityTagPicker_units'] },
        { id: "Driver", label: messages['entityTagPicker_drivers'] },
        { id: "Landmark", label: messages['entityTagPicker_landmarks'] },
        { id: "Geofence", label: messages['entityTagPicker_geofences'] },
        { id: "Form", label: messages['entityTagPicker_forms'] },
        { id: "User", label: messages['entityTagPicker_users'] },
    ];

    const rowsPerPage = 100;
    const [searchText, setSearchText] = useState("");
    const [page, setPage] = React.useState(0);

    useEffect(() => {
        dispatch(entityTagPickerGetTags(entity?.id));
        onChangeEntity(entity);
        return () => {
            dispatch(clearRedux('NEW_ENTITY_TAG_PICKER'));
        }
    }, []);

    useEffect(() => {
        if(entities?.length){
            onChangeEntity(entity);
        }
    }, [entities?.length])

    useEffect(() => {
        if (error) {
            NotificationManager.error(
                messages['errorMessage'],
                messages['error'],
                3000,
                null,
                null,
                'danger',
            );
        }
    }, [error]);

    const onChangeEntity = (newEntity) => {
        if (newEntity) {
            const countUnits = ignoreUnitStatus ? unitListToShow?.length : units?.length;
            const countDrivers = includeInactiveDrivers ? driversIgnoreStatus?.length : drivers?.length;
            if (newEntity?.id === "Driver" && !countDrivers) {
                if (includeInactiveDrivers)
                    dispatch(catalogLoad(["driversIgnoreStatus"]));
                else
                    dispatch(catalogLoad(["drivers"]));
            } else if (newEntity?.id === "Unit" && !countUnits) {
                if(ignoreUnitStatus){
                    dispatch(catalogLoad(["unitListToShow"]));
                } else {
                    dispatch(catalogLoad(["unitsList"]));
                }
            } else if (newEntity?.id === "User" && !subusers?.length) {
                dispatch(catalogLoad(["subusers"]));
            } else if (newEntity?.id === "Form" && !forms?.length) {
                dispatch(catalogLoad(["forms"]));
            } else if ((newEntity?.id === "Geofence" || newEntity?.id === "Landmark") && (!landmarks?.length || !geofences.length)) {
                dispatch(getKmls());
            }

            dispatch(entityTagPickerGetTags(newEntity?.id));
            setPage(1);
            setSearchText("");
        }
    };

    const [data, total] = useMemo(() => {
        let tagIds = tags?.filter?.(t => !t?.disabled).map(t => t?.id);
        let tempData = [];
        if (option?.id === "tags") {
            if (searchText && typeof searchText !== 'undefined' && searchText.length > 0) {
                tempData = [...tags];
            } else {
                tempData = [...tree];
            }
        } else if (entity?.id === "Driver") {
            if (includeInactiveDrivers){
                tempData = [...driversIgnoreStatus];
            } else {
              tempData = [...drivers];
              
              if(includeUnassignedDriver){
                tempData.unshift({
                    ...tempData[0],
                    "__typename": "Driver",
                    "name": "Unassigned Driver",
                    "externalId": null,
                    "driverId": 0,
                    "status": 1,
                    "contactInfo": ""
                })
              }
              
            }
        } else if (entity?.id === "Unit") {
            if(modeCameras){
                let currentCameras = cameras?.filter((item) =>  priorityDeviceModel && item.deviceModel?.name?.toUpperCase() === priorityDeviceModel?.toUpperCase())
                tempData = [...currentCameras]
            }else if(ignoreUnitStatus){
                tempData = [...unitListToShow];
            } else {
                tempData = [...units];
            }

        } else if (entity?.id === "User") {
            tempData = [...subusers];
        } else if (entity?.id === "Form") {
            tempData = [...forms];
        } else if (entity?.id === "Geofence") {
            if (user?.isSubUser || !user) {
                let newGeofences = geofences?.filter(geofence => {
                    let geofenceTagIds = geofence?.tagsId?.split(",");
                    return geofenceTagIds?.some(geofenceTagId => tagIds?.includes(parseInt(geofenceTagId)));
                });

                tempData = [...newGeofences];
            } else {
                tempData = [...geofences];
            }
        } else if (entity?.id === "Landmark") {
            if (user?.isSubUser || !user) {
                let newLandmarks = landmarks?.filter(landmark => {
                    let landmarkTagIds = landmark?.tagsId?.split(",");
                    return landmarkTagIds?.some(landmarkTagId => tagIds?.includes(parseInt(landmarkTagId)));
                });

                tempData = [...newLandmarks];
            } else {
                tempData = [...landmarks];
            }
        }

        let viewData = tempData;
        let total = tempData.length;

        if (searchText && typeof searchText !== 'undefined' && searchText.length > 0) {
            viewData = tempData.filter(x => {
                let result = false;
                let label = x?.name || x?.label || x?.userName || "";
                if (label && label?.toLowerCase().indexOf(searchText.toLowerCase()) >= 0) {
                    result = true;
                }

                return result;
            })

            total = viewData.length;
        }

        return [viewData, total];
    });

    const dataPaginated = useMemo(() => {
        let valuesPaginate = data.slice(0, (page * rowsPerPage));
        return valuesPaginate;
    });

    return (
        <div>
            {
                (isLoading) ?
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            justifyContent: "center"
                        }}
                    >
                        <Grid item>
                            <Loading/>
                        </Grid>
                    </Grid> :
                    <div>
                        {
                            entityLabel && <div className={cx("entity-tag-picker-label", entityLabelClass)}>{entityLabel}</div>
                        }

                        <Grid
                            key={module}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            spacing={1}
                            className={entityButtonClass}
                            style={{marginBottom: 14}}
                        >
                            {
                                availableEntities?.length > 0 &&
                                availableEntities?.filter(ae => entities?.includes(ae?.id))?.map((newEntity, index) => {
                                    return (
                                        <Grid item xs key={index}>
                                            <Pill
                                                key={index}
                                                textField="label"
                                                item={newEntity}
                                                outlined={newEntity?.id !== entity?.id}
                                                onClick={() => {
                                                    if (newEntity?.id !== entity?.id) {
                                                        onChangeEntity(newEntity)
                                                        if (props.onChangeEntity) props.onChangeEntity(newEntity);
                                                        if (props.onChange) props.onChange([], newEntity);
                                                    }
                                                }}
                                                style={{border: '0', width: '100%'}}
                                                colorField="default"
                                                colorSelection="66bb6a"
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        {
                            ['Driver-all', 'Driver-hideTags'].includes(useIncludesInactive) && <div className={cx("entity-tag", entityOptionLabelClass)}>
                                <Checkbox
                                    value={includeInactiveDrivers}
                                    onChange={onOptionChangeStatus}
                                    size="small"
                                    id={"entity-tag-picker-toogle-" + module}
                                    optionLabels={[messages['entityTagPicker_yesOption'], messages['entityTagPicker_notOption']]}
                                    classNameLabel={'label-exclude-data'}
                                    style={{ marginRight: '0' }}
                                />
                                <span>{messages['entityTagPicker_includeInactiveDrivers']}</span>
                            </div>
                        }
                        {
                            optionLabel && <div className={cx("entity-tag-picker-label", entityOptionLabelClass)}>
                                <span className={"entity-tag-picker-span-left"}>{optionLabel}</span>
                            </div>
                        }

                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                            className={entityOptionButtonClass}
                            spacing={1}
                        >
                            {
                                options.map((newOption, index) => {
                                    return (
                                        <Grid item xs key={index}>
                                            <Pill
                                                key={index}
                                                textField="label"
                                                item={newOption}
                                                outlined={newOption?.id !== option?.id}
                                                onClick={() => {
                                                    if (props.onOptionChange) props.onOptionChange(newOption);
                                                    if (props.onChange) props.onChange([], entity);
                                                    setPage(1);
                                                    setSearchText("");
                                                }}
                                                style={{ border: '0', width: '100%' }}
                                                colorField="default"
                                                colorSelection="66bb6a"
                                            />
                                        </Grid>
                                    )
                                })
                            }
                        </Grid>
                        <div className={entityTagPickerContainerClass} style={{ marginTop: 14 }}>
                            {
                                useIncludesInactive === 'Driver-entities' && <div className={cx("entity-tag", entityOptionLabelClass)}>
                                    <Checkbox
                                        value={includeInactiveDrivers}
                                        onChange={onOptionChangeStatus}
                                        size="small"
                                        id={"entity-tag-picker-toogle-" + module}
                                        optionLabels={[messages['entityTagPicker_yesOption'], messages['entityTagPicker_notOption']]}
                                        classNameLabel={'label-exclude-data'}
                                        style={{ marginRight: '0' }}
                                    />
                                    <span>{messages['entityTagPicker_includeInactiveDrivers']}</span>
                                </div>
                            }
                            {
                            ['entities', 'tags', 'hideTags'].includes(option?.id) && <div className={cx("entity-tag-picker-label-min", entityOptionLabelClass)}>
                                    <span className={"entity-tag-picker-span-left"}>{messages[['tags', 'hideTags'].includes(option?.id) ? 'entityTagPicker_searchTag' : modeCameras ? 'entityTagPicker_searchCameras' : `entityTagPicker_search${entity?.id}`]}</span>
                                </div>
                            }

                            {
                                option?.id === "all" &&
                                <span className='text-muted'>
                                    {modeCameras ? messages['entityTagPicker_allSelectedCameras'] : messages[`entityTagPicker_all${entity?.id}Selected`]}
                                </span>
                            }

                            {
                                option?.id === "entities" &&
                                <Single
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    dataPaginated={dataPaginated}
                                    total={total}
                                    values={values}
                                    onChange={onChange}
                                    page={page}
                                    setPage={setPage}
                                    entity={entity}
                                    disabled={disabled}
                                    onOpen={onOpen}
                                    hiddenCheckbox={hiddenCheckbox}
                                    useIncludesIdle={includeInactiveDrivers}
                                    modeCameras={modeCameras}
                                />
                            }

                            {
                                option?.id === "tags" &&
                                <Tags
                                    searchText={searchText}
                                    setSearchText={setSearchText}
                                    tags={dataPaginated}
                                    values={values}
                                    onChange={onChange}
                                    disabled={disabled}
                                />
                            }
                        </div>
                    </div>
            }
        </div>
    )
};

export default EntityTagPicker;
