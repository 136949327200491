import { createAction } from "@reduxjs/toolkit"

const resetALL = createAction('RESET/ALL');

export { resetALL }

export * from 'Core/.globals/redux/actions';
export * from 'Core/common/redux/actions';
export * from 'Modules/maintenance/.globals/redux/actions';
export * from 'Modules/behavior/.globals/redux/actions';
export * from 'Modules/fleet/.globals/redux/actions';
export * from 'Modules/reports/.globals/redux/actions';
export * from 'Modules/alerts/.globals/redux/actions';
export * from 'Modules/.common/components/EntitySelector/redux/actions';
export * from 'Modules/realtimeMaps/.globals/redux/actions';
export * from 'Modules/.common/.globals/redux/actions';
export * from 'Modules/video/.globals/redux/actions';
export * from 'Modules/account/.globals/redux/actions';
export * from 'Modules/settings/.globals/redux/actions';
export * from 'Modules/dashboard/.globals/redux/actions';
export * from 'Modules/fsm/.globals/redux/actions';
export * from 'Modules/dynamicForms/.globals/redux/actions';
export * from '../../modules/chat/.globals/redux/actions';
export * from '../../modules/routes/.globals/redux/actions';
export * from '../../modules/incidents/.globals/redux/actions';
export * from '../../modules/.template/.globals/redux/actions';
