export default {
    fsmRoute_edit_panel_route_savedRoute: "Informacion almacenada correctamente",
    fsmRoute_edit_panel_route_remove_point: "Elimine el punto incorrecto para poder continuar",
    fsmRoute_edit_panel_route_template: "Plantilla",
    fsmRoute_edit_panel_route_driver: "Conductor",
    fsmRoute_edit_panel_route_schedule_at: "Programada en",
    fsmRoute_edit_panel_route_is_template: "Pantilla",
    fsmRoute_edit_panel_route_assigned_route: "Rutas Asignadas",
    fsmRoute_edit_panel_route_assigned_actions: "Acciones",
    fsmRoute_edit_panel_route_assigned_add_stop: "Agregar Parada",
    fsmRoute_edit_panel_route_close_confirmation_modal_title: "¡Aviso!",
    fsmRoute_edit_panel_route_close_confirmation_modal: "¿Está seguro de que desea cerrar el editor de rutas?, perderá los cambios no guardados.",
    fsmRoute_edit_panel_route_accordion_point: "Parada o punto de paso (Adicionar / Editar)",
    fsmRoute_edit_panel_route_accordion_stops: "Paradas de la ruta",
    fsmRoute_edit_panel_route_accordion_clear: "Limpiar",
    fsmRoute_edit_panel_route_info_optimize_route: '¿Tienes múltiples paradas en tu ruta? Nuestro sistema de direcciones te ayuda a organizarlas de manera eficiente. El botón "Optimizar Ruta" permite que la ruta se cree automáticamente, reordenando los puntos de parada para minimizar la distancia total del viaje y adaptarse a las condiciones actuales del tráfico.',
    fsmRoute_edit_panel_route_accordion_edit: "Editar",
    fsmRoute_edit_panel_route_accordion_name_mandatory: "El nombre de la ruta debe tener al menos 3 caracteres de longitud",
    fsmRoute_edit_panel_route_close_option_title: "Cerrar la ruta automáticamente cuando:",
    fsmRoute_edit_panel_route_close_option_final_stop: "El Vehículo llega a la parada final",
    fsmRoute_edit_panel_route_close_option_final_all_stop_visited: "Se completan todas las paradas",
    fsmRoute_edit_panel_route_close_option_final_after: "Después de",
    fsmRoute_edit_panel_route_close_option_final_after_end: "horas de inicio de la ruta",
    fsmRoute_edit_panel_route_close_validation_new_point_title: "Punto no guardado detectado",
    fsmRoute_edit_panel_route_close_validation_new_point: "Se ha agregado un nuevo punto en el mapa pero no lo has adicionado a tu ruta.",
    fsmRoute_edit_panel_route_close_validation_new_point_continue: "Si continúas, este punto {will not be saved} con tu ruta",
    fsmRoute_edit_panel_route_close_validation_new_point_continue_will_not: "no se guardará",
    fsmRoute_edit_panel_route_close_validation_new_point_add: "Para incluirlo, haz clic primero en 'Agregar o Actualizar'",
    fsmRoute_edit_panel_route_close_validation_new_point_question: "¿Deseas guardar sin este punto o volver para Adicionarlo a la ruta?",
    fsmRoute_edit_panel_route_close_validation_continue: "Continuar sin agregar",
    fsmRoute_edit_panel_route_close_validation_return: "Volver a editar",
    fsmRoute_edit_panel_route_info_points_stop_start: "Rastrea la llegada y salida en esta parada. Esta es la primera visita del conductor.",
    fsmRoute_edit_panel_route_info_points_waypoint_start: "Desactiva el rastreo de llegada/salida. Úsalo solo para la ruta o cuando comienza en una de mis ubicaciones.",
    fsmRoute_edit_panel_route_info_points_stop_end: "Rastrea la llegada y salida en esta parada. Esta es la última visita del conductor.",
    fsmRoute_edit_panel_route_info_points_waypoint_end: "Desactivado el rastreo de llegada/salida. Úsalo solo para la ruta o cuando termina en una de mis ubicaciones."
};