export default {
    accountApiKeys_name: 'Name',
    accountApiKeys_nameLabel: 'Name / Label',
    accountApiKeys_apiKey: 'API Key ',
    accountApiKeys_assignedToUser: 'Assigned to User',
    accountApiKeys_createTitle: 'New API Key',
    accountApiKeys_editTitle: 'Edit',
    accountApiKeys_minLengthMessage: "The name must contain minimum chars: ",
    accountApiKeys_maxLengthMessage: "The name must contain maximum chars: ",
    accountApiKeys_deleteTitle: "Delete API keys",
    accountApiKeys_deleteWarning: "Are you sure you want delete the following API keys?",
    accountApiKeys_success: "API key saved",
    accountApiKeys_deleted: "API keys deleted",
    accountApiKeys_user_assignedToUser: "Assigned to user", 
    accountApiKeys_user_select: "Select User", 
    accountApiKeys_user_select_place: "Select", 
};