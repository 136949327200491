import { createSlice } from "@reduxjs/toolkit";

//STATE INITIAL
const INIT_STATE = {
  isModalOpen: false
};

export const reportsListShowReportFilterRedux = createSlice(
  {
    name: 'reportsMainModalRT',
    initialState: INIT_STATE,
    reducers: {
      resetList(state) {
        return INIT_STATE;
      },
      openModal(state) {
        state.isModalOpen = true;
      },
      closeModal(state) {
        state.isModalOpen = false;
      }
    },
  })

const {actions, reducer} = reportsListShowReportFilterRedux;

// Extract and export each action creator by name
export const {
    resetList,
  openModal,
  closeModal,
} = actions;

export default reducer;
