//Modules

import commomRedux from 'Core/common/redux/reducer';
import preferencesRedux from 'Core/containers/Preferences/redux/slice';
import securityRedux from 'Core/containers/Auth/redux/reducer';
import loginRedux from 'Core/views/login/redux/reducer';
import resellerRedux from 'Core/resellers/redux/reducer';
import forgotPasswordRedux from 'Core/views/forgotPassword/redux/reducer';
import catalogsRedux from 'Core/catalogs/redux/reducer';
import migrateUserRedux from 'Core/views/migrateUser/redux/reducer';
import userAlertsRedux from 'Core/views/userAlerts/redux/reducer';
import entitySelectorRedux from 'Modules/.common/components/EntitySelector/redux/reducer';
import videoAlertRedux from 'Core/views/videoAlert/redux/reducer';
import forceChangePasswordRedux from 'Core/views/forceChangePassword/redux/reducer';
import alertLogRedux from 'Core/views/alertLog/redux/reducer';
import etaLinkRedux from 'Core/views/etaLink/redux/reducer';
import debugUnitRedux from 'Core/views/debugUnit/redux/reducer';
import shortCodeRedux from 'Core/views/shortCodes/redux/slice';
import LiveIncidentRedux from 'Core/views/incident/redux/slice';
import sharedLiveVideoRedux from '../../views/sharedLiveVideoSec/redux/slice';

import catalogsNRedux from '../../catalogs/redux/slice';


//export redux objects
export default {
  commomRedux,
  preferencesRedux,
  securityRedux,
  loginRedux,
  forgotPasswordRedux,
  catalogsNRedux,
  catalogsRedux,
  migrateUserRedux,
  userAlertsRedux,
  entitySelectorRedux,
  resellerRedux,
  videoAlertRedux,
  forceChangePasswordRedux,
  alertLogRedux,
  etaLinkRedux,
  debugUnitRedux,
  shortCodeRedux,
  LiveIncidentRedux,
  sharedLiveVideoRedux
};
