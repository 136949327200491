//IMPORT REDUCERS
import templateListRedux from '../../views/list/redux/slice';
import templateEditRedux from '../../modals/edit/redux/slice';
import templateDeleteRedux from '../../modals/delete/redux/slice';

//EXPORT ALL REDUCERS UNIQUE NAMES TO MAPPING IN COMPONENTS
export default {
    templateListRedux,
    templateEditRedux,
    templateDeleteRedux
};
