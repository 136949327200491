// Dependencies
import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVidFleetEvent } from 'Core/data/VideoEvents';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Icon
} from '@mui/material';

// Components
import VidFleetVideo from 'Modules/.common/components/VidFleetVideo';
import SmartWitnessSnapshot from 'Modules/.common/components/SmartWitnessSnapshot';
import { MenuComponent } from 'Components/Menu';
import { Button, Tooltip, LinearProgress } from 'Components';
import { Pill } from '@gpstrackit/gtc-react-controls';
import { HasPermission } from "Core/security";
import { TagsGroup } from 'Components/Tag';
import { getTagColor } from 'Core/data/Helpers';

//actions
import {
  openSharedMediaModal,
  openEventAddNotesModal,
  updateAvailableMedia,
  restoreAvailableMedia,
  openVideoAdvancedFilters,
  clearRedux,
} from 'Redux/actions';

import {
  openVideoAssignDriverModal,
} from '../../../../../../.common/components/VideoAssignDriver/redux/slice';

import {
  getFormattedDateByTimeZone,
  speedFormatMPH,
  copyToClipboard
} from 'Core/data/Helpers';

import { getLabel } from "Modules/video/.globals/helpers";
import './index.scss'
import { useIntl } from 'react-intl';
import colors from 'Assets/sass/core/vars.scss';
import { Timezone } from 'Components/uiControls'
import { openModalChangeStatus } from 'Modules/video/modals/changeStatus/redux/slice';
import { openModalDeleteVideo } from 'Modules/video/modals/delete/redux/slice';

const STATUS_ASSETS_PATH = '/assets/images/';


export const getVideoStates = (messages) => {

  const reviewStates = {
    "Unreviewed": { id: "Unreviewed", label: messages[`videoMainView_unreviewed`], color: colors['warning'] },
    "Accepted": { id: "Accepted", label: messages[`videoMainView_accepted`], color: colors['green'] },
    "Disputed": { id: "Disputed", label: messages[`videoMainView_disputed`], color: colors['red'] },
    "Reviewed": { id: "Reviewed", label: messages['videoMainView_reviewed'], color: colors['brown'] },
    "FalsePositive": { id: "FalsePositive", label: messages['videoMainView_falsePositive'], color: colors['purple'] },
  }

  return reviewStates
}

const EventCard = (props) => {

  const vidFleetVideoRef = useRef();
  const dispatch = useDispatch();

  const { messages, locale } = useIntl()

  const {
    loadingUpdateAvailableMedia,
    updateAvailableMediaResponse
  } = useSelector(state => state.videoDetailRedux);

  const { user } = useSelector(state => state.securityRedux);
  const { isKph } = user;
  const { row, isDeleted, callback, tab, openSliderVideo } = props;
  const { dateformat } = user

  const reviewStates = getVideoStates(messages)
  const {
    deviceId,
    label,
    eventName,
    unitTime,
    timeZone,
    deviceModelName,
    id,
    driverName,
    starred,
    speed,
    ecuSpeed,
    thumbnailUrl,
    clipID,
    notes,
    eventCode,
    unitType,
    useEcuSpeed,
    duration,
    deviceData,
    tags,
    url,
    mediaDeletedByUsername,
    reviewStatus
  } = row;

  const [localStarred, setLocalStarred] = useState(starred)

  useEffect(() => {
    if (updateAvailableMediaResponse[id]) {
      if (tab === 2 && callback) callback();
      dispatch(clearRedux("VIDEO_DETAIL", {
        updateAvailableMediaResponse: { ...updateAvailableMediaResponse, [id]: null },
        loadingUpdateAvailableMedia: { ...loadingUpdateAvailableMedia, [id]: false }
      }));
    }
  }, [updateAvailableMediaResponse[id]]);

  const isSnapshot = !thumbnailUrl || eventName === 'AutoSnapshot' ? true : false


  const contextualCardMenuConfig = [];

  //if (!isDeleted) contextualCardMenuConfig.push(messages['videoMainView_addNote']);
  if (!isDeleted && HasPermission(user, ["dtlvdo"], [282])) contextualCardMenuConfig.push(messages['videoMainView_delete']);
  if (!isDeleted) contextualCardMenuConfig.push(messages['videoMainView_share']);
  if (isSnapshot && !isDeleted) contextualCardMenuConfig.push(messages['videoMainView_requestClip']);
  if (isDeleted) contextualCardMenuConfig.push(messages['videoMainView_restore']);
  if (!isDeleted) contextualCardMenuConfig.push(messages['videoMainView_changeStatus']);
  if (HasPermission(user, ["vassdrv"], [283])) contextualCardMenuConfig.push(messages['videoMainView_assign']);

  const imageRoute = `${STATUS_ASSETS_PATH}clipRequestFailed.png`;


  const options = {
    messages: messages, //For to access to language keys
    mode: eventName === 'AutoSnapshot' || !thumbnailUrl || duration === 0 ? 'picture' : 'video',
    type: "playback", //Depends of the playing type, the url VidFleet API changes
    id: clipID, // This could be a ESN or Clip Id
    autoplay: false, //If is true the video will be played automatically
    deviceModel: deviceModelName || '4K', //4K|360
    url: url || null,
    thumbnailUrl: thumbnailUrl || url || imageRoute, // For the available media there is always an available resource
    pictureId: null,  // The id of the picture for request to VidFleet API for the thumbnail
    disabled: false, // true if not completed
    name: `${clipID}-${id}-${getFormattedDateByTimeZone(unitTime, timeZone?.name, messages, 'YYYY-MM-DD-HH-mm-ss')}`
  };

  let event = getVidFleetEvent(eventCode);

  const eventLabel = `${messages[event?.lanKey] || (eventName === 'Crash' ? messages['videoMainView_drivingHit'] : eventName)}`;

  if (isSnapshot) {
    event = { ...getVidFleetEvent('AutoSnapshot'), lanKey: event?.lanKey };
  }

  const getSpeed = () => {
    const newSpeed = useEcuSpeed ? ecuSpeed ? ecuSpeed : speed : speed;
    const speedFormat = speedFormatMPH(newSpeed, unitType, isKph, false, false)
    return speedFormat;
  }

  const starredAction = () => {
    dispatch(updateAvailableMedia({
      id,
      notes: notes,
      deviceId: deviceId,
      starred: !localStarred,
      hideTableLoading: true,
      mediaDeleted: isDeleted
    }))
    setLocalStarred(!localStarred)
  }


  let labelWidth = locale === "en" ? isDeleted ? 78 : 55 : isDeleted ? 95 : 68;

  return (
    <>
      <Card
        className={`${HasPermission(user, ["vidrw"], [261]) ? `gtc-video-${reviewStatus?.status?.toLowerCase() || "unreviewed"}` : ""}`}
      >
        <Grid item style={{ display: 'flex', justifyContent: 'center', backgroundColor: 'black' }}>
          <div className={'ctn-vid-fleet-video'}>
            {/* revisar este componente para ver si se puede cambiar el icono a 
            search y agg el modal para ver la imagen */}
            {deviceModelName === "SmartWitness" ? (
              <SmartWitnessSnapshot
                ref={vidFleetVideoRef}
                response={{ thumbnailUrl: thumbnailUrl || url }}
                vehicleTrail={{ deviceData }}
                indicators={false}
                height={240}
              />
            ) : (
              <VidFleetVideo ref={vidFleetVideoRef} {...options} />
            )}
          </div>
        </Grid>

        <CardContent style={{ height: isDeleted ? 200 : 185, maxHeight: isDeleted ? 200 : 185 }}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <img src={event?.icon} alt={event?.name} style={{ width: 35, height: 35 }} />
              </Grid>

              <Grid item xs zeroMinWidth style={{ paddingRight: 30 }}>
                <Button
                  type="link"
                  style={{ color: 'black' }}
                  url={`/app/video/videoDetail/media/${id}?deviceId=${deviceId}`}
                  onClick={() => dispatch(clearRedux("AVAILABLE_MEDIA", { availableMedia: [] }))}
                >
                  <Tooltip
                    key={"event"}
                    title={eventLabel}
                  >
                    <Typography noWrap style={{ fontSize: 18, fontWeight: 500, marginLeft: 8 }}>
                      {eventLabel}
                    </Typography>
                  </Tooltip>
                </Button>
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item className='gtc-video-label' style={{ width: labelWidth }}>
                <span style={{ color: colors.color_disabled_gray }}>
                  {messages['videoMainView_when']}:
                </span>
              </Grid>

              <Grid item xs zeroMinWidth>
                <Timezone
                  date={unitTime}
                  dateFormat={dateformat}
                  timeFormat="hh:mm a"
                  timezone={timeZone?.name}
                  className="gtc-video-date-time"
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item className='gtc-video-label' style={{ width: labelWidth }}>
                <span style={{ color: colors.color_disabled_gray }}>
                  {messages["videoClipRequest_driver"]}:
                </span>
              </Grid>

              <Grid item xs zeroMinWidth>
                <Tooltip
                  key={"driver"}
                  title={driverName || messages["videoClipRequest_unassigned"]}
                >
                  <Typography noWrap style={{ fontSize: 14 }}>
                    {driverName || messages["videoClipRequest_unassigned"]}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item className='gtc-video-label' style={{ width: labelWidth }}>
                <span style={{ color: colors.color_disabled_gray }}>
                  {messages["videoClipRequest_unit"]}:
                </span>
              </Grid>

              <Grid item xs zeroMinWidth>
                <Tooltip
                  key={"unit"}
                  title={label || messages["videoClipRequest_unassigned"]}
                >
                  <Typography noWrap style={{ fontSize: 14 }}>
                    {label || messages["videoClipRequest_unassigned"]}
                  </Typography>
                </Tooltip>
              </Grid>

              <Grid item>
                <div className='gtc-video-speed-container'>
                  <Icon fontSize="small">
                    speed
                  </Icon>

                  <div style={{ fontSize: 14, paddingLeft: 2 }}>
                    {getSpeed() || ""}
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item className='gtc-video-label' style={{ width: labelWidth }}>
                <span style={{ color: colors.color_disabled_gray }}>
                  {messages['videoMainView_tags']}:
                </span>
              </Grid>

              <Grid container item xs wrap="nowrap" style={{ width: "72%" }}>
                <TagsGroup
                  options={tags?.length ? tags?.map(t => {
                    return { ...t, color: getTagColor(t?.color) };
                  }) : []}
                  nameField="label"
                  colorField="color"
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item className='gtc-video-label' style={{ width: labelWidth }}>
                <span style={{ color: colors.color_disabled_gray }}>
                  {messages['videoMainView_eventId']}:
                </span>
              </Grid>

              <Grid item xs zeroMinWidth>
                <div className='gtc-video-event-id-container'>
                  <Typography noWrap style={{ fontSize: 14, paddingRight: 5 }}>
                    {deviceId || ""}-{id || ""}
                  </Typography>

                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(`${deviceId || ""}-${id || ""}`)}
                    disabled={loadingUpdateAvailableMedia[id]}
                    fontSize="small"
                    style={{ fontSize: 15 }}
                    className="btn-no-padding"
                  />
                </div>
              </Grid>
            </Grid>
            {
              isDeleted && mediaDeletedByUsername &&
              <Grid
                container
                item
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item className='gtc-video-label' style={{ width: labelWidth }}>
                  <span style={{ color: colors.color_disabled_gray }}>
                    {messages['videoMainView_deletedBy']}:
                  </span>
                </Grid>
                <Grid item xs zeroMinWidth>
                  <div className='gtc-video-event-id-container'>
                    <Typography noWrap style={{ fontSize: 14, paddingRight: 5 }}>
                      {mediaDeletedByUsername}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            }


            <Grid item style={{ paddingTop: 5, width: '100%', fontSize: '15px', display: 'flex', justifyContent: 'start', flexDirection: 'column' }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item style={{ marginTop: 8 }}>
                  {
                    HasPermission(user, ["vidrw"], [261]) &&
                    <Tooltip title={reviewStatus?.description} placement='bottom'>
                      <div>
                        <Pill
                          textField={"label"}
                          item={reviewStates[reviewStatus?.status] || reviewStates['Unreviewed']}
                          variant='filled'
                        />
                      </div>
                    </Tooltip>
                  }
                </Grid>

                <Grid item>
                  <Box style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
                    <Box style={{ display: 'flex' }}>
                      {
                        (notes && notes !== 'undefined' && notes !== 'null') &&
                        <Tooltip title={notes} placement={'bottom'}>
                          <div style={{ padding: 7 }}>
                            <Icon name="notes" fontSize="small">
                              notes
                            </Icon>
                          </div>
                        </Tooltip>
                      }

                      {
                        !isDeleted &&
                        <Button
                          type="icon"
                          // icon={starred ? <StarIcon/> : <StarBorderIcon/>}
                          icon={localStarred ? "star" : "star_outline"}
                          tooltip={localStarred ? messages['videoMainView_removeStarred'] : messages['videoMainView_addStarred']}
                          onClick={() => starredAction()}
                          disabled={loadingUpdateAvailableMedia[id]}
                        />
                      }

                      {/* TODO: disable download action for 360 videos temporary, while the API dewrapping is ready*/}
                      {
                        deviceModelName != "360" &&
                        <Button
                          type="icon"
                          icon="get_app"
                          tooltip={(isDeleted || deviceModelName === "360") ? messages['videoMainView_download360Error'] : messages['download']}
                          disabled={isDeleted || deviceModelName === "360"}
                          onClick={(e) => {
                            if (e) e.preventDefault();
                            if (vidFleetVideoRef?.current) {
                              if (options.mode === "video") {
                                vidFleetVideoRef?.current.downloadVideo();
                              } else if (options.mode === "picture") {
                                vidFleetVideoRef?.current.downloadPicture();
                              }
                            }
                          }}
                        />
                      }


                      <Grid item xs>
                        <MenuComponent
                          disabled={loadingUpdateAvailableMedia[id]}
                          options={contextualCardMenuConfig}
                          icon={'more_vert'}
                          className="icon-menu-vehicle-service showInHover"
                          // eslint-disable-next-line no-unused-vars
                          onClick={(type, value) => {
                            switch (type) {
                              case messages['videoMainView_addNote']:
                                dispatch(openVideoAdvancedFilters(
                                  false,
                                  "Events"
                                ));
                                dispatch(openEventAddNotesModal({
                                  open: true,
                                  data: {
                                    id,
                                    deviceId,
                                    notes,
                                    label: getLabel(messages, row)
                                  }
                                }));
                                break;
                              case messages['videoMainView_share']:
                                dispatch(openVideoAdvancedFilters(
                                  false,
                                  "Events"
                                ));
                                dispatch(openSharedMediaModal({
                                  open: true,
                                  items: [{
                                    entity: {
                                      id: id,
                                      name: 'DeviceReading',
                                      label: getLabel(messages, row)
                                    },
                                    deviceId: deviceId,
                                  }]
                                }));
                                break;
                              case messages['videoMainView_delete']:
                                dispatch(openVideoAdvancedFilters(
                                  false,
                                  "Events"
                                ));
                                dispatch(openModalDeleteVideo({
                                  type: 'media',
                                  items: [{ label: getLabel(messages, row, locale), id: { rowId: id, deviceId } }]
                                }));
                                break;

                              case messages['videoMainView_restore']:
                                dispatch(restoreAvailableMedia([{ id, deviceId }]));
                                break;

                              case messages['videoMainView_assign']:
                                dispatch(openVideoAssignDriverModal(row));
                                break;

                              case messages['videoMainView_requestClip']:
                                openSliderVideo(row)
                                break;
                              case messages['videoMainView_changeStatus']:
                                //dispatch(openModalEventChangeStatus({open: true, data: [row] }))
                                dispatch(openModalChangeStatus({ items: [row] }))
                                break;
                              default:
                                break;
                            }
                          }}
                          value={row}
                          visible
                        />
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </CardContent>
        {
          loadingUpdateAvailableMedia[id] && <LinearProgress color="primary" />
        }

      </Card >
    </>
  )
}

export default EventCard;
