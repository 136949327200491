import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

// components
import {Grid} from '@mui/material';
import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark';

// helpers
import {
  formatDateFromFilter,
  formatDatetime,
  getGroupId,
  getMethodToFormatDuration,
  getNameUnitDriverTag,
  useHandleExportReport
} from 'Modules/reports/views/main/components/reportsTab/helper';

// hooks
import {useReportsSelector, useSidePanelMap} from 'Core/hooks';
// actions
import {clearRedux, reportsMainGenerateReportUpdate} from 'Redux/actions'

// styles
import './startStopIdleReport.scss';
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
import InfiniteScrollTable from "Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable";
import {GetFormatForMoment} from '../../../../../../../../../core/data/Helpers';
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";
import { isKPHAcronym, isKPHAcronymSpeed } from '../../../helper';

const ROW_PER_PAGE = 25;

const StartStopIdleReport = (props) => {

  const { messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive } = props;
  const {durationFormat} = user;
  const dispatch = useDispatch();
  const { loading, data, loadingSummary, filters, timeZone, summary, loadingSubEvents, asyncLoading, events, } = report;
  const { filterEntity, filterType, filterArray } = filters;

  const exportHandler = useHandleExportReport(report)
  const [SideMapComponent, onToggleSideMap] = useSidePanelMap();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRowsPerPage, setCurrentRowsPerPage] = useState(10);
  const [currentNewSortField, setCurrentNewSortField] = useState("deviceId");
  const [currentNewSortOrder, setCurrentNewSortOrder] = useState("asc");
  const [lastEntity, setLastEntity] = useState({});


  const currentIndexScroll = useRef({});
  const detailReportTableRef = useRef();
  const reportRef = useRef();
  const entitiesArrayRef = useRef();
  const lastEntityRef = useRef({});


  const loadingCreateLandMark = useSelector(state => state.vehicleLandMarkRedux.loadingCreateLandMark)
  const createLandMarkResult = useSelector(state => state.vehicleLandMarkRedux.createLandMarkResult)

  const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
  const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)

  const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)
  const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
  const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;


  useEffect(() => {
    if (report) {
      const newReport = JSON.parse(JSON.stringify(report));
      // to keep the reference to the units selected
      reportRef.current = newReport;
    }
  }, [JSON.stringify(report)]);

  useEffect(() => {
    if (isActive) {
      reportTableSelect.onChangeResetCache();
    }
  }, [isActive])


  useEffect(() => {
    if (!loadingCreateLandMark && createLandMarkResult) {
      dispatch(reportsMainGenerateReportUpdate(report, currentPage, currentRowsPerPage, currentNewSortField, currentNewSortOrder))
      dispatch(clearRedux("LANDMARK", { "createLandMarkResult": null }));
    }
  }, [loadingCreateLandMark, createLandMarkResult])


  const durationFormatTime = getMethodToFormatDuration(durationFormat);
  const TableViewSummaryByGroup = ({ index, style, content, id, previousItem }) => {

    const dataReport = content?.recordDetail;

    return (
      <div
        className="listItem groupItemSummary startStopIdle--summary"
        style={style}
        id={id}
      >
        <Grid container direction={"column"} alignItems={"flex-start"} className={'startStopIdle--summary__container'}>
          <Grid container item>
            <Grid item sm={2} className='table-scroll-reports-header'>
              <span>{ filters.filterType === 'deviceId' ? messages['generatedReport_unitSummary'] : messages['generatedReport_driverSummary']}</span>
            </Grid>
            <Grid item sm={10} className='table-scroll-reports-header'>
              <span>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()}</span>
            </Grid>
          </Grid>
          <div className='table-scroll-reports-separator'></div>
          <Grid container item className='table-scroll-reports-body' spacing={1.2}>
            <Grid item sm={2.1}>
              <b>{messages['generatedReport_engineOn']}:</b> {durationFormatTime(dataReport?.engineOn || 0)}
            </Grid>
            <Grid item sm={2.1}>
              <b>{messages['generatedReport_engineOff']}:</b> {durationFormatTime(dataReport?.engineOff || 0)}
            </Grid>
            <Grid item sm={2}>
              <b>{messages['generatedReport_idleTime']}:</b> {durationFormatTime(dataReport?.idleTime || 0)}
            </Grid>
            <Grid item sm={2}>
              <b>{messages['generatedReport_travelTime']}:</b> {durationFormatTime(dataReport?.travelTime || 0)}
            </Grid>
            <Grid item sm={1}>
              <b>{messages['generatedReport_distance']}:</b> {dataReport?.distance || 0}
            </Grid>
            <Grid item sm={1.5}>
              <b>{messages['generatedReport_AverageSpeed']}:</b> {dataReport?.averageSpeed || 0} {isKPHAcronymSpeed(dataReport?.isKPH)}
            </Grid>
            <Grid item sm={1.3}>
              <b>{messages['generatedReport_maxSpeed']}:</b> {dataReport?.maxSpeed || 0} {isKPHAcronymSpeed(dataReport?.isKPH)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const TableViewSummary = ({ index, style, content, id, previousItem }) => {

    const dataReport = content?.recordDetail;

    return (
      <div
        className="listItem startStopIdle--summaryTrip"
        style={style}
        id={id}
      >
        <Grid container direction={"column"}>
          <Grid container item>
            <Grid item sm={2} className='table-scroll-reports-header'>
              {messages['generatedReport_tripSummary'].toUpperCase()}
            </Grid>
            <Grid item sm={10} className='table-scroll-reports-header'>
              {formatDatetime(dataReport?.startTimeUTC, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(dataReport?.endTimeUTC, "h:mm A", timeZone).toUpperCase()}
            </Grid>

          </Grid>
          <div className='table-scroll-reports-separator'></div>
          <Grid container item className='table-scroll-reports-body' spacing={1.2}>
            <Grid item sm={2.5}>
              <b>{messages['generatedReport_engineOn']}:</b> {durationFormatTime(dataReport?.engineOn || 0, messages)}
            </Grid>
            <Grid item sm={2.5}>
              <b>{messages['generatedReport_engineOff']}:</b> {durationFormatTime(dataReport?.engineOff || 0, messages)}
            </Grid>
            <Grid item sm={2.5}>
              <b>{messages['generatedReport_idleTime']}:</b> {durationFormatTime(dataReport?.idleTime || 0, messages)}
            </Grid>
            <Grid item sm={2.5}>
              <b>{messages['generatedReport_travelTime']}:</b> {durationFormatTime(dataReport?.travelTime || 0, messages)}
            </Grid>
            <Grid item sm={1}>
              <b>{messages['generatedReport_distance']}:</b> {dataReport?.distance || 0}
            </Grid>
            <Grid item sm={1}>
              <b>{messages['generatedReport_maxSpeed']}:</b> {dataReport?.maxSpeed || 0} {isKPHAcronymSpeed(dataReport?.isKPH)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }


  const buildTableHeader = () => {
    return (
      <Grid container item direction={"row"} className={"table-scroll-reports-title-details startStopIdle-details"}>
        <Grid item sm={3}>
          {filters.filterType !== 'deviceId' ? messages['generatedReport_unit'] : messages['generatedReport_driver'] }
        </Grid>
        <Grid item sm={1.5}>
          {messages['generatedReport_event']}
        </Grid>
        <Grid item sm={4}>
          {messages['generatedReport_address']}
        </Grid>
        <Grid item sm={1.3}>
          {messages['generatedReport_eventDistance']}
        </Grid>
        <Grid item sm={2.2}>
          {messages['generatedReport_timeInLandmark']}
        </Grid>
      </Grid>
    )
  }

  const TableViewDetail = ({ index, style, content, id, previousItem }) => {

    const dataReport = content?.recordDetail;
    const previousItemData = previousItem?.recordInfo;



    const idleTime = (dataReport) => {

      if (dataReport?.eventName === 'Idle') {
        return <span className={'startStopIdle--detail__idle'}>{durationFormatTime(parseInt(dataReport?.duration || 0), messages)}</span>
      } else {
        return formatDatetime(dataReport?.unitTime, `h:mm A, ${GetFormatForMoment(user.dateformat)}`, timeZone)
      }
    }

    return (
      <div
        className="listItem detailItem startStopIdle--detail"
        style={style}
        id={id}
      >
        <Grid container direction={"column"} spacing={.5}>
          {previousItemData?.recordType != "DETAIL" && previousItemData?.recordType != "SUMMARY_TRAVEL" && (
            buildTableHeader()
          )}
          <Grid container item direction={"row"} style={{ padding: "10px 0" }} spacing={.5}>
            <Grid item sm={3} className={'startStopIdle--detail__firstColumn'}>
               {filters.filterType !== 'deviceId' ? dataReport.deviceLabel : dataReport?.driverName }
            </Grid>
            <Grid item sm={1.5}>
              <EventReports element={{ event: dataReport?.eventName || '' }} />
            </Grid>
            <Grid item sm={4}>
              <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={{
                event: dataReport?.eventName,
                address: dataReport?.address,
                landmarkName: dataReport?.landmarkName,
                landmarkId: dataReport?.landmarkId,
              }} iconStyle={{ fontSize: 18 }} classNameButtonAddress={'report-address-button'} />
            </Grid>
            <Grid item sm={1.3}>
              {dataReport?.distance}
            </Grid>
            <Grid item sm={2.2}>
              {idleTime(dataReport)}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const TableViewTravel = ({ index, style, content, id, previousItem }) => {

    const dataReport = content?.recordDetail;

    if (dataReport?.distanceSummaryTravels === '' && dataReport?.durationSummaryTravels === '')  return <></>

    return (
      <div
        className="listItem startStopIdle--travel"
        style={style}
        id={id}
      >
        <Grid className='table-scroll-reports-title-summary' container direction={"column"} className={'startStopIdle--travel__container'} spacing={.5}>
          <Grid container item direction={"row"}className={"startStopIdle--travel__item"} spacing={.5}>
            <Grid item sm={8.5}></Grid>
            <Grid item sm={1.3}>
              {`${dataReport?.distanceSummaryTravels} ${messages[isKPHAcronym(dataReport.isKPH)]}`}
            </Grid>
            <Grid item sm={2.2}>
              {dataReport?.durationSummaryTravels}
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }

  const TableViewGroup = ({ index, style, content, id, previousItem }) => {
    const element = content?.recordInfo;
    const recordInfo = content?.recordInfo;
    return (
      <>
        <div
          className="listItem groupItem"
          id={id}
          key={id}
          style={style}
        >

          <Grid container direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group'>
            <Grid container item className='table-scroll-reports-header'>
              <Grid item sm={3}>

                {
                  element?.tagId > 0 ?
                    (<LocalOfferIcon
                      style={{
                        fontSize: "16.8px",
                        color: `#${element.groupTagColor && element.groupTagColor !== "ffffff" ? element.groupTagColor : "000"}`,
                        position: 'absolute'
                      }} />)
                    : ""
                }
                <span
                  className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding' : ''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)}`}</span>
                {
                    report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                }
              </Grid>
              <Grid item sm={9}>
                <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
              </Grid>
            </Grid>
            <div className='table-scroll-reports-separator'></div>
            <Grid item>
              <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
            </Grid>
          </Grid>
        </div>
        <div>
          <br/>
          <br/>
          <br/>
          <br/>
        </div>
      </>
    )
  }

  const renderItems = (parameters) => {

    const { content } = parameters;
    const type = content.recordInfo?.recordType;
    if (type === "GROUP") {
      const groupId = getGroupId(content)
      const entitiesArray = entitiesArrayRef.current || {};
      if (!entitiesArray[groupId]) {
        const newEntityArray = { ...entitiesArray };
        newEntityArray[groupId] = content;
        entitiesArrayRef.current = newEntityArray
      }
      // skip the first group beacuse the first group is replaced with the sticky header
      if (parameters.index === 0) {
        return;
      }
      return (
        <TableViewGroup {...parameters}></TableViewGroup>
      )
    } else if (type === "UNIT_SUMMARY") {
      return <TableViewSummaryByGroup {...parameters}></TableViewSummaryByGroup>;
    } else if (type === "TRIP_SUMMARY") {
      return <TableViewSummary {...parameters}></TableViewSummary>
    } else if (type === "DETAIL") {
      return <TableViewDetail {...parameters}></TableViewDetail>;
    } else if (type === "SUMMARY_TRAVEL") {
      return <TableViewTravel {...parameters}></TableViewTravel>;
    }

    if (parameters.index === 0) {
      return;
    }

    return (
      <div>
        unknown type
      </div>
    )
  }

  const onStartIndex = (index) => {

    const item = data?.items[index];

    if (item) {
      if(item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId){
        setLastEntity(item)
      }
      lastEntityRef.current = item
    }
  }

  const scrollEvent = (index) => {
    currentIndexScroll.current = index;
  }

  const renderHeader = () => {
    const groupId = getGroupId(lastEntity);
    const entitiesArray = entitiesArrayRef.current || {};
    const itemHeader = entitiesArray[groupId];
    if (itemHeader) {
      return (
        <TableViewGroup
          style={{
            backgroundColor: '#fff',
            position: 'absolute',
            top: 0,
            zIndex: 2,
            width: 'inherit'
          }}
          content={itemHeader}
          id={itemHeader?.recordInfo?.entityId}
          index={currentIndexScroll.current}
        ></TableViewGroup>
      );
    }
  };

  const itemsReport = []

  data?.items?.forEach(item => {
    itemsReport.push(item)
    if (item.recordDetail.eventName === "Travel Stop") {
      const {recordInfo} = item;
      const cleneRecodInfo = {...recordInfo};
      cleneRecodInfo.recordType = 'SUMMARY_TRAVEL'
      itemsReport.push({...item, recordInfo: cleneRecodInfo})
      data.total += 1;
    }
  });


  const findTag = filterArray?.findIndex((item) => item.name === "Tag")
  const hasTag = findTag >= 0;
  const type = hasTag ? messages['generatedReport_tag'] :
    (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver'])

  return (
    <>
      <InfiniteScrollTable
        refContainer={detailReportTableRef}
        renderItems={renderItems}
        loading={asyncLoading || loading}
        data={itemsReport || []}
        total={data.total}
        reportTitle={messages['reportsMainRT_startStopIdleReport']} //Name report
        editReport={() => {
          copyReportToSetup({ ...report, data: [] })
        }}
        onSchedule={() => {
          scheduleReport(report)
        }}
        onCreate={openReportSetup}
        tableContainerClass={"detailed-report-reports-container startStopIdle"}
        type={type}
        handleNewPageLoad={(page) => {
          let currentReport = { ...report };
          currentReport.exportType = "json";
          currentReport.filters = {
            ...currentReport.filters,
            unitsSelected: unitDriverSelected?.id !== -99 ? unitDriverSelected : null,
            accumulateData: true,
            dateSelected: dateSelected?.id !== -99 ? unitDriverSelected : null,
          };
          dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
        }}
        renderHeader={renderHeader}
        onStartIndex={onStartIndex}
        scrollEvent={scrollEvent}
        isActive={isActive}
        {...reportTableSelect}
        {...exportHandler}
      />
      <SideMapComponent hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"} />
    </>
  );

};

export default StartStopIdleReport;
