//imports
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Loading } from 'Components';

import SalesForceApi from 'Core/salesForceApi';
import ThirdPartyApi from 'Core/thirdpartyApi';

//Actions
import { getUserInfo, catalogInitialRefreshResult } from 'Redux/actions';


//component
const AuthContainer = (props) => {

	const dispatch = useDispatch()
	const navigate = useNavigate()

	const { user, loading } = useSelector(state => state.securityRedux);
	const getUserInfoRefresh = useSelector((state) => state.catalogsRedux.getUserInfoRefresh);
	const isNavigate = useSelector((state) => state.catalogsRedux.isNavigate);
	const { children } = props;

	const authCheck = () => {
		let token = localStorage.getItem('IdToken');

		if (!user && token) {
			dispatch(getUserInfo(navigate));
		} else {
			if (!token) navigate('/login');
		}
	}

	useEffect(() => {
		authCheck();
	}, [])

	//sns refresh userInfo
	useEffect(() => {
		if(getUserInfoRefresh){
			dispatch(getUserInfo(isNavigate ? navigate : ''))
			dispatch(catalogInitialRefreshResult())
		}
	}, [getUserInfoRefresh])

	return (
		<Fragment>
			{
				loading &&
				<Loading full />
			}
			{
				user && (
					<Fragment>
						<ThirdPartyApi />
						<SalesForceApi user={user} />
						{children}
					</Fragment>
				)
			}
		</Fragment>
	);

}

//Export Component
export default AuthContainer
