import {
  VIDEO_DETAIL_GET_AVAILABLE_MEDIA,
  VIDEO_DETAIL_GET_CLIP_REQUEST,
  VIDEO_DETAIL_GET_MEDIA_DETAIL_RESPONSE,
  VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA,
  VIDEO_DETAIL_UPDATE_CLIP_REQUEST,
  VIDEO_DETAIL_UPDATE_CLIP_REQUEST_RESPONSE,
  VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_DETAIL_DELETE_CLIPS_REQUEST,
  VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA,
  VIDEO_DETAIL_DELETE_CLIPS_REQUEST_RESPONSE,
  VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA_RESPONSE,
  VIDEO_DETAIL_OPEN_DELETE_MODAL,
  VIDEO_DETAIL_ADD_NOTE,
  VIDEO_DETAIL_ADD_NOTE_RESPONSE,
  VIDEO_DETAIL_DELETE_NOTES,
  VIDEO_DETAIL_DELETE_NOTES_RESPONSE
} from 'Redux/actionTypes';

export  { refreshPageVideo } from '../../videoDetail/views/media/redux/slice';

// Get
export const getAvailableMediaDetail = (data) => ({
  type: VIDEO_DETAIL_GET_AVAILABLE_MEDIA,
  payload: { data },
});

export const getClipRequestDetail = (data) => ({
  type: VIDEO_DETAIL_GET_CLIP_REQUEST,
  payload: { data },
});

export const getMediaDetailResponse = (data) => ({
  type: VIDEO_DETAIL_GET_MEDIA_DETAIL_RESPONSE,
  payload: { data },
});

// Update
export const updateAvailableMedia = (data) => ({
  type: VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA,
  payload: { data },
});

export const updateClipRequest = (data) => ({
  type: VIDEO_DETAIL_UPDATE_CLIP_REQUEST,
  payload: { data },
});

export const updateClipRequestResponse = (data) => ({
  type: VIDEO_DETAIL_UPDATE_CLIP_REQUEST_RESPONSE,
  payload: { data },
});

export const updateAvailableMediaResponse = (data) => ({
  type: VIDEO_DETAIL_UPDATE_AVAILABLE_MEDIA_RESPONSE,
  payload: { data },
});

// Delete
export const openDeleteMediaDetailModal = (data) => ({
  type: VIDEO_DETAIL_OPEN_DELETE_MODAL,
  payload: { data },
});

export const deleteClipsRequest = (data) => ({
  type: VIDEO_DETAIL_DELETE_CLIPS_REQUEST,
  payload: { data },
});

export const deleteClipsRequestResponse = (data) => ({
  type: VIDEO_DETAIL_DELETE_CLIPS_REQUEST_RESPONSE,
  payload: { data },
});

export const deleteAvailableMedia = (data) => ({
  type: VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA,
  payload: { data },
});

export const deleteAvailableMediaResponse = (data) => ({
  type: VIDEO_DETAIL_DELETE_AVAILABLE_MEDIA_RESPONSE,
  payload: { data },
});

export const addNoteAvailableMedia = (data) => ({
  type: VIDEO_DETAIL_ADD_NOTE,
  payload: { data },
});

export const addNoteAvailableMediaResponse = (data) => ({
  type: VIDEO_DETAIL_ADD_NOTE_RESPONSE,
  payload: { data },
});

export const deleteNotesAvailableMedia = (data) => ({
  type: VIDEO_DETAIL_DELETE_NOTES,
  payload: { data },
});

export const deleteNotesAvailableMediaResponse = (data) => ({
  type: VIDEO_DETAIL_DELETE_NOTES_RESPONSE,
  payload: { data },
});