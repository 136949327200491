//imports
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { clientGraphQLAuthMutation, clientQuery } from 'Core/data/GraphQLApi';
import { clientPOST } from 'Core/data/RestApi';
import { LogManager, ExceptionManager } from 'Core/logManager';
import { endpoints } from 'Core/defaultValues';
import { getLegacyUrl } from 'Core/data/Helpers';
//Action types
import {
    LOGIN,
    LOGIN_CONFIRM,
    LOGIN_PM,
    CHECK_LOGIN_USER_BY_CODE,
    CHECK_MIGRATE_USER,
    GET_LOGIN_PAGE,
    TERMS_AND_CONDITION
} from 'Redux/actionTypes';

//actions
import {
    loginResult,
    loginConfirmResult,
    clearRedux,
    checkMigrateResult,
    setPermissionToMigrateUser,
    logout,
    getLoginPageResult,
    newPasswordRequiredResult
    //getResellerInfo
} from 'Redux/actions';


import {
    setCookie,
    eraseCookie
} from 'Core/views/login/helper';
import { termsAndCondition } from './actions';

/*import {
  getResellerInfo
} from 'Core/resellers/redux/saga';*/
/* FUNTIONS ASYNC */
/**
 * Method that received the code and send the request to cognito to get the 
 * AccessToken
* RefreshToken
* IdToken
* After that with this information we fetch the username with the method getLoginInformation and
* we sign in to the legacy
 */



function* checkLoginUserByCode({ payload }) {
    const domainUri = window.location.hostname === "localhost" ? `http://localhost:40000` : `https://${window.location.hostname}`;
    const { code, navigate } = payload;
    try {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        let data = yield call(
            clientPOST,
            '',
            {
                code,
                client_id: `${endpoints.COGNITO_CLIENT_ID}`,
                grant_type: 'authorization_code',
                redirect_uri: `${domainUri}/login-social-media`
            },
            `${endpoints.COGNITO_DOMAIN_USER_POOL_EXTERNAL}oauth2/token`,
            headers,
            true
        );
        if (data) {
            localStorage.setItem("AccessToken", data.access_token);
            localStorage.setItem("RefreshToken", data.refresh_token);
            localStorage.setItem("IdToken", data.id_token);
            setCookie("GPSTSharedOAUTHToken", data.id_token, 1)
            //remove local preferences
            localStorage.removeItem("preferencesUser")
            localStorage.removeItem("preferencesCompany")
            localStorage.removeItem("preferencesOther")

            let dataToLoginPHP = yield call(
                clientQuery,
                `{
                    User: getLoginInformation{
                    username
                    }
                }`,
                {},
                endpoints.GRAPHQL_GENERAL,
            );
            if (dataToLoginPHP?.User) {
                const username = dataToLoginPHP?.User?.username;
                localStorage.setItem("username", username);
                navigate('/');
                yield put(loginResult(data, false));
                yield put(clearRedux("LOGIN"));
            } else {
                throw Error("Error getting User information")
            }
        }
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/login/redux', 'Error Login with social media');
        //navigate('/login');
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('RefreshToken');
        localStorage.removeItem('IdToken');
        localStorage.removeItem('username');
        eraseCookie('GPSTSharedOAUTHToken');
        const domainUri = window.location.hostname === "localhost" ? `http://localhost:40000` : `https://${window.location.hostname}`;
        window.location = `${endpoints.COGNITO_DOMAIN_USER_POOL_EXTERNAL}logout?client_id=${endpoints.COGNITO_CLIENT_ID}&logout_uri=${domainUri}/login`
    }
}

function* checkMigrateUser({ payload }) {
    try {
        const { username, navigate } = payload;

        let data = yield call(
            clientGraphQLAuthMutation,
            `
              mutation ($username: String!){
                result: checkMigrateUser(username: $username) {
                  migrated
                  username
                  email
                  companyId
                }
              }
          `,
            {
                username
            }
        );
        if (data.error) {
            throw Error("Username or email entered is incorrect.")
        }

        if (data.result?.migrated) {
            yield put(setPermissionToMigrateUser(true));
            yield put(loginResult());
            navigate(`/migrateUser/${data.result.username}/${(data.result.email ? data.result.email + '/' : '')}${data.result.companyId || ''}`);
            return
        }
        yield put(checkMigrateResult(true));
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/login/redux', 'checkMigrateUser')
        yield put(loginResult(null, false, exc));

    }
}

function* loginRequest({ payload }) {
    try {
        const { username, password, navigate } = payload;
        yield put(logout());

        let data = yield call(
            clientGraphQLAuthMutation,
            `
                mutation ($username: String!, $password: String!){
                    result: signIn(username:$username, password:$password){
                        authenticationResult{
                            AccessToken
                            ExpiresIn
                            IdToken
                            RefreshToken
                            ChallengeName
                            Session
                        },
                        emailVerified,
                        username
                    }
                }
            `,
            {
                username,
                password
            }
        );
        if (data && data.result && data.result.authenticationResult) {
            const { emailVerified, authenticationResult, username } = data.result
            if (!emailVerified) {
                yield put(loginResult(authenticationResult, true));
                return
            } else if (authenticationResult.ChallengeName === "NEW_PASSWORD_REQUIRED") {
                let values = {
                    username,
                    session: authenticationResult.Session
                }
                yield put(newPasswordRequiredResult(values));
                navigate('/forceChangePassword');
            } else {
                localStorage.removeItem('sm');
                localStorage.setItem("AccessToken", authenticationResult.AccessToken)
                localStorage.setItem("RefreshToken", authenticationResult.RefreshToken)
                localStorage.setItem("IdToken", authenticationResult.IdToken)
                setCookie("GPSTSharedOAUTHToken", authenticationResult.IdToken, 1)
                localStorage.setItem("username", data.result.username)

                //remove local preferences
                localStorage.removeItem("preferencesUser")
                localStorage.removeItem("preferencesCompany")
                localStorage.removeItem("preferencesOther")

                navigate('/');
                yield put(loginResult(authenticationResult, false));
                yield put(clearRedux("LOGIN"));
            }
        } else {
            if (data.errorMessage || data?.errors[0]?.message) {
                throw Error(data.errorMessage || data?.errors[0]?.message);
            } else {
                throw Error("Incorrect Username or Password");
            }
        }
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/login/redux', 'loginRequest')
        yield put(loginResult(null, false, exc));

    }
}

function* termsAndConditionRequest({ payload }) {
    try {
        const { username, password, navigate } = payload;

        let data = yield call(
            clientGraphQLAuthMutation,
            `
                mutation ($username: String!, $password: String!){
                    result: acceptedTermAndCondition(username:$username, password:$password){
                        authenticationResult{
                            AccessToken
                            ExpiresIn
                            IdToken
                            RefreshToken
                            ChallengeName
                            Session
                        },
                        emailVerified,
                        username
                    }
                }
            `,
            {
                username,
                password
            }
        );

        if (data && data.result && data.result.authenticationResult) {
            const { emailVerified, authenticationResult, username } = data.result

            localStorage.removeItem('sm');
            localStorage.setItem("AccessToken", authenticationResult.AccessToken)
            localStorage.setItem("RefreshToken", authenticationResult.RefreshToken)
            localStorage.setItem("IdToken", authenticationResult.IdToken)
            setCookie("GPSTSharedOAUTHToken", authenticationResult.IdToken, 1)
            localStorage.setItem("username", data.result.username)

            //remove local preferences
            localStorage.removeItem("preferencesUser")
            localStorage.removeItem("preferencesCompany")
            localStorage.removeItem("preferencesOther")

            navigate('/');
            yield put(loginResult(authenticationResult, false));
            yield put(clearRedux("LOGIN"));

        }
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/login/redux', 'loginRequest')
        yield put(loginResult(null, false, exc));

    }
}
/*function* createTag(id) {
  // store in redux state
  yield put(getResellerInfo('id', id));
}*/

function* LoginWithPMRequest({ payload }) {
    const { username, token, navigate } = payload
    try {

        yield put(logout());
        yield put(clearRedux("LOGIN", { loadingSM: true }))

        let data = yield call(
            clientGraphQLAuthMutation,
            `
                mutation signInWithPM ($token: String!, $username: String!) {
                    result: signInWithPM (token: $token, username: $username) {
                        authenticationResult{
                            AccessToken
                            ExpiresIn
                            IdToken
                            RefreshToken
                        }
                        emailVerified
                        username
                    }
                }
            `,
            {
                username, token
            }
        );

        if (data && data.result && data.result.authenticationResult) {

            const { authenticationResult, username } = data.result

            localStorage.setItem("AccessToken", authenticationResult.AccessToken)
            localStorage.setItem("RefreshToken", authenticationResult.RefreshToken)
            localStorage.setItem("IdToken", authenticationResult.IdToken)
            setCookie("GPSTSharedOAUTHToken", authenticationResult.IdToken, 1)
            localStorage.setItem("username", username)
            localStorage.setItem("sm", true)
            //remove local preferences
            localStorage.removeItem("preferencesUser")
            localStorage.removeItem("preferencesCompany")
            localStorage.removeItem("preferencesOther")
            //console.log('270 putResolve >>> ');

            navigate('/app');
            yield put(loginResult(authenticationResult, false));
            yield put(clearRedux("LOGIN"));

        } else {
            throw Error("Incorrect Username or Password")
        }
    } catch (exc) {
        navigate('/login');
        // catch throw
        ExceptionManager(exc, 'Core/views/login/redux', 'loginRequest')
        yield put(loginResult(null, false, "Incorrect Username or Password"));
    }
}

function* loginConfirmRequest({ payload }) {
    try {
        const { username, code, cognitoValues, navigate, messages } = payload

        let data = yield call(
            clientGraphQLAuthMutation,
            `
                mutation ($accessToken: String!, $code: String!) {
                    result: verifyEmail(accessToken: $accessToken, code: $code)
                }
            `,
            {
                accessToken: cognitoValues.AccessToken,
                code: code.toString()
            }
        );
        if (data && data.result) {
            localStorage.removeItem("sm")
            localStorage.setItem("AccessToken", cognitoValues.AccessToken)
            localStorage.setItem("RefreshToken", cognitoValues.RefreshToken)
            localStorage.setItem("IdToken", cognitoValues.IdToken)
            setCookie("GPSTSharedOAUTHToken", cognitoValues.IdToken, 1)
            localStorage.setItem("username", username)
            //remove local preferences
            localStorage.removeItem("preferencesUser")
            localStorage.removeItem("preferencesCompany")
            localStorage.removeItem("preferencesOther")

            navigate('/');
            yield put(loginConfirmResult());
            yield put(clearRedux("LOGIN"));

        } else {
            yield put(loginConfirmResult(data.errorMessage || data?.errors[0]?.message || messages['migrateUser_verifyCodeError']));
        }
    } catch (exc) {
        // catch throw
        ExceptionManager(exc, 'Core/views/login/redux', 'loginRequest')
        yield put(loginConfirmResult(exc));

    }
}

function* getLoginPageRequest({ payload }) {
    const { fileKey, preview, language, navigate } = payload;
    try {

        const query = `query getLoginPage ($fileKey: String!, $preview: Boolean!, $language: String!) {
            page: getLoginPage (fileKey: $fileKey, preview: $preview, language: $language)
        }`;


        const response = yield call(
            clientQuery,
            query,
            {
                fileKey,
                preview,
                language,
            },
            endpoints.GRAPHQL_GENERAL,
            {
                'x-api-key': endpoints.PUBLIC_API_KEY
            }
        );

        if (!response || !response?.page || response?.page === "<p></p>") {
            throw Error("Login page content not found")
        }
        yield put(getLoginPageResult(response.page));
        navigate('/login', { replace: true })
    } catch (exc) {
        navigate('/login', { replace: true })
        ExceptionManager(exc, 'Core/views/login/redux', 'getLoginPageRequest');
        yield put(getLoginPageResult(null));
    }
}

function* watchLoginRequests() {
    yield takeEvery(LOGIN, loginRequest);
    yield takeEvery(LOGIN_PM, LoginWithPMRequest);
    yield takeEvery(LOGIN_CONFIRM, loginConfirmRequest);
    yield takeEvery(CHECK_MIGRATE_USER, checkMigrateUser);
    yield takeEvery(CHECK_LOGIN_USER_BY_CODE, checkLoginUserByCode);
    yield takeEvery(GET_LOGIN_PAGE, getLoginPageRequest);
    yield takeEvery(TERMS_AND_CONDITION, termsAndConditionRequest);
}

//Saga Export
export default function* rootSaga() {
    yield all([
        fork(watchLoginRequests)
    ]);
}