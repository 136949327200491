import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Checkbox } from "Components";
import { useSelector } from "react-redux";

const alertList = [
  { label: "incident_alert_start", value: "incidentStart" },
  { label: "incident_alert_vehicle_stopped", value: "vehicleStopped" },
  { label: "incident_alert_incident_end", value: "incidentEnd" },
];

export const AlertInformation = ({data}) => {
  const { messages } = useIntl()
  const contacts = useSelector(state => state.catalogsRedux.contacts);
  const [listSubscribers, setListSubscribers] = useState([])

  useEffect(() => {
    const currentSubscribers = data?.subscribers ? JSON.parse(data?.subscribers) : []
    if(currentSubscribers?.length > 0){
      const buildSubs = []
      currentSubscribers?.map(item => {
        if(item?.contactId === -1){
          return buildSubs.push({
            "alert": true,
            "alertAfter": false,
            "contactId": -1,
            "title": "All Contacts"
          })
        }
        if(item?.contactId === -2){
          return buildSubs.push({
            "alert": true,
            "alertAfter": false,
            "contactId": -2,
            "title": "Current Driver"
          })
        }
        if(!item?.contactId && item?.address){
          return buildSubs.push({
            "alert": true,
            "alertAfter": false,
            "title": item?.address,
            "type": item?.type
          })
        }
        const find = contacts?.find(contact => contact?.id === item?.contactId)
        if(find){
          buildSubs.push(find)
        }
      })
      setListSubscribers(buildSubs)
    }
  }, [contacts])
  
  if(!data?.systemAlertId) return <></>
  const activeAlerts = JSON.parse(data?.config)

  return (
    <div>
      <p className="incident_information_title_alerts">
        {messages["incendent_active_alerts_title"]}
      </p>
      <div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {alertList.map(({ label, value }, index) => (
            <Checkbox
              key={value}
              id={value}
              label={messages[label]}
              value={activeAlerts[value]}
              size="md"
              isDisabled={true}
              onChange={() => {}}
            />
          ))}
        </div>
        <div>
          {listSubscribers?.map(item => (
            <p style={{
              display: 'flex',
              width: '90%',
              background: '#66bb6b',
              padding: '5px 0px 5px 20px',
              borderRadius: '25px',
              margin: 0,
              marginBottom: '5px',
              color: 'white'
            }}>{item?.title}</p>
          ))}
        </div>

      </div>
    </div>
  );
};
