import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../core/defaultValues';
import { resetALL } from '../../../../../.globals/redux/actions';
import { LogManagerConsole } from "../../../../../core/logManager";

import { getMediaDetailNotes } from "../../../views/videoDetail/views/media/redux/slice"

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    open: false,
    type: 'media',
    result: null,
    error: null,
    items: []
};

const route = 'modules/video/modals/delete/redux/slice'

export const deleteVideoRequest = createAsyncThunk('video/delete/request', async ({ ids, note }, { rejectWithValue, dispatch, getState }) => {
    try {

        const state = getState()
        const { rowId, type, deviceId } = state.videoDeleteRedux

        LogManagerConsole("VIDEO", "deleteVideoRequest", ids, note, type)
        let result
        if (type == "notes") {


            result = await clientMutation(`
                mutation deleteDeviceReadingExtraFields ($ids: [Int]!, $rowId: String) {
                    data: deleteDeviceReadingExtraFields (ids: $ids, rowId: $rowId)
                }
               `,
                {
                    ids,
                    rowId
                },
                endpoints.GRAPHQL_GENERAL_V2
            )

            //load notes
            dispatch(getMediaDetailNotes({ readingId: `${deviceId}-${rowId}` }))
        }
        else if (type == "media") {
            result = await clientMutation(`
                mutation deleteAvailableMedia ($ids: [ReadingIdInput]!, $note: String!) {
                    data: deleteAvailableMedia (ids: $ids, note: $note)
                }
               `,
                {
                    ids,
                    note
                },
                endpoints.GRAPHQL_GENERAL_V2
            )
        }
        else if (type == "clip") {
            result = await clientMutation(`
                mutation DeleteClipsRequest($ids: [String]!) {
                    data: deleteClipsRequest(ids: $ids)
                }
               `,
                {
                    ids
                },
                endpoints.GRAPHQL_GENERAL_V2
            )
        }


        return { data: result?.data, action: type };
    } catch (exc) {
        ExceptionManager(exc, route, 'chatListContactsOpen');
        return rejectWithValue(exc)
    }
});


//Slice
const videoDeleteRedux = createSlice({
    name: 'video/delete',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        close(state, action) {
            state.open = false
        },
        openModalDeleteVideo(state, action) {
            state.open = true
            state.items = action.payload.items
            state.type = action.payload.type
            state.rowId = action.payload.rowId
            state.deviceId = action.payload.deviceId
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(deleteVideoRequest.pending, (state, action) => {
            state.loading = true
            state.error = null
        });
        builder.addCase(deleteVideoRequest.fulfilled, (state, action) => {
            state.loading = false;
            state.result = action.payload
        });
        builder.addCase(deleteVideoRequest.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
})

export const { actions, reducer } = videoDeleteRedux;
// Extract and export each action creator by name
export const { reset, openModalDeleteVideo, close } = actions;
export default reducer;