// Reducers
import videoMainRedux from 'Modules/video/views/main/redux/reducers';
import videoAdvancedFiltersRedux from 'Modules/video/views/main/.components/advancedFilters/redux/reducers';
import eventsRedux from 'Modules/video/views/main/.components/events/redux/reducer';
import clipsRedux from 'Modules/video/views/main/.components/clips/redux/reducer';
import manageCamerasRedux from 'Modules/video/views/manageCamerasTab/redux/reducer';
import videoDetailRedux from 'Modules/video/views/videoDetail/redux/reducer';

import clipRequestRedux from 'Modules/video/views/main/.components/clips/redux/slice';
import videoEventsRedux from 'Modules/video/views/main/redux/slice';

import videoDetailMediaRedux from 'Modules/video/views/videoDetail/views/media/redux/slice';
import videoDetailClipRedux from 'Modules/video/views/videoDetail/views/clip/redux/slice';
import videoDeleteRedux from 'Modules/video/modals/delete/redux/slice';
import videoChangeStatusRedux from 'Modules/video/modals/changeStatus/redux/slice';


export default {
  videoMainRedux,
  videoAdvancedFiltersRedux,
  eventsRedux,
  clipsRedux,
  manageCamerasRedux,
  videoDetailRedux,
  clipRequestRedux,
  videoEventsRedux,
  videoDetailMediaRedux,
  videoDeleteRedux,
  videoDetailClipRedux,
  videoChangeStatusRedux
};