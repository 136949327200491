import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Icon} from '@mui/material';
import { LogManager } from 'Core/logManager';
import {getLandmarkById, openFleetLandmarkModal} from 'Redux/actions'

import {isJson} from 'Modules/reports/views/main/components/reportsTab/helper';

import {useGeocoder} from 'Core/hooks'

const AddressLandmark = (props) => {
  const [onGetGeocoder, addressGeocode] = useGeocoder()
    const dispatch = useDispatch()
    let address = props.element.address || props.element.Address;
    let latitude = props.element?.latitude ? parseFloat(props.element?.latitude): 0;
    let longitude = props.element?.longitude ? parseFloat(props.element?.longitude): 0;
    let isVisibleAddress = props.element?.isVisibleAddress ?? true;
    let isVisibleLandmark = props?.isVisibleLandmark ?? true;
  if(address) {
        if (isJson(address)) {
            address = JSON.parse(address)?.formatted
        } else if (address?.includes && !address?.includes('"') && address?.includes('{')) {
            const originalAddress = address
            const newQuotedKeysString = originalAddress.replace(/({|,)(?:\s*)(?:')?([A-Za-z_$\.][A-Za-z0-9_\-\.$]*)(?:')?(?:\s*):/g, "\"$1\"$2\":\"");
            const format = newQuotedKeysString.split('{')[1];
            try {
                const add = JSON.parse(`{${format?.substring(0, format.length - 1)}"}`)
                address = `${add.address}, ${add.city}, ${add.state} ${add.zip}`
            } catch(e) {
                LogManager(`Invalid address`, e);
                address = ""
            }
        }
    } else {
        address = ""
    }

    const [stateModel, setStateModel] = useState();

    useEffect(() => {
        if (addressGeocode && addressGeocode.data && addressGeocode.data.length) {
            if (stateModel === 'map') {
                props.onToggleSideMap(true,
                    [{stateModel, latitude: addressGeocode.data[0].lat, longitude: addressGeocode.data[0].lng, direction: 35, heading: '1', Label: props.element?.Label, eventName: (props.element?.eventName || props.element?.event || 'Stop')}]);
            } else if (stateModel === 'mapLandmarkId') {
                props.onToggleSideMap(true,
                    [{stateModel, latitude: addressGeocode.data[0].lat, longitude: addressGeocode.data[0].lng, direction: 35, heading: '1', Label: props.element?.Label, eventName: (props.element?.eventName || props.element?.event || 'Stop')}]);
                dispatch(getLandmarkById(props.element.landmarkId))
            } else {
                dispatch(openFleetLandmarkModal(true, {...props.element, latitude: addressGeocode.data[0].lat, longitude: addressGeocode.data[0].lng}))
            }
        }
    }, [addressGeocode]);

    return (
        <div>
            <div style={ isVisibleAddress ? {} :  {display: 'none'}}>
                {
                    !props.hideAddress && (
                    <div className="flex">
                        <Icon className="iconFlag" style={props.iconStyle}>room</Icon>
                        {
                            !props.notUseButtonAddress ? (
                                <button
                                    className={`address report-address-landmark-button ${props.classNameButtonAddress}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setStateModel('map')
                                        if (!latitude && !longitude) {
                                            onGetGeocoder({
                                                from: 'address',
                                                address: address
                                            });
                                        } else {
                                            props.onToggleSideMap(true,
                                                [{stateModel: 'map', latitude, longitude, direction: 35, heading: '1', Label: props.element?.Label, address: address, eventName: (props.element?.eventName || props.element?.event || 'Stop')}]);
                                        }
                                    }}
                                >
                                    {address || props.messages['generatedReport_noAddress']}
                                </button>
                            ) : (
                                <a
                                    className="address report-margin-left-5"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setStateModel('map')
                                        if (!latitude && !longitude) {
                                            onGetGeocoder({
                                                from: 'address',
                                                address: address
                                            });
                                        } else {
                                            props.onToggleSideMap(true,
                                                [{stateModel: 'map', latitude, longitude, direction: 35, heading: '1', Label: props.element?.Label, address: address, eventName: (props.element?.eventName || props.element?.event || 'Stop')}]);
                                        }
                                    }}
                                >
                                    {address || props.messages['generatedReport_noAddress']}
                                </a>
                            )
                        }

                    </div>
                    )
                }
            </div>
            {
                !props.hideLandmark && (
                <div style={ isVisibleLandmark ? {marginTop: "5px"} : { display : 'none' } }>
                  <div
                    className="flex address"
                    onClick={ ( e ) => {
                      e.preventDefault();
                      if ( ( props.element.landmarkName || props.element.name ) && props.element.landmarkId ) {
                        if ( ! latitude && ! longitude ) {
                          setStateModel( 'mapLandmarkId' )
                          onGetGeocoder( {
                            from : 'address' ,
                            address : address
                          } );
                        } else {
                          props.onToggleSideMap( true ,
                            [ {
                              stateModel : 'mapLandmarkId' ,
                              latitude ,
                              longitude ,
                              direction : 35 ,
                              heading : '1' ,
                              Label : props.element?.Label ,
                              address : address ,
                              eventName : ( props.element?.eventName || props.element?.event || 'Stop' )
                            } ] );
                          dispatch( getLandmarkById( props.element.landmarkId ) )
                        }
                      } else {
                        setStateModel( 'mapLandmark' )
                        if ( ! latitude && ! longitude ) {
                          onGetGeocoder( {
                            from : 'address' ,
                            address : address
                          } );
                        } else {
                          dispatch( openFleetLandmarkModal( true , { ...props.element , latitude , longitude } ) )
                        }
                      }
                    } }
                  >
                    <Icon
                      style={ props.iconStyle }
                      className={ `iconFlag ${ ( props.element.landmarkName || props.element.name ) ? 'landmark-set' : 'landmark' }` }
                    >flag</Icon>
                    {
                      ( props.element.landmarkName || props.element.name ) ? (
                          <div style={{maxWidth: 'calc(100% - 30px)'}}>
                                <p style={ { padding : "0 5px", margin: "0",  wordWrap: "break-word", textAlign: "left"} }>{ props.element.landmarkName }</p>
                          </div>
                        )
                        : (
                          <>
                            {
                              ! props.notUseButtonAddress ? (
                                <button className="address report-address-landmark-button">{ props.messages['generatedReport_landmark'] }</button>
                              ) : (
                                <a className="address report-margin-left-5 report-address-landmark-button">{ props.messages['generatedReport_landmark'] }</a>
                              )
                            }
                          </>
                        )
                    }
                  </div>
                </div>
              )
            }

        </div>
    )
}

export default AddressLandmark;
