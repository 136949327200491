export default {
    fsmRoute_label_name: "Name",
    fsmRoute_modal_label_waypoint: "Waypoint",
    fsmRoute_modal_label_stop: "Stop",
    fsmRoute_modal_label_street_level: "Street",
    fsmRoute_modal_label_route_level: "Route",
    fsmRoute_modal_label_route_delete_stop: "Delete Stop",
    fsmRoute_modal_label_route_delete_waypoint: "Delete Waypoint",
    fsmRoute_modal_label_startRoute: "Start Route",
    fsmRoute_modal_label_endRoute: "End Route",
    fsmRoute_modal_label_route_delete_startRoute: "Delete Start Route",
    fsmRoute_modal_label_route_delete_endRoute: "Delete End Route"
}