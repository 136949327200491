//imports
import React, { Fragment, useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loading } from 'Components';
import { LogManagerConsole } from 'Core/logManager';

import { reset, getAllPreferences, savePreference } from './redux/slice'

//component
const PreferencesContainer = forwardRef((props, ref) => {

	const dispatch = useDispatch()
	const { preferencesCompany, preferencesUser, preferencesOther, result, resultUpdate } = useSelector(state => state.preferencesRedux);
	const { authenticatedId, user } = useSelector(state => state.securityRedux);
	const [loadPage, setLoadPage] = useState(false)

	useEffect(() => {
		dispatch(getAllPreferences({ authenticatedId, companyId: user?.companyId }))
	}, [])

	useEffect(() => {
		if (result || resultUpdate) {
			setLoadPage(true)
			dispatch(reset({ result: false, resultUpdate: false }))

			LogManagerConsole('PREFERENCES', 'useEffect', result, preferencesUser, props.preferencesKey)

			let preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === props.preferencesKey)
			let preferencesUserJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
			preferencesUserJSON = JSON.parse(preferencesUserJSON)

			preferencesKeyTemp = preferencesCompany.find(x => x.preferenceKey === props.preferencesKey)
			let preferencesCompanyJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
			preferencesCompanyJSON = JSON.parse(preferencesCompanyJSON)

			if (props.onInitialPreferences) props.onInitialPreferences(preferencesUserJSON, preferencesCompanyJSON);
		}

		/* if (resultUpdate) {
			dispatch(reset({ resultUpdate: false }))
		} */
	}, [result, resultUpdate])

	useImperativeHandle(ref, () => ({
		getPreferences: (entity, key) => {

			let preferencesKeyTemp
			let preferencesJSON

			switch (entity) {
				case "User":
					preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === key)
					preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
					preferencesJSON = JSON.parse(preferencesJSON)
					return preferencesJSON

				case "Company":
					preferencesKeyTemp = preferencesCompany.find(x => x.preferenceKey === key)
					preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
					preferencesJSON = JSON.parse(preferencesJSON)
					return preferencesJSON

				case "Other":
					preferencesKeyTemp = preferencesOther.find(x => x.preferenceKey === key)
					preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}"
					preferencesJSON = JSON.parse(preferencesJSON)
					return preferencesJSON

			}
		},
		savePreference: (entity, key, preference) => {
			//dispatch(updatePreferenceView(true))
			switch (entity) {
				case "User":
					dispatch(savePreference({ entityName: "User", entityId: authenticatedId, preferenceKey: key, preferenceValue: preference }))
					break;
				case "Company":
					dispatch(savePreference({ entityName: "Company", entityId: user.companyId, preferenceKey: key, preferenceValue: preference }))
					break;
				case "Other":
					dispatch(savePreference({ entityName: "Other", entityId: authenticatedId, preferenceKey: key, preferenceValue: preference }))
					break;
			}
		}
	}));

	return (
		<Fragment>
			{
				!loadPage
					? <Loading full />
					: props.children
			}
		</Fragment>
	);

})

//Export Component
export default PreferencesContainer