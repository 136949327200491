import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ExceptionManager } from "Core/logManager";
import { clientQuery, clientMutation } from "Core/data/GraphQLApi";
import { endpoints } from "Core/defaultValues";

const INIT_STATE = {
  loading: false, 
  error: null,
  subUsers: []
};

const route = "modules/account/views/apiKeys/redux/slice";

export const searchSubUsers = createAsyncThunk(
  "account/apikeys/searchSubUserts",
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      let data = await clientQuery(
        `
          query searchSubUsers ($conditions: [SubUserSearchInput], $sort: [SubUserSortInput], $limit: Int, $offset: Int) {
            response: searchSubUsers (conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
              items {
                id
                firstName
                lastName
                userName
                isSupervisor
              }
              total
            }
          }
        `,
        {
        },
        endpoints.GRAPHQL_GENERAL_V2
      );

      if (data) {
        //const filter = data?.response?.items?.filter(item => item?.isSupervisor === true)
        const dataFullName = data?.response?.items?.map((subUser) => {
            return {
              ...subUser,
              fullname: subUser?.firstName || subUser?.lastName  ? `${subUser?.firstName} ${subUser?.lastName}` : subUser?.userName,
            };
        });

        return dataFullName;
      } else {
        throw "Error while getting supervisor users";
      }
    } catch (exc) {
      ExceptionManager(exc, route, "searchSubUserts");
      rejectWithValue(exc);
    }
  }
);


export const AccountApikeysRedux = createSlice({
    name: "account/apikeys",
    initialState: INIT_STATE,
    reducers: {
      resetApikeys: (state, action) => {
        return action.payload
          ? { ...state, ...action.payload }
          : { ...INIT_STATE };
      },
    },
    extraReducers: (builder) => {
      //Get sub users
      builder.addCase(searchSubUsers.pending, (state, action) => {
        state.loading = true;
      });
      builder.addCase(searchSubUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.subUsers = payload;
      });
      builder.addCase(searchSubUsers.rejected, (state, { payload }) => {
        state.loadingSupervisor = false;
        state.subUsers = payload;
      });
    },
  });

  
  const { actions, reducer } = AccountApikeysRedux;
  
  export const { resetApikeys } = actions;
  
  export default reducer;
  