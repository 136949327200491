//View Keys
export default {
  realtimemaps_vehicletrails_unit: 'Unidad',
  realtimemaps_vehicletrails_date: 'Fecha / Hora',
  realtimemaps_vehicletrails_event: 'Evento',
  realtimemaps_vehicletrails_filter: 'Filtro',
  realtimemaps_vehicletrails_allevents: '- Todos los Eventos -',
  realtimemaps_vehicletrails_gettrail: 'Obtener la Ruta',
  realtimemaps_vehicletrails_gettrail_infoBubble: 'Rastro del Vehículo',
  realtimemaps_vehicletrails_nodata: 'No hay datos disponibles para el periodo solicitado',
  realtimemaps_vehicletrails_closetable: 'Cerrar Tabla De Rutas',
  realtimemaps_vehicletrails_closesettings: 'Cerrar Configuración',
  realtimemaps_vehicletrails_vehicletrail_table: 'Ver Tabla De Rutas De Vehículos',
  realtimemaps_vehicletrails_vehicletrail_view: 'Tabla de Rutas de Vehículos',
  realtimemaps_vehicletrails_vehicletrail_err: 'ha ocurrido una incidencia en la carga. por favor intente nuevamente.',
  realtimemaps_vehicletrails_vehicletrail_validatedate: 'El rango de fechas no puede exceder a 30 dias',
  realtimemaps_vehicletrails_vehicletrail_validateunit: 'La Unidad es un campo obligatorio',

  realtimemaps_vehicletrails_all: 'Todos',
  realtimemaps_vehicletrails_drive: 'Conduciendo',
  realtimemaps_vehicletrails_ping: 'Ping',
  realtimemaps_vehicletrails_travelStartStop: 'Inicio/Parada de viaje',
  realtimemaps_vehicletrails_travelStop: 'Parada de viaje',
  realtimemaps_vehicletrails_travelStart: 'Inicio de viaje',
  realtimemaps_vehicletrails_ignitionOnOff: 'Encendido/Apagado',
  realtimemaps_vehicletrails_ignitionOff: 'Apagado',
  realtimemaps_vehicletrails_ignitionOn: 'Encendido',
  realtimemaps_vehicletrails_driveEvent: 'Driving', //all events in this file are in Spanish except this one because the events comes in english
  realtimemaps_vehicletrails_motionEvent: 'Moving',  //all events in this file are in Spanish except this one because the events comes in english
  realtimemaps_vehicletrails_totaltime: 'Tiempo Total',
  realtimemaps_vehicletrails_overSpeed: 'Sobre la velocidad permitida',
  realtimemaps_vehicletrails_maxSpeed: 'Maxima Velicidad Registrada',
  realtimemaps_vehicletrails_travelStart: 'Inicio del viaje',
  realtimemaps_vehicletrails_motionStart: 'Inicio del movimiento',
};