/********************************************************
 * Third Controls
 ********************************************************/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
/********************************************************
 * Controls
 ********************************************************/
import Pagination from 'Components/TableView/Pagination'
import PlaybackBar from './PlaybackBar'
import MapObject from 'Components/Map/providers/mapObject'
import { TypeObjectMap, TypeLayer } from '../../../../components/Map/providers/enums';
import { convertDateToSeconds, validateLatLng, validateEventColor } from 'Components/Map/utils';

/********************************************************
 * Styles
 ********************************************************/
 import './style.scss' 

/********************************************************
 * Actions
 ********************************************************/
 import {
  setVehicleTrailsPlayback,
  getVehicleTrails,
  savePreference
 } from "Redux/actions";

 /********************************************************
 * Global var to timer
 ********************************************************/
 let timerCountTrail = null;
 let timerSavePrefence = null;
 var index = 0;
 let speedPlayback = 1;
 let reproduceElements = [];

 /*********************************************************
 * Author:  Walter Paz Londoño
 * Date:    24 Abr 2021
 * Module:  Realtime Maps
 * 
 * Map pagination into the Realtime maps asociate to 
 * vehice trails
 ********************************************************/
const MapPagination = (props) => {
  const { mapRef, preferences } = props;
  const { messages } = useIntl();
  const dispatch = useDispatch();

  const keyPreferences = "mapSettings";

  const [rowsPerPage, setRowsPerPage] = useState(100)
  const [page, setPage] = useState(1)
  const [rowsCount, setRowsCount] = useState(0)
  const [play, setPlay] = useState(false)
  const [preferencesValue, setPreferencesValue] = useState(null)
  
  /********************************************************
  * Selectors to get data from redux
  ********************************************************/
  const vehicleTrails = useSelector((state) => state.realtimeMapUtilitiesvehiclesTrailRedux.vehicleTrails);
  const loadVehicleTrails = useSelector((state) => state.realtimeMapUtilitiesvehiclesTrailRedux.loadVehicleTrails);
  const authenticatedId = useSelector(state => state.securityRedux.authenticatedId);
  const queryPagination = useSelector(state => state.realtimeMapUtilitiesvehiclesTrailRedux.queryPagination);
  const paramsVehicleTrails = useSelector(state => state.realtimeMapUtilitiesvehiclesTrailRedux.paramsVehicleTrails);
  
  useEffect(()=>{
    return () => {
        stopPlayBack();
        restartValues();
    }
  },[])

  /********************************************************
  * Restar vars when the user get info again
  ********************************************************/
  useEffect(()=> {
      stopPlayBack();
      restartValues();
  },[vehicleTrails?.length])

  /********************************************************
  * Get the total of rows after the new query
  ********************************************************/
  useEffect(() =>{
    if(paramsVehicleTrails){
      const { total } = paramsVehicleTrails
      setRowsCount(total)
    }
  }, [paramsVehicleTrails])

  /********************************************************
  * If new query pagination, send the restart values
  ********************************************************/
  useEffect(() => {
    if(!queryPagination){
      restartValues()
    }
  }, [queryPagination])

  /********************************************************
  * update the preferences elements into the route
  ********************************************************/
  useEffect(()=> {
    if(preferences){
      setPreferencesValue(preferences);
      if(!preferencesValue){
        const { trailRowsPerPage, trailConnectingLine, trailShowWaypointNumbers, trailPlaybackSpeed } = preferences
        mapRef.current.showMapObjectByLayer(TypeObjectMap.Polyline, TypeLayer.VechileTrails, trailConnectingLine == undefined ? true : trailConnectingLine || false)
        if(trailShowWaypointNumbers){
          mapRef.current.addLabelMarkers(TypeLayer.VechileTrails, true)
        }else{
          mapRef.current.removeMapObjects(TypeObjectMap.Label);
        }

        if (trailRowsPerPage && trailRowsPerPage != rowsPerPage) {
          setRowsPerPage(trailRowsPerPage);
        }
        speedPlayback = trailPlaybackSpeed || 1;
      }
      restartValues(false);
    }
  },[preferences?.trailRowsPerPage, preferences?.trailConnectingLine, preferences?.trailShowWaypointNumbers, rowsCount])

  useEffect(() => {
    if (preferences?.trailRowsPerPage && preferences?.trailRowsPerPage != rowsPerPage) {
      setRowsPerPage(preferences?.trailRowsPerPage);
    }
  },[preferences?.trailRowsPerPage])

  /********************************************************
  * funtion to restart values
  ********************************************************/
  const restartValues = (removeLayer = true) => {

    //1. stop the animations into the map
    //----------------------------------------
    clearInterval(timerCountTrail);
    timerCountTrail = null;
    mapRef?.current?.focusStopTrailMarkersAnimations();

    //2. Remove the markers and polylines dray into the map
    //----------------------------------------
    if(index >= 0 && index < vehicleTrails?.items?.length && removeLayer){
      mapRef?.current?.removeLayer(TypeLayer.VechileTrails);
    }

    //3. Reset vars
    //----------------------------------------
    startingIndex(); //restart the index
    reproduceElements = []; //restar the elements to validate old positions drawed on map
    setPlay(false) //reset buton play
    dispatch(setVehicleTrailsPlayback(false)); //flag to playback start // draw all elements on map
  }

  /*********************************************************
  * Change the rows per pager
  *********************************************************/
  const onChangeRowsPerPage = (paramRowsPerPage) => {
    setRowsPerPage(paramRowsPerPage)
    setPage(1)
    const paramToGetVehicleTrails = {
      ...paramsVehicleTrails,
      limit: paramRowsPerPage, 
      offset: 1
    }

    //Save row per page in user preferences
    const newPreferences = { ...preferences || {}, trailRowsPerPage: paramRowsPerPage };
    dispatch(savePreference({ entityName: "User", entityId: authenticatedId, preferenceKey: keyPreferences, preferenceValue: newPreferences }));

    restartValues();

    dispatch(getVehicleTrails(paramToGetVehicleTrails, false));
  }

  /*********************************************************
  * Change the page at the control
  *********************************************************/
  const onChangePage = (event, numberPage) => {
    setPage(numberPage)
    const paramToGetVehicleTrails = {
      ...paramsVehicleTrails,
      limit: rowsPerPage,
      offset: numberPage, 
    }
    dispatch(getVehicleTrails(paramToGetVehicleTrails, false));

    restartValues();
  }

  /*********************************************************
  * Starting add elements into the map
  *********************************************************/
  const onPlay = () => {
    //---------------------------------------------
    //1. Reset the elements in the view
    if(!reproduceElements?.length){
      dispatch(setVehicleTrailsPlayback(true))
      startingIndex();
      mapRef.current.showVehicleTrails(true);
      mapRef.current.hideMarkerModal();
    }
    if(timerCountTrail){
      clearInterval(timerCountTrail)
    }
    //onNextElement();
    //interval to reproduce trails on the ui, the index is the var to select items
    timerCountTrail = setInterval(onNextElement.bind(null, true), 1000)
  }

  /*********************************************************
  * Event Previous from the UI buttons
  *********************************************************/
  const onPrevious = () => {
    stopPlayBack();
    onPreviousElement()
  }

  /*********************************************************
  * back to the last marker
  *********************************************************/
  const onPreviousElement = () => {
    
    if(index + 1 > rowsPerPage - 1 || index + 1 >= rowsCount) return

    //1. Stop all animations
    //------------------------------------------------------------------
    mapRef?.current?.focusStopTrailMarkersAnimations();
    //update the markers to the last position
    mapRef?.current?.focusStartTrailMarkerAnimations();

    //2. Get the object to delete items
    //------------------------------------------------------------------
    const mapIdObject = vehicleTrails.items[index + 1]?.id;

    const stopAnimationMarker = mapRef.current.getObject(TypeObjectMap.Marker, `marker_${mapIdObject}`)
    if(stopAnimationMarker){
      stopAnimationMarker.isAnimated = false;
      stopAnimationMarker.stopAnimation = true;
      if(stopAnimationMarker.stopAnimationFunction){
        stopAnimationMarker.stopAnimationFunction()
      }
    }

    //3. Remove elements
    //------------------------------------------------------------------
    //Remove the items on the map with id
    mapRef?.current.removeMapObject(`marker_${mapIdObject}`, TypeObjectMap.Marker);
    mapRef?.current.removeMapObject(`label_${mapIdObject}`, TypeObjectMap.Label);
    mapRef?.current.removeMapObject(`label_marker_${mapIdObject}`, TypeObjectMap.Label);
    mapRef?.current.removeMapObject(`polyline_${mapIdObject}`, TypeObjectMap.Polyline);
    mapRef?.current.removeMapObject(`polyline_complete_${mapIdObject}`, TypeObjectMap.Polyline);

    //remove the items into array reproduce elements
    reproduceElements = reproduceElements.filter((x) => x.index != index + 1);

    //4. Update the GLOBAL index value
    //------------------------------------------------------------------
    index++;
  }

  /*********************************************************
  * Event next for vehicle trail
  *********************************************************/
  const onNext = () => {
    stopPlayBack()
    
    //---------------------------------------------
    //1. Reset the elements in the view
    if(!reproduceElements?.length){
      dispatch(setVehicleTrailsPlayback(true))
      startingIndex();
      mapRef.current.showVehicleTrails(true);
      mapRef.current.hideMarkerModal();
    }

    onNextElement()
  }

  /*********************************************************
  * draw the next marker into the map
  *********************************************************/
  const onNextElement = (isPlayback = false) => {

    //1. Eval in animation is active. return the next step
    //------------------------------------------------------------------------------------
    if(isPlayback){

      const currentItemOnPlay = vehicleTrails?.items[index + 1];

      //find item on the mapObjects
      const getMarkerObjectOnMap = mapRef?.current?.getObject(TypeObjectMap.Marker, `marker_${currentItemOnPlay?.id}`);

      //is not time to continue with the reproduce item
      if(getMarkerObjectOnMap?.isAnimated){
        return;
      } 
    }

    //2. is the last index stop playback
    //------------------------------------------------------------------------------------
    if(index < 0) {
      stopPlayBack()
      return
    }

    //3. Stops the current reprocdution items
    //------------------------------------------------------------------------------------
    const markerWithAnimations = mapRef?.current?.focusStopTrailMarkersAnimations()
    //update the markers to the last position
    mapRef?.current?.focusStartTrailMarkerAnimations();
    

    //4. Complete the draw line to next reprodution item
    //------------------------------------------------------------------------------------
    if(markerWithAnimations && markerWithAnimations.length > 0 || !isPlayback){
      //draw all before polyline
      drawOldPolyline(index);
    }

    //5. Get the index to next vehicle trails
    //------------------------------------------------------------------------------------
    if(vehicleTrails?.items && index > vehicleTrails.items.length - 1){
      if(rowsCount > (rowsPerPage * page)){
        index = rowsPerPage - 1
      }else{
        index = rowsCount - (rowsPerPage * (page - 1)) - 1
      }
    }

    //6. Reproduce items for new vehicle trails
    //------------------------------------------------------------------------------------
    //addMarkerVehicleTrails(vehicleTrails.items[index], TypeLayer.VechileTrails, null, true);
    reproduceStepOnVehicleTrails(vehicleTrails?.items[index], isPlayback);

    //7. Increate index for the next reproduction items
    index = index - 1;
  }

  /***************************************************************************************
   * Draw all polyline when user click on next element.
   * @param {*} index 
   **************************************************************************************/
  const drawOldPolyline = (index) => {
    //1. Validate index to reproduce
    //------------------------------------------------------------------------------------
    if(!(index + 1 > 0 && index + 1 <= rowsPerPage - 1)) return

    //2. Set the current value in item var
    //------------------------------------------------------------------------------------
    const item = vehicleTrails.items[index + 1];

    //3. Return is not have item to draw
    //------------------------------------------------------------------------------------
    if(!item) return

    //4. Clear the line to draw the new element
    //------------------------------------------------------------------------------------
    mapRef?.current.removeMapObject(`polyline_${item?.id}`, TypeObjectMap.Polyline);
    mapRef?.current.removeMapObject(`polyline_complete_${item?.id}`, TypeObjectMap.Polyline);

    //5. Get the preferences to draw lines on route
    //------------------------------------------------------------------------------------
    let showPolyLine = true;
    if(preferences){
      const { trailConnectingLine } = preferences
      showPolyLine = trailConnectingLine == undefined ? true : trailConnectingLine || false;
    }

    if(!showPolyLine) return; //is not necesary to dray route on map

    //6. Get the last item on the play elements
    //------------------------------------------------------------------------------------
    let lastItem = null
    if(index + 1 > 0 && index + 2 <= rowsPerPage - 1){
      lastItem = vehicleTrails.items[index + 2];
    }

    //7. Set the elements into the array to reproduce
    //------------------------------------------------------------------------------------
    let coordinatesToPolyline = lastItem ? [{ lat: lastItem.latitude, lng: lastItem.longitude }] : [];
    if(item?.trail?.length > 0){ //get the trails points is the item have this
      let trailRoutesPoints = [...item?.trail];
      trailRoutesPoints = trailRoutesPoints.sort((a, b) => {
        const unitTimeA = parseInt(a?.timestamp);
        const unitTimeB = parseInt(b?.timestamp);
        // sort
        return parseInt(unitTimeA - unitTimeB);
      }); 

      coordinatesToPolyline.push(...trailRoutesPoints?.map((x) => { return { lat: x.lat, lng: x?.lon || x?.lng } }));
    }

    //return if no have last positions to draw
    if(coordinatesToPolyline?.length <= 0) return;

    //add the current position into the last element before create the line
    coordinatesToPolyline.push({ lat: item.latitude, lng: item.longitude });

    //8. Draw all polyline.
    //------------------------------------------------------------------------------------
    mapRef.current.addMapObject(new MapObject().buildPolyLine({
      id: `polyline_${item.id}`,
      coordinates: coordinatesToPolyline,
      layerName: TypeLayer.VechileTrails,
      label: 'Polyline Vehicle Trails',
      heading: 0,
      color: validateEventColor(item),
      fillOpacity: 0.6,
      strokeWeight: 8
    }));

  }

  /*************************************************************************************
   * Reproduce the marker animation on map
   * @param {*} item comes whit the object for vehicle trails
   * @param {*} type 
   * @param {*} paramColor 
   * @param {*} isShowNumberOnLabelUnit 
   ************************************************************************************/
  const reproduceStepOnVehicleTrails = (item, isPlayback) => {
    if(!item) return;

    //get the properties for trails from preferences
    let showPolyLine = true, showTrailPointNumber = false
    if(preferences){
      const { trailConnectingLine, trailShowWaypointNumbers } = preferences
      showPolyLine = trailConnectingLine == undefined ? true : trailConnectingLine || false
      showTrailPointNumber = trailShowWaypointNumbers
    }
    
    //make a validations for element
    const position = validateLatLng(item, true);
    if(!position) return;

    //1. Draw marker on the route.
    //--------------------------------------------------------------
    
    const findRoutesPoints = insertUniqueLocations([...reproduceElements], item?.trail || []);
    reproduceElements = Array.from(new Set([...reproduceElements, ...findRoutesPoints]));

    let firstPosition = null, lastItem = null;
    //get the first point on the route. the first position is the last event.
    if(index + 1 > 0 && vehicleTrails?.items?.length > index + 1){
      firstPosition = validateLatLng(vehicleTrails.items[index + 1], true);
      lastItem = vehicleTrails.items[index + 1];
    }else{
      firstPosition = findRoutesPoints.length > 0 ? validateLatLng(findRoutesPoints[0], true) : position;
      lastItem = item;
    }
    reproduceElements.push({ lat: lastItem.latitude, lon: lastItem.longitude, timestamp: convertDateToSeconds(Date.parse(lastItem.unitTime)), index: index + 1 });

    //2. Update the last marker on the map (For camera devices or events)
    //--------------------------------------------------------------
    mapRef?.current.removeMapObject(`marker_${lastItem.id}`, TypeObjectMap.Marker);
    const newMapObjectElement = new MapObject().buildMarker({
      id: `marker_${lastItem.id}`,
      deviceId: lastItem?.deviceId,
      position: {lat: lastItem.latitude, lng: lastItem.longitude},
      layerName: TypeLayer.VechileTrails,
      label: lastItem?.wayPoint ? lastItem?.wayPoint.toString() : '',
      labelDriver: lastItem?.wayPoint ? lastItem?.wayPoint.toString() : '',
      showLabel: showTrailPointNumber,
      heading: lastItem?.heading,
      color: validateEventColor(lastItem),
      eventCode: lastItem?.eventCode,
      eventName: lastItem?.eventName,
      hasVideo: lastItem?.hasVideo || false,
      draggable: lastItem?.draggable || false,
      trailTimestamp: 0,
      isVehicleTrail: true,
    });
    //add the marker into the map
    mapRef?.current.addMapObject(newMapObjectElement);

    
    //Add the marker object into the map, if have showTrailPointNumber in true show the number in label
    const mapObject = new MapObject().buildMarker({
      id: `marker_${item.id}`,
      deviceId: item?.deviceId,
      position : firstPosition,
      layerName: TypeLayer.VechileTrails,
      label: item?.wayPoint ? item?.wayPoint.toString() : '',
      labelDriver: item?.wayPoint ? item?.wayPoint.toString() : '',
      showLabel: showTrailPointNumber,
      heading: lastItem?.heading || item?.heading,
      color: validateEventColor(item),
      eventCode: item?.eventCode,
      eventName: item?.eventName,
      hasVideo: item?.hasVideo || false,
      draggable: item?.draggable || false,
      trailTimestamp: 0,
      isVehicleTrail: true
    })

    //add the marker into the map
    mapRef?.current.addMapObject(mapObject);

    let trailItems = [];
    trailItems.push({
      lat: lastItem?.latitude,
      lon: lastItem?.longitude,
      lng: lastItem?.longitude,
      color:  validateEventColor(item),
      eventName: item?.eventName,
      timestamp: convertDateToSeconds(Date.parse(lastItem.unitTime)),
      mainId: item.id,
      isTrail: true
    });
    //add the last point to trail events.
    if(findRoutesPoints && findRoutesPoints?.length > 0) {
      let trailRoutesPoints = [...findRoutesPoints];
      trailRoutesPoints = trailRoutesPoints.sort((a, b) => {
        const unitTimeA = parseInt(a?.timestamp);
        const unitTimeB = parseInt(b?.timestamp);
        // sort
        return parseInt(unitTimeA - unitTimeB);
      }); 
      //get trail events
      trailItems = [...trailItems, ...trailRoutesPoints].reverse();
    }

    const firstEvent = {      
      ...item,
      trail: trailItems,
      position: position,
      lat: position.lat,
      lng: position.lng,
      id: `marker_${item.id}`,
      trailTimestamp: 0,
      unitTime: convertDateToSeconds(Date.parse(item.unitTime)),
      isVehicleTrail: true,
      trailPlaybackSpeed: speedPlayback
    }
    
    //3. Start the animation for the marker
    //--------------------------------------------------------------
    if(isPlayback){
      mapRef?.current.updateObject(firstEvent, TypeObjectMap.Marker);
    }else{
      //set the marker to the last position
      mapRef?.current.updateObject({ ...firstEvent, trail: []} , TypeObjectMap.Marker);
    }

    //4. Draw the polyline
    //--------------------------------------------------------------
    if(!showPolyLine){
      return;
    }

    let drawPointsToPolyline = [];
    if(isPlayback){
      drawPointsToPolyline = trailItems?.length > 1 ? [] : [{ lat: lastItem?.latitude, lng: lastItem?.longitude }, { lat: item.latitude, lng: item.longitude }]
    } else{
      //draw all lines to the polygon
      drawPointsToPolyline.push({ lat: lastItem.latitude, lng: lastItem.longitude });
      if(item?.trail?.length > 0){ //get the trails points is the item have this
        let trailRoutesPoints = [...item?.trail];
        trailRoutesPoints = trailRoutesPoints.sort((a, b) => {
          const unitTimeA = parseInt(a?.timestamp);
          const unitTimeB = parseInt(b?.timestamp);
          // sort
          return parseInt(unitTimeA - unitTimeB);
        }); 

        drawPointsToPolyline.push(...trailRoutesPoints.map((x) => { return { lat: x.lat, lng: x?.lon || x?.lng } }));
      }
      drawPointsToPolyline.push({ lat: item.latitude, lng: item.longitude });
    }
    
    //add the Polyline  object
    mapRef.current.addMapObject(new MapObject().buildPolyLine({
      //change the id to not draw element for animation function in google provider
      id: trailItems?.length > 1 ? `polyline_${item.id}` : `polyline_complete_${item.id}`,
      //for the data no have trail draw the start and end point (sent 2 points, starting and end)
      coordinates: drawPointsToPolyline,
      layerName: TypeLayer.VechileTrails,
      label: 'Polyline Vehicle Trails',
      heading: 0,
      color: validateEventColor(item),
      fillOpacity: 0.6,
      strokeWeight: 8
    }));

    //5. Draw the Waypoint info in marker (Label Object)
    //--------------------------------------------------------------
    if(showTrailPointNumber && lastItem && item){
      const objectLabel = new MapObject().buildLabel({
          id: `label_marker_${item?.id}`,
          label: lastItem?.wayPoint ? lastItem.wayPoint.toString() : '',
          position: {lat: lastItem?.latitude, lng: lastItem?.longitude},
          layerName: TypeLayer.VechileTrails
      })

      //add the object into the map
      mapRef?.current.addMapObject(objectLabel)
    }

    //6. Center de element on the netxt position
    //--------------------------------------------------------------
    //mapRef.current?.centerMapAnimated(position.lat, position.lng)
  }

  /************************************************************************************
   * Inserts unique objects into the target array based on lat, lon, and timestamp.
   * If an object with the same values already exists, it will not be added.
   *
   * @param {Array} targetArray - The array where new unique values will be inserted.
   * @param {Array} newValues - The array containing new objects to insert.
   * @returns {Array} The updated target array with unique values.
   *************************************************************************************/
  function insertUniqueLocations(targetArray = [], newValues) {
      // Create a Set to track existing lat, lon, and timestamp combinations
      const existingEntries = new Set(
          targetArray.map(({ lat, lon, timestamp }) => `${lat},${lon},${timestamp}`)
      );

      // filter through new values and insert only unique ones
      const newUniqueValues = newValues.filter(({ lat, lon, timestamp }) => {
        const key = `${lat},${lon},${timestamp}`;
        if (!existingEntries.has(key)) {
            existingEntries.add(key);
            return true; // Include into the response
        }
          return false; // skip if is exist.
      });

      return newUniqueValues?.length > 0 ? newUniqueValues.map((x)=> { return { ...x, index } }) : newUniqueValues;
  }

  /*********************************************************
  * Save the event after 2 seconds start at the last event make 
  * from the user UI
  *********************************************************/
  const stopPlayBack = () => {
    clearInterval(timerCountTrail)
    timerCountTrail = null
    setPlay(false)

    //stop all animations
    mapRef?.current?.focusStopTrailMarkersAnimations();
    //update the markers to the last position
    mapRef?.current?.focusStartTrailMarkerAnimations();
  }

  /*********************************************************
  * Event Pause or Stop from the UI buttons
  *********************************************************/
  const onPause = () => {
    stopPlayBack();
    onPreviousElement();
  }

  /*********************************************************
  * Change the reproduce speed, is saved into preference
  *********************************************************/
  const onChangeSpeed = (speedValue) => {
    
    if(index == 0){
      startingIndex()
    }
    speedPlayback = speedValue;

    const nameObject = vehicleTrails?.items[index]?.id;
    const animationMarker = mapRef.current.getObject(TypeObjectMap.Marker, `marker_${nameObject}`);

    if(animationMarker){
      //modify the speed value for the unit
      animationMarker.trailPlaybackSpeed = speedValue;
    }

    const markerWithAnimations = mapRef?.current?.focusStopTrailMarkersAnimations()
    //update the markers to the last position
    mapRef?.current?.focusStartTrailMarkerAnimations();

    if(markerWithAnimations && markerWithAnimations.length > 0){
      //draw all before polyline
      onPreviousElement();
    }
    //save preferences for speed
    if(timerSavePrefence){
      clearInterval(timerSavePrefence)
    }
    timerSavePrefence = setInterval(() => { onEndSavePrefenceTrails() }, 1000);

  }

  /*********************************************************
  * Save the preference value and end the interval
  *********************************************************/
  const onEndSavePrefenceTrails = () => {
    clearInterval(timerSavePrefence)
    timerSavePrefence = null;
    const newPreferences = { ...preferences || {}, trailPlaybackSpeed: speedPlayback };
    dispatch(savePreference({ entityName: "User", entityId: authenticatedId, preferenceKey: keyPreferences, preferenceValue: newPreferences })); 
  }

  /*********************************************************
  * Set the starting index
  *********************************************************/
  const startingIndex = () => {
    index = rowsCount < rowsPerPage ? rowsCount - 1 : rowsPerPage - 1
    index = index || 99
  }

  return(
    <div className={'mapPagination'}>
      <Pagination
        count={rowsCount}
        page={page}
        rowsPerPage={rowsPerPage}
        messages={messages}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        valuesPerPage={[100,200,300,500]}
        classTotal={'mapPagination-classtotal'}
        onlyLine
        hideAllInPagination
        sizeChildren={3}
      >
        {
          (!loadVehicleTrails && vehicleTrails && vehicleTrails?.items?.length > 0)
          &&
          (
            <PlaybackBar 
              speeds ={[1, 5, 10, 20]}
              speedPlayback={speedPlayback}
              play={play}
              onPlay={onPlay}
              onPause={()=>{
                onPause()
              }}
              onPrevious={onPrevious}
              onNext={onNext}
              onChangeSpeed={onChangeSpeed}
              setPlay={(value) => setPlay(value)}
            />
          )
        }
        
      </Pagination>
    </div>
  )

}

export default MapPagination;