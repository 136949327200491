import React, { forwardRef, useEffect, useRef, useState } from "react";
import Datepicker, { CalendarContainer } from 'react-datepicker';
import { useIntl } from "react-intl";
import moment from "moment";
import { Icon } from '@mui/material'
import cx from "classnames";


import AreaMenu, { Area, Menu } from 'Components/AreaMenu';

import '../style.scss'


const DatePickerComponent = (props) => {
    const { id, value, format = 'MMMM DD, yyyy', showButtons = false, onChange, iconName = 'calendar_month', maxDate, disabledDates = [], disabled, inline, className} = props
    const [dateValue, setDateValue] = useState(value && moment(value) || moment())
    const areaMenuRef = useRef()
    const { messages, locale } = useIntl()

    useEffect(() => {
        setDateValue(value && moment(value).locale(locale) || moment().locale(locale))
    }, [value, locale])

    return (
        <div className="datepicker">
            <AreaMenu id={id || "datepicker"} ref={areaMenuRef}>
                <Area>
                    <input className="datepicker_input input-normal" value={dateValue?.format(format) || ''} onChange={() => { }} />
                    <Icon className="datepicker_icon">{iconName}</Icon>
                </Area>
                <Menu className={cx("datepicker_container",className)}>
                    <Datepicker
                        selected={dateValue.toDate()}
                        onChange={(newDate) => {
                            setDateValue(moment(newDate))
                            if (!showButtons) {
                                onChange && onChange(newDate, moment(newDate))
                                areaMenuRef?.current?.toggle(false)
                            }
                        }}
                        disabled={disabled}
                        inline={inline}
                        maxDate={maxDate}
                        includeDates={disabledDates.length > 0 && disabledDates}
                        locale={locale}
                    />
                    {
                        showButtons &&
                        <div className="datepicker_buttons_container">

                            <button className="btn btn-cancel btn-datepicker" onClick={() => {
                                areaMenuRef?.current?.toggle(false)
                                setDateValue(value && moment(value) || moment())
                            }}>
                                {messages["cancel"] || "Cancel"}
                            </button>

                            <button className="btn btn-blue btn-datepicker" onClick={() => {
                                areaMenuRef?.current?.toggle(false)
                                onChange && onChange(dateValue.toDate(), dateValue)
                            }}>
                                {messages["apply"] || "Apply"}
                            </button>
                        </div>
                    }
                </Menu>
            </AreaMenu>
        </div>
    )
}

export default DatePickerComponent