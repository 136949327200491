// dependencies
import React, {useEffect, useRef, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Grid} from '@mui/material';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
// controls
import InfiniteScrollTable from 'Modules/reports/views/main/components/reportsTab/.components/infiniteScrollTable'

import AddressLandmark from 'Modules/reports/views/main/components/reportsTab/.components/addressLandmark';
// hooks
import {useReportsSelector, useSidePanelMap} from 'Core/hooks';
import {GetFormatForMoment} from 'Core/data/Helpers';

// helpers
//helpers
import {
    formatDateFromFilter,
    formatDatetime,
    getNameUnitDriverForSubtables,
    getNameUnitDriverTag,
    isKPH,
    postedSpeedColor,
    useHandleExportReport,
    getGroupId, getMethodToFormatDuration
} from 'Modules/reports/views/main/components/reportsTab/helper';

// actions
import {reportsMainGenerateReportUpdate} from 'Redux/actions'

// styles
import './simpleReport.scss';
import EventReports from "Modules/reports/views/main/components/reportsTab/.components/eventReport";
import moment from "moment/moment";
import {getAbbvTimezone} from "Components/uiControls/Timezone/tools";
import { isKPHAcronym, isKPHAcronymSpeed } from '../../../helper';

//constants
const ROW_PER_PAGE = 25;

const SimpleReport = (props) => {
    const {messages, report, user, copyReportToSetup, openReportSetup, scheduleReport, isActive} = props;
    const { durationFormat } = user;
    const dispatch = useDispatch();
    const [lastEntity, setLastEntity] = useState({});
    const {loading, asyncLoading, data, filters, timeZone} = report
    const {filterEntity, filterArray, filterType} = filters;
    const exportHandler = useHandleExportReport(report)

    const [SideMapComponent, onToggleSideMap] = useSidePanelMap();

    const momentFormat = GetFormatForMoment(user.dateformat)

    const startDayFilter = formatDateFromFilter(report.filters.startRange, timeZone)
    const endDayFilter = formatDateFromFilter(report.filters.endRange, timeZone)

    const reportTableSelect = useReportsSelector(report, startDayFilter, endDayFilter, `MMMM DD, YYYY hh:mm A`, filterArray, filterType, timeZone)

    const reportRef = useRef();
    const entitiesArrayRef = useRef();
    const detailReportTableRef = useRef();
    const lastEntityRef = useRef({});

    const unitDriverSelected = reportTableSelect.valueSelecteEntity?.length > 0 ? reportTableSelect.valueSelecteEntity : null;
    const dateSelected = reportTableSelect.dateSelected?.length > 0 ? reportTableSelect.dateSelected : null;

    useEffect(() => {
        if (report) {
            const newReport = JSON.parse(JSON.stringify(report));
            // to keep the reference to the units selected
            reportRef.current = newReport;
        }
    }, [JSON.stringify(report)]);

    useEffect(() => {
        if(isActive){
            reportTableSelect.onChangeResetCache();
        }
    }, [isActive])

    const durationFormatTime = getMethodToFormatDuration(durationFormat);
    const buildTableHeader = () => {
        return (
            <Grid container item direction={"row"} className={"table-scroll-reports-title-details"}>
                <Grid item sm={1.5} className={'reports-cut-text'}>
                    {getNameUnitDriverForSubtables(filterEntity, messages)}
                </Grid>
                <Grid item sm={1.5} className={'reports-cut-text'}>
                    {messages['generatedReport_time']}
                </Grid>
                <Grid item sm={3} className={'reports-cut-text'}>
                    {messages["generatedReport_address"]}
                </Grid>
                <Grid item sm={2} className={'reports-cut-text'}>
                    {messages['infoBubble_distance']}
                </Grid>
                <Grid item sm={1} className={'reports-cut-text'}>
                    {messages['infoBubble_speed']}
                </Grid>
                <Grid item sm={1} className={'reports-cut-text'}>
                    {messages['generatedReport_posted']}
                </Grid>
                <Grid item sm={2} className={'reports-cut-text'}>
                    {messages['generatedReport_event']}
                </Grid>
            </Grid>
        )
    }

    const TableViewGroup = ({index, style, content, id, previousItem}) => {
        const element = content?.recordInfo;
        const recordInfo = content?.recordInfo;
        return (
            <div
                className="listItem groupItem"
                style={style}
                id={id}
            >

                <Grid container direction={"column"} alignItems={"flex-start"} className='table-scroll-reports-group' style={{padding: "13px 0"}}>
                    <Grid container item className='table-scroll-reports-header'>
                        <Grid item sm={3}>

                            {
                                element?.tagId > 0 ?
                                    (<LocalOfferIcon
                                        style={{fontSize: "16.8px", color: `#${element.groupTagColor && element.groupTagColor != "ffffff" ? element.groupTagColor : "000"}`, position: 'absolute'}}/>)
                                    : ""
                            }
                            <span className={`color-text-black ${element?.tagId > 0 ? 'report-home-header-padding':''}`}>{`${getNameUnitDriverTag(recordInfo?.groupLabel, messages)} `}</span>
                            {
                                report?.filters?.filterArray.length !== 1 && (<span  className={'color-text-black report-home-header-padding-text'}>{` (${element?.tagId > 0 ? `${getNameUnitDriverTag(filterEntity, messages)} ` : ''}${recordInfo?.groupIndex} ${messages['OF']} ${recordInfo?.groupTotal})`}</span>)
                            }
                        </Grid>
                        <Grid item sm={9}>
                            <span className={'color-text-black'}>{formatDatetime(report.filters.startRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} - {formatDatetime(report.filters.endRange, "MMMM D, YYYY h:mm A", timeZone).toUpperCase()} ({getAbbvTimezone(timeZone)})</span>
                        </Grid>
                    </Grid>
                    <div className='table-scroll-reports-separator'></div>
                    <Grid item>
                        <p className='table-scroll-reports-label'>{element?.entityLabel}</p>
                    </Grid>
                </Grid>
                {buildTableHeader()}
            </div>
        )
    }

    const TableViewDetail = ({index, style, content, id, previousItem}) => {

        const element = content?.recordDetail;
        let speedLimit = Number(element.speedLimit) ? Math.round(+element.speedLimit) : '0';

        return (
            <div
                className="listItem detailItem"
                style={style}
                id={id}
            >
                <Grid container direction={"column"} style={{margin: "12px 0"}} className={'simple-detail'}>
                    {
                        !element.noData ? (
                            <Grid container item direction={"row"} style={{padding: "2px 0"}}>
                            <Grid item className={"report-ctn-text"} sm={1.5} style={{paddingLeft: "1.2rem"}}>
                                {filterEntity === "Unit" ? element?.driverName : element?.deviceLabel}
                            </Grid>
                            <Grid item className={"report-ctn-text"} sm={1.5}>
                                {element.unitTime && formatDatetime(element.unitTime, `h:mm A ${momentFormat || "MMMM D, YYYY"}`, timeZone)}
                            </Grid>
                            <Grid item className={"report-ctn-text"} sm={3}>
                                <AddressLandmark onToggleSideMap={onToggleSideMap} messages={messages} element={element} iconStyle={{fontSize: 18}} classNameButtonAddress={'report-address-button'}/>
                            </Grid>

                            <Grid item className={"report-ctn-text"} sm={2}>
                                {`${element.distance} ${messages[isKPHAcronym(element.isKPH)]}`}
                            </Grid>
                            <Grid item className={"report-ctn-text"} sm={1}>
                                <div className={`simple-report-speed`} style={{color: postedSpeedColor(element.speed, speedLimit)}}>
                                    <b>
                                        <span>
                                            {Math.round(+element.speed) || "0"}
                                        </span>
                                        {isKPHAcronymSpeed(element.isKPH)} (e)
                                    </b>
                                </div>
                            </Grid>
                                <Grid item className={"report-ctn-text"} sm={1}>
                                    <div>
                                        {
                                            speedLimit === '0' ?
                                                <span></span> :
                                                <span className="detailed-report-speed-limit-delta">{speedLimit}</span>
                                        }
                                    </div>
                                </Grid>
                                <Grid item className={"report-ctn-text"} sm={2}>
                                    <div>
                                    <span className="simple-report-stacked" style={{fontWeight: "bold"}}>
                                        <EventReports element={{event: element.eventName || ''}}/>
                                        {element.temperature || ''}
                                    </span>
                                </div>
                            </Grid>
                        </Grid>
                        ) : (
                            <span>{messages['reportsMainRT_noDataEvent']}</span>
                        )
                    }
                </Grid>
            </div>
        )
    }

    const renderItems = (parameters) => {
        const {content} = parameters;
        const type = content.recordInfo?.recordType;
        if (type === "GROUP") {
            const groupId = getGroupId(content)
            const entitiesArray = entitiesArrayRef.current || {};
            if(!entitiesArray[groupId]){
                const newEntityArray = {...entitiesArray};
                newEntityArray[groupId] = content;
                entitiesArrayRef.current = newEntityArray
            }

            // skip the first group beacuse the first group is replaced with the sticky header
            if(parameters.index === 0){
                return <></>;
            }
            return (
                <TableViewGroup {...parameters}></TableViewGroup>
            )
        } else if (type === "DETAIL") {
            return <TableViewDetail {...parameters}></TableViewDetail>;
        }
        ;

        return (
            <div>
                unknown type
            </div>
        )
    }

    const renderHeader = () => {
        const groupId = getGroupId(lastEntity);
        const entitiesArray = entitiesArrayRef.current || {};
        const itemHeader = entitiesArray[groupId];

        if(itemHeader){
            return (
            <TableViewGroup
                style={{
                backgroundColor: '#fff',
                position: 'sticky',
                top: 0,
                zIndex: 2

                }}
                content={itemHeader}
                id={itemHeader?.recordInfo?.entityId}
            ></TableViewGroup>
            );
        }
        return;
    };

    const onStartIndex = (index) => {
        const item = data?.items[index];
        if(item) {
            if(item.recordInfo?.entityId !== lastEntityRef.current.recordInfo?.entityId || item.recordInfo?.tagId !== lastEntityRef.current.recordInfo?.tagId){
                setLastEntity(item)
            }
            lastEntityRef.current = item
        }
    }
    const findTag = filterArray?.findIndex((item) => item.name == "Tag")
    const hasTag = findTag >= 0;
    const type = hasTag ? messages['generatedReport_tag'] :
        (report?.filters?.filterType === "deviceId" ? messages['generatedReport_unit'] : messages['generatedReport_driver']);
    return (
        <>
            <InfiniteScrollTable
                refContainer={detailReportTableRef}
                renderItems={renderItems}
                loading={asyncLoading || loading}
                handleNewPageLoad={(page) => {
                    let currentReport = {...report};
                    currentReport.exportType = "json";
                    currentReport.filters = {
                        ...currentReport.filters,
                        unitsSelected: unitDriverSelected?.id != -99 ? unitDriverSelected : null,
                        accumulateData: true,
                        dateSelected: dateSelected?.id != -99 ? unitDriverSelected : null,
                    };
                    dispatch(reportsMainGenerateReportUpdate(currentReport, page, ROW_PER_PAGE))
                }}
                data={data.items || []}
                total={data.total}
                reportTitle={messages['reportsMainRT_simpleReport']}
                editReport={() => {
                    copyReportToSetup({...report, data: []})
                }}
                onSchedule={() => {
                    scheduleReport(report)
                }}
                onCreate={openReportSetup}
                tableContainerClass={"simple-report-reports-container simple-report"}
                type={type}
                renderHeader={renderHeader}
                onStartIndex={onStartIndex}
                isActive={isActive}
                {...reportTableSelect}
                {...exportHandler}
            />
            <SideMapComponent hiddenButton hiddenSearchMap overlayClass={"overlay-class-reports"}/>
        </>
    );
};


export default SimpleReport;
