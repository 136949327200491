import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { clientMutation, clientQuery } from '../../../../../../../core/data/GraphQLApi';
import { endpoints } from '../../../../../../../core/defaultValues';
import { resetALL } from '../../../../../../../.globals/redux/actions';

import { checkDeviceData, GetFormatForMoment, validateJsonObject, getFormattedDateByTimeZone } from '../../../../../../../core/data/Helpers'
import { LogManagerConsole, ExceptionManager } from '../../../../../../../core/logManager'

//STATE INITIAL
const INIT_STATE = {
    loading: false,
    data: null,
    loadingNotes: false,
    loadingSaveNotes: false,
    notes: [],
    result: null,
    error: null,
    nextPreviousEventsIds: null,
    refreshVideo: false
};

const route = 'modules/video/views/videoDetail/components/media/redux/slice'

export const getMediaDetail = createAsyncThunk('video/detail/media/get', async ({ id, deviceId, messages }, { rejectWithValue, dispatch, getState }) => {
    try {

        const state = getState()
        const user = state.securityRedux.user
        const result = await clientQuery(`
                query ($readingId: Float!, $deviceId: String!) {
                    data: getAvailableMedia (readingId: $readingId, deviceId: $deviceId) {
                        reading {
                            id
                            deviceId
                            latitude
                            longitude
                            heading
                            eventCode
                            eventName
                            address
                            unitTime
                            unitType
                            driverName
                            mediaDeleted
                            starred
                            speed
                            ecuSpeed
                            deviceData
                        }
                        unit {
                            id
                            label
                            timeZone {
                                name
                            }
                            tags {
                                id
                                label
                                color
                                hasChildren
                            }
                            useEcuSpeed
                            driver {
                                id
                                supervisor {
                                    firstName
                                    lastName
                                }
                            }
                        }
                        linkedDevice {
                            id
                            esn
                            manufacturerName
                        }
                        reviewStatus {
                            jsonValue
                        }
                    }
                }`,
            {
                readingId: id,
                deviceId
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        let data
        LogManagerConsole("VIDEO", "getMediaDetail", result)
        if (result?.data) {
            const { reading, linkedDevice, unit, reviewStatus: reviewStatusJSON } = result?.data
            let deviceData = reading && checkDeviceData(reading.deviceData);
            let reviewStatus = reviewStatusJSON && JSON.parse(JSON.parse(reviewStatusJSON.jsonValue))

            let videoOptions = {
                mode: "picture",
                id: deviceData?.pictureID,
                pictureId: deviceData?.pictureID,
            };

            if (deviceData.clipID) {
                if (deviceData.duration) {
                    videoOptions.mode = "video";
                }
                videoOptions.id = deviceData?.clipID;
                videoOptions.pictureId = null;
            }

            if (deviceData.url) {
                videoOptions.url = deviceData?.url;
            }

            videoOptions = {
                ...videoOptions,
                messages: messages,
                type: "playback",
                autoplay: true,
                thumbnailUrl: deviceData.duration === 0
                    ? deviceData.url
                    : !deviceData?.clipID && !deviceData?.thumbnailUrl
                        ? `/assets/images/clipRequestFailed.png`
                        : deviceData?.thumbnailUrl,
                deviceModel: linkedDevice?.deviceModelName === "360" ? "360" : "4K",
            };

            let speed = reading?.speed;
            if (reading?.useEcuSpeed) {
                speed = reading?.ecuSpeed;
            }

            if (speed) speed = parseFloat(speed);
            if (isNaN(speed)) speed = 0;

            let address = validateJsonObject(reading?.address);

            data = {
                eventName: reading?.eventName,
                eventCode: reading?.eventCode,
                latitude: reading?.latitude,
                longitude: reading?.longitude,
                heading: reading?.heading,
                unitType: reading?.unitType,
                unitTime: getFormattedDateByTimeZone(
                    reading?.unitTime,
                    unit?.timeZone?.name,
                    messages,
                    `h:mm:ss A, ${GetFormatForMoment(user.dateformat)}`
                ),
                address: address?.formatted || address,
                label: unit?.label,
                driverName: reading?.driverName,
                speed: speed || 0,
                //reviewNotes: notes,
                starred: reading?.starred ? true : false,
                isDeleted: reading?.mediaDeleted,
                reviewStatus: reviewStatus?.status || "Unreviewed",
                deviceData: reading,
                type: linkedDevice?.manufacturerName,
                mediaDeletedByUsername: deviceData?.mediaDeletedByUsername,
                videoOptions,

                tags: unit?.tags,
                useEcuSpeed: unit?.useEcuSpeed,

                supervisor:
                    unit?.driver?.supervisor?.firstName &&
                        unit?.driver?.supervisor?.lastName
                        ? `${unit.driver.supervisor.firstName} ${unit.driver.supervisor.lastName}`
                        : messages["videoMainView_unassigned"],
            }

            dispatch(getMediaDetailNotes({ readingId: `${reading.deviceId}-${reading.id}` }))

            // let newFormData = { ...formData };

            // if (deviceData?.videoReview) {
            //     newFormData = {
            //         ...newFormData,
            //         status: deviceData?.videoReview?.status || "Unreviewed",
            //         notes: "",
            //     };
            // } else {
            //     newFormData = { ...newFormData, status: "Unreviewed", notes: "" };
            // }


        }

        return data
    } catch (exc) {
        ExceptionManager(exc, route, 'getMediaDetail');
        return rejectWithValue(exc)
    }
});

export const getMediaDetailNotes = createAsyncThunk('video/detail/media/getNotes', async ({ readingId }, { rejectWithValue, dispatch, getState }) => {
    try {
        LogManagerConsole("VIDEO", "getMediaDetailNotes", readingId)
        const state = getState()
        const user = state.securityRedux.user
        const result = await clientQuery(`
            query SearchDeviceReadingExtraFields($conditions: [DeviceReadingExtraFieldSearchInput], $sort: [DeviceReadingExtraFieldSortInput], $limit: Int, $offset: Int) {
                data: searchDeviceReadingExtraFields(conditions: $conditions, sort: $sort, limit: $limit, offset: $offset) {
                    total
                    items {
                        id
                        fieldName
                        jsonValue
                        visibleToDriver
                        createdOn
                        createdBy
                        updatedOn
                        updatedBy
                    }
                }
            }
            `,
            {
                "conditions": [
                    {
                        "terms": [readingId],
                        "fields": ["readingId"],
                        "exact": true
                    },
                    {
                        "terms": ["notes", "videoReview"],
                        "fields": ["fieldName"],
                        "exact": true
                    }
                ],
                "sort": { "field": "createdOn", "order": "DESC" },
                "limit": 100,
                "offset": 0
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        LogManagerConsole("VIDEO", "getMediaDetailNotes", result?.data?.items)

        return result?.data?.items?.map((item) => {
            LogManagerConsole("VIDEO", "getMediaDetailNotes", item, item.jsonValue)

            return {
                ...item,
                jsonValue: JSON.parse(JSON.parse(item.jsonValue))
            }
        })
    } catch (exc) {
        ExceptionManager(exc, route, 'getMediaDetailNotes');
        return rejectWithValue(exc)
    }
});

export const saveNote = createAsyncThunk('video/detail/media/saveNote', async ({ id, rowId, deviceId, jsonValue, visibleToDriver }, { rejectWithValue, dispatch, getState }) => {
    try {
        LogManagerConsole("VIDEO", "saveNote", id, rowId, deviceId, jsonValue, visibleToDriver)
        let result
        if (id) {
            result = await clientMutation(`
                mutation UpdateDeviceReadingExtraField($id: Int!, $rowId: String, $deviceId: String, $fieldName: String, $jsonValue: String, $visibleToDriver: Boolean) {
                    data: updateDeviceReadingExtraField(id: $id, rowId: $rowId, deviceId: $deviceId, fieldName: $fieldName, jsonValue: $jsonValue, visibleToDriver: $visibleToDriver) {
                        id
                        readingId
                    }
                }
            `,
                {
                    id,
                    jsonValue: JSON.stringify(jsonValue),
                    visibleToDriver: visibleToDriver == 1 ? true : false
                },
                endpoints.GRAPHQL_GENERAL_V2
            )
        }
        else {
            result = await clientMutation(`
                mutation CreateDeviceReadingExtraField($rowId: String!, $deviceId: String!, $fieldName: String!, $jsonValue: String!, $visibleToDriver: Boolean) {
                    data:createDeviceReadingExtraField(rowId: $rowId, deviceId: $deviceId, fieldName: $fieldName, jsonValue: $jsonValue, visibleToDriver: $visibleToDriver) {
                        id
                        readingId
                    }
                }
            `,
                {
                    rowId,
                    deviceId,
                    fieldName: 'notes',
                    jsonValue: JSON.stringify(jsonValue),
                    visibleToDriver: visibleToDriver == 1 ? true : false
                },
                endpoints.GRAPHQL_GENERAL_V2
            )
        }

        LogManagerConsole("VIDEO", "saveNote", result)

        if (!result?.data?.readingId)
            throw 'error'

        dispatch(getMediaDetailNotes({ readingId: result.data.readingId }))
        return { id: result.data.id, action: 'updateNote' }
    } catch (exc) {
        ExceptionManager(exc, route, 'saveNote');
        return rejectWithValue(exc)
    }
});

export const saveVideoReview = createAsyncThunk('video/detail/media/saveVideoReview', async ({ rowId, deviceId, status, notes, visibleToDriver }, { rejectWithValue, dispatch, getState }) => {
    try {
        LogManagerConsole("VIDEO", "saveVideoReview", rowId, deviceId, status, notes, visibleToDriver)
        let result = await clientMutation(`
                mutation UpdateVideoReviewStatus($ids: [ReadingIdInput]!, $reviewStatus: enumVideoReviewStatus!, $notes: String, $visibleToDriver: Boolean) {
                    data: updateVideoReviewStatus(ids: $ids, reviewStatus: $reviewStatus, notes: $notes, visibleToDriver: $visibleToDriver)
                }
            `,
            {
                ids: [{ deviceId, rowId }],
                reviewStatus: status,
                notes,
                visibleToDriver
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        LogManagerConsole("VIDEO", "saveVideoReview", result)

        if (!result?.data)
            throw 'error'

        dispatch(getMediaDetailNotes({ readingId: `${deviceId}-${rowId}` }))

        return { id: result.data, action: 'videoReview', reviewStatus: status }
    } catch (exc) {
        ExceptionManager(exc, route, 'saveVideoReview');
        return rejectWithValue(exc)
    }
});

export const getNextPreviousIds = createAsyncThunk('video/detail/media/getNextPreviousIds', async (payload, { rejectWithValue, dispatch }) => {

    const { filters, rowId, deviceId, action, navigate } = payload;
    LogManagerConsole("VIDEO", "getMediaDetail", payload)
    const entities =
        !filters?.entities || !filters?.entities?.length ?
            [{ name: 'All', id: -1 }] :
            filters?.entities?.map(filter => {
                return { id: filter?.id, label: filter?.label, name: filter?.name }
            })

    try {
        const result = await clientQuery(`
            query getNextPreviousEventsIds( $filters: NextPreviousEventBody, $readingId: Float! ) {
                data: getNextPreviousEventsIds( filters: $filters, readingId: $readingId ) {
                    previousEventId
                    nextEventId
                }
            }`,
            {
                filters: {
                    openSearch: filters?.openSearch,
                    dateRange: filters?.dateRange,
                    entities: entities,
                    mediaDeleted: filters?.mediaDeleted || false,
                    mediaType: filters?.mediaType || "All",
                    reviewStatus: (!filters?.reviewStatus || filters?.reviewStatus === "All" ? null : filters?.reviewStatus),
                    starred: filters?.starred || false,
                    eventNames: filters?.events?.length ? filters?.events : [],
                    hasNotes: filters?.hasNotes || false,
                },
                readingId: rowId
            },
            endpoints.GRAPHQL_GENERAL_V2
        )

        if (result?.data) {
            LogManagerConsole("VIDEO", "getMediaDetail", result?.data)
            let values = [deviceId, rowId]
            if (action == "next") {
                values = result.data.nextEventId?.split('-')
            }
            else if (action == "previous") {
                values = result.data.previousEventId?.split('-')
            }

            if (values.length < 2) throw 'error'

            dispatch(reset())
            navigate(`/app/video/videoDetail/media/${values[1]}?deviceId=${values[0]}`)
        }

        return result?.getNextPreviousEventsIds || {};
    } catch (exc) {
        return rejectWithValue({ exc, nextPreviousVideo: true })
    }
});

//Slice
const videoDetailMediaRedux = createSlice({
    name: 'video/detail/media',
    initialState: INIT_STATE,
    reducers: {
        reset(state, action) {
            return action.payload ? { ...state, ...action.payload } : { ...INIT_STATE }
        },
        refreshPageVideo(state, action) {
            return {
                ...state,
                refreshVideo: { ...action.payload }
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(resetALL, () => INIT_STATE);

        builder.addCase(getMediaDetail.pending, (state, action) => {
            state.loading = true
            state.error = null
        });
        builder.addCase(getMediaDetail.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload
        });
        builder.addCase(getMediaDetail.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

        builder.addCase(getMediaDetailNotes.pending, (state, action) => {
            state.loadingNotes = true
            state.error = null
        });
        builder.addCase(getMediaDetailNotes.fulfilled, (state, action) => {
            state.loadingNotes = false;
            state.notes = action.payload || []
        });
        builder.addCase(getMediaDetailNotes.rejected, (state, action) => {
            state.loadingNotes = false;
            state.error = action.payload;
        });

        builder.addCase(saveNote.pending, (state, action) => {
            state.loadingSaveNotes = true
            state.error = null
        });
        builder.addCase(saveNote.fulfilled, (state, action) => {
            state.loadingSaveNotes = false;
            state.result = action.payload
        });
        builder.addCase(saveNote.rejected, (state, action) => {
            state.loadingSaveNotes = false;
            state.error = action.payload;
        });

        //saveVideoReview
        builder.addCase(saveVideoReview.pending, (state, action) => {
            state.loadingNotes = true
            state.error = null
        });
        builder.addCase(saveVideoReview.fulfilled, (state, action) => {
            state.loadingNotes = false;
            state.result = action.payload
            state.data.reviewStatus = action.payload.reviewStatus
        });
        builder.addCase(saveVideoReview.rejected, (state, action) => {
            state.loadingNotes = false;
            state.error = action.payload;
        });

        builder.addCase(getNextPreviousIds.pending, (state, action) => {
            state.loading = true
            state.error = null
        });
        builder.addCase(getNextPreviousIds.fulfilled, (state, action) => {
            state.loading = false;
            // state.nextPreviousEventsIds = action.payload
        });
        builder.addCase(getNextPreviousIds.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });

    },
})

export const { actions, reducer } = videoDetailMediaRedux;
// Extract and export each action creator by name
export const { reset, refreshPageVideo } = actions;
export default reducer;