import {
  DatePicker,
  Icons,
  Input,
  Select,
} from "@gpstrackit/gtc-react-controls";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
// import Map from "Components/Map";
import { incidentGetLastReading } from "Redux/actions";
import { Skeleton } from "@mui/material";
import {
  getMethodToFormatDuration
} from 'Modules/reports/views/main/components/reportsTab/helper';
import moment from "moment";
import Map from "./map";
import { speedFormatMPH } from "Core/data/Helpers";


const Step1 = ({ formData, setFormData, errors, setErrors }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.securityRedux);
  const { durationFormat, isKph } = user;
  const units = useSelector((state) => state.catalogsRedux.units);
  const { lastReading, loadingLastReading } = useSelector((state) => state.incidentsRedux);

  const [lastTimeReading, setLastTimeReading] = useState(0)
  const [coords, setCoords] = useState({lat: 0, lng: 0})
  const [speedLabel, setSpeedLabel] = useState(0)

  useEffect(() => {
    if (
      !loadingLastReading &&
      lastReading?.latitude &&
      lastReading?.longitude
    ) {
      setCoords({
        lat: lastReading?.latitude,
        lng: lastReading?.longitude
      })

      const newSpeed = lastReading?.useEcuSpeed ? (lastReading?.ecuSpeed ? lastReading?.ecuSpeed : lastReading?.speed) : lastReading?.speed;
      const speedFormat = speedFormatMPH(newSpeed, lastReading?.type, isKph);
      setSpeedLabel(speedFormat)

      const unixTime = Number(lastReading?.unitTime)
      const duration = moment().diff(moment(unixTime), 's');
      const durationFormatTime = getMethodToFormatDuration(durationFormat);
      const durationResult = duration ? durationFormatTime(duration, messages): '0 min, 0 sec'
      setLastTimeReading(durationResult)
    }
  }, [lastReading, loadingLastReading]);

  return (
    <div style={{ padding: "15px", textAlign: 'start', height: 'auto' }}>
      <p style={{ textAlign: "left", margin: '5px' }}>{messages["incidents_message"]}</p>
      <p style={{ marginBottom: "4px" }}>
        {messages["incident_name"]} <i style={{ color: "red" }}>*</i>{" "}
      </p>
      <Input
        placeholder={messages["incident_name"]}
        value={formData?.name}
        maxLength={30}
        onChange={(e) => {
          setFormData({ ...formData, name: e })
          setErrors({...errors, name: ''})
        }}
      />
      {errors?.name?.length > 0 && (
        <p style={{ marginTop: "2px", color: "red" }}>
          {messages[errors?.name]}
        </p>
      )}
      <p style={{ marginBottom: "4px" }}>
        {messages["incident_estimate_start"]}
      </p>
      <DatePicker
        value={formData?.date}
        maxDate={new Date()}
        calendarPosition="bottom"
        onChange={(newDate) => {
          setFormData({ ...formData, date: newDate })
          setErrors({...errors, date: ''})
        }}
        showButtons
        showTimePicker
      />
      {errors?.date?.length > 0 && (
        <p style={{ marginTop: "2px", color: "red" }}>
          {messages[errors?.date]}
        </p>
      )}
      <h3>{messages["incident_vehicle_info"]}</h3>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Icons
          color="#ff0000"
          style={{ fontSize: "20px", marginRight: "5px", color: "red" }}
          name="fmd_good"
        ></Icons>
        <p style={{ margin: 0 }}>
          {messages["incident_choose_vehicle"]}{" "}
          <i style={{ color: "red" }}>*</i>
        </p>
      </div>
      <Select
        style={{ marginTop: "4px" }}
        name="incident_vehicle"
        placeholder={messages["incident_choose_vehicle"]}
        isMulti={false}
        isClearable={true}
        isSearchable={true}
        showSelection={false}
        isDisabled={formData?.id ? true : false}
        textField="label"
        valueField="id"
        options={units}
        value={formData?.unitId}
        onChange={(item) => {
          setFormData({ ...formData, unitId: item });
          if (!item?.id) return;
          dispatch(incidentGetLastReading({ id: item?.id }));
          setErrors({...errors, unitId: 0})
        }}
      />
      {errors?.unitId?.length > 0 && (
        <p style={{ marginTop: "2px", color: "red" }}>
          {messages[errors?.unitId]}
        </p>
      )}
      <div className="incidents_maps">
        <Map coords={coords} zoom={15}/>
      </div>
      <div className="incidents_last_reading">
        <div>
          <div>
            <p>{messages["incident_last_event"]}</p>
            {loadingLastReading ? (
              <Skeleton
                variant="text"
                sx={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  width: "200px",
                  marginTop: "5px",
                }}
              />
            ) : lastReading && lastReading?.eventName ? (
              <p style={{ marginLeft: "5px" }}>{lastReading?.eventName}, {lastTimeReading}</p>
            ) : (
              <p style={{ marginLeft: "5px" }}>...</p>
            )}
          </div>
          <div>
            <p>{messages["incident_speed"]}</p>
            {loadingLastReading ? (
              <Skeleton
                variant="text"
                sx={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  width: "200px",
                  marginTop: "5px",
                }}
              />
            ) : lastReading && lastReading?.speed ? (
              <p style={{ marginLeft: "5px" }}>{speedLabel}</p>
            ) : (
              <p style={{ marginLeft: "5px" }}>...</p>
            )}
          </div>
          <div>
            <p>{messages["incident_address"]}</p>
            {loadingLastReading ? (
              <Skeleton
                variant="text"
                sx={{
                  marginLeft: "5px",
                  fontSize: "14px",
                  width: "200px",
                  marginTop: "5px",
                }}
              />
            ) : lastReading && lastReading?.address?.formatted ? (
              <p style={{ marginLeft: "5px" }}>
                {lastReading?.address?.formatted}
              </p>
            ) : (
              <p style={{ marginLeft: "5px" }}>...</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
