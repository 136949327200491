import React, { forwardRef, useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { endpoints } from "Core/defaultValues";

//Utils
import { getFormattedDateByTimeZone } from "Core/data/Helpers";
// Styles
import "./vidFleet.scss";

// Components
import { VideoPlayer, Image360, LinearProgress } from "Components";
import { Icon, IconButton } from "@mui/material";
import { ModalImg } from "./.components/modalImg";
import { DEVICEMODEL_ENUM, MODE_ENUM, TYPE_ENUM } from "./constants";
import { useIntl } from "react-intl";
import { useVidFleetVideo } from "./useVidFleetVideo";

export const RenderThumbnail = ({
  deviceModel,
  dewrapImage,
  thumbnail,
  id,
  pictureId,
  isUpsidedown,
}) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      {deviceModel === "360" && dewrapImage ? (
        <Image360
          imageUrl={thumbnail}
          id={id || pictureId}
          isUpsidedown={isUpsidedown}
        />
      ) : (
        thumbnail && (
          <img src={thumbnail} style={{ width: "100%", height: "100%" }} />
        )
      )}
    </div>
  );
};

export const Streaming = ({
  isUpsidedown,
  videoPlayerRef,
  options,
  response,
  timeZone,
  type
}) => {
  const { messages } = useIntl();

  return (
    <>
      {type === TYPE_ENUM.streaming ||
      type === TYPE_ENUM.playback ||
      type === TYPE_ENUM.clipStreaming ? (
        <VideoPlayer
          isUpsidedown={isUpsidedown}
          ref={videoPlayerRef}
          {...options}
        />
      ) : (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <img src={url} style={{ width: "100%", height: "100%" }} />
          {response?.time && (
            <div style={{ position: "absolute", bottom: 8, right: 8 }}>
              <span style={{ color: "white", fontSize: 14 }}>
                {getFormattedDateByTimeZone(
                  response?.time,
                  timeZone,
                  messages,
                  "HH:mm:ss"
                )}
              </span>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const Video = ({thumbnailUrl}) => {
  return (
    <video
      controls
      style={{
        width: "96%",
        marginLeft: "2%",
        height: "100%",
        position: "relative",
      }}
    >
      <source
        src={`${endpoints.SMARTWITNESS_API_URL}${thumbnailUrl}`}
        type="video/mp4"
      />
    </video>
  );
};

export const PlayButton = ({
  thumbnail,
  onClickPlay,
  startStopLiveStreaming,
  setPlayingVideo,
  setShowPlayButton,
  disabled,
  response,
  type
}) => {
  return (
    <div className="vidFleet-play-button">
      <IconButton
        aria-label="play"
        style={{ color: thumbnail ? "black" : "white" }}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          onClickPlay && onClickPlay();
          if (type === TYPE_ENUM.streaming || type === TYPE_ENUM.photo) {
            startStopLiveStreaming("start");
          } else if (type === TYPE_ENUM.playback) {
            setPlayingVideo(true);
            setShowPlayButton(false);
          }
        }}
        size="large"
      >
        <Icon
          className={"icon-vidFleet-play-replay"}
          style={{
            fontSize: 50,
            color: disabled ? "rgba(255, 255, 255, 0.3)" : "white",
          }}
        >
          {response && response && response.error ? "replay" : "play_arrow"}
        </Icon>
      </IconButton>
    </div>
  );
};

export const SearchButton = ({thumbnail, disabled, setOpenImg}) => {
  return (
    <div className="vidFleet-play-button">
      <IconButton
        aria-label="search"
        style={{ color: thumbnail ? "black" : "white" }}
        disabled={disabled}
        onClick={(e) => {
          e.preventDefault();
          setOpenImg(true);
        }}
        size="large"
      >
        <Icon
          className={"icon-vidFleet-play-replay"}
          style={{
            fontSize: 50,
            color: disabled ? "rgba(255, 255, 255, 0.3)" : "white",
          }}
        >
          search
        </Icon>
      </IconButton>
    </div>
  );
};

//This component uses Video.js library, for more information please visit the Video.js documentation
//and search about the player options
const VidFleetVideoComponent = forwardRef((props, ref) => {
  const {
    disabled,
    deviceModel,
    dewrapImage,
    thumbnail,
    id,
    pictureId,
    isUpsidedown,
    videoPlayerRef,
    options,
    response,
    timeZone,
    thumbnailUrl,
    onClickPlay,
    startStopLiveStreaming,
    setPlayingVideo,
    setShowPlayButton,
    setOpenImg,
    playingVideo,
    showPlayButton,
    showLoading,
    message,
    openImg,
    loadingCameraStatus,
    loadingPicture,
    loading,
    mode,
    type,
    url
  } = useVidFleetVideo(ref, props);

  return (
    <div
      className="vidFleet-container"
      style={{ backgroundColor: disabled ? "white" : "black" }}
    >
      {mode === MODE_ENUM.picture && (
        <RenderThumbnail
          deviceModel={deviceModel}
          dewrapImage={dewrapImage}
          thumbnail={thumbnail}
          id={id}
          pictureId={pictureId}
          isUpsidedown={isUpsidedown}
        />
      )}

      {mode === MODE_ENUM.video &&
        deviceModel !== DEVICEMODEL_ENUM.SmartWitness && (
          <>
            {playingVideo && (
              <Streaming
                videoPlayerRef={videoPlayerRef}
                options={options}
                response={response}
                timeZone={timeZone}
                type={type}
              />
            )}

            {thumbnail && !playingVideo && (
              <RenderThumbnail
                deviceModel={deviceModel}
                dewrapImage={dewrapImage}
                thumbnail={thumbnail}
                id={id}
                pictureId={pictureId}
                isUpsidedown={isUpsidedown}
              />
            )}
          </>
        )}
      {mode === MODE_ENUM.video &&
        deviceModel === DEVICEMODEL_ENUM.SmartWitness && (
          <Video thumbnailUrl={thumbnailUrl} />
        )}
      <div className="vidFleet-actions-container">
        {(loading || showLoading || loadingPicture || loadingCameraStatus) && (
          <LinearProgress />
        )}

        <div className="vidFleet-message">{message}</div>

        {showPlayButton &&
        !loading &&
        !showLoading &&
        !loadingPicture &&
        !loadingCameraStatus &&
        deviceModel !== DEVICEMODEL_ENUM.SmartWitness ? (
          <PlayButton
            thumbnail={thumbnail}
            onClickPlay={onClickPlay}
            startStopLiveStreaming={startStopLiveStreaming}
            setPlayingVideo={setPlayingVideo}
            setShowPlayButton={setShowPlayButton}
            disabled={disabled}
            response={response}
            type={type}
          />
        ) : (
          (mode === MODE_ENUM.picture || type === TYPE_ENUM.photo) &&
          !loading &&
          !showLoading &&
          !loadingPicture &&
          !loadingCameraStatus && (
            <SearchButton
              thumbnail={thumbnail}
              disabled={disabled}
              setOpenImg={setOpenImg}
            />
          )
        )}
      </div>
      <ModalImg
        setOpenImg={setOpenImg}
        openImg={openImg}
        img={thumbnailUrl || thumbnail || url}
      />
    </div>
  );
});

export default VidFleetVideoComponent;

VidFleetVideoComponent.propTypes = {
  id: PropTypes.string, //This could be a ESN or Clip Id
  autoplay: PropTypes.bool, //If is true the video will be played automatically
  type: PropTypes.string.isRequired, //Depends of the playing type, the url VidFleet API changes
  type: PropTypes.oneOf(["streaming", "playback", "photo", "clip-streaming"]),
  deviceModel: PropTypes.string.isRequired, //4k|360
  deviceModel: PropTypes.oneOf(["4K", "4k", "360"]),
  mode: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["video", "picture"]), // This component could be use just for show images and dewrapping 360 images.
  thumbnailUrl: PropTypes.string, //An url for the thumbnail image of the video
  pictureId: PropTypes.string,
  disabled: PropTypes.bool, //If is true the user can not play the video
};
