import { Badge, Icon, IconButton } from "@mui/material";
import React, { useState, useRef, useEffect } from "react";
import { useIntl } from "react-intl";
import { AreaMenu, Tooltip } from "Components";
import { Area, Menu } from "Components/AreaMenu";
import { useDispatch, useSelector } from "react-redux";
import { clearRedux } from "Redux/actions";
import { searchIncidents } from "Redux/actions";
import {
  Alert,
  EndIncident,
  IncidentsList
} from "Modules/incidents/views/overview";
import { permissionUnit } from 'Modules/incidents/.globals/utils'
import { reset } from "Modules/incidents/views/overview/redux/slice";
import moment from "moment";
import "../header.scss";
import { NotificationManager } from 'Components/Notifications';


const INIT_FILTER = {
  conditions: [
    {
      fields: "ended",
      terms: ["0"],
      exact: true,
    },
    {
      fields: "isDeleted",
      terms: ["0"],
      exact: false,
    }
  ],
  sort: [
    {
      field: "id",
      order: "DESC",
    },
  ],
  limit: 100,
  offset: 0,
};

export const IncidentButton = ({ user }) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const areaMenuRef = useRef();
  const listInnerRef = useRef();

  const notificationAlertList = useSelector((state) => state.catalogsRedux.notificationAlertList);
  const notificationAlert = useSelector((state) => state.catalogsRedux.notificationAlert);
  const endIncidentNotification = useSelector((state) => state.catalogsRedux.endIncidentNotification);
  const { 
    incidents: originalIncidents, 
    loadingIncidents, 
    totalIncidents} = useSelector(
    (state) => state.incidentsRedux
  );
  const unitsList = useSelector(state => state.catalogsRedux.unitsList);


  const [tabActive, setTabActive] = useState(0);
  const [openDelete, setOpenDelete] = useState({
    open: false,
    deleteItem: null,
  });
  const [animeButton, setAnimeButton] = useState(false);
  const [openSearch, setOpenSearch] = useState("");
  const [openAlert, setOpenAlert] = useState(false);
  const [filters, setFilters] = useState(INIT_FILTER);
  const [initialLoading, setInitialLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [incidents, setIncidents] = useState([])

  useEffect(() => {
    getAlertIncident();
    const checkIntervalStorage = () => {
      const items = localStorage.getItem("incidents");
      if(!items) return
      const jsonParse = JSON.parse(items);
      const count = jsonParse.count
      const lastIncidentDate = jsonParse?.date
        ? moment(jsonParse?.date)
        : moment();
      const diff = moment().diff(lastIncidentDate, "seconds");
      const path = window.location.pathname.split("/")[2];

      if (diff > 0 && diff <= 300 && count > 0) {
        setAnimeButton(true);
      } else {
        setAnimeButton(false);
      }

      if (diff > 0 && diff <= 3600 && path === "realtimemaps") {
        setOpenAlert(true);
      } else {
        setOpenAlert(false);
      }
    };
    checkIntervalStorage();
    const intervalId = setInterval(checkIntervalStorage, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if(endIncidentNotification?.length === 0) return
    const getUnitPermission = permissionUnit(endIncidentNotification, unitsList)
    if(getUnitPermission?.length === 0) return
    let namesIncidentsToEnd = ''
    getUnitPermission?.map(item => {
      namesIncidentsToEnd = namesIncidentsToEnd + `, ${item?.name}`
    })
    const showMessage = `${messages["incident_message_end_by_cron"]}: ${namesIncidentsToEnd}`
    NotificationManager.warning(
      showMessage,
      messages["warning"],
      5000,
      null,
      null,
      "info"
    )
    dispatch(
      searchIncidents(filters)
    );
    dispatch(clearRedux("CATALOG_REDUX", { endIncidentNotification: [] }));
  }, [endIncidentNotification])

  useEffect(() => {
    dispatch(
      searchIncidents(filters)
    );
  }, [filters])

  useEffect(() => {
    if (!loadingIncidents && unitsList.length > 0) {
      const pureData = permissionUnit(originalIncidents, unitsList)

      if(pureData?.length > 0){
        setAnimeButton(true)
      }else{
        setAnimeButton(false)
      }

      localStorage.setItem(
        "incidents",
        JSON.stringify({
          count: pureData?.length,
          date: moment(),
        })
      );

      setInitialLoading(false);
      setIncidents(pureData)
    }
  }, [originalIncidents, loadingIncidents, unitsList]);

  useEffect(() => {
    setFilters({
      ...filters,
      offset: page * filters?.limit,
      research: page === 0 ? true : false,
      currentData: originalIncidents,
    })
  }, [page]);

  useEffect(() => {
    if (notificationAlert > 0 && notificationAlertList?.length > 0) {
      const validIncidentsActive = permissionUnit(notificationAlertList, unitsList)
      if(validIncidentsActive?.length === 0) return 
      dispatch(
        searchIncidents({
          ...filters,
        })
      );
      saveAlertIncident();
      dispatch(clearRedux("CATALOG_REDUX", { notificationAlertList: [] }));
    }
  }, [notificationAlert, notificationAlertList]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (tabActive === 0) {
        setFilters(INIT_FILTER)
      } else {
        dispatch(reset({
          incidents: [], 
          totalIncidents: 0
        }))
        setInitialLoading(true)
        listInnerRef.current.scrollTop = 0;
        setPage(0);
        setFilters({
          ...filters,
          conditions: [
            {
              range: {
                GTE: "2030-01-01T00:00:00.683Z",
                LTE: "2020-01-01T00:00:00.683Z",
              },
            },
            {
              fields: "isDeleted",
              terms: ["1"],
              exact: false,
            },
            {
              fields: "name",
              terms: [openSearch],
              exact: false,
            },
            {
              fields: "label",
              terms: [openSearch],
              exact: false,
            },
          ],
        })
      }
    }, 1000);
    return () => {
      clearTimeout(handler);
    };
  }, [openSearch]);

  useEffect(() => {
    dispatch(reset({
      incidents: [], 
      totalIncidents: 0
    }))
    setInitialLoading(true)
    listInnerRef.current.scrollTop = 0;
    let newFilters = {}
    if (tabActive === 0) {
      newFilters = INIT_FILTER;
    } else if (tabActive === 1) {
      newFilters = {
        ...filters,
        conditions: [
          {
            range: {
              GTE: "2030-01-01T00:00:00.683Z",
              LTE: "2020-01-01T00:00:00.683Z",
            },
          },
          {
            fields: "isDeleted",
            terms: ["0"],
            exact: false,
          },
          {
            fields: "ended",
            terms: ["1"],
            exact: true,
          },
          {
            fields: "name",
            terms: [openSearch],
            exact: false,
          },
        ],
      };
    }
    if(page === 0){
      newFilters = {
        ...newFilters,
        offset: page * filters?.limit,
        research: page === 0 ? true : false,
        currentData: originalIncidents,
      }
    }else{
      setPage(0);
    }

    setFilters(newFilters)
  }, [tabActive]);

  const saveAlertIncident = () => {
    localStorage.setItem(
      "incidents",
      JSON.stringify({
        count: incidents?.length,
        date: moment(),
      })
    );
  };

  const getAlertIncident = () => {
    const items = localStorage.getItem("incidents");
    const jsonParse = JSON.parse(items);
    return jsonParse?.count ? Number(jsonParse?.count) : 0;
  };

  const getColorIcon = () => {
    if (incidents?.length === 0) return "gray";
    return getAlertIncident() > 0 ? "red" : "";
  };

  const clearAlertIncient = () => {
    localStorage.setItem("incidents", 0);
  };

  return (
    <div className="header-action">
      <AreaMenu
        id="areaUser"
        noHideOnBlur={true}
        ref={areaMenuRef}
        noCloseAway={true}
      >
        <Area>
          <Tooltip title={messages["header_incidentIcon"]}>
            <IconButton
              aria-label="placeholder"
              style={{ fontSize: 25, color: "#1D2734" }}
              size="large"
              onClick={() => {
                dispatch(clearRedux("CATALOG_REDUX", { notificationAlert: 0 }));
                // clearAlertIncient();
              }}
            >
              <Badge color="error" badgeContent={getAlertIncident()}>
                <Icon
                  className={animeButton ? "flash-incident-button" : ''}
                  style={{ color: getColorIcon() }}
                >
                  error
                </Icon>
              </Badge>
            </IconButton>
          </Tooltip>
        </Area>
        <Menu className="menu-option">
          <IncidentsList
            closeList={() => areaMenuRef?.current?.toggle(false)}
            incidents={incidents}
            loadingIncidents={loadingIncidents}
            tabActive={tabActive}
            setTabActive={setTabActive}
            openSearch={openSearch}
            setOpenSearch={setOpenSearch}
            openDelete={openDelete}
            setOpenDelete={setOpenDelete}
            filters={filters}
            setFilters={setFilters}
            initialLoading={initialLoading}
            page={page}
            setPage={setPage}
            totalIncidents={totalIncidents}
            listInnerRef={listInnerRef}
          />
        </Menu>
      </AreaMenu>
      <Alert
        open={openAlert && getAlertIncident() > 0 ? true : false}
        onClick={() => {
          areaMenuRef?.current?.toggle(true);
          dispatch(clearRedux("CATALOG_REDUX", { notificationAlert: 0 }));
          // clearAlertIncient();
        }}
        handleClose={() => {}}
      />
      <EndIncident
        item={openDelete?.deleteItem}
        openDelete={openDelete?.open}
        setDeleteOpen={() =>
          setOpenDelete({ ...openDelete, open: false })
        }
      />
    </div>
  );
};
