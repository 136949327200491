import React from 'react';
import cx from 'classnames';
import { Icon, Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Tooltip } from 'Components';
// Styles
import './checkbox.scss';

const Check = (props) => {
	const {
		id,
		size,
		value = false,
		onChange,
		label,
		checkedIcon,
		unCheckedIcon,
		required,
		disabled,
		infoText,
		style,
		indeterminate = false
	} = props;

	let checked = value
	if (!checked || checked == undefined)
		checked = false

	return (
		<FormControlLabel
			className={props.className || ""}
			style={style || {}}
			control={
				<Checkbox
					id={id}
					className={cx(`${disabled ? "" : "check-color"} ${size && 'checkbox-' + size}`, props.className)}
					checked={value || false}
					indeterminate={indeterminate || false}
					onChange={(event) => {
						if (onChange) onChange(event.target.checked);
					}}
					name={id}
					checkedIcon={checkedIcon}
					icon={unCheckedIcon}
					disabled={disabled}
				/>
			}
			label={(
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Grid item className='checkbox-label-mandatory'>
						<h3 className={`${size && 'label-' + size} ${props.classNameLabel}`} style={{margin: 0, fontWeight: 'normal'}}>{label}</h3>
						{required && (
							<span className="text-danger checkbox-label-space"> *</span>
						)}
					</Grid>
					<Grid item>
						{infoText && (
							<Tooltip title={infoText} placement={'top'}>
								<Icon style={{ fontSize: 18, marginTop: 8, marginLeft: 3 }}>help_outline</Icon>
							</Tooltip>
						)}
					</Grid>
				</Grid>
			)}
		/>
	);
};

export default Check;