import React from 'react';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import './dragDropList.scss';

const DragDropList = (props) => {
  const { items, keyName, itemRenderer, onDragEnd, style, className, direction, isDragDisabled } = props;

  return (
    <div style={style || {}} className={className || ""}>
      <DragDropContext onDragEnd={onDragEnd} alwaysRenderSuggestions={true}>
        <Droppable
          droppableId="droppable"
          direction={direction || 'vertical'}
          renderClone={(provided, snapshot, rubric) => (
            <div
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              {
                itemRenderer && itemRenderer(items[rubric.source.index]) || items[rubric.source.index][keyName]
              }
            </div>
          )}
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`drag-drop-list-container ${snapshot.isDraggingOver ? 'dragged' : 'dragging'}`}
            >
              {
                items && items.map((item, index) => (
                  <Draggable
                    key={`${item[keyName]}`}
                    draggableId={`${item[keyName]}`}
                    index={index}
                    isDragDisabled={isDragDisabled}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {
                          itemRenderer && itemRenderer(item) || item[keyName]
                        }
                      </div>
                    )}
                  </Draggable>
                ))
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DragDropList;