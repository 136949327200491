import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Material UI
import { Grid } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// Cloud React Components
import { TagsGroup } from 'Components/Tag';
import { Tooltip, Button } from 'Components'
// Utils
import {
  formatOffsetDisplayOdometer,
  formatOffsetDisplayEngineHours,
  copyToClipboard,
  cutNotes
} from 'Core/data/Helpers';
// Core
import { HasPermission } from "Core/security"

import LinearProgress from '@mui/material/LinearProgress';
import { checkDeviceData } from "../../../../../core/data/Helpers";

// Actions
import {
  dtcGetDeviceDTC,
  centerOnUnit
} from 'Redux/actions';
// Global styles
import styles from 'Assets/sass/core/vars.scss';
// Styles
import '../infoBubble.scss';
import '../../../../../components/Map/providers/google/InfoWindow/style.scss'
import OdometerView, { getOdometerValue } from 'Modules/.common/components/OdometerView';

const Location = (props) => {
  const dispatch = useDispatch();
  const { type, data, messages } = props;

  const [infoData, setInfoData] = useState({});

  const { followUnit, centerUnitId } = useSelector(state => state.realtimeMapRedux);
  const user = useSelector(state => state.securityRedux.user);


  

  useEffect(() => {
    if (data) {
      formatData();
    }
  }, [data]);

  const formatData = useCallback(() => {
    let newInfoData = {};
    let deviceData = null;

    let vehicle = {
      useEcuOdometer: data?.markerDetail?.unit?.useEcuOdometer,
      useEcuEngineHours: data?.markerDetail?.unit?.useEcuEngineHours,
      offsetEcuEngineOdometer: data?.markerDetail?.unit?.offsetEcuEngineOdometer,
      offsetEcuEngineHours: data?.markerDetail?.unit?.offsetEcuEngineHours,
      offsetEngineHours: data?.markerDetail?.unit?.offsetEngineHours,
      offsetEngineOdometer: data?.markerDetail?.unit?.offsetEngineOdometer
    };

    if (type === "Vehicle") {
      deviceData = data?.markerDetail?.unit?.lastReading?.deviceData;
      vehicle = {
        ...data?.markerDetail?.unit?.lastReading,
        ...vehicle
      };
    } else if (type === "Trail") {
      deviceData = data?.markerDetail?.vehicleTrail?.deviceData;
      vehicle = {
        ...data?.markerDetail?.vehicleTrail,
        ...vehicle
      };
    }

    let engineHours = formatOffsetDisplayEngineHours(vehicle);

    //get data for fuel percentage
    let fuelPercentage = null;
    if (deviceData && HasPermission(user, "pFuelLev", [291])) {
      let deviceDataJson = checkDeviceData(deviceData);      
      fuelPercentage = deviceDataJson?.ecuData?.fuelPercentage || null;
    }

    newInfoData = {
      odometer: getOdometerValue(vehicle, user?.isKph),
      engineHours: getFormattedField(engineHours, 'H'),
      useEcuOdometer: data?.markerDetail?.unit?.useEcuOdometer,
      useEcuEngineHours: data?.markerDetail?.unit?.useEcuEngineHours,
      fuelPercentage: fuelPercentage != null && !isNaN(fuelPercentage) ? parseInt(fuelPercentage) : null 
    }

    setInfoData(newInfoData);
  });

  const getFormattedField = useCallback((field, unit) => {
    if (field) {
      return `${field} ${unit}`;
    }

    return 'N/A';
  });

  const getModel = useCallback(() => {
    let info = [];
    if (data?.markerDetail?.unit?.vehicleInformation?.year && data?.markerDetail?.unit?.vehicleInformation?.year !== "true") {
      info.push(data?.markerDetail?.unit?.vehicleInformation?.year);
    }

    if (data?.markerDetail?.unit?.vehicleInformation?.make) {
      info.push(data?.markerDetail?.unit?.vehicleInformation?.make);
    }

    if (data?.markerDetail?.unit?.vehicleInformation?.model) {
      info.push(data?.markerDetail?.unit?.vehicleInformation?.model);
    }

    if (!info.length) {
      return 'N/A';
    }

    return `${info.join('/')}`;
  });

  /********************************************************************************
   * Function to show the percentage of the fuel
   * @param {*} props 
   * @returns 
   ********************************************************************************/
  const LinearProgressWithLabel = (props) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: '100%', marginRight: '8px' }}>
          <LinearProgress 
            style={{ backgroundColor: percentageToShowColor(props?.value)?.secondary }} 
            sx={{ '& .MuiLinearProgress-bar': { backgroundColor: percentageToShowColor(props?.value)?.main }}} 
            variant="determinate" 
            {...props} 
          />
        </div>
        <div style={{ minWidth: 35 }}>
          <label style={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)', fontWeight: 400 }}>
            {`${Math.round(props?.value)}%`}
          </label>
        </div>
      </div>
    );
  }

  /********************************************************************************
   * Function to show the color of the percentage
   * @param {number} value
   * @returns {object}
   ********************************************************************************/
  const percentageToShowColor = (value) => {
    if (value <= 15) {
      return { main: 'red', secondary: '#ffcccc' };
    } else if (value > 15 && value <= 30) {
      return { main: '#ffcc00', secondary: '#fbffcc' };
    } else {    
      return  { main: '#48bf65', secondary: '#ccffd7' };
    }
  }

  return (
    <div className='info-window-tab'>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={1}
        style={{
          padding: '16px 45px 16px 16px'
        }}
      >
        <Grid item xs>
          <h2 style={{ margin: 0 }}>
            {messages['infoBubble_vehicleInfo']}
          </h2>
        </Grid>

        <Grid item xs>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
          >
            <Grid item>
              <span style={{
                backgroundColor: '#66bb6a',
                color: 'white',
                padding: '2px 4px',
                textTransform: 'uppercase'
              }}>
                {data?.markerDetail?.unit?.deviceCategory}
              </span>
            </Grid>

            <Grid item xs>
              <TagsGroup
                options={(data && data?.markerDetail && data?.markerDetail?.unit?.tags ? data?.markerDetail?.unit?.tags : [])}
                nameField="label"
                colorField="color"
                alwaysGroup={true}
              />
            </Grid>

            <Grid item>
              <div style={{ cursor: 'pointer' }}>
                {followUnit && data?.markerDetail?.unit?.id == centerUnitId
                  ?
                  <Tooltip title={messages['maputilities_vehiclelist_follow_unit']}>
                    <span>
                      <VisibilityIcon
                        style={{ color: '#00AEEF' }}
                        onClick={() => {
                          dispatch(centerOnUnit(data?.markerDetail?.unit?.id, false));
                        }}
                      />
                    </span>
                  </Tooltip>
                  :
                  <VisibilityOffIcon
                    onClick={() => {
                      dispatch(centerOnUnit(data?.markerDetail?.unit?.id, true))
                    }}
                  />
                }
              </div>
            </Grid>

            {
              (data?.markerDetail?.unit?.jbus == 1 || data?.markerDetail?.unit?.vbus == 1)
                ?
                <Grid item>
                  {
                    (data?.markerDetail?.unit?.dtcCount?.history || data?.markerDetail?.unit?.dtcCount?.active)
                      ?
                      <span
                        className={`info-bubble-badge ${data?.markerDetail?.unit?.dtcCount?.active > 0 ? 'info-bubble-badge-red' : 'info-bubble-badge-black'}`}
                        onClick={() => {
                          dispatch(dtcGetDeviceDTC({
                            device: {
                              id: id,
                              label: data?.markerDetail?.unit?.lastReading?.deviceLabel || data?.markerDetail?.unit?.label
                            },
                            history: (data?.markerDetail?.unit?.dtcCount?.active > 0 ? false : true)
                          }));
                        }}
                      >
                        {messages['infoBubble_dtc']}
                      </span>
                      :
                      <span className={`info-bubble-badge info-bubble-badge-disabled`}>
                        {messages['infoBubble_dtc']}
                      </span>
                  }
                </Grid>
                :
                <Grid item></Grid>
            }
          </Grid>
        </Grid>

      </Grid>

      <div style={{ borderTop: 'solid 1px #e4e4e4' }}></div>

      <div>
        <table className="info-bubble-table">
          <tbody>
            <tr>
              <td>
                {messages['infoBubble_vehicleName']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {data?.markerDetail?.unit?.label || data?.markerDetail?.unit?.lastReading?.deviceLabel || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(data?.markerDetail?.unit?.label || data?.markerDetail?.unit?.lastReading?.deviceLabel || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_odometer']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {
                      (infoData?.useEcuOdometer) &&
                      <span
                        className="ecuChip"
                        style={{ background: styles.ecu }}
                      >
                        ECU
                      </span>
                    }
                    {infoData?.odometer || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(infoData?.odometer || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_engineHours']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {
                      (infoData?.useEcuEngineHours) &&
                      <span
                        className="ecuChip"
                        style={{ background: styles.ecu }}
                      >
                        ECU
                      </span>
                    }
                    {infoData?.engineHours || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(infoData?.engineHours || "N/A")}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_yearMakeModel']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {getModel()}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(getModel())}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_vin']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {data?.markerDetail?.unit?.vehicleInformation?.vin === 'true' ? 'N/A' : (data?.markerDetail?.unit?.vehicleInformation?.vin || "N/A")}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(`${data?.markerDetail?.unit?.vehicleInformation?.vin === 'true' ? 'N/A' : (data?.markerDetail?.unit?.vehicleInformation?.vin || "N/A")}`)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            <tr>
              <td>
                {messages['infoBubble_licensePlate']}
              </td>
              <td>
                <div className="hover-container">
                  <span style={{ paddingRight: 8 }}>
                    {data?.markerDetail?.unit?.vehicleInformation?.licensePlate || "N/A"}
                  </span>
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(`${data?.markerDetail?.unit?.vehicleInformation?.licensePlate ? data?.markerDetail?.unit?.vehicleInformation?.licensePlate : 'N/A'}`)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>

            {
              infoData?.fuelPercentage != null
              &&
              <tr>
                <td>
                  {messages['infoBubble_fuel']}
                </td>
                <td>
                  <div className="hover-container" style={{ width: '60%' }}>
                    <LinearProgressWithLabel value={infoData?.fuelPercentage} />
                  </div>
                </td>
              </tr>
            }

            <tr>
              <td>
                {messages['infoBubble_notes']}
              </td>
              <td>
                <div className="hover-container">
                  {
                    data?.markerDetail?.unit?.notes?.length > 52
                      ?
                      <Tooltip title={data?.markerDetail?.unit?.notes}>
                        <span style={{ paddingRight: 8 }}>
                          {cutNotes(data?.markerDetail?.unit?.notes, 52)}
                        </span>
                      </Tooltip>
                      :
                      <span style={{ paddingRight: 8 }}>
                        {data?.markerDetail?.unit?.notes || 'N/A'}
                      </span>
                  }
                  <Button
                    type="icon"
                    icon="content_copy"
                    onClick={() => copyToClipboard(data?.markerDetail?.unit?.notes)}
                    fontSize="inherit"
                    className="btn-no-padding hover-item"
                  />
                </div>
              </td>
            </tr>


          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Location;