import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import TrafficIcon from '@mui/icons-material/Traffic';

import {
	savePreference
} from 'Redux/actions';

const TrafficToggle = (props) => {
    const { onShowTraffic, onHideTraffic, isTrafficEnabled } = props;
    const keyPreferences = "mapSettings";
    const dispatch = useDispatch();
    const { messages } = useIntl();

    const { preferencesUser } = useSelector(state => state.preferencesRedux);
	const { authenticatedId } = useSelector(state => state.securityRedux);

    const [ preferences, setPreferences ] = useState({});

    useEffect(() => {
        if (preferencesUser) {
            let preferencesKeyTemp = preferencesUser.find(x => x.preferenceKey === keyPreferences);
            let preferencesJSON = preferencesKeyTemp && preferencesKeyTemp.preferenceValue != "" && preferencesKeyTemp.preferenceValue || "{}";
            preferencesJSON = JSON.parse(preferencesJSON);

            setPreferences(preferencesJSON);
            if (preferencesJSON?.showTraffic && !isTrafficEnabled) {
                onShowTraffic();
            } else if (!preferencesJSON?.showTraffic && isTrafficEnabled) {
                onHideTraffic();
            }
        }
    }, [preferencesUser]);
    

    const showTraffic = () => {
        if (isTrafficEnabled) {
            onShowTraffic();
        } else {
            onHideTraffic();
        }

        const newPreferences = { ...preferences || {}, showTraffic: !isTrafficEnabled };
        dispatch(savePreference({ entityName: "User", entityId: authenticatedId, preferenceKey: keyPreferences, preferenceValue: newPreferences }));
    }

    return (
        <button
            className={"traffic-control"}
            onClick={showTraffic}
            title="Traffic"
            style={{ color: `${(isTrafficEnabled ? '#00AEEF' : 'rgba(0, 0, 0, 0.6)')}` }}
        >
            {messages['map_traffic']}
            <TrafficIcon className={'icon-traffic-button'} style={{ color: `${(isTrafficEnabled ? '#00AEEF' : '#aeaeae')}` }} />
        </button>
    );

}

export default TrafficToggle