import {
  Button,
  Icons,
  Tooltip,
} from "@gpstrackit/gtc-react-controls";
import moment from "moment";
import React from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { openOverViewUnit } from "Redux/actions";
import { getUnit } from "../../../../.globals/utils";

export const ItemActive = ({
  id,
  unitId,
  name,
  start,
  end,
  shortcode,
  note,
  reading,
  dateFormat,
  setOpenDelete,
  incidentLog,
  closeList,
  assignments,
  config,
  subscribers,
  systemAlertId
}) => {
  const { messages } = useIntl();
  const dispatch = useDispatch();
  const units = useSelector((state) => state.catalogsRedux.units);

  const unit = getUnit(unitId, units);
  let unixTime = Number(reading?.unitTime);

  let formatName = name;
  if (formatName?.length > 15) {
    formatName = <Tooltip title={name}>{name.slice(0, 15) + "..."}</Tooltip>;
  }

  let vehicleFormat = unit?.label;
  if (vehicleFormat?.length > 20) {
    vehicleFormat = (
      <Tooltip title={vehicleFormat}>
        {vehicleFormat.slice(0, 20) + "..."}
      </Tooltip>
    );
  }

  let reportedByFormat = incidentLog?.reportedBy;
  if (vehicleFormat?.length > 25) {
    reportedByFormat = (
      <Tooltip title={incidentLog?.reportedBy}>
        {reportedByFormat.slice(0, 15) + "..."}
      </Tooltip>
    );
  }

  return (
    <div key={id} className="item_incident">
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div className="item_title_incident">
            <Icons
              color="#ff0000"
              style={{ fontSize: "20px", marginRight: "5px", color: "red" }}
              name="error"
            ></Icons>
            <div
              onClick={() => {
                dispatch(
                  openOverViewUnit({
                    data: {
                      incident: {
                        id,
                        unitId,
                        unitLabel: unit?.label,
                        name,
                        start,
                        end,
                        shortcode,
                        note,
                        incidentLog: { reportedBy: incidentLog?.reportedBy },
                        assignments,
                        config,
                        subscribers,
                        systemAlertId
                      },
                      reading,
                      unit,
                    },
                    open: true,
                  })
                );
                closeList && closeList();
              }}
              style={{ margin: 0, fontWeight: "bold", fontSize: "18px", cursor: 'pointer' }}
            >
              {formatName}
            </div>
          </div>
          <Icons
            style={{ fontSize: "20px", marginLeft: "5px", cursor: 'pointer' }}
            name="open_in_new"
            onClick={() => {
              dispatch(
                openOverViewUnit({
                  data: {
                    incident: {
                      id,
                      unitId,
                      name,
                      start,
                      unitLabel: unit?.label,
                      end,
                      shortcode,
                      note,
                      incidentLog: { reportedBy: incidentLog?.reportedBy },
                      assignments,
                      config,
                      subscribers,
                      systemAlertId
                    },
                    reading,
                    unit,
                  },
                  open: true,
                })
              );
              closeList && closeList();
            }}
          ></Icons>
        </div>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_vehicle"]}</p>
        <div style={{margin: '5px 10px 0 5px'}}>{vehicleFormat ? vehicleFormat : "..."}</div>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_stared"]}</p>
        <p>{moment(start).format(dateFormat)}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_last_report"]}</p>
        <p>{reading?.unitTime ? moment(unixTime).format(dateFormat) : "..."}</p>
      </div>
      <div className="item_date_incident">
        <p>{messages["incident_reported_by"]}</p>
        <div style={{margin: '5px 10px 0 5px'}}>{reportedByFormat ? reportedByFormat : "..."}</div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: "-15px",
        }}
      >
        <Button
          size="xsmall"
          style={{ background: "#1d2734", color: "white" }}
          onClick={() =>
            setOpenDelete({
              open: true,
              deleteItem: { id, unitId, name, start, end, shortcode, note },
            })
          }
        >
          {messages["incident_end"]}
        </Button>
      </div>
    </div>
  );
};
